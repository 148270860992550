(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.gw.V === region.eL.V)
	{
		return 'on line ' + region.gw.V;
	}
	return 'on lines ' + region.gw.V + ' through ' + region.eL.V;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fm,
		impl.gV,
		impl.gC,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		bp: func(record.bp),
		bT: record.bT,
		by: record.by
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.bp;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.bT;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.by) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fm,
		impl.gV,
		impl.gC,
		function(sendToApp, initialModel) {
			var view = impl.g_;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fm,
		impl.gV,
		impl.gC,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.bN && impl.bN(sendToApp)
			var view = impl.g_;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.d1);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.dB) && (_VirtualDom_doc.title = title = doc.dB);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.f$;
	var onUrlRequest = impl.f0;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		bN: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.c6 === next.c6
							&& curr.cD === next.cD
							&& curr.c2.a === next.c2.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		fm: function(flags)
		{
			return A3(impl.fm, flags, _Browser_getUrl(), key);
		},
		g_: impl.g_,
		gV: impl.gV,
		gC: impl.gC
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { fe: 'hidden', a1: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { fe: 'mozHidden', a1: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { fe: 'msHidden', a1: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { fe: 'webkitHidden', a1: 'webkitvisibilitychange' }
		: { fe: 'hidden', a1: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		dg: _Browser_getScene(),
		g$: {
			dN: _Browser_window.pageXOffset,
			dO: _Browser_window.pageYOffset,
			g2: _Browser_doc.documentElement.clientWidth,
			fc: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		g2: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		fc: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			dg: {
				g2: node.scrollWidth,
				fc: node.scrollHeight
			},
			g$: {
				dN: node.scrollLeft,
				dO: node.scrollTop,
				g2: node.clientWidth,
				fc: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			dg: _Browser_getScene(),
			g$: {
				dN: x,
				dO: y,
				g2: _Browser_doc.documentElement.clientWidth,
				fc: _Browser_doc.documentElement.clientHeight
			},
			eK: {
				dN: x + rect.left,
				dO: y + rect.top,
				g2: rect.width,
				fc: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.cv.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.cv.b, xhr)); });
		$elm$core$Maybe$isJust(request.dE) && _Http_track(router, xhr, request.dE.a);

		try {
			xhr.open(request.br, request.dJ, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.dJ));
		}

		_Http_configureRequest(xhr, request);

		request.d1.a && xhr.setRequestHeader('Content-Type', request.d1.a);
		xhr.send(request.d1.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.bh; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.bZ.a || 0;
	xhr.responseType = request.cv.d;
	xhr.withCredentials = request.dS;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		dJ: xhr.responseURL,
		dr: xhr.status,
		gx: xhr.statusText,
		bh: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			gr: event.loaded,
			$7: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			gf: event.loaded,
			$7: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}

// BYTES

function _Bytes_width(bytes)
{
	return bytes.byteLength;
}

var _Bytes_getHostEndianness = F2(function(le, be)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(new Uint8Array(new Uint32Array([1]))[0] === 1 ? le : be));
	});
});


// ENCODERS

function _Bytes_encode(encoder)
{
	var mutableBytes = new DataView(new ArrayBuffer($elm$bytes$Bytes$Encode$getWidth(encoder)));
	$elm$bytes$Bytes$Encode$write(encoder)(mutableBytes)(0);
	return mutableBytes;
}


// SIGNED INTEGERS

var _Bytes_write_i8  = F3(function(mb, i, n) { mb.setInt8(i, n); return i + 1; });
var _Bytes_write_i16 = F4(function(mb, i, n, isLE) { mb.setInt16(i, n, isLE); return i + 2; });
var _Bytes_write_i32 = F4(function(mb, i, n, isLE) { mb.setInt32(i, n, isLE); return i + 4; });


// UNSIGNED INTEGERS

var _Bytes_write_u8  = F3(function(mb, i, n) { mb.setUint8(i, n); return i + 1 ;});
var _Bytes_write_u16 = F4(function(mb, i, n, isLE) { mb.setUint16(i, n, isLE); return i + 2; });
var _Bytes_write_u32 = F4(function(mb, i, n, isLE) { mb.setUint32(i, n, isLE); return i + 4; });


// FLOATS

var _Bytes_write_f32 = F4(function(mb, i, n, isLE) { mb.setFloat32(i, n, isLE); return i + 4; });
var _Bytes_write_f64 = F4(function(mb, i, n, isLE) { mb.setFloat64(i, n, isLE); return i + 8; });


// BYTES

var _Bytes_write_bytes = F3(function(mb, offset, bytes)
{
	for (var i = 0, len = bytes.byteLength, limit = len - 4; i <= limit; i += 4)
	{
		mb.setUint32(offset + i, bytes.getUint32(i));
	}
	for (; i < len; i++)
	{
		mb.setUint8(offset + i, bytes.getUint8(i));
	}
	return offset + len;
});


// STRINGS

function _Bytes_getStringWidth(string)
{
	for (var width = 0, i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		width +=
			(code < 0x80) ? 1 :
			(code < 0x800) ? 2 :
			(code < 0xD800 || 0xDBFF < code) ? 3 : (i++, 4);
	}
	return width;
}

var _Bytes_write_string = F3(function(mb, offset, string)
{
	for (var i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		offset +=
			(code < 0x80)
				? (mb.setUint8(offset, code)
				, 1
				)
				:
			(code < 0x800)
				? (mb.setUint16(offset, 0xC080 /* 0b1100000010000000 */
					| (code >>> 6 & 0x1F /* 0b00011111 */) << 8
					| code & 0x3F /* 0b00111111 */)
				, 2
				)
				:
			(code < 0xD800 || 0xDBFF < code)
				? (mb.setUint16(offset, 0xE080 /* 0b1110000010000000 */
					| (code >>> 12 & 0xF /* 0b00001111 */) << 8
					| code >>> 6 & 0x3F /* 0b00111111 */)
				, mb.setUint8(offset + 2, 0x80 /* 0b10000000 */
					| code & 0x3F /* 0b00111111 */)
				, 3
				)
				:
			(code = (code - 0xD800) * 0x400 + string.charCodeAt(++i) - 0xDC00 + 0x10000
			, mb.setUint32(offset, 0xF0808080 /* 0b11110000100000001000000010000000 */
				| (code >>> 18 & 0x7 /* 0b00000111 */) << 24
				| (code >>> 12 & 0x3F /* 0b00111111 */) << 16
				| (code >>> 6 & 0x3F /* 0b00111111 */) << 8
				| code & 0x3F /* 0b00111111 */)
			, 4
			);
	}
	return offset;
});


// DECODER

var _Bytes_decode = F2(function(decoder, bytes)
{
	try {
		return $elm$core$Maybe$Just(A2(decoder, bytes, 0).b);
	} catch(e) {
		return $elm$core$Maybe$Nothing;
	}
});

var _Bytes_read_i8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getInt8(offset)); });
var _Bytes_read_i16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getInt16(offset, isLE)); });
var _Bytes_read_i32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getInt32(offset, isLE)); });
var _Bytes_read_u8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getUint8(offset)); });
var _Bytes_read_u16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getUint16(offset, isLE)); });
var _Bytes_read_u32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getUint32(offset, isLE)); });
var _Bytes_read_f32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getFloat32(offset, isLE)); });
var _Bytes_read_f64 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 8, bytes.getFloat64(offset, isLE)); });

var _Bytes_read_bytes = F3(function(len, bytes, offset)
{
	return _Utils_Tuple2(offset + len, new DataView(bytes.buffer, bytes.byteOffset + offset, len));
});

var _Bytes_read_string = F3(function(len, bytes, offset)
{
	var string = '';
	var end = offset + len;
	for (; offset < end;)
	{
		var byte = bytes.getUint8(offset++);
		string +=
			(byte < 128)
				? String.fromCharCode(byte)
				:
			((byte & 0xE0 /* 0b11100000 */) === 0xC0 /* 0b11000000 */)
				? String.fromCharCode((byte & 0x1F /* 0b00011111 */) << 6 | bytes.getUint8(offset++) & 0x3F /* 0b00111111 */)
				:
			((byte & 0xF0 /* 0b11110000 */) === 0xE0 /* 0b11100000 */)
				? String.fromCharCode(
					(byte & 0xF /* 0b00001111 */) << 12
					| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
					| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
				)
				:
				(byte =
					((byte & 0x7 /* 0b00000111 */) << 18
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 12
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
						| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
					) - 0x10000
				, String.fromCharCode(Math.floor(byte / 0x400) + 0xD800, byte % 0x400 + 0xDC00)
				);
	}
	return _Utils_Tuple2(offset, string);
});

var _Bytes_decodeFailure = F2(function() { throw 0; });




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $author$project$Translation$KeyWithDefault = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Confirm$Blurbs$blurbs = {
	aT: A2($author$project$Translation$KeyWithDefault, 728430, 'Are you sure you want to leave?'),
	aV: A2($author$project$Translation$KeyWithDefault, 728415, 'You have <b>{{remainingCredits}} private lesson(s)</b> remaining'),
	aW: A2($author$project$Translation$KeyWithDefault, 728183, 'Bilingual'),
	aZ: A2($author$project$Translation$KeyWithDefault, 728416, 'Book class'),
	a$: A2($author$project$Translation$KeyWithDefault, 728414, 'Unable to make booking'),
	a0: A2($author$project$Translation$KeyWithDefault, 711122, 'Cancel'),
	a1: A2($author$project$Translation$KeyWithDefault, 728412, 'Change'),
	a3: A2($author$project$Translation$KeyWithDefault, 728431, 'Your class is still not booked.'),
	a5: A2($author$project$Translation$KeyWithDefault, 728397, 'Confirm & book'),
	a7: A2($author$project$Translation$KeyWithDefault, 728433, 'Continue booking'),
	ey: A2($author$project$Translation$KeyWithDefault, 711207, 'Date'),
	bf: A2($author$project$Translation$KeyWithDefault, 728349, 'Error!'),
	bF: A2($author$project$Translation$KeyWithDefault, 728417, 'Please review & confirm the details of your selected class.'),
	gq: A2($author$project$Translation$KeyWithDefault, 708472, 'Send me the class recording'),
	bW: A2($author$project$Translation$KeyWithDefault, 685505, 'Terms and Conditions'),
	bX: A2($author$project$Translation$KeyWithDefault, 708943, '<p>No content</p>'),
	bY: A2($author$project$Translation$KeyWithDefault, 683217, 'By clicking the button above, you agree to the ^termsAndConditions^ of the Private Class services.'),
	gM: A2($author$project$Translation$KeyWithDefault, 717381, 'Time'),
	b5: A2($author$project$Translation$KeyWithDefault, 708473, 'A video recording of this class will be available from your Class Feedback & Review page within 24 hours of class completion.'),
	b8: A2($author$project$Translation$KeyWithDefault, 728432, 'Yes, leave'),
	b9: A2($author$project$Translation$KeyWithDefault, 728413, 'Your teacher')
};
var $author$project$Confirm$Blurbs$allBlurbs = _List_fromArray(
	[$author$project$Confirm$Blurbs$blurbs.a5, $author$project$Confirm$Blurbs$blurbs.bF, $author$project$Confirm$Blurbs$blurbs.aZ, $author$project$Confirm$Blurbs$blurbs.bf, $author$project$Confirm$Blurbs$blurbs.aW, $author$project$Confirm$Blurbs$blurbs.ey, $author$project$Confirm$Blurbs$blurbs.gM, $author$project$Confirm$Blurbs$blurbs.b9, $author$project$Confirm$Blurbs$blurbs.a1, $author$project$Confirm$Blurbs$blurbs.bY, $author$project$Confirm$Blurbs$blurbs.bW, $author$project$Confirm$Blurbs$blurbs.bX, $author$project$Confirm$Blurbs$blurbs.a$, $author$project$Confirm$Blurbs$blurbs.aV, $author$project$Confirm$Blurbs$blurbs.a0, $author$project$Confirm$Blurbs$blurbs.aT, $author$project$Confirm$Blurbs$blurbs.a3, $author$project$Confirm$Blurbs$blurbs.b8, $author$project$Confirm$Blurbs$blurbs.a7, $author$project$Confirm$Blurbs$blurbs.gq, $author$project$Confirm$Blurbs$blurbs.b5]);
var $author$project$DateUtils$monthBlurbs = {
	ae: A2($author$project$Translation$KeyWithDefault, 710995, 'Apr'),
	af: A2($author$project$Translation$KeyWithDefault, 710999, 'Aug'),
	aj: A2($author$project$Translation$KeyWithDefault, 711003, 'Dec'),
	an: A2($author$project$Translation$KeyWithDefault, 710993, 'Feb'),
	as: A2($author$project$Translation$KeyWithDefault, 710992, 'Jan'),
	at: A2($author$project$Translation$KeyWithDefault, 710998, 'Jul'),
	au: A2($author$project$Translation$KeyWithDefault, 710997, 'Jun'),
	ay: A2($author$project$Translation$KeyWithDefault, 710994, 'Mar'),
	bn: A2($author$project$Translation$KeyWithDefault, 710996, 'May'),
	aD: A2($author$project$Translation$KeyWithDefault, 711002, 'Nov'),
	aE: A2($author$project$Translation$KeyWithDefault, 711001, 'Oct'),
	aL: A2($author$project$Translation$KeyWithDefault, 711000, 'Sep')
};
var $author$project$DateUtils$allMonthBlurbs = _List_fromArray(
	[$author$project$DateUtils$monthBlurbs.as, $author$project$DateUtils$monthBlurbs.an, $author$project$DateUtils$monthBlurbs.ay, $author$project$DateUtils$monthBlurbs.ae, $author$project$DateUtils$monthBlurbs.au, $author$project$DateUtils$monthBlurbs.at, $author$project$DateUtils$monthBlurbs.af, $author$project$DateUtils$monthBlurbs.aL, $author$project$DateUtils$monthBlurbs.aE, $author$project$DateUtils$monthBlurbs.aD, $author$project$DateUtils$monthBlurbs.aj]);
var $author$project$DateUtils$weekdayBlurbs = {
	ao: A2($author$project$Translation$KeyWithDefault, 714339, 'Friday'),
	aB: A2($author$project$Translation$KeyWithDefault, 714335, 'Monday'),
	aH: A2($author$project$Translation$KeyWithDefault, 714340, 'Saturday'),
	aN: A2($author$project$Translation$KeyWithDefault, 714334, 'Sunday'),
	aO: A2($author$project$Translation$KeyWithDefault, 714338, 'Thursday'),
	aQ: A2($author$project$Translation$KeyWithDefault, 714336, 'Tuesday'),
	aR: A2($author$project$Translation$KeyWithDefault, 714337, 'Wednesday')
};
var $author$project$DateUtils$allWeekdayBlurbs = _List_fromArray(
	[$author$project$DateUtils$weekdayBlurbs.aB, $author$project$DateUtils$weekdayBlurbs.aQ, $author$project$DateUtils$weekdayBlurbs.aR, $author$project$DateUtils$weekdayBlurbs.aO, $author$project$DateUtils$weekdayBlurbs.ao, $author$project$DateUtils$weekdayBlurbs.aH, $author$project$DateUtils$weekdayBlurbs.aN]);
var $elm$core$Basics$append = _Utils_append;
var $author$project$SelectTimeslot$Blurbs$blurbs = {
	aS: A2($author$project$Translation$KeyWithDefault, 728419, '<b>All times in</b>: '),
	aU: A2($author$project$Translation$KeyWithDefault, 728500, 'At what time?'),
	a_: A2($author$project$Translation$KeyWithDefault, 728429, 'You are scheduled for a {{classType}}: \"{{className}}\", on this day'),
	a1: A2($author$project$Translation$KeyWithDefault, 728412, 'Change'),
	a2: A2($author$project$Translation$KeyWithDefault, 728412, 'Change'),
	ey: A2($author$project$Translation$KeyWithDefault, 711207, 'Date'),
	bf: A2($author$project$Translation$KeyWithDefault, 728349, 'Error!'),
	bu: A2($author$project$Translation$KeyWithDefault, 728426, 'There are no available timeslots on this day'),
	bv: A2($author$project$Translation$KeyWithDefault, 728428, 'No recommended teacher returned'),
	f9: A2($author$project$Translation$KeyWithDefault, 718681, 'private class'),
	bA: A2($author$project$Translation$KeyWithDefault, 728427, 'Unable to load recommended teachers'),
	bG: A2($author$project$Translation$KeyWithDefault, 728422, 'Search for a specific teacher'),
	bH: A2($author$project$Translation$KeyWithDefault, 728404, 'Select'),
	bJ: A2($author$project$Translation$KeyWithDefault, 728396, 'Select Time'),
	bK: A2($author$project$Translation$KeyWithDefault, 728409, 'Unable to load timeslot data'),
	bP: A2($author$project$Translation$KeyWithDefault, 728423, 'Show availability for <b>all teachers</b>'),
	bQ: A2($author$project$Translation$KeyWithDefault, 728424, 'Showing availability for <b>all teachers</b>'),
	bR: A2($author$project$Translation$KeyWithDefault, 728425, 'Showing availability for <b>{{currentTeacher}}</b>'),
	gM: A2($author$project$Translation$KeyWithDefault, 717381, 'Time'),
	b_: A2($author$project$Translation$KeyWithDefault, 728421, 'Unable to get timeslots'),
	b$: A2($author$project$Translation$KeyWithDefault, 728418, 'Unable to load timezone data'),
	gQ: A2($author$project$Translation$KeyWithDefault, 725120, 'Topic'),
	gU: A2($author$project$Translation$KeyWithDefault, 718698, 'unit review'),
	b3: A2($author$project$Translation$KeyWithDefault, 728420, 'Unable to update timezone')
};
var $author$project$SelectTimeslot$Blurbs$allBlurbs = _Utils_ap(
	_List_fromArray(
		[$author$project$SelectTimeslot$Blurbs$blurbs.bK, $author$project$SelectTimeslot$Blurbs$blurbs.bf, $author$project$SelectTimeslot$Blurbs$blurbs.b$, $author$project$SelectTimeslot$Blurbs$blurbs.aS, $author$project$SelectTimeslot$Blurbs$blurbs.a2, $author$project$SelectTimeslot$Blurbs$blurbs.b3, $author$project$SelectTimeslot$Blurbs$blurbs.gQ, $author$project$SelectTimeslot$Blurbs$blurbs.ey, $author$project$SelectTimeslot$Blurbs$blurbs.gM, $author$project$SelectTimeslot$Blurbs$blurbs.b_, $author$project$SelectTimeslot$Blurbs$blurbs.bJ, $author$project$SelectTimeslot$Blurbs$blurbs.bG, $author$project$SelectTimeslot$Blurbs$blurbs.bH, $author$project$SelectTimeslot$Blurbs$blurbs.a1, $author$project$SelectTimeslot$Blurbs$blurbs.bP, $author$project$SelectTimeslot$Blurbs$blurbs.bQ, $author$project$SelectTimeslot$Blurbs$blurbs.bR, $author$project$SelectTimeslot$Blurbs$blurbs.bu, $author$project$SelectTimeslot$Blurbs$blurbs.bA, $author$project$SelectTimeslot$Blurbs$blurbs.bv, $author$project$SelectTimeslot$Blurbs$blurbs.a_, $author$project$SelectTimeslot$Blurbs$blurbs.f9, $author$project$SelectTimeslot$Blurbs$blurbs.gU, $author$project$SelectTimeslot$Blurbs$blurbs.aU]),
	_Utils_ap($author$project$DateUtils$allMonthBlurbs, $author$project$DateUtils$allWeekdayBlurbs));
var $author$project$SelectTopic$Blurbs$blurbs = {
	a4: A2($author$project$Translation$KeyWithDefault, 717581, 'Close'),
	ba: A2($author$project$Translation$KeyWithDefault, 728405, 'What do you want your class to be about? (Please write your request in English.)'),
	bd: A2($author$project$Translation$KeyWithDefault, 728402, 'Didn\'t find what you were looking for?'),
	bf: A2($author$project$Translation$KeyWithDefault, 728349, 'Error!'),
	bz: A2($author$project$Translation$KeyWithDefault, 728401, 'Unable to load private classes'),
	bB: A2($author$project$Translation$KeyWithDefault, 728407, 'Related to your current study'),
	bC: A2($author$project$Translation$KeyWithDefault, 728403, 'Request a topic'),
	bH: A2($author$project$Translation$KeyWithDefault, 728404, 'Select'),
	bI: A2($author$project$Translation$KeyWithDefault, 728406, 'Select a topic'),
	b0: A2($author$project$Translation$KeyWithDefault, 728408, 'Unable to load topics for selected level')
};
var $author$project$SelectTopic$Blurbs$allBlurbs = _List_fromArray(
	[$author$project$SelectTopic$Blurbs$blurbs.bz, $author$project$SelectTopic$Blurbs$blurbs.bf, $author$project$SelectTopic$Blurbs$blurbs.a4, $author$project$SelectTopic$Blurbs$blurbs.bd, $author$project$SelectTopic$Blurbs$blurbs.bC, $author$project$SelectTopic$Blurbs$blurbs.bH, $author$project$SelectTopic$Blurbs$blurbs.ba, $author$project$SelectTopic$Blurbs$blurbs.bI, $author$project$SelectTopic$Blurbs$blurbs.bB, $author$project$SelectTopic$Blurbs$blurbs.b0]);
var $author$project$Blurbs$blurbs = {
	aY: A2($author$project$Translation$KeyWithDefault, 728394, 'Book a private class'),
	a5: A2($author$project$Translation$KeyWithDefault, 728397, 'Confirm & book'),
	bf: A2($author$project$Translation$KeyWithDefault, 728349, 'Error!'),
	bg: A2($author$project$Translation$KeyWithDefault, 728399, 'Go to current bookings'),
	bi: A2($author$project$Translation$KeyWithDefault, 728316, 'hide'),
	bj: A2($author$project$Translation$KeyWithDefault, 728398, 'Unable to load private class page data'),
	bk: A2($author$project$Translation$KeyWithDefault, 728201, 'Sorry, you don\'t have enough remaining credits to book a class'),
	bJ: A2($author$project$Translation$KeyWithDefault, 728396, 'Select time'),
	bL: A2($author$project$Translation$KeyWithDefault, 728395, 'Select topic'),
	bO: A2($author$project$Translation$KeyWithDefault, 695864, 'show'),
	bS: A2($author$project$Translation$KeyWithDefault, 718942, 'Sorry!'),
	bV: A2($author$project$Translation$KeyWithDefault, 724632, 'Take me home'),
	b2: A2($author$project$Translation$KeyWithDefault, 728400, 'We\'re sorry, but you are not licensed to use this feature.'),
	b7: A2($author$project$Translation$KeyWithDefault, 690360, 'We\'re sorry, but you have reached the maximum number of bookings.')
};
var $author$project$Blurbs$allBlurbs = _Utils_ap(
	_List_fromArray(
		[$author$project$Blurbs$blurbs.aY, $author$project$Blurbs$blurbs.bf, $author$project$Blurbs$blurbs.bL, $author$project$Blurbs$blurbs.bJ, $author$project$Blurbs$blurbs.a5, $author$project$Blurbs$blurbs.bi, $author$project$Blurbs$blurbs.bO, $author$project$Blurbs$blurbs.b7, $author$project$Blurbs$blurbs.bS, $author$project$Blurbs$blurbs.bj, $author$project$Blurbs$blurbs.bV, $author$project$Blurbs$blurbs.bg, $author$project$Blurbs$blurbs.bk, $author$project$Blurbs$blurbs.b2]),
	_Utils_ap(
		$author$project$Confirm$Blurbs$allBlurbs,
		_Utils_ap($author$project$SelectTimeslot$Blurbs$allBlurbs, $author$project$SelectTopic$Blurbs$allBlurbs)));
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.e) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.g),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.g);
		} else {
			var treeLen = builder.e * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.h) : builder.h;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.e);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.g) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.g);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{h: nodeList, e: (len / $elm$core$Array$branchFactor) | 0, g: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {cy: fragment, cD: host, c_: path, c2: port_, c6: protocol, c7: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $author$project$Scaffold$InitialisationFailed = {$: 2};
var $author$project$Scaffold$ReceiveViewport = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Scaffold$Uninitialised = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Ports$bubbleError = _Platform_outgoingPort(
	'bubbleError',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'display',
					function ($) {
						return A3(
							$elm$core$Maybe$destruct,
							$elm$json$Json$Encode$null,
							function ($) {
								return $elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'message',
											$elm$json$Json$Encode$string($.bp)),
											_Utils_Tuple2(
											'title',
											$elm$json$Json$Encode$string($.dB))
										]));
							},
							$);
					}($.al)),
					_Utils_Tuple2(
					'log',
					function ($) {
						return A3(
							$elm$core$Maybe$destruct,
							$elm$json$Json$Encode$null,
							function ($) {
								return $elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'context',
											$elm$json$Json$Encode$string($.el)),
											_Utils_Tuple2(
											'message',
											$elm$json$Json$Encode$string($.bp))
										]));
							},
							$);
					}($.ax))
				]));
	});
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Messages$LogMessage = F2(
	function (context, message) {
		return {el: context, bp: message};
	});
var $author$project$Messages$MessageDisplay = F2(
	function (title, message) {
		return {bp: message, dB: title};
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Messages$decodingErrorMessage = F4(
	function (displayTitle, displayMessage, context, err) {
		return {
			al: A2(
				$elm$core$Maybe$map,
				$author$project$Messages$MessageDisplay(displayTitle),
				displayMessage),
			ax: $elm$core$Maybe$Just(
				A2(
					$author$project$Messages$LogMessage,
					context,
					$elm$json$Json$Decode$errorToString(err)))
		};
	});
var $author$project$ScaffoldData$Flags = function (student) {
	return function (lang) {
		return function (config) {
			return function (url) {
				return function (fullUrl) {
					return function (urlParams) {
						return function (cachedBlurbs) {
							return function (externalBlurbs) {
								return function (externalLogo) {
									return function (isMarketCN) {
										return {U: cachedBlurbs, N: config, eS: externalBlurbs, am: externalLogo, ap: fullUrl, ar: isMarketCN, H: lang, gy: student, dJ: url, gW: urlParams};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$JsonUtils$apply = F2(
	function (da, fAtoB) {
		return A3(
			$elm$json$Json$Decode$map2,
			F2(
				function (f, a) {
					return f(a);
				}),
			fAtoB,
			da);
	});
var $author$project$ScaffoldData$CachedBlurb = F3(
	function (loadedAt, maxAge, value) {
		return {fG: loadedAt, fN: maxAge, gY: value};
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$JsonUtils$withDefault = F2(
	function (def, dec) {
		return A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$withDefault(def),
			$elm$json$Json$Decode$maybe(dec));
	});
var $author$project$ScaffoldData$cachedBlurbDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'maxAge', $elm$json$Json$Decode$int),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$author$project$JsonUtils$withDefault,
				$elm$time$Time$millisToPosix(0),
				A2(
					$elm$json$Json$Decode$field,
					'loadedAtPosix',
					A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int))),
			$elm$json$Json$Decode$succeed($author$project$ScaffoldData$CachedBlurb))));
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$ScaffoldData$blurbCacheDecoder = $elm$json$Json$Decode$dict(
	$elm$json$Json$Decode$dict($author$project$ScaffoldData$cachedBlurbDecoder));
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$ScaffoldData$externalBlurbDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'defaultVal', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'blurbId', $elm$json$Json$Decode$int),
		$elm$json$Json$Decode$succeed($author$project$Translation$KeyWithDefault)));
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$ScaffoldData$externalBlurbsDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Dict$values,
	$elm$json$Json$Decode$dict($author$project$ScaffoldData$externalBlurbDecoder));
var $author$project$ScaffoldData$GeneralAppConfig = F6(
	function (languages, countries, genders, departments, seniorities, paths) {
		return {ag: countries, ak: departments, aq: genders, av: languages, f4: paths, aK: seniorities};
	});
var $author$project$ScaffoldData$EfConstant = F3(
	function (value, blurb, defaultLabel) {
		return {d$: blurb, eB: defaultLabel, gY: value};
	});
var $author$project$ScaffoldData$efConstantDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'defaultLabel', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'blurb', $elm$json$Json$Decode$int),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$succeed($author$project$ScaffoldData$EfConstant))));
var $author$project$ScaffoldData$Language = F2(
	function (value, label) {
		return {fu: label, gY: value};
	});
var $author$project$ScaffoldData$languageDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'label', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$ScaffoldData$Language)));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Paths$Paths = function (currentCourse) {
	return function (changeCourse) {
		return function (grammarLab) {
			return function (appsAndTools) {
				return function (privateClass) {
					return function (currentBookings) {
						return function (groupClass) {
							return function (helpCenter) {
								return function (onboarding) {
									return function (accountSettings) {
										return function (progressAndGoals) {
											return function (efset30) {
												return function (efset30Retake) {
													return function (levelTest) {
														return function (home) {
															return {dQ: accountSettings, dV: appsAndTools, d9: changeCourse, cl: currentBookings, es: currentCourse, eG: efset30, eH: efset30Retake, e5: grammarLab, e7: groupClass, fd: helpCenter, ff: home, fD: levelTest, f1: onboarding, f9: privateClass, gd: progressAndGoals};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Paths$PathObject = F3(
	function (url, external, blank) {
		return {d_: blank, eR: external, dJ: url};
	});
var $author$project$ScaffoldData$pathObjectDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'blank', $elm$json$Json$Decode$bool),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'external', $elm$json$Json$Decode$bool),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$succeed($author$project$Paths$PathObject))));
var $author$project$ScaffoldData$pathsDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'home', $author$project$ScaffoldData$pathObjectDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'levelTest', $author$project$ScaffoldData$pathObjectDecoder),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'efset30Retake', $author$project$ScaffoldData$pathObjectDecoder),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'efset30', $author$project$ScaffoldData$pathObjectDecoder),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'progressAndGoals', $author$project$ScaffoldData$pathObjectDecoder),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'accountSettings', $author$project$ScaffoldData$pathObjectDecoder),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'onboarding', $author$project$ScaffoldData$pathObjectDecoder),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'helpCenter', $author$project$ScaffoldData$pathObjectDecoder),
								A2(
									$author$project$JsonUtils$apply,
									A2($elm$json$Json$Decode$field, 'groupClass', $author$project$ScaffoldData$pathObjectDecoder),
									A2(
										$author$project$JsonUtils$apply,
										A2($elm$json$Json$Decode$field, 'currentBookings', $author$project$ScaffoldData$pathObjectDecoder),
										A2(
											$author$project$JsonUtils$apply,
											A2($elm$json$Json$Decode$field, 'privateClass', $author$project$ScaffoldData$pathObjectDecoder),
											A2(
												$author$project$JsonUtils$apply,
												A2($elm$json$Json$Decode$field, 'appsAndTools', $author$project$ScaffoldData$pathObjectDecoder),
												A2(
													$author$project$JsonUtils$apply,
													A2($elm$json$Json$Decode$field, 'grammarLab', $author$project$ScaffoldData$pathObjectDecoder),
													A2(
														$author$project$JsonUtils$apply,
														A2($elm$json$Json$Decode$field, 'changeCourse', $author$project$ScaffoldData$pathObjectDecoder),
														A2(
															$author$project$JsonUtils$apply,
															A2($elm$json$Json$Decode$field, 'currentCourse', $author$project$ScaffoldData$pathObjectDecoder),
															$elm$json$Json$Decode$succeed($author$project$Paths$Paths))))))))))))))));
var $author$project$ScaffoldData$generalAppConfigDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'paths', $author$project$ScaffoldData$pathsDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'seniorities',
			$elm$json$Json$Decode$list($author$project$ScaffoldData$efConstantDecoder)),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$elm$json$Json$Decode$field,
				'departments',
				$elm$json$Json$Decode$list($author$project$ScaffoldData$efConstantDecoder)),
			A2(
				$author$project$JsonUtils$apply,
				A2(
					$elm$json$Json$Decode$field,
					'genders',
					$elm$json$Json$Decode$list($author$project$ScaffoldData$efConstantDecoder)),
				A2(
					$author$project$JsonUtils$apply,
					A2(
						$elm$json$Json$Decode$field,
						'countries',
						$elm$json$Json$Decode$list($author$project$ScaffoldData$efConstantDecoder)),
					A2(
						$author$project$JsonUtils$apply,
						A2(
							$elm$json$Json$Decode$field,
							'languages',
							$elm$json$Json$Decode$list($author$project$ScaffoldData$languageDecoder)),
						$elm$json$Json$Decode$succeed($author$project$ScaffoldData$GeneralAppConfig)))))));
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$ScaffoldData$listToTupleDecoder = function (decoder) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (things) {
			if (things.b && things.b.b) {
				var a = things.a;
				var _v1 = things.b;
				var b = _v1.a;
				return $elm$json$Json$Decode$succeed(
					_Utils_Tuple2(a, b));
			} else {
				return $elm$json$Json$Decode$fail('Expected a list of things with at least two values to create a tuple from');
			}
		},
		$elm$json$Json$Decode$list(decoder));
};
var $author$project$ApiTypes$Student$Index$Student = function (firstName) {
	return function (lastName) {
		return function (country) {
			return function (gender) {
				return function (emailAddress) {
					return function (phoneNumber) {
						return function (emailLanguage) {
							return function (seniority) {
								return function (department) {
									return function (bookingConfirmationEmail) {
										return function (id) {
											return function (userId) {
												return function (companyId) {
													return function (grantsAndCredits) {
														return function (reportType) {
															return function (courseStart) {
																return function (courseExpiry) {
																	return function (studyTargets) {
																		return function (timezone) {
																			return function (clientTimezone) {
																				return {cd: bookingConfirmationEmail, eg: clientTimezone, ei: companyId, ck: country, eo: courseExpiry, eq: courseStart, cp: department, cq: emailAddress, cr: emailLanguage, eW: firstName, cA: gender, e6: grantsAndCredits, fh: id, fv: lastName, c$: phoneNumber, da: reportType, dj: seniority, gA: studyTargets, gO: timezone, gX: userId};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$ApiTypes$GrantsAndCredits$GrantsAndCreditFeatures = function (faceToFaceStudy) {
	return function (selfStudy) {
		return function (privateLesson) {
			return function (groupDiscussion) {
				return function (writingClass) {
					return function (personalCoaching) {
						return function (spinCourse) {
							return function (unitReview) {
								return function (efsetSpeaking) {
									return function (efset50) {
										return function (liveChat) {
											return function (retakePlacementTest) {
												return function (hultEfPro) {
													return function (groupScheduledLesson) {
														return function (privateScheduledLesson) {
															return function (catalyst) {
																return function (generalEnglish) {
																	return function (businessEnglish) {
																		return function (industryEnglish) {
																			return function (travelEnglish) {
																				return function (german) {
																					return function (french) {
																						return function (spanish) {
																							return function (swedish) {
																								return function (portuguese) {
																									return function (dubaiAirports) {
																										return function (customRocheSAG) {
																											return function (customRwanda) {
																												return function (customMcDonalds) {
																													return function (mango) {
																														return {d4: businessEnglish, d8: catalyst, ev: customMcDonalds, ew: customRocheSAG, ex: customRwanda, eF: dubaiAirports, eI: efset50, eJ: efsetSpeaking, eU: faceToFaceStudy, e$: french, e0: generalEnglish, e2: german, e8: groupDiscussion, fa: groupScheduledLesson, fg: hultEfPro, fk: industryEnglish, fF: liveChat, fL: mango, f5: personalCoaching, f6: portuguese, ga: privateLesson, gc: privateScheduledLesson, gj: retakePlacementTest, gp: selfStudy, gt: spanish, gu: spinCourse, gD: swedish, gT: travelEnglish, gU: unitReview, g4: writingClass};
																													};
																												};
																											};
																										};
																									};
																								};
																							};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$ApiTypes$GrantsAndCredits$GrantsAndCreditsEntry = F3(
	function (expired, expirationDate, quota) {
		return {eO: expirationDate, eP: expired, ge: quota};
	});
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$ApiTypes$GrantsAndCredits$QuotaLimited = function (a) {
	return {$: 1, a: a};
};
var $author$project$ApiTypes$GrantsAndCredits$QuotaUnlimited = {$: 0};
var $author$project$ApiTypes$GrantsAndCredits$Limited = F2(
	function (amount, remaining) {
		return {dT: amount, gh: remaining};
	});
var $author$project$Student$limitedDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'remaining', $elm$json$Json$Decode$float),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'amount', $elm$json$Json$Decode$float),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$GrantsAndCredits$Limited)));
var $author$project$Student$quotaDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$andThen,
			function (s) {
				return (s === 'unlimited') ? $elm$json$Json$Decode$succeed($author$project$ApiTypes$GrantsAndCredits$QuotaUnlimited) : $elm$json$Json$Decode$fail('unexpected string');
			},
			$elm$json$Json$Decode$string),
			A2($elm$json$Json$Decode$map, $author$project$ApiTypes$GrantsAndCredits$QuotaLimited, $author$project$Student$limitedDecoder)
		]));
var $author$project$Student$grantsAndCreditsEntryDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'quota', $author$project$Student$quotaDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'expirationDate',
			$elm$json$Json$Decode$maybe($elm$json$Json$Decode$float)),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$elm$json$Json$Decode$field,
				'expired',
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$bool)),
			$elm$json$Json$Decode$succeed($author$project$ApiTypes$GrantsAndCredits$GrantsAndCreditsEntry))));
var $author$project$Student$grantsAndCreditsDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'mango', $author$project$Student$grantsAndCreditsEntryDecoder)),
	A2(
		$author$project$JsonUtils$apply,
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'customMcDonalds', $author$project$Student$grantsAndCreditsEntryDecoder)),
		A2(
			$author$project$JsonUtils$apply,
			$elm$json$Json$Decode$maybe(
				A2($elm$json$Json$Decode$field, 'customRwanda', $author$project$Student$grantsAndCreditsEntryDecoder)),
			A2(
				$author$project$JsonUtils$apply,
				$elm$json$Json$Decode$maybe(
					A2($elm$json$Json$Decode$field, 'customRocheSAG', $author$project$Student$grantsAndCreditsEntryDecoder)),
				A2(
					$author$project$JsonUtils$apply,
					$elm$json$Json$Decode$maybe(
						A2($elm$json$Json$Decode$field, 'dubaiAirports', $author$project$Student$grantsAndCreditsEntryDecoder)),
					A2(
						$author$project$JsonUtils$apply,
						$elm$json$Json$Decode$maybe(
							A2($elm$json$Json$Decode$field, 'portuguese', $author$project$Student$grantsAndCreditsEntryDecoder)),
						A2(
							$author$project$JsonUtils$apply,
							$elm$json$Json$Decode$maybe(
								A2($elm$json$Json$Decode$field, 'swedish', $author$project$Student$grantsAndCreditsEntryDecoder)),
							A2(
								$author$project$JsonUtils$apply,
								$elm$json$Json$Decode$maybe(
									A2($elm$json$Json$Decode$field, 'spanish', $author$project$Student$grantsAndCreditsEntryDecoder)),
								A2(
									$author$project$JsonUtils$apply,
									$elm$json$Json$Decode$maybe(
										A2($elm$json$Json$Decode$field, 'french', $author$project$Student$grantsAndCreditsEntryDecoder)),
									A2(
										$author$project$JsonUtils$apply,
										$elm$json$Json$Decode$maybe(
											A2($elm$json$Json$Decode$field, 'german', $author$project$Student$grantsAndCreditsEntryDecoder)),
										A2(
											$author$project$JsonUtils$apply,
											$elm$json$Json$Decode$maybe(
												A2($elm$json$Json$Decode$field, 'travelEnglish', $author$project$Student$grantsAndCreditsEntryDecoder)),
											A2(
												$author$project$JsonUtils$apply,
												$elm$json$Json$Decode$maybe(
													A2($elm$json$Json$Decode$field, 'industryEnglish', $author$project$Student$grantsAndCreditsEntryDecoder)),
												A2(
													$author$project$JsonUtils$apply,
													$elm$json$Json$Decode$maybe(
														A2($elm$json$Json$Decode$field, 'businessEnglish', $author$project$Student$grantsAndCreditsEntryDecoder)),
													A2(
														$author$project$JsonUtils$apply,
														$elm$json$Json$Decode$maybe(
															A2($elm$json$Json$Decode$field, 'generalEnglish', $author$project$Student$grantsAndCreditsEntryDecoder)),
														A2(
															$author$project$JsonUtils$apply,
															$elm$json$Json$Decode$maybe(
																A2($elm$json$Json$Decode$field, 'catalyst', $author$project$Student$grantsAndCreditsEntryDecoder)),
															A2(
																$author$project$JsonUtils$apply,
																$elm$json$Json$Decode$maybe(
																	A2($elm$json$Json$Decode$field, 'privateScheduledLesson', $author$project$Student$grantsAndCreditsEntryDecoder)),
																A2(
																	$author$project$JsonUtils$apply,
																	$elm$json$Json$Decode$maybe(
																		A2($elm$json$Json$Decode$field, 'groupScheduledLesson', $author$project$Student$grantsAndCreditsEntryDecoder)),
																	A2(
																		$author$project$JsonUtils$apply,
																		$elm$json$Json$Decode$maybe(
																			A2($elm$json$Json$Decode$field, 'hultEfPro', $author$project$Student$grantsAndCreditsEntryDecoder)),
																		A2(
																			$author$project$JsonUtils$apply,
																			$elm$json$Json$Decode$maybe(
																				A2($elm$json$Json$Decode$field, 'retakePlacementTest', $author$project$Student$grantsAndCreditsEntryDecoder)),
																			A2(
																				$author$project$JsonUtils$apply,
																				$elm$json$Json$Decode$maybe(
																					A2($elm$json$Json$Decode$field, 'liveChat', $author$project$Student$grantsAndCreditsEntryDecoder)),
																				A2(
																					$author$project$JsonUtils$apply,
																					$elm$json$Json$Decode$maybe(
																						A2($elm$json$Json$Decode$field, 'efset50', $author$project$Student$grantsAndCreditsEntryDecoder)),
																					A2(
																						$author$project$JsonUtils$apply,
																						$elm$json$Json$Decode$maybe(
																							A2($elm$json$Json$Decode$field, 'efsetSpeaking', $author$project$Student$grantsAndCreditsEntryDecoder)),
																						A2(
																							$author$project$JsonUtils$apply,
																							$elm$json$Json$Decode$maybe(
																								A2($elm$json$Json$Decode$field, 'unitReview', $author$project$Student$grantsAndCreditsEntryDecoder)),
																							A2(
																								$author$project$JsonUtils$apply,
																								$elm$json$Json$Decode$maybe(
																									A2($elm$json$Json$Decode$field, 'spinCourse', $author$project$Student$grantsAndCreditsEntryDecoder)),
																								A2(
																									$author$project$JsonUtils$apply,
																									$elm$json$Json$Decode$maybe(
																										A2($elm$json$Json$Decode$field, 'personalCoaching', $author$project$Student$grantsAndCreditsEntryDecoder)),
																									A2(
																										$author$project$JsonUtils$apply,
																										$elm$json$Json$Decode$maybe(
																											A2($elm$json$Json$Decode$field, 'writingClass', $author$project$Student$grantsAndCreditsEntryDecoder)),
																										A2(
																											$author$project$JsonUtils$apply,
																											$elm$json$Json$Decode$maybe(
																												A2($elm$json$Json$Decode$field, 'groupDiscussion', $author$project$Student$grantsAndCreditsEntryDecoder)),
																											A2(
																												$author$project$JsonUtils$apply,
																												$elm$json$Json$Decode$maybe(
																													A2($elm$json$Json$Decode$field, 'privateLesson', $author$project$Student$grantsAndCreditsEntryDecoder)),
																												A2(
																													$author$project$JsonUtils$apply,
																													$elm$json$Json$Decode$maybe(
																														A2($elm$json$Json$Decode$field, 'selfStudy', $author$project$Student$grantsAndCreditsEntryDecoder)),
																													A2(
																														$author$project$JsonUtils$apply,
																														$elm$json$Json$Decode$maybe(
																															A2($elm$json$Json$Decode$field, 'faceToFaceStudy', $author$project$Student$grantsAndCreditsEntryDecoder)),
																														$elm$json$Json$Decode$succeed($author$project$ApiTypes$GrantsAndCredits$GrantsAndCreditFeatures)))))))))))))))))))))))))))))));
var $author$project$ApiTypes$Student$Index$StudentTimezone = F2(
	function (key, displayName) {
		return {eD: displayName, ft: key};
	});
var $author$project$Student$studentTimezoneDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'displayName', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$Student$Index$StudentTimezone)));
var $author$project$ApiTypes$Student$Index$StudyTargets = F8(
	function (generalEnglishUnits, industryEnglishUnits, businessEnglishUnits, privateLessons, groupLessons, mandatoryIndustryTopics, mandatoryBusinessTopics, initialTopic) {
		return {d5: businessEnglishUnits, e1: generalEnglishUnits, e9: groupLessons, fl: industryEnglishUnits, fn: initialTopic, fJ: mandatoryBusinessTopics, fK: mandatoryIndustryTopics, gb: privateLessons};
	});
var $author$project$ApiTypes$Student$Index$Course = $elm$core$Basics$identity;
var $author$project$ApiTypes$Student$Index$Topic = F2(
	function (id, course) {
		return {en: course, fh: id};
	});
var $author$project$Student$topicDecoder = A2(
	$author$project$JsonUtils$apply,
	A2(
		$elm$json$Json$Decode$andThen,
		function (str) {
			switch (str) {
				case 'BE':
					return $elm$json$Json$Decode$succeed('BE');
				case 'IND':
					return $elm$json$Json$Decode$succeed('IND');
				case 'INDB2B':
					return $elm$json$Json$Decode$succeed('INDB2B');
				default:
					var course = str;
					return $elm$json$Json$Decode$fail('Unexpected topic course: ' + course);
			}
		},
		A2($elm$json$Json$Decode$field, 'course', $elm$json$Json$Decode$string)),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$Student$Index$Topic)));
var $author$project$Student$studyTargetsDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'initialTopic', $author$project$Student$topicDecoder)),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'mandatoryBusinessTopics',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$elm$json$Json$Decode$field,
				'mandatoryIndustryTopics',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'groupLessons', $elm$json$Json$Decode$float),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'privateLessons', $elm$json$Json$Decode$float),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'businessEnglishUnits', $elm$json$Json$Decode$float),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'industryEnglishUnits', $elm$json$Json$Decode$float),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'generalEnglishUnits', $elm$json$Json$Decode$float),
								$elm$json$Json$Decode$succeed($author$project$ApiTypes$Student$Index$StudyTargets)))))))));
var $author$project$Student$studentDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'clientTimezone', $author$project$Student$studentTimezoneDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'timezone', $author$project$Student$studentTimezoneDecoder),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'studyTargets', $author$project$Student$studyTargetsDecoder),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'courseExpiry', $elm$json$Json$Decode$string),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'courseStart', $elm$json$Json$Decode$string),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'reportType', $elm$json$Json$Decode$string),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'grantsAndCredits', $author$project$Student$grantsAndCreditsDecoder),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'companyId', $elm$json$Json$Decode$string),
								A2(
									$author$project$JsonUtils$apply,
									A2($elm$json$Json$Decode$field, 'userId', $elm$json$Json$Decode$string),
									A2(
										$author$project$JsonUtils$apply,
										A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
										A2(
											$author$project$JsonUtils$apply,
											A2($elm$json$Json$Decode$field, 'bookingConfirmationEmail', $elm$json$Json$Decode$bool),
											A2(
												$author$project$JsonUtils$apply,
												$elm$json$Json$Decode$maybe(
													A2($elm$json$Json$Decode$field, 'department', $elm$json$Json$Decode$string)),
												A2(
													$author$project$JsonUtils$apply,
													$elm$json$Json$Decode$maybe(
														A2($elm$json$Json$Decode$field, 'seniority', $elm$json$Json$Decode$string)),
													A2(
														$author$project$JsonUtils$apply,
														A2($elm$json$Json$Decode$field, 'emailLanguage', $elm$json$Json$Decode$string),
														A2(
															$author$project$JsonUtils$apply,
															$elm$json$Json$Decode$maybe(
																A2($elm$json$Json$Decode$field, 'phoneNumber', $elm$json$Json$Decode$string)),
															A2(
																$author$project$JsonUtils$apply,
																A2($elm$json$Json$Decode$field, 'emailAddress', $elm$json$Json$Decode$string),
																A2(
																	$author$project$JsonUtils$apply,
																	A2($elm$json$Json$Decode$field, 'gender', $elm$json$Json$Decode$string),
																	A2(
																		$author$project$JsonUtils$apply,
																		A2($elm$json$Json$Decode$field, 'country', $elm$json$Json$Decode$string),
																		A2(
																			$author$project$JsonUtils$apply,
																			A2($elm$json$Json$Decode$field, 'lastName', $elm$json$Json$Decode$string),
																			A2(
																				$author$project$JsonUtils$apply,
																				A2($elm$json$Json$Decode$field, 'firstName', $elm$json$Json$Decode$string),
																				$elm$json$Json$Decode$succeed($author$project$ApiTypes$Student$Index$Student)))))))))))))))))))));
var $author$project$ScaffoldData$flagsDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'isMarketCN', $elm$json$Json$Decode$bool),
	A2(
		$author$project$JsonUtils$apply,
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'externalLogo', $elm$json$Json$Decode$string)),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'externalBlurbs', $author$project$ScaffoldData$externalBlurbsDecoder),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'cachedBlurbs', $author$project$ScaffoldData$blurbCacheDecoder),
				A2(
					$author$project$JsonUtils$apply,
					A2(
						$elm$json$Json$Decode$field,
						'urlParams',
						$elm$json$Json$Decode$list(
							$author$project$ScaffoldData$listToTupleDecoder($elm$json$Json$Decode$string))),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'fullUrl', $elm$json$Json$Decode$string),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
							A2(
								$author$project$JsonUtils$apply,
								A2($elm$json$Json$Decode$field, 'config', $author$project$ScaffoldData$generalAppConfigDecoder),
								A2(
									$author$project$JsonUtils$apply,
									A2($elm$json$Json$Decode$field, 'lang', $elm$json$Json$Decode$string),
									A2(
										$author$project$JsonUtils$apply,
										$elm$json$Json$Decode$maybe(
											A2($elm$json$Json$Decode$field, 'student', $author$project$Student$studentDecoder)),
										$elm$json$Json$Decode$succeed($author$project$ScaffoldData$Flags)))))))))));
var $elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var $author$project$Scaffold$init = F2(
	function (resources, encodedFlags) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$ScaffoldData$flagsDecoder, encodedFlags);
		if (_v0.$ === 1) {
			var err = _v0.a;
			return _Utils_Tuple2(
				$author$project$Scaffold$InitialisationFailed,
				$author$project$Ports$bubbleError(
					A4(
						$author$project$Messages$decodingErrorMessage,
						'Error!',
						$elm$core$Maybe$Just('Unable to initialise the school'),
						'Decoding intial data',
						err)));
		} else {
			var flags = _v0.a;
			return _Utils_Tuple2(
				A2($author$project$Scaffold$Uninitialised, flags, $elm$core$Maybe$Nothing),
				A2(
					$elm$core$Task$perform,
					$author$project$Scaffold$ReceiveViewport(resources),
					$elm$browser$Browser$Dom$getViewport));
		}
	});
var $author$project$Scaffold$AppMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Scaffold$LanguageChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Scaffold$ModalMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Scaffold$StudentChanged = function (a) {
	return {$: 2, a: a};
};
var $author$project$Scaffold$UrlChanged = function (a) {
	return {$: 3, a: a};
};
var $author$project$Scaffold$WindowResize = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Ports$onLanguageChange = _Platform_incomingPort('onLanguageChange', $elm$json$Json$Decode$string);
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {c0: pids, du: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {cu: event, ft: key};
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.c0,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.ft;
		var event = _v0.cu;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.du);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Ports$onStudentChange = _Platform_incomingPort('onStudentChange', $elm$json$Json$Decode$value);
var $author$project$Ports$onUrlChange = _Platform_incomingPort('onUrlChange', $elm$json$Json$Decode$string);
var $author$project$Modal$InternalHide = {$: 3};
var $author$project$Modal$NoOp = {$: 4};
var $author$project$Modal$escDecoder = A2(
	$elm$json$Json$Decode$map,
	function (k) {
		return (k === 'Escape') ? $author$project$Modal$InternalHide : $author$project$Modal$NoOp;
	},
	A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$onKeyDown = A2($elm$browser$Browser$Events$on, 0, 'keydown');
var $author$project$Modal$subscriptions = function (model) {
	return model.B ? $elm$browser$Browser$Events$onKeyDown($author$project$Modal$escDecoder) : $elm$core$Platform$Sub$none;
};
var $author$project$Scaffold$subscriptions = F2(
	function (appSubs, model) {
		if (model.$ === 1) {
			var external = model.a;
			var appModel = model.b;
			var modal = model.c;
			return $elm$core$Platform$Sub$batch(
				_List_fromArray(
					[
						A2(
						$elm$core$Platform$Sub$map,
						$author$project$Scaffold$AppMsg,
						A2(appSubs, external, appModel)),
						$author$project$Ports$onLanguageChange($author$project$Scaffold$LanguageChanged),
						$author$project$Ports$onStudentChange($author$project$Scaffold$StudentChanged),
						$author$project$Ports$onUrlChange($author$project$Scaffold$UrlChanged),
						A2(
						$elm$core$Platform$Sub$map,
						$author$project$Scaffold$ModalMsg,
						$author$project$Modal$subscriptions(modal)),
						$elm$browser$Browser$Events$onResize($author$project$Scaffold$WindowResize)
					]));
		} else {
			return $elm$core$Platform$Sub$none;
		}
	});
var $author$project$Scaffold$CompleteInitialisation = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Scaffold$Initialised = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var $author$project$Scaffold$ReceivedTranslations = function (a) {
	return {$: 8, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Translation$translate = F2(
	function (_v0, textOrKey) {
		var dictionary = _v0;
		if (!textOrKey.$) {
			var txt = textOrKey.a;
			return txt;
		} else {
			var key = textOrKey.a;
			var _default = textOrKey.b;
			return A2(
				$elm$core$Maybe$withDefault,
				_default,
				A2(
					$elm$core$Dict$get,
					$elm$core$String$fromInt(key),
					dictionary));
		}
	});
var $author$project$Translation$TranslationLookup = $elm$core$Basics$identity;
var $author$project$Translation$translationLookup = $elm$core$Basics$identity;
var $author$project$ScaffoldData$createTranslationFunc = F2(
	function (lang, blurbCache) {
		return $author$project$Translation$translate(
			$author$project$Translation$translationLookup(
				A2(
					$elm$core$Dict$map,
					F2(
						function (_v0, _v1) {
							var value = _v1.gY;
							return value;
						}),
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Dict$empty,
						A2($elm$core$Dict$get, lang, blurbCache)))));
	});
var $author$project$Scaffold$decodeStudentErrorBlurb = A2($author$project$Translation$KeyWithDefault, 728351, 'Unable to load student details');
var $author$project$Modal$defaultConfig = function (toggleEffect) {
	return {B: true, K: toggleEffect};
};
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(0),
				dictionary));
	});
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$ScaffoldData$encodeCachedBlurb = function (blurb) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'loadedAtPosix',
				$elm$json$Json$Encode$int(
					$elm$time$Time$posixToMillis(blurb.fG))),
				_Utils_Tuple2(
				'maxAge',
				$elm$json$Json$Encode$int(blurb.fN)),
				_Utils_Tuple2(
				'value',
				$elm$json$Json$Encode$string(blurb.gY))
			]));
};
var $author$project$ScaffoldData$encodeBlurbCache = A2(
	$elm$json$Json$Encode$dict,
	$elm$core$Basics$identity,
	A2($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $author$project$ScaffoldData$encodeCachedBlurb));
var $author$project$Scaffold$errorTitleBlurb = A2($author$project$Translation$KeyWithDefault, 728349, 'Error!');
var $author$project$Scaffold$extraBlurbs = _List_fromArray(
	[$author$project$Scaffold$errorTitleBlurb, $author$project$Scaffold$decodeStudentErrorBlurb]);
var $author$project$HttpUtils$logMessageFromHttpError = function (err) {
	switch (err.$) {
		case 1:
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Nothing;
		case 3:
			var status = err.a;
			if (status === 401) {
				return $elm$core$Maybe$Just('401 received from the server. This should have been intercepted by the decorated XHR object');
			} else {
				return $elm$core$Maybe$Nothing;
			}
		case 4:
			var text = err.a;
			return $elm$core$Maybe$Just('Unable to decode the response from the api: ' + text);
		default:
			var url = err.a;
			return $elm$core$Maybe$Just('Malformed url: ' + url);
	}
};
var $author$project$Messages$httpErrorMessage = F4(
	function (displayTitle, displayMessage, context, err) {
		return {
			al: A2(
				$elm$core$Maybe$map,
				$author$project$Messages$MessageDisplay(displayTitle),
				displayMessage),
			ax: A2(
				$elm$core$Maybe$map,
				$author$project$Messages$LogMessage(context),
				$author$project$HttpUtils$logMessageFromHttpError(err))
		};
	});
var $author$project$Modal$init = function (config) {
	return {B: config.B, Y: false, K: config.K, S: false};
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$ServerPath$serverPathParts = _List_fromArray(
	['api', 'bff']);
var $author$project$ServerPath$serverPath = A2($elm$url$Url$Builder$absolute, $author$project$ServerPath$serverPathParts, _List_Nil);
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $author$project$HttpUtils$standardStringResolver = function (decoder) {
	return $elm$http$Http$stringResolver(
		function (resp) {
			switch (resp.$) {
				case 0:
					var url = resp.a;
					return $elm$core$Result$Err(
						$elm$http$Http$BadUrl(url));
				case 1:
					return $elm$core$Result$Err($elm$http$Http$Timeout);
				case 2:
					return $elm$core$Result$Err($elm$http$Http$NetworkError);
				case 3:
					var metadata = resp.a;
					return $elm$core$Result$Err(
						$elm$http$Http$BadStatus(metadata.dr));
				default:
					var body = resp.b;
					var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
					if (!_v1.$) {
						var value = _v1.a;
						return $elm$core$Result$Ok(value);
					} else {
						var err = _v1.a;
						return $elm$core$Result$Err(
							$elm$http$Http$BadBody(
								$elm$json$Json$Decode$errorToString(err)));
					}
			}
		});
};
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{dS: false, d1: r.d1, cv: r.bE, bh: r.bh, br: r.br, bZ: r.bZ, dE: $elm$core$Maybe$Nothing, dJ: r.dJ});
};
var $author$project$ScaffoldApi$loadStudentTask = $elm$http$Http$task(
	{
		d1: $elm$http$Http$emptyBody,
		bh: _List_Nil,
		br: 'get',
		bE: $author$project$HttpUtils$standardStringResolver($author$project$Student$studentDecoder),
		bZ: $elm$core$Maybe$Nothing,
		dJ: $author$project$ServerPath$serverPath + '/auth/student'
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$ScaffoldData$findHardCodedStrings = $elm$core$List$filterMap(
	function (b) {
		if (!b.$) {
			var txt = b.a;
			return $elm$core$Maybe$Just(txt);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$HttpUtils$whateverResolver = $elm$http$Http$stringResolver(
	function (resp) {
		switch (resp.$) {
			case 0:
				var url = resp.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = resp.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.dr));
			default:
				return $elm$core$Result$Ok(0);
		}
	});
var $author$project$ScaffoldApi$logHardCodedStrings = function (allBlurbs) {
	var hardcodedStrings = $author$project$ScaffoldData$findHardCodedStrings(allBlurbs);
	return $elm$core$List$isEmpty(hardcodedStrings) ? $elm$core$Task$succeed(0) : A2(
		$elm$core$Task$onError,
		function (_v0) {
			return $elm$core$Task$succeed(0);
		},
		$elm$http$Http$task(
			{
				d1: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'message',
								$elm$json$Json$Encode$string('The following hard coded strings must be replaced with blurbs:')),
								_Utils_Tuple2(
								'strings',
								A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, hardcodedStrings))
							]))),
				bh: _List_Nil,
				br: 'post',
				bE: $author$project$HttpUtils$whateverResolver,
				bZ: $elm$core$Maybe$Nothing,
				dJ: $author$project$ServerPath$serverPath + '/logging/error'
			}));
};
var $author$project$ScaffoldData$blurbExpired = F2(
	function (now, _v0) {
		var loadedAt = _v0.fG;
		var maxAge = _v0.fN;
		var nowMs = $elm$time$Time$posixToMillis(now);
		var loadedAtMs = $elm$time$Time$posixToMillis(loadedAt);
		var expiresAtMs = loadedAtMs + (maxAge * 1000);
		return _Utils_cmp(expiresAtMs, nowMs) < 0;
	});
var $author$project$ScaffoldData$blurbIsMissing = F4(
	function (now, blurbCache, lang, textOrKey) {
		if (!textOrKey.$) {
			return $elm$core$Maybe$Nothing;
		} else {
			var key = textOrKey.a;
			var _v1 = A2($elm$core$Dict$get, lang, blurbCache);
			if (_v1.$ === 1) {
				return $elm$core$Maybe$Just(key);
			} else {
				var lookup = _v1.a;
				var _v2 = A2(
					$elm$core$Dict$get,
					$elm$core$String$fromInt(key),
					lookup);
				if (_v2.$ === 1) {
					return $elm$core$Maybe$Just(key);
				} else {
					var cachedBlurb = _v2.a;
					return A2($author$project$ScaffoldData$blurbExpired, now, cachedBlurb) ? $elm$core$Maybe$Just(key) : $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $author$project$ScaffoldData$missingBlurbKeys = F4(
	function (requiredBlurbs, blurbCache, now, lang) {
		return A2(
			$elm$core$List$filterMap,
			A3($author$project$ScaffoldData$blurbIsMissing, now, blurbCache, lang),
			requiredBlurbs);
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$core$Basics$round = _Basics_round;
var $author$project$DateUtils$floatToPosix = A2($elm$core$Basics$composeR, $elm$core$Basics$round, $elm$time$Time$millisToPosix);
var $author$project$ScaffoldData$translatedResourceToCachedBlurb = function (_v0) {
	var loadedAtPosix = _v0.fH;
	var maxAge = _v0.fN;
	var value = _v0.gY;
	return {
		fG: $author$project$DateUtils$floatToPosix(loadedAtPosix),
		fN: $elm$core$Basics$round(maxAge),
		gY: value
	};
};
var $author$project$ScaffoldData$mergeBlurbs = F3(
	function (lang, transResp, blurbCache) {
		return A3(
			$elm$core$Dict$insert,
			lang,
			A3(
				$elm$core$List$foldr,
				F2(
					function (_v0, dict) {
						var k = _v0.a;
						var v = _v0.b;
						return A3(
							$elm$core$Dict$insert,
							k,
							$author$project$ScaffoldData$translatedResourceToCachedBlurb(v),
							dict);
					}),
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Dict$empty,
					A2($elm$core$Dict$get, lang, blurbCache)),
				$elm$core$Dict$toList(transResp)),
			blurbCache);
	});
var $author$project$ApiTypes$Translation$TranslatedResource$TranslatedResource = F4(
	function (maxAge, value, loadedAt, loadedAtPosix) {
		return {fG: loadedAt, fH: loadedAtPosix, fN: maxAge, gY: value};
	});
var $author$project$ScaffoldApi$translatedResourceDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'loadedAtPosix', $elm$json$Json$Decode$float),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'loadedAt', $elm$json$Json$Decode$string),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'maxAge', $elm$json$Json$Decode$float),
				$elm$json$Json$Decode$succeed($author$project$ApiTypes$Translation$TranslatedResource$TranslatedResource)))));
var $author$project$ScaffoldApi$translationResponseDecoder = F2(
	function (lang, blurbCache) {
		return A2(
			$elm$json$Json$Decode$map,
			function (transResp) {
				return A3($author$project$ScaffoldData$mergeBlurbs, lang, transResp, blurbCache);
			},
			$elm$json$Json$Decode$dict($author$project$ScaffoldApi$translatedResourceDecoder));
	});
var $author$project$ScaffoldApi$loadTranslationsTask = F3(
	function (allBlurbs, lang, blurbCache) {
		return A2(
			$elm$core$Task$andThen,
			function (now) {
				return A2(
					$elm$core$Task$andThen,
					function (_v0) {
						var keys = A4($author$project$ScaffoldData$missingBlurbKeys, allBlurbs, blurbCache, now, lang);
						return $elm$core$List$isEmpty(keys) ? $elm$core$Task$succeed(blurbCache) : $elm$http$Http$task(
							{
								d1: $elm$http$Http$jsonBody(
									$elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'lang',
												$elm$json$Json$Encode$string(lang)),
												_Utils_Tuple2(
												'keys',
												A2(
													$elm$json$Json$Encode$list,
													A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$json$Json$Encode$string),
													keys))
											]))),
								bh: _List_Nil,
								br: 'post',
								bE: $author$project$HttpUtils$standardStringResolver(
									A2($author$project$ScaffoldApi$translationResponseDecoder, lang, blurbCache)),
								bZ: $elm$core$Maybe$Nothing,
								dJ: $author$project$ServerPath$serverPath + '/translate'
							});
					},
					$author$project$ScaffoldApi$logHardCodedStrings(allBlurbs));
			},
			$elm$time$Time$now);
	});
var $author$project$ScaffoldApi$loadInitialData = F3(
	function (allBlurbs, flags, onResult) {
		return A2(
			$elm$core$Task$attempt,
			onResult,
			A2(
				$elm$core$Task$andThen,
				function (blurbCache) {
					var _v0 = flags.gy;
					if (!_v0.$) {
						var student = _v0.a;
						return $elm$core$Task$succeed(
							{d0: blurbCache, gy: student});
					} else {
						return A2(
							$elm$core$Task$map,
							function (s) {
								return {d0: blurbCache, gy: s};
							},
							$author$project$ScaffoldApi$loadStudentTask);
					}
				},
				A3($author$project$ScaffoldApi$loadTranslationsTask, allBlurbs, flags.H, flags.U)));
	});
var $author$project$ScaffoldApi$loadTranslations = F4(
	function (allBlurbs, lang, blurbCache, onResult) {
		return A2(
			$elm$core$Task$attempt,
			onResult,
			A3($author$project$ScaffoldApi$loadTranslationsTask, allBlurbs, lang, blurbCache));
	});
var $author$project$ScaffoldData$ExtraLargeScreen = 3;
var $author$project$Scaffold$mediaUrlBase = 'https://et2.ef-cdn.com/';
var $author$project$Scaffold$mediaUrl = function (path) {
	return A2($elm$core$String$startsWith, 'http', path) ? path : _Utils_ap($author$project$Scaffold$mediaUrlBase, path);
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$ScaffoldData$LargeScreen = 2;
var $author$project$ScaffoldData$MediumScreen = 1;
var $author$project$ScaffoldData$SmallScreen = 0;
var $author$project$Scaffold$screenSizeFromWidth = function (width) {
	return (width < 768) ? 0 : ((width < 1024) ? 1 : ((width < 1264) ? 2 : 3));
};
var $author$project$Scaffold$makeExternalState = F5(
	function (flags, blurbCache, viewport, allBlurbs, student) {
		var screenSizeFromViewport = A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.g$;
			},
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.g2;
				},
				A2($elm$core$Basics$composeR, $elm$core$Basics$round, $author$project$Scaffold$screenSizeFromWidth)));
		var screenSize = A2(
			$elm$core$Maybe$withDefault,
			3,
			A2($elm$core$Maybe$map, screenSizeFromViewport, viewport));
		return _Utils_Tuple2(
			{
				ad: allBlurbs,
				U: blurbCache,
				ag: flags.N.ag,
				ak: flags.N.ak,
				am: flags.am,
				ap: flags.ap,
				aq: flags.N.aq,
				ar: flags.ar,
				H: flags.H,
				av: flags.N.av,
				bo: $author$project$Scaffold$mediaUrl,
				f4: flags.N.f4,
				aI: screenSize,
				aK: flags.N.aK,
				gy: student,
				gS: A2($author$project$ScaffoldData$createTranslationFunc, flags.H, blurbCache),
				dJ: flags.dJ,
				gW: $elm$core$Dict$fromList(flags.gW)
			},
			$elm$core$Platform$Cmd$none);
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Ports$modalShowing = _Platform_outgoingPort('modalShowing', $elm$json$Json$Encode$bool);
var $author$project$Modal$FadeOut = function (a) {
	return {$: 2, a: a};
};
var $author$project$Modal$fadeDelay = 200;
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Modal$hide = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{S: false}),
		A2(
			$elm$core$Task$perform,
			$author$project$Modal$FadeOut,
			$elm$core$Process$sleep($author$project$Modal$fadeDelay)));
};
var $author$project$Modal$FadeIn = function (a) {
	return {$: 1, a: a};
};
var $author$project$Modal$show = function (model) {
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{Y: true, S: false}),
		A2(
			$elm$core$Task$perform,
			$author$project$Modal$FadeIn,
			$elm$core$Process$sleep($author$project$Modal$fadeDelay / 2)));
};
var $author$project$Scaffold$processEffects = F4(
	function (modal, cmds, config, effs) {
		switch (effs.$) {
			case 4:
				return _Utils_Tuple3(modal, cmds, config);
			case 3:
				var curved = effs.a;
				var inverted = effs.b;
				var content = effs.c;
				var _v1 = $author$project$Modal$show(modal);
				var m = _v1.a;
				var c = _v1.b;
				return _Utils_Tuple3(
					m,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								cmds,
								A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$ModalMsg, c)
							])),
					$elm$core$Maybe$Just(
						{a6: content, a9: curved, bl: inverted}));
			case 2:
				var _v2 = $author$project$Modal$hide(modal);
				var m = _v2.a;
				var c = _v2.b;
				return _Utils_Tuple3(
					m,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								cmds,
								A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$ModalMsg, c)
							])),
					config);
			case 0:
				var cmd = effs.b;
				return _Utils_Tuple3(
					modal,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								cmds,
								A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$AppMsg, cmd)
							])),
					config);
			default:
				var childEffs = effs.a;
				return A3(
					$elm$core$List$foldl,
					F2(
						function (eff_, _v3) {
							var modal_ = _v3.a;
							var cmds_ = _v3.b;
							var config_ = _v3.c;
							return A4($author$project$Scaffold$processEffects, modal_, cmds_, config_, eff_);
						}),
					_Utils_Tuple3(modal, cmds, config),
					childEffs);
		}
	});
var $elm$core$Basics$not = _Basics_not;
var $author$project$Modal$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 4:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Platform$Cmd$none);
			case 0:
				var p = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$elm$core$Task$perform,
						$elm$core$Basics$identity,
						$elm$core$Task$succeed(p)),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{S: true}),
					$elm$core$Platform$Cmd$none,
					model.K(true));
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{Y: false}),
					$elm$core$Platform$Cmd$none,
					model.K(false));
			default:
				if (!model.B) {
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Platform$Cmd$none);
				} else {
					var _v1 = $author$project$Modal$hide(model);
					var m = _v1.a;
					var c = _v1.b;
					return _Utils_Tuple3(m, $elm$core$Platform$Cmd$none, c);
				}
		}
	});
var $author$project$Ports$updateBlurbCache = _Platform_outgoingPort('updateBlurbCache', $elm$core$Basics$identity);
var $author$project$Scaffold$update = F4(
	function (appInit, appUpdate, msg, model) {
		switch (msg.$) {
			case 7:
				var resources = msg.a;
				var viewport = msg.b;
				if (!model.$) {
					var flags = model.a;
					var allBlurbs = _Utils_ap(
						$author$project$Scaffold$extraBlurbs,
						_Utils_ap(resources, flags.eS));
					return _Utils_Tuple2(
						A2(
							$author$project$Scaffold$Uninitialised,
							flags,
							$elm$core$Maybe$Just(viewport)),
						A3(
							$author$project$ScaffoldApi$loadInitialData,
							allBlurbs,
							flags,
							$author$project$Scaffold$CompleteInitialisation(allBlurbs)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var url = msg.a;
				if (model.$ === 1) {
					var external = model.a;
					var appModel = model.b;
					var modal = model.c;
					var modalContent = model.d;
					return _Utils_Tuple2(
						A4(
							$author$project$Scaffold$Initialised,
							_Utils_update(
								external,
								{dJ: url}),
							appModel,
							modal,
							modalContent),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var encoded = msg.a;
				switch (model.$) {
					case 1:
						var external = model.a;
						var translate = external.gS;
						var appModel = model.b;
						var modal = model.c;
						var modalContent = model.d;
						var _v4 = A2($elm$json$Json$Decode$decodeValue, $author$project$Student$studentDecoder, encoded);
						if (_v4.$ === 1) {
							var err = _v4.a;
							return _Utils_Tuple2(
								A4($author$project$Scaffold$Initialised, external, appModel, modal, modalContent),
								$author$project$Ports$bubbleError(
									A4(
										$author$project$Messages$decodingErrorMessage,
										translate($author$project$Scaffold$errorTitleBlurb),
										$elm$core$Maybe$Just(
											translate($author$project$Scaffold$decodeStudentErrorBlurb)),
										'Decoding Student',
										err)));
						} else {
							var student = _v4.a;
							return _Utils_Tuple2(
								A4(
									$author$project$Scaffold$Initialised,
									_Utils_update(
										external,
										{gy: student}),
									appModel,
									modal,
									modalContent),
								$elm$core$Platform$Cmd$none);
						}
					case 0:
						var flags = model.a;
						var viewport = model.b;
						return _Utils_Tuple2(
							A2($author$project$Scaffold$Uninitialised, flags, viewport),
							$elm$core$Platform$Cmd$none);
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				if (msg.b.$ === 1) {
					var err = msg.b.a;
					return _Utils_Tuple2(
						model,
						$author$project$Ports$bubbleError(
							A4(
								$author$project$Messages$httpErrorMessage,
								'Error!',
								$elm$core$Maybe$Just('Unable to initialise the school'),
								'GetNextGroupClass',
								err)));
				} else {
					var allBlurbs = msg.a;
					var blurbs = msg.b.a.d0;
					var student = msg.b.a.gy;
					switch (model.$) {
						case 0:
							var flags = model.a;
							var viewport = model.b;
							var modal = $author$project$Modal$init(
								$author$project$Modal$defaultConfig($author$project$Ports$modalShowing));
							var _v6 = A5($author$project$Scaffold$makeExternalState, flags, blurbs, viewport, allBlurbs, student);
							var external = _v6.a;
							var cmd = _v6.b;
							var _v7 = appInit(external);
							var appModel = _v7.a;
							var appEffects = _v7.b;
							var _v8 = A4($author$project$Scaffold$processEffects, modal, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing, appEffects);
							var updatedModal = _v8.a;
							var initCmds = _v8.b;
							var updatedModalConfig = _v8.c;
							return _Utils_Tuple2(
								A4($author$project$Scaffold$Initialised, external, appModel, updatedModal, updatedModalConfig),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											initCmds,
											cmd,
											$author$project$Ports$updateBlurbCache(
											$author$project$ScaffoldData$encodeBlurbCache(blurbs))
										])));
						case 1:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 8:
				if (msg.a.$ === 1) {
					var err = msg.a.a;
					return _Utils_Tuple2(
						model,
						$author$project$Ports$bubbleError(
							A4(
								$author$project$Messages$httpErrorMessage,
								'Error!',
								$elm$core$Maybe$Just('Error retrieving translations'),
								'GetTranslations',
								err)));
				} else {
					var blurbCache = msg.a.a;
					switch (model.$) {
						case 1:
							var external = model.a;
							var appModel = model.b;
							var modal = model.c;
							var modalContent = model.d;
							return _Utils_Tuple2(
								A4(
									$author$project$Scaffold$Initialised,
									_Utils_update(
										external,
										{
											U: blurbCache,
											gS: A2($author$project$ScaffoldData$createTranslationFunc, external.H, blurbCache)
										}),
									appModel,
									modal,
									modalContent),
								$author$project$Ports$updateBlurbCache(
									$author$project$ScaffoldData$encodeBlurbCache(blurbCache)));
						case 0:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			case 0:
				var lang = msg.a;
				switch (model.$) {
					case 1:
						var external = model.a;
						var appModel = model.b;
						var modal = model.c;
						var modalContent = model.d;
						return _Utils_Tuple2(
							A4(
								$author$project$Scaffold$Initialised,
								_Utils_update(
									external,
									{H: lang}),
								appModel,
								modal,
								modalContent),
							A4($author$project$ScaffoldApi$loadTranslations, external.ad, lang, external.U, $author$project$Scaffold$ReceivedTranslations));
					case 0:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var appMsg = msg.a;
				if (model.$ === 1) {
					var external = model.a;
					var appModel = model.b;
					var modal = model.c;
					var modalConfig = model.d;
					var _v12 = A3(appUpdate, external, appMsg, appModel);
					var updatedAppModel = _v12.a;
					var appEffects = _v12.b;
					var _v13 = A4($author$project$Scaffold$processEffects, modal, $elm$core$Platform$Cmd$none, modalConfig, appEffects);
					var updatedModal = _v13.a;
					var cmds = _v13.b;
					var updatedModalConfig = _v13.c;
					return _Utils_Tuple2(
						A4($author$project$Scaffold$Initialised, external, updatedAppModel, updatedModal, updatedModalConfig),
						cmds);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var subMsg = msg.a;
				if (model.$ === 1) {
					var external = model.a;
					var appModel = model.b;
					var modal = model.c;
					var modalContent = model.d;
					var _v15 = A2($author$project$Modal$update, subMsg, modal);
					var updated = _v15.a;
					var parentCmd = _v15.b;
					var subCmds = _v15.c;
					return _Utils_Tuple2(
						A4($author$project$Scaffold$Initialised, external, appModel, updated, modalContent),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$ModalMsg, subCmds),
									A2($elm$core$Platform$Cmd$map, $author$project$Scaffold$AppMsg, parentCmd)
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var width = msg.a;
				if (model.$ === 1) {
					var external = model.a;
					var appModel = model.b;
					var modal = model.c;
					var modalContent = model.d;
					return _Utils_Tuple2(
						A4(
							$author$project$Scaffold$Initialised,
							_Utils_update(
								external,
								{
									aI: $author$project$Scaffold$screenSizeFromWidth(width)
								}),
							appModel,
							modal,
							modalContent),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Modal$ModalContent = $elm$core$Basics$identity;
var $author$project$Modal$Parent = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Modal$wrap = $elm$html$Html$map($author$project$Modal$Parent);
var $author$project$Modal$content = function (child) {
	return function (_v0) {
		return $author$project$Modal$wrap(child);
	};
};
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$HtmlUtils$empty = $elm$html$Html$text('');
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $author$project$Icons$CloseIcon$icon = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 30 30')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M25.29 27a1 1 0 01-.7-.29L5.29 7.41a1 1 0 010-1.41L6 5.29a1 1 0 011.41 0l19.3 19.3a1 1 0 010 1.41l-.71.71a1 1 0 01-.71.29z')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M6.71 27a1 1 0 01-.71-.29L5.29 26a1 1 0 010-1.41l19.3-19.3a1 1 0 011.41 0l.71.71a1 1 0 010 1.41l-19.3 19.3a1 1 0 01-.7.29z')
				]),
			_List_Nil)
		]));
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $author$project$Modal$modal = F4(
	function (curved, inverted, model, _v0) {
		var renderContent = _v0;
		return model.Y ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-modal js-modal'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('is-visible', model.S)
						])),
					$elm$html$Html$Events$onClick($author$project$Modal$InternalHide)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-modal__container')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-modal__inner-w'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('-curved', curved)
										])),
									A2($elm$html$Html$Attributes$attribute, 'data-qa', 'modal'),
									A2(
									$elm$html$Html$Events$stopPropagationOn,
									'click',
									$elm$json$Json$Decode$succeed(
										_Utils_Tuple2($author$project$Modal$NoOp, true)))
								]),
							_List_fromArray(
								[
									model.B ? A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-modal__close-btn'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('-inverted', inverted)
												])),
											A2($elm$html$Html$Attributes$attribute, 'data-qa', 'modal-close'),
											A2($elm$html$Html$Attributes$attribute, 'tabindex', '0'),
											$elm$html$Html$Events$onClick($author$project$Modal$InternalHide)
										]),
									_List_fromArray(
										[$author$project$Icons$CloseIcon$icon])) : $author$project$HtmlUtils$empty,
									renderContent(model)
								]))
						]))
				])) : $author$project$HtmlUtils$empty;
	});
var $author$project$Scaffold$view = F3(
	function (appView, loadingView, model) {
		switch (model.$) {
			case 1:
				var external = model.a;
				var appModel = model.b;
				var modal = model.c;
				var modalConfig = model.d;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$map,
							$author$project$Scaffold$AppMsg,
							A2(appView, external, appModel)),
							function () {
							if (!modalConfig.$) {
								var curved = modalConfig.a.a9;
								var inverted = modalConfig.a.bl;
								var content = modalConfig.a.a6;
								return A2(
									$elm$html$Html$map,
									$author$project$Scaffold$ModalMsg,
									A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												A4(
												$author$project$Modal$modal,
												curved,
												inverted,
												modal,
												$author$project$Modal$content(
													A2(content, external, appModel)))
											])));
							} else {
								return $author$project$HtmlUtils$empty;
							}
						}()
						]));
			case 0:
				return A2($elm$html$Html$map, $author$project$Scaffold$AppMsg, loadingView);
			default:
				return A2($elm$html$Html$map, $author$project$Scaffold$AppMsg, loadingView);
		}
	});
var $author$project$Scaffold$bootstrap = F6(
	function (resources, appView, loadingView, appInit, appUpdate, appSubs) {
		return $elm$browser$Browser$element(
			{
				fm: $author$project$Scaffold$init(resources),
				gC: $author$project$Scaffold$subscriptions(appSubs),
				gV: A2($author$project$Scaffold$update, appInit, appUpdate),
				g_: A2($author$project$Scaffold$view, appView, loadingView)
			});
	});
var $author$project$Data$AnnouncementEffect = 2;
var $author$project$Data$AnnouncementMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Data$InitialDataEffect = 0;
var $author$project$Data$InitialDataReceived = function (a) {
	return {$: 6, a: a};
};
var $author$project$Data$NoSubView = 3;
var $author$project$Scaffold$BatchEffect = function (a) {
	return {$: 1, a: a};
};
var $author$project$EffectUtils$batchEffect = $author$project$Scaffold$BatchEffect;
var $author$project$Scaffold$Effect = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$EffectUtils$fromCmd = $author$project$Scaffold$Effect;
var $author$project$Api$apiPath = _Utils_ap(
	$author$project$ServerPath$serverPathParts,
	_List_fromArray(
		['privateclass']));
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.dr));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {db: reqs, du: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.dE;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.db));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.du)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					dS: r.dS,
					d1: r.d1,
					cv: A2(_Http_mapExpect, func, r.cv),
					bh: r.bh,
					br: r.br,
					bZ: r.bZ,
					dE: r.dE,
					dJ: r.dJ
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{dS: false, d1: r.d1, cv: r.cv, bh: r.bh, br: r.br, bZ: r.bZ, dE: r.dE, dJ: r.dJ}));
};
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{d1: $elm$http$Http$emptyBody, cv: r.cv, bh: _List_Nil, br: 'GET', bZ: $elm$core$Maybe$Nothing, dE: $elm$core$Maybe$Nothing, dJ: r.dJ});
};
var $author$project$ApiTypes$Privateclass$Index$PrivateClassPageData = F5(
	function (bookings, announcement, maxPrivateLessons, maxUnitReviews, videoOptions) {
		return {dU: announcement, d2: bookings, fO: maxPrivateLessons, fP: maxUnitReviews, gZ: videoOptions};
	});
var $author$project$ApiTypes$Privateclass$Index$BookedClass = F7(
	function (imageUrl, name, teacherName, teacherFullName, time, teachingMinutes, isUnitReview) {
		return {cF: imageUrl, fs: isUnitReview, cW: name, gH: teacherFullName, gI: teacherName, dy: teachingMinutes, gM: time};
	});
var $author$project$ApiTypes$ClassTime$ClassTime = F3(
	function (start, end, classroomFreezesAt) {
		return {ef: classroomFreezesAt, eL: end, gw: start};
	});
var $author$project$ApiTypes$FormattedDate$FormattedDate = F5(
	function (posix, formatted, formattedShort, formattedDay, formattedTime) {
		return {eX: formatted, eY: formattedDay, eZ: formattedShort, e_: formattedTime, f7: posix};
	});
var $author$project$DateUtils$formattedDateDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'formattedTime', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'formattedDay', $elm$json$Json$Decode$string),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'formattedShort', $elm$json$Json$Decode$string),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'formatted', $elm$json$Json$Decode$string),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'posix', $elm$json$Json$Decode$float),
					$elm$json$Json$Decode$succeed($author$project$ApiTypes$FormattedDate$FormattedDate))))));
var $author$project$Data$classTimeDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'classroomFreezesAt', $elm$json$Json$Decode$float),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'end', $author$project$DateUtils$formattedDateDecoder),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'start', $author$project$DateUtils$formattedDateDecoder),
			$elm$json$Json$Decode$succeed($author$project$ApiTypes$ClassTime$ClassTime))));
var $author$project$Data$bookedClassDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'isUnitReview', $elm$json$Json$Decode$bool),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'teachingMinutes', $elm$json$Json$Decode$float),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'time', $author$project$Data$classTimeDecoder),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'teacherFullName', $elm$json$Json$Decode$string),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'teacherName', $elm$json$Json$Decode$string),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
						A2(
							$author$project$JsonUtils$apply,
							$elm$json$Json$Decode$maybe(
								A2($elm$json$Json$Decode$field, 'imageUrl', $elm$json$Json$Decode$string)),
							$elm$json$Json$Decode$succeed($author$project$ApiTypes$Privateclass$Index$BookedClass))))))));
var $author$project$ApiTypes$Privateclass$Index$VideoOptions = F2(
	function (classRecordingEnabled, classRecordingDefault) {
		return {ed: classRecordingDefault, ee: classRecordingEnabled};
	});
var $author$project$Data$videoOptionsDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'classRecordingDefault', $elm$json$Json$Decode$bool),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'classRecordingEnabled', $elm$json$Json$Decode$bool),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$Privateclass$Index$VideoOptions)));
var $author$project$Data$privateClassPageDataDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'videoOptions', $author$project$Data$videoOptionsDecoder),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'maxUnitReviews', $elm$json$Json$Decode$float),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'maxPrivateLessons', $elm$json$Json$Decode$float),
			A2(
				$author$project$JsonUtils$apply,
				$elm$json$Json$Decode$maybe(
					A2($elm$json$Json$Decode$field, 'announcement', $elm$json$Json$Decode$string)),
				A2(
					$author$project$JsonUtils$apply,
					A2(
						$elm$json$Json$Decode$field,
						'bookings',
						$elm$json$Json$Decode$list($author$project$Data$bookedClassDecoder)),
					$elm$json$Json$Decode$succeed($author$project$ApiTypes$Privateclass$Index$PrivateClassPageData))))));
var $author$project$Api$getInitialData = function (onResult) {
	return $elm$http$Http$get(
		{
			cv: A2($elm$http$Http$expectJson, onResult, $author$project$Data$privateClassPageDataDecoder),
			dJ: A2(
				$elm$url$Url$Builder$absolute,
				_Utils_ap(
					$author$project$Api$apiPath,
					_List_fromArray(
						['initialdata'])),
				_List_Nil)
		});
};
var $author$project$Announcement$ReadFromLocalStorage = 1;
var $author$project$Ports$requestFromLocalStorage = _Platform_outgoingPort(
	'requestFromLocalStorage',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					function ($) {
					return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$core$Basics$identity, $);
				}(b)
				]));
	});
var $author$project$Announcement$toggleKey = 'evc-ui-widget-announcementbar_expanded';
var $author$project$Announcement$init = _Utils_Tuple2(
	false,
	A2(
		$author$project$EffectUtils$fromCmd,
		1,
		$author$project$Ports$requestFromLocalStorage(
			_Utils_Tuple2(
				$author$project$Announcement$toggleKey,
				$elm$core$Maybe$Just(
					$elm$json$Json$Encode$bool(true))))));
var $author$project$Components$ProgressIndicator$Navigate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$ProgressIndicator$NoOp = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$ProgressIndicator$Step = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Components$ProgressIndicator$init = F2(
	function (initialIdx, steps) {
		return {
			o: initialIdx,
			aM: A2(
				$elm$core$List$indexedMap,
				F2(
					function (idx, _v0) {
						var b = _v0.a;
						var enabled = _v0.b;
						return enabled ? A2(
							$author$project$Components$ProgressIndicator$Step,
							$author$project$Components$ProgressIndicator$Navigate(idx),
							b) : A2(
							$author$project$Components$ProgressIndicator$Step,
							$author$project$Components$ProgressIndicator$NoOp(idx),
							b);
					}),
				steps)
		};
	});
var $author$project$Scaffold$CloseModal = {$: 2};
var $author$project$Scaffold$NoEffect = {$: 4};
var $author$project$Scaffold$OpenModal = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$EffectUtils$mapEffect = F4(
	function (messageMapper, modelExtractor, effectType, eff) {
		switch (eff.$) {
			case 4:
				return $author$project$Scaffold$NoEffect;
			case 2:
				return $author$project$Scaffold$CloseModal;
			case 3:
				var curved = eff.a;
				var inverted = eff.b;
				var fn = eff.c;
				return A3(
					$author$project$Scaffold$OpenModal,
					curved,
					inverted,
					F2(
						function (e, s) {
							return A2(
								$elm$html$Html$map,
								messageMapper,
								A2(
									fn,
									e,
									modelExtractor(s)));
						}));
			case 0:
				var cmd = eff.b;
				return A2(
					$author$project$EffectUtils$fromCmd,
					effectType,
					A2($elm$core$Platform$Cmd$map, messageMapper, cmd));
			default:
				var effs = eff.a;
				return $author$project$Scaffold$BatchEffect(
					A2(
						$elm$core$List$map,
						A3($author$project$EffectUtils$mapEffect, messageMapper, modelExtractor, effectType),
						effs));
		}
	});
var $author$project$Control$init = function (_v0) {
	var _v1 = $author$project$Announcement$init;
	var announcementModel = _v1.a;
	var announcementEffect = _v1.b;
	return _Utils_Tuple2(
		{
			dU: announcementModel,
			aX: false,
			t: $elm$core$Maybe$Nothing,
			ai: $elm$core$Maybe$Nothing,
			l: A2($author$project$Components$ProgressIndicator$init, 0, _List_Nil),
			w: $elm$core$Maybe$Nothing,
			bL: $elm$core$Maybe$Nothing,
			D: 3
		},
		$author$project$EffectUtils$batchEffect(
			_List_fromArray(
				[
					A4(
					$author$project$EffectUtils$mapEffect,
					$author$project$Data$AnnouncementMsg,
					function ($) {
						return $.dU;
					},
					2,
					announcementEffect),
					A2(
					$author$project$EffectUtils$fromCmd,
					0,
					$author$project$Api$getInitialData($author$project$Data$InitialDataReceived))
				])));
};
var $author$project$Data$LanguageChanged = function (a) {
	return {$: 3, a: a};
};
var $author$project$Data$OnStudentChange = function (a) {
	return {$: 10, a: a};
};
var $author$project$Data$SelectTimeslotMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$SelectTimeslot$Data$AutoCompleterMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$SelectTimeslot$Data$OnStudentChange = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Autocompleter$DecrementHighlightedIndex = {$: 8};
var $author$project$Components$Autocompleter$IncrementHighlightedIndex = {$: 7};
var $author$project$Components$Autocompleter$NoOp = {$: 10};
var $author$project$Components$Autocompleter$SelectThing = function (a) {
	return {$: 4, a: a};
};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $author$project$Components$Autocompleter$keyDownDecoder = function (model) {
	return A2(
		$elm$json$Json$Decode$map,
		function (k) {
			switch (k) {
				case 'ArrowDown':
					return $author$project$Components$Autocompleter$IncrementHighlightedIndex;
				case 'ArrowUp':
					return $author$project$Components$Autocompleter$DecrementHighlightedIndex;
				case 'Enter':
					var _v1 = A2($elm_community$list_extra$List$Extra$getAt, model.u, model.E);
					if (!_v1.$) {
						var thing = _v1.a;
						return $author$project$Components$Autocompleter$SelectThing(thing);
					} else {
						return $author$project$Components$Autocompleter$NoOp;
					}
				default:
					return $author$project$Components$Autocompleter$NoOp;
			}
		},
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
};
var $author$project$Components$Autocompleter$subscriptions = function (model) {
	return $elm$browser$Browser$Events$onKeyDown(
		$author$project$Components$Autocompleter$keyDownDecoder(model));
};
var $author$project$SelectTimeslot$Control$subscriptions = F2(
	function (_v0, model) {
		if (model.$ === 1) {
			var initModel = model.a;
			return $elm$core$Platform$Sub$batch(
				_List_fromArray(
					[
						$author$project$Ports$onStudentChange($author$project$SelectTimeslot$Data$OnStudentChange),
						A2(
						$elm$core$Platform$Sub$map,
						$author$project$SelectTimeslot$Data$AutoCompleterMsg,
						$author$project$Components$Autocompleter$subscriptions(initModel.dX))
					]));
		} else {
			return $elm$core$Platform$Sub$none;
		}
	});
var $author$project$Control$subscriptions = F2(
	function (external, model) {
		return $elm$core$Platform$Sub$batch(
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Sub$none,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeL,
							$elm$core$Platform$Sub$map($author$project$Data$SelectTimeslotMsg),
							$author$project$SelectTimeslot$Control$subscriptions(external)),
						model.w)),
					$author$project$Ports$onLanguageChange($author$project$Data$LanguageChanged),
					$author$project$Ports$onStudentChange($author$project$Data$OnStudentChange)
				]));
	});
var $author$project$Data$BookingsReceived = function (a) {
	return {$: 11, a: a};
};
var $author$project$Data$BubbleErrorEffect = 6;
var $author$project$Data$ChangeStudentEffect = 9;
var $author$project$Data$ChangeUrlEffect = 1;
var $author$project$Data$ConfirmEffect = 5;
var $author$project$Data$ConfirmMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Data$ConfirmPage = 2;
var $author$project$Data$MaxBookings = 4;
var $author$project$Data$NoCredit = 5;
var $author$project$Data$ProgressEffect = 7;
var $author$project$Data$ProgressMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Data$ReloadBookingsEffect = 8;
var $author$project$Data$SelectTimeslotEffect = 4;
var $author$project$Data$SelectTimeslotPage = 1;
var $author$project$Data$SelectTopicEffect = 3;
var $author$project$Data$SelectTopicMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Data$SelectTopicPage = 0;
var $author$project$SelectTimeslot$Data$TopicFromUnit = function (a) {
	return {$: 1, a: a};
};
var $author$project$SelectTimeslot$Data$TopicSelected = function (a) {
	return {$: 0, a: a};
};
var $author$project$Data$Unlicensed = 6;
var $author$project$Ports$changeStudent = _Platform_outgoingPort('changeStudent', $elm$core$Basics$identity);
var $author$project$Ports$changeUrl = _Platform_outgoingPort(
	'changeUrl',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'blank',
					$elm$json$Json$Encode$bool($.d_)),
					_Utils_Tuple2(
					'external',
					$elm$json$Json$Encode$bool($.eR)),
					_Utils_Tuple2(
					'url',
					$elm$json$Json$Encode$string($.dJ))
				]));
	});
var $author$project$EffectUtils$closeModal = $author$project$Scaffold$CloseModal;
var $author$project$ListUtils$appendMaybe = F2(
	function (x, xs) {
		if (!x.$) {
			var x_ = x.a;
			return _Utils_ap(
				xs,
				_List_fromArray(
					[x_]));
		} else {
			return xs;
		}
	});
var $author$project$JsonUtils$encodeMaybe = function (encoder) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(encoder),
		$elm$core$Maybe$withDefault($elm$json$Json$Encode$null));
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$Student$encodeLimit = function (limit) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'amount',
				$elm$json$Json$Encode$float(limit.dT)),
				_Utils_Tuple2(
				'remaining',
				$elm$json$Json$Encode$float(limit.gh))
			]));
};
var $author$project$Student$encodeQuota = function (quota) {
	if (!quota.$) {
		return $elm$json$Json$Encode$string('unlimited');
	} else {
		var limit = quota.a;
		return $author$project$Student$encodeLimit(limit);
	}
};
var $author$project$Student$encodeGrantsAndCreditsEntry = function (entry) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'quota',
				$author$project$Student$encodeQuota(entry.ge)),
				_Utils_Tuple2(
				'expired',
				A2($author$project$JsonUtils$encodeMaybe, $elm$json$Json$Encode$bool, entry.eP)),
				_Utils_Tuple2(
				'expirationDate',
				A2($author$project$JsonUtils$encodeMaybe, $elm$json$Json$Encode$float, entry.eO))
			]));
};
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$JsonUtils$encodeMaybeNameValue = F2(
	function (n, encoder) {
		return $elm$core$Maybe$map(
			A2(
				$elm$core$Basics$composeR,
				encoder,
				$elm$core$Tuple$pair(n)));
	});
var $author$project$Student$encodeGrantsAndCredits = function (gandc) {
	var encode = function (n) {
		return A2($author$project$JsonUtils$encodeMaybeNameValue, n, $author$project$Student$encodeGrantsAndCreditsEntry);
	};
	return $elm$json$Json$Encode$object(
		A2(
			$author$project$ListUtils$appendMaybe,
			A2(encode, 'mango', gandc.fL),
			A2(
				$author$project$ListUtils$appendMaybe,
				A2(encode, 'customMcDonalds', gandc.ev),
				A2(
					$author$project$ListUtils$appendMaybe,
					A2(encode, 'customRwanda', gandc.ex),
					A2(
						$author$project$ListUtils$appendMaybe,
						A2(encode, 'customRocheSAG', gandc.ew),
						A2(
							$author$project$ListUtils$appendMaybe,
							A2(encode, 'dubaiAirports', gandc.eF),
							A2(
								$author$project$ListUtils$appendMaybe,
								A2(encode, 'portuguese', gandc.f6),
								A2(
									$author$project$ListUtils$appendMaybe,
									A2(encode, 'swedish', gandc.gD),
									A2(
										$author$project$ListUtils$appendMaybe,
										A2(encode, 'spanish', gandc.gt),
										A2(
											$author$project$ListUtils$appendMaybe,
											A2(encode, 'french', gandc.e$),
											A2(
												$author$project$ListUtils$appendMaybe,
												A2(encode, 'german', gandc.e2),
												A2(
													$author$project$ListUtils$appendMaybe,
													A2(encode, 'travelEnglish', gandc.gT),
													A2(
														$author$project$ListUtils$appendMaybe,
														A2(encode, 'industryEnglish', gandc.fk),
														A2(
															$author$project$ListUtils$appendMaybe,
															A2(encode, 'businessEnglish', gandc.d4),
															A2(
																$author$project$ListUtils$appendMaybe,
																A2(encode, 'generalEnglish', gandc.e0),
																A2(
																	$author$project$ListUtils$appendMaybe,
																	A2(encode, 'catalyst', gandc.d8),
																	A2(
																		$author$project$ListUtils$appendMaybe,
																		A2(encode, 'retakePlacementTest', gandc.gj),
																		A2(
																			$author$project$ListUtils$appendMaybe,
																			A2(encode, 'liveChat', gandc.fF),
																			A2(
																				$author$project$ListUtils$appendMaybe,
																				A2(encode, 'efset50', gandc.eI),
																				A2(
																					$author$project$ListUtils$appendMaybe,
																					A2(encode, 'efsetSpeaking', gandc.eJ),
																					A2(
																						$author$project$ListUtils$appendMaybe,
																						A2(encode, 'privateScheduledLesson', gandc.gc),
																						A2(
																							$author$project$ListUtils$appendMaybe,
																							A2(encode, 'groupScheduledLesson', gandc.fa),
																							A2(
																								$author$project$ListUtils$appendMaybe,
																								A2(encode, 'hultEfPro', gandc.fg),
																								A2(
																									$author$project$ListUtils$appendMaybe,
																									A2(encode, 'unitReview', gandc.gU),
																									A2(
																										$author$project$ListUtils$appendMaybe,
																										A2(encode, 'spinCourse', gandc.gu),
																										A2(
																											$author$project$ListUtils$appendMaybe,
																											A2(encode, 'personalCoaching', gandc.f5),
																											A2(
																												$author$project$ListUtils$appendMaybe,
																												A2(encode, 'writingClass', gandc.g4),
																												A2(
																													$author$project$ListUtils$appendMaybe,
																													A2(encode, 'groupDiscussion', gandc.e8),
																													A2(
																														$author$project$ListUtils$appendMaybe,
																														A2(encode, 'privateLesson', gandc.ga),
																														A2(
																															$author$project$ListUtils$appendMaybe,
																															A2(encode, 'selfStudy', gandc.gp),
																															A2(
																																$author$project$ListUtils$appendMaybe,
																																A2(encode, 'faceToFaceStudy', gandc.eU),
																																_List_Nil)))))))))))))))))))))))))))))));
};
var $author$project$Student$encodeCourse = function (_v0) {
	var course = _v0;
	return $elm$json$Json$Encode$string(course);
};
var $author$project$Student$encodeTopic = function (topic) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(topic.fh)),
				_Utils_Tuple2(
				'course',
				$author$project$Student$encodeCourse(topic.en))
			]));
};
var $author$project$Student$encodeStudyTargets = function (targets) {
	return $elm$json$Json$Encode$object(
		A2(
			$author$project$ListUtils$appendMaybe,
			A3($author$project$JsonUtils$encodeMaybeNameValue, 'initialTopic', $author$project$Student$encodeTopic, targets.fn),
			_List_fromArray(
				[
					_Utils_Tuple2(
					'generalEnglishUnits',
					$elm$json$Json$Encode$float(targets.e1)),
					_Utils_Tuple2(
					'industryEnglishUnits',
					$elm$json$Json$Encode$float(targets.fl)),
					_Utils_Tuple2(
					'businessEnglishUnits',
					$elm$json$Json$Encode$float(targets.d5)),
					_Utils_Tuple2(
					'privateLessons',
					$elm$json$Json$Encode$float(targets.gb)),
					_Utils_Tuple2(
					'groupLessons',
					$elm$json$Json$Encode$float(targets.e9)),
					_Utils_Tuple2(
					'mandatoryIndustryTopics',
					A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, targets.fK)),
					_Utils_Tuple2(
					'mandatoryBusinessTopics',
					A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, targets.fJ))
				])));
};
var $author$project$Student$encodeStudent = function (student) {
	return $elm$json$Json$Encode$object(
		A2(
			$author$project$ListUtils$appendMaybe,
			A3($author$project$JsonUtils$encodeMaybeNameValue, 'department', $elm$json$Json$Encode$string, student.cp),
			A2(
				$author$project$ListUtils$appendMaybe,
				A3($author$project$JsonUtils$encodeMaybeNameValue, 'seniority', $elm$json$Json$Encode$string, student.dj),
				A2(
					$author$project$ListUtils$appendMaybe,
					A3($author$project$JsonUtils$encodeMaybeNameValue, 'phoneNumber', $elm$json$Json$Encode$string, student.c$),
					_List_fromArray(
						[
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$string(student.fh)),
							_Utils_Tuple2(
							'userId',
							$elm$json$Json$Encode$string(student.gX)),
							_Utils_Tuple2(
							'firstName',
							$elm$json$Json$Encode$string(student.eW)),
							_Utils_Tuple2(
							'lastName',
							$elm$json$Json$Encode$string(student.fv)),
							_Utils_Tuple2(
							'country',
							$elm$json$Json$Encode$string(student.ck)),
							_Utils_Tuple2(
							'gender',
							$elm$json$Json$Encode$string(student.cA)),
							_Utils_Tuple2(
							'companyId',
							$elm$json$Json$Encode$string(student.ei)),
							_Utils_Tuple2(
							'emailAddress',
							$elm$json$Json$Encode$string(student.cq)),
							_Utils_Tuple2(
							'emailLanguage',
							$elm$json$Json$Encode$string(student.cr)),
							_Utils_Tuple2(
							'reportType',
							$elm$json$Json$Encode$string(student.da)),
							_Utils_Tuple2(
							'grantsAndCredits',
							$author$project$Student$encodeGrantsAndCredits(student.e6)),
							_Utils_Tuple2(
							'bookingConfirmationEmail',
							$elm$json$Json$Encode$bool(student.cd)),
							_Utils_Tuple2(
							'courseExpiry',
							$elm$json$Json$Encode$string(student.eo)),
							_Utils_Tuple2(
							'courseStart',
							$elm$json$Json$Encode$string(student.eq)),
							_Utils_Tuple2(
							'studyTargets',
							$author$project$Student$encodeStudyTargets(student.gA)),
							_Utils_Tuple2(
							'timezone',
							$elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'key',
										$elm$json$Json$Encode$string(student.gO.ft)),
										_Utils_Tuple2(
										'displayName',
										$elm$json$Json$Encode$string(student.gO.eD))
									]))),
							_Utils_Tuple2(
							'clientTimezone',
							$elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'key',
										$elm$json$Json$Encode$string(student.eg.ft)),
										_Utils_Tuple2(
										'displayName',
										$elm$json$Json$Encode$string(student.eg.eD))
									])))
						])))));
};
var $elm$core$Basics$ge = _Utils_ge;
var $author$project$Data$PrivateLesson = {$: 0};
var $author$project$Data$UnitReview = function (a) {
	return {$: 1, a: a};
};
var $author$project$Data$getLessonType = function (_v0) {
	var urlParams = _v0.gW;
	var _v1 = A2($elm$core$Dict$get, 'templateUnitId', urlParams);
	if (_v1.$ === 1) {
		return $author$project$Data$PrivateLesson;
	} else {
		var templateUnitId = _v1.a;
		return $author$project$Data$UnitReview(templateUnitId);
	}
};
var $author$project$Data$getVideoOptions = function (_v0) {
	var data = _v0.ai;
	if (data.$ === 1) {
		return {ed: false, ee: false};
	} else {
		var videoOptions = data.a.gZ;
		return videoOptions;
	}
};
var $author$project$Student$NoGrantOrCredit = {$: 2};
var $author$project$Student$Credit = function (a) {
	return {$: 1, a: a};
};
var $author$project$Student$Grant = {$: 0};
var $author$project$Student$getGrantOrRemainingCredit = function (feature) {
	if (feature.$ === 1) {
		return $author$project$Student$NoGrantOrCredit;
	} else {
		var quota = feature.a.ge;
		if (quota.$ === 1) {
			var remaining = quota.a.gh;
			return (remaining > 0) ? $author$project$Student$Credit(remaining) : $author$project$Student$NoGrantOrCredit;
		} else {
			return $author$project$Student$Grant;
		}
	}
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$Student$hasGrantOrRemainingCredit = function (feature) {
	return !_Utils_eq(
		$author$project$Student$getGrantOrRemainingCredit(feature),
		$author$project$Student$NoGrantOrCredit);
};
var $author$project$Confirm$Data$GetTeacherDetailsEffect = 0;
var $author$project$Confirm$Data$NoModal = 0;
var $author$project$Confirm$Data$TeacherReceived = function (a) {
	return {$: 0, a: a};
};
var $author$project$Confirm$Api$apiPath = _Utils_ap(
	$author$project$ServerPath$serverPathParts,
	_List_fromArray(
		['privateclass']));
var $author$project$ApiTypes$Privateclass$Index$Teacher = F7(
	function (available, displayName, firstName, lastName, lastTeacher, teacherId, bilingualism) {
		return {dY: available, dZ: bilingualism, eD: displayName, eW: firstName, fv: lastName, fw: lastTeacher, dx: teacherId};
	});
var $author$project$Confirm$Data$teacherDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'bilingualism', $elm$json$Json$Decode$string)),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'teacherId', $elm$json$Json$Decode$string),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'lastTeacher', $elm$json$Json$Decode$bool),
			A2(
				$author$project$JsonUtils$apply,
				$elm$json$Json$Decode$maybe(
					A2($elm$json$Json$Decode$field, 'lastName', $elm$json$Json$Decode$string)),
				A2(
					$author$project$JsonUtils$apply,
					$elm$json$Json$Decode$maybe(
						A2($elm$json$Json$Decode$field, 'firstName', $elm$json$Json$Decode$string)),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'displayName', $elm$json$Json$Decode$string),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'available', $elm$json$Json$Decode$bool),
							$elm$json$Json$Decode$succeed($author$project$ApiTypes$Privateclass$Index$Teacher))))))));
var $author$project$Confirm$Api$getTeacherDetails = F2(
	function (teacherId, onResult) {
		return $elm$http$Http$get(
			{
				cv: A2($elm$http$Http$expectJson, onResult, $author$project$Confirm$Data$teacherDecoder),
				dJ: A2(
					$elm$url$Url$Builder$absolute,
					_Utils_ap(
						$author$project$Confirm$Api$apiPath,
						_List_fromArray(
							['teacher', teacherId])),
					_List_Nil)
			});
	});
var $author$project$Confirm$Control$init = F7(
	function (_v0, videoOptions, topic, teacher, timeslot, teacherSelectedByStudent, zone) {
		return _Utils_Tuple2(
			{cf: false, d6: false, aA: 0, gq: videoOptions.ee && videoOptions.ed, gG: teacher, gJ: teacherSelectedByStudent, gN: timeslot, gQ: topic, g5: zone},
			A2(
				$author$project$EffectUtils$fromCmd,
				0,
				A2($author$project$Confirm$Api$getTeacherDetails, teacher.dx, $author$project$Confirm$Data$TeacherReceived)));
	});
var $author$project$SelectTimeslot$Data$InitialDataReceived = function (a) {
	return {$: 1, a: a};
};
var $author$project$SelectTimeslot$Data$LoadDataEffect = 0;
var $author$project$SelectTimeslot$Data$Uninitialised = function (a) {
	return {$: 0, a: a};
};
var $author$project$SelectTimeslot$Data$Initialised = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.gw, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		cm: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		cU: month,
		dP: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cm;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cU;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).dP;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$SelectTimeslot$Api$addTemplateUnitIdParam = F2(
	function (external, qs) {
		var _v0 = $author$project$Data$getLessonType(external);
		if (_v0.$ === 1) {
			var templateUnitId = _v0.a;
			return A2(
				$elm$core$List$cons,
				A2($elm$url$Url$Builder$string, 'templateUnitId', templateUnitId),
				qs);
		} else {
			return qs;
		}
	});
var $author$project$SelectTimeslot$Api$apiPath = _Utils_ap(
	$author$project$ServerPath$serverPathParts,
	_List_fromArray(
		['privateclass']));
var $author$project$ApiTypes$Privateclass$Index$Timeslot = function (evcServer) {
	return function (freezeTime) {
		return function (isVideoClass) {
			return function (language) {
				return function (levelCode) {
					return function (marketCode) {
						return function (partnerCode) {
							return function (serviceSubType) {
								return function (serviceType) {
									return function (teachLanguage) {
										return function (techCheckUrlCn) {
											return function (techCheckUrlUs) {
												return function (endTime) {
													return function (startTime) {
														return function (teachingMinutes) {
															return {cs: endTime, ct: evcServer, cz: freezeTime, cI: isVideoClass, cO: language, aw: levelCode, cQ: marketCode, cZ: partnerCode, dl: serviceSubType, dm: serviceType, dq: startTime, dw: teachLanguage, dy: teachingMinutes, dz: techCheckUrlCn, dA: techCheckUrlUs};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$SelectTimeslot$Data$timeslotDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'teachingMinutes', $elm$json$Json$Decode$float),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'startTime', $author$project$DateUtils$formattedDateDecoder),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'endTime', $author$project$DateUtils$formattedDateDecoder),
			A2(
				$author$project$JsonUtils$apply,
				A2($elm$json$Json$Decode$field, 'techCheckUrlUs', $elm$json$Json$Decode$string),
				A2(
					$author$project$JsonUtils$apply,
					A2($elm$json$Json$Decode$field, 'techCheckUrlCn', $elm$json$Json$Decode$string),
					A2(
						$author$project$JsonUtils$apply,
						$elm$json$Json$Decode$maybe(
							A2($elm$json$Json$Decode$field, 'teachLanguage', $elm$json$Json$Decode$string)),
						A2(
							$author$project$JsonUtils$apply,
							$elm$json$Json$Decode$maybe(
								A2($elm$json$Json$Decode$field, 'serviceType', $elm$json$Json$Decode$string)),
							A2(
								$author$project$JsonUtils$apply,
								$elm$json$Json$Decode$maybe(
									A2($elm$json$Json$Decode$field, 'serviceSubType', $elm$json$Json$Decode$string)),
								A2(
									$author$project$JsonUtils$apply,
									A2($elm$json$Json$Decode$field, 'partnerCode', $elm$json$Json$Decode$string),
									A2(
										$author$project$JsonUtils$apply,
										A2($elm$json$Json$Decode$field, 'marketCode', $elm$json$Json$Decode$string),
										A2(
											$author$project$JsonUtils$apply,
											A2($elm$json$Json$Decode$field, 'levelCode', $elm$json$Json$Decode$string),
											A2(
												$author$project$JsonUtils$apply,
												A2($elm$json$Json$Decode$field, 'language', $elm$json$Json$Decode$string),
												A2(
													$author$project$JsonUtils$apply,
													A2($elm$json$Json$Decode$field, 'isVideoClass', $elm$json$Json$Decode$bool),
													A2(
														$author$project$JsonUtils$apply,
														A2($elm$json$Json$Decode$field, 'freezeTime', $elm$json$Json$Decode$float),
														A2(
															$author$project$JsonUtils$apply,
															A2($elm$json$Json$Decode$field, 'evcServer', $elm$json$Json$Decode$string),
															$elm$json$Json$Decode$succeed($author$project$ApiTypes$Privateclass$Index$Timeslot))))))))))))))));
var $author$project$SelectTimeslot$Api$getTimeslotsTask = F3(
	function (external, selectedTeacher, _v0) {
		var startDate = _v0.a;
		var endDate = _v0.b;
		var posixToString = A2($elm$core$Basics$composeL, $elm$core$String$fromInt, $elm$time$Time$posixToMillis);
		var rangeParams = _List_fromArray(
			[
				A2(
				$elm$url$Url$Builder$string,
				'startDate',
				posixToString(startDate)),
				A2(
				$elm$url$Url$Builder$string,
				'endDate',
				posixToString(endDate))
			]);
		var withTeacherId = function () {
			if (!selectedTeacher.$) {
				return rangeParams;
			} else {
				var teacherId = selectedTeacher.a.dx;
				return A2(
					$elm$core$List$cons,
					A2($elm$url$Url$Builder$string, 'teacherId', teacherId),
					rangeParams);
			}
		}();
		var withTemplateUnitId = A2($author$project$SelectTimeslot$Api$addTemplateUnitIdParam, external, withTeacherId);
		var baseUrl = $elm$url$Url$Builder$absolute(
			_Utils_ap(
				$author$project$SelectTimeslot$Api$apiPath,
				_List_fromArray(
					['gettimeslots'])));
		return $elm$http$Http$task(
			{
				d1: $elm$http$Http$emptyBody,
				bh: _List_Nil,
				br: 'get',
				bE: $author$project$HttpUtils$standardStringResolver(
					$elm$json$Json$Decode$list($author$project$SelectTimeslot$Data$timeslotDecoder)),
				bZ: $elm$core$Maybe$Nothing,
				dJ: baseUrl(withTemplateUnitId)
			});
	});
var $author$project$Components$Autocompleter$init = function (lookup) {
	return {u: 0, P: false, bm: lookup, Z: true, E: _List_Nil, gY: ''};
};
var $author$project$Components$Datepicker$Model = $elm$core$Basics$identity;
var $author$project$Components$Datepicker$init = $elm$core$Maybe$Nothing;
var $elm$core$Tuple$mapBoth = F3(
	function (funcA, funcB, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			funcA(x),
			funcB(y));
	});
var $author$project$SelectTimeslot$Data$AllTeachers = function (a) {
	return {$: 0, a: a};
};
var $author$project$SelectTimeslot$Data$SpecificTeacher = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$SelectTimeslot$Data$overwriteTimeslotCache = F2(
	function (cache, selectedTeacher) {
		if (!selectedTeacher.$) {
			return $author$project$SelectTimeslot$Data$AllTeachers(cache);
		} else {
			var teacherId = selectedTeacher.a;
			return A2($author$project$SelectTimeslot$Data$SpecificTeacher, teacherId, cache);
		}
	});
var $justinmimbs$date$Date$Days = 3;
var $justinmimbs$date$Date$Months = 1;
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {cm: d, cU: m, dP: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		bx: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		dP: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.dP, 0, date.bx);
};
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.dP - 1)) + ($justinmimbs$date$Date$monthToNumber(date.cU) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.cm,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.cU;
	});
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $author$project$Components$Datepicker$dateRangeFromDate = function (date) {
	var year = $justinmimbs$date$Date$year(date);
	var month = $justinmimbs$date$Date$month(date);
	var firstOfMonth = A3($justinmimbs$date$Date$fromCalendarDate, year, month, 1);
	var firstWeekday = $justinmimbs$date$Date$weekdayToNumber(
		$justinmimbs$date$Date$weekday(firstOfMonth));
	var startDate = A3($justinmimbs$date$Date$add, 3, -(firstWeekday - 1), firstOfMonth);
	var endDate = A3($justinmimbs$date$Date$add, 3, 35, startDate);
	return _Utils_Tuple2(startDate, endDate);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.cm;
	});
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0;
	return rd;
};
var $justinmimbs$time_extra$Time$Extra$dateToMillis = function (date) {
	var daysSinceEpoch = $justinmimbs$date$Date$toRataDie(date) - 719163;
	return daysSinceEpoch * 86400000;
};
var $justinmimbs$time_extra$Time$Extra$timeFromClock = F4(
	function (hour, minute, second, millisecond) {
		return (((hour * 3600000) + (minute * 60000)) + (second * 1000)) + millisecond;
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $justinmimbs$time_extra$Time$Extra$timeFromPosix = F2(
	function (zone, posix) {
		return A4(
			$justinmimbs$time_extra$Time$Extra$timeFromClock,
			A2($elm$time$Time$toHour, zone, posix),
			A2($elm$time$Time$toMinute, zone, posix),
			A2($elm$time$Time$toSecond, zone, posix),
			A2($elm$time$Time$toMillis, zone, posix));
	});
var $justinmimbs$time_extra$Time$Extra$toOffset = F2(
	function (zone, posix) {
		var millis = $elm$time$Time$posixToMillis(posix);
		var localMillis = $justinmimbs$time_extra$Time$Extra$dateToMillis(
			A2($justinmimbs$date$Date$fromPosix, zone, posix)) + A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix);
		return ((localMillis - millis) / 60000) | 0;
	});
var $justinmimbs$time_extra$Time$Extra$posixFromDateTime = F3(
	function (zone, date, time) {
		var millis = $justinmimbs$time_extra$Time$Extra$dateToMillis(date) + time;
		var offset0 = A2(
			$justinmimbs$time_extra$Time$Extra$toOffset,
			zone,
			$elm$time$Time$millisToPosix(millis));
		var posix1 = $elm$time$Time$millisToPosix(millis - (offset0 * 60000));
		var offset1 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix1);
		if (_Utils_eq(offset0, offset1)) {
			return posix1;
		} else {
			var posix2 = $elm$time$Time$millisToPosix(millis - (offset1 * 60000));
			var offset2 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix2);
			return _Utils_eq(offset1, offset2) ? posix2 : posix1;
		}
	});
var $justinmimbs$time_extra$Time$Extra$partsToPosix = F2(
	function (zone, _v0) {
		var year = _v0.dP;
		var month = _v0.cU;
		var day = _v0.cm;
		var hour = _v0.cE;
		var minute = _v0.cS;
		var second = _v0.dh;
		var millisecond = _v0.cR;
		return A3(
			$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
			zone,
			A3($justinmimbs$date$Date$fromCalendarDate, year, month, day),
			A4(
				$justinmimbs$time_extra$Time$Extra$timeFromClock,
				A3($elm$core$Basics$clamp, 0, 23, hour),
				A3($elm$core$Basics$clamp, 0, 59, minute),
				A3($elm$core$Basics$clamp, 0, 59, second),
				A3($elm$core$Basics$clamp, 0, 999, millisecond)));
	});
var $author$project$SelectTimeslot$Data$dateRangeToPosixRange = F2(
	function (zone, _v0) {
		var startDate = _v0.a;
		var endDate = _v0.b;
		var startParts = {
			cm: $justinmimbs$date$Date$day(startDate),
			cE: 0,
			cR: 0,
			cS: 0,
			cU: $justinmimbs$date$Date$month(startDate),
			dh: 0,
			dP: $justinmimbs$date$Date$year(startDate)
		};
		var endParts = {
			cm: $justinmimbs$date$Date$day(endDate),
			cE: 23,
			cR: 999,
			cS: 59,
			cU: $justinmimbs$date$Date$month(endDate),
			dh: 59,
			dP: $justinmimbs$date$Date$year(endDate)
		};
		return _Utils_Tuple2(
			A2($justinmimbs$time_extra$Time$Extra$partsToPosix, zone, startParts),
			A2($justinmimbs$time_extra$Time$Extra$partsToPosix, zone, endParts));
	});
var $author$project$SelectTimeslot$Data$posixRangeFromDate = F2(
	function (zone, date) {
		return A2(
			$author$project$SelectTimeslot$Data$dateRangeToPosixRange,
			zone,
			$author$project$Components$Datepicker$dateRangeFromDate(date));
	});
var $author$project$SelectTimeslot$Data$or = F2(
	function (a, b) {
		if (!a.$) {
			return a;
		} else {
			return b;
		}
	});
var $author$project$SelectTimeslot$Data$recommendedTeacher = function (teachers) {
	var _v0 = A2(
		$author$project$SelectTimeslot$Data$or,
		$elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (t) {
					return t.dY && t.fw;
				},
				teachers)),
		$elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (t) {
					return t.dY;
				},
				teachers)));
	if (_v0.$ === 1) {
		return $author$project$SelectTimeslot$Data$AllTeachers($elm$core$Dict$empty);
	} else {
		var teacher = _v0.a;
		return A2($author$project$SelectTimeslot$Data$SpecificTeacher, teacher, $elm$core$Dict$empty);
	}
};
var $author$project$SelectTimeslot$Data$teacherDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'bilingualism', $elm$json$Json$Decode$string)),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'teacherId', $elm$json$Json$Decode$string),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'lastTeacher', $elm$json$Json$Decode$bool),
			A2(
				$author$project$JsonUtils$apply,
				$elm$json$Json$Decode$maybe(
					A2($elm$json$Json$Decode$field, 'lastName', $elm$json$Json$Decode$string)),
				A2(
					$author$project$JsonUtils$apply,
					$elm$json$Json$Decode$maybe(
						A2($elm$json$Json$Decode$field, 'firstName', $elm$json$Json$Decode$string)),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'displayName', $elm$json$Json$Decode$string),
						A2(
							$author$project$JsonUtils$apply,
							A2($elm$json$Json$Decode$field, 'available', $elm$json$Json$Decode$bool),
							$elm$json$Json$Decode$succeed($author$project$ApiTypes$Privateclass$Index$Teacher))))))));
var $author$project$SelectTimeslot$Api$searchTeachers = F3(
	function (external, prefix, onResult) {
		var qs = A2(
			$author$project$SelectTimeslot$Api$addTemplateUnitIdParam,
			external,
			_List_fromArray(
				[
					A2($elm$url$Url$Builder$string, 'prefix', prefix)
				]));
		return $elm$http$Http$get(
			{
				cv: A2(
					$elm$http$Http$expectJson,
					onResult,
					$elm$json$Json$Decode$list($author$project$SelectTimeslot$Data$teacherDecoder)),
				dJ: A2(
					$elm$url$Url$Builder$absolute,
					_Utils_ap(
						$author$project$SelectTimeslot$Api$apiPath,
						_List_fromArray(
							['searchteachers'])),
					qs)
			});
	});
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$SelectTimeslot$Api$createInitialisedModel = F3(
	function (external, initialisationMode, _v0) {
		var _v1 = _v0.a;
		var zone = _v1.a;
		var now = _v1.b;
		var data = _v0.b;
		var topic = _v0.c;
		var today = A2($justinmimbs$date$Date$fromPosix, zone, now);
		var timeslotRange = A2($author$project$SelectTimeslot$Data$posixRangeFromDate, zone, today);
		var selectedTeacher = $author$project$SelectTimeslot$Data$recommendedTeacher(data.f8);
		var intRange = A3($elm$core$Tuple$mapBoth, $elm$time$Time$posixToMillis, $elm$time$Time$posixToMillis, timeslotRange);
		return A2(
			$elm$core$Task$map,
			function (slots) {
				var selectedDate = A2(
					$elm$core$Maybe$withDefault,
					today,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.dq;
							},
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.f7;
								},
								A2(
									$elm$core$Basics$composeR,
									$elm$core$Basics$round,
									A2(
										$elm$core$Basics$composeR,
										$elm$time$Time$millisToPosix,
										$justinmimbs$date$Date$fromPosix(zone))))),
						$elm$core$List$head(
							A2(
								$elm$core$List$sortBy,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.dq;
									},
									function ($) {
										return $.f7;
									}),
								slots))));
				return $author$project$SelectTimeslot$Data$Initialised(
					{
						dX: $author$project$Components$Autocompleter$init(
							$author$project$SelectTimeslot$Api$searchTeachers(external)),
						ea: false,
						eb: false,
						ek: false,
						ai: data,
						ez: $author$project$Components$Datepicker$init,
						fo: initialisationMode,
						fI: false,
						bM: selectedDate,
						gm: A2(
							$author$project$SelectTimeslot$Data$overwriteTimeslotCache,
							$elm$core$Dict$fromList(
								_List_fromArray(
									[
										_Utils_Tuple2(intRange, slots)
									])),
							selectedTeacher),
						go: $elm$core$Maybe$Nothing,
						dC: today,
						gQ: topic,
						g0: timeslotRange,
						g5: zone
					});
			},
			A3($author$project$SelectTimeslot$Api$getTimeslotsTask, external, selectedTeacher, timeslotRange));
	});
var $author$project$SelectTimeslot$Api$HereAndNow = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$bytesResolver = A2(_Http_expect, 'arraybuffer', _Http_toDataView);
var $elm$bytes$Bytes$Encode$getWidth = function (builder) {
	switch (builder.$) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 4;
		case 6:
			return 4;
		case 7:
			return 8;
		case 8:
			var w = builder.a;
			return w;
		case 9:
			var w = builder.a;
			return w;
		default:
			var bs = builder.a;
			return _Bytes_width(bs);
	}
};
var $elm$bytes$Bytes$LE = 0;
var $elm$bytes$Bytes$Encode$write = F3(
	function (builder, mb, offset) {
		switch (builder.$) {
			case 0:
				var n = builder.a;
				return A3(_Bytes_write_i8, mb, offset, n);
			case 1:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i16, mb, offset, n, !e);
			case 2:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i32, mb, offset, n, !e);
			case 3:
				var n = builder.a;
				return A3(_Bytes_write_u8, mb, offset, n);
			case 4:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u16, mb, offset, n, !e);
			case 5:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u32, mb, offset, n, !e);
			case 6:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f32, mb, offset, n, !e);
			case 7:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f64, mb, offset, n, !e);
			case 8:
				var bs = builder.b;
				return A3($elm$bytes$Bytes$Encode$writeSequence, bs, mb, offset);
			case 9:
				var s = builder.b;
				return A3(_Bytes_write_string, mb, offset, s);
			default:
				var bs = builder.a;
				return A3(_Bytes_write_bytes, mb, offset, bs);
		}
	});
var $elm$bytes$Bytes$Encode$writeSequence = F3(
	function (builders, mb, offset) {
		writeSequence:
		while (true) {
			if (!builders.b) {
				return offset;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$builders = bs,
					$temp$mb = mb,
					$temp$offset = A3($elm$bytes$Bytes$Encode$write, b, mb, offset);
				builders = $temp$builders;
				mb = $temp$mb;
				offset = $temp$offset;
				continue writeSequence;
			}
		}
	});
var $elm$bytes$Bytes$Decode$decode = F2(
	function (_v0, bs) {
		var decoder = _v0;
		return A2(_Bytes_decode, decoder, bs);
	});
var $justinmimbs$tzif$TZif$V1 = 0;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$bytes$Bytes$Decode$Decoder = $elm$core$Basics$identity;
var $elm$bytes$Bytes$Decode$andThen = F2(
	function (callback, _v0) {
		var decodeA = _v0;
		return F2(
			function (bites, offset) {
				var _v1 = A2(decodeA, bites, offset);
				var newOffset = _v1.a;
				var a = _v1.b;
				var _v2 = callback(a);
				var decodeB = _v2;
				return A2(decodeB, bites, newOffset);
			});
	});
var $justinmimbs$tzif$TZif$blockLengthV1 = function (_v0) {
	var isutCount = _v0.cK;
	var isstdCount = _v0.cJ;
	var leapCount = _v0.cP;
	var timeCount = _v0.aP;
	var typeCount = _v0.b1;
	var charCount = _v0.ci;
	var timeSize = 4;
	return ((((((timeCount * timeSize) + timeCount) + (typeCount * 6)) + charCount) + (leapCount * (timeSize + 4))) + isstdCount) + isutCount;
};
var $elm$bytes$Bytes$Decode$fail = _Bytes_decodeFailure;
var $elm$bytes$Bytes$Decode$succeed = function (a) {
	return F2(
		function (_v0, offset) {
			return _Utils_Tuple2(offset, a);
		});
};
var $justinmimbs$tzif$TZif$blockToZone = function (_v0) {
	var transitionTimes = _v0.dG;
	var transitionTypes = _v0.dH;
	var typeOffsets = _v0.dI;
	if (!typeOffsets.b) {
		return $elm$bytes$Bytes$Decode$fail;
	} else {
		var defaultOffset = typeOffsets.a;
		var changes = A3(
			$elm$core$List$map2,
			F2(
				function (time, typeIndex) {
					var offset = A2(
						$elm$core$Maybe$withDefault,
						defaultOffset,
						$elm$core$List$head(
							A2($elm$core$List$drop, typeIndex, typeOffsets)));
					return {b: (offset / 60) | 0, gw: (time / 60) | 0};
				}),
			transitionTimes,
			transitionTypes);
		return $elm$bytes$Bytes$Decode$succeed(
			A2($elm$time$Time$customZone, (defaultOffset / 60) | 0, changes));
	}
};
var $elm$bytes$Bytes$Decode$bytes = function (n) {
	return _Bytes_read_bytes(n);
};
var $justinmimbs$tzif$TZif$Block = F3(
	function (transitionTimes, transitionTypes, typeOffsets) {
		return {dG: transitionTimes, dH: transitionTypes, dI: typeOffsets};
	});
var $elm$bytes$Bytes$Decode$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$bytes$Bytes$Decode$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$bytes$Bytes$Decode$map = F2(
	function (func, _v0) {
		var decodeA = _v0;
		return F2(
			function (bites, offset) {
				var _v1 = A2(decodeA, bites, offset);
				var aOffset = _v1.a;
				var a = _v1.b;
				return _Utils_Tuple2(
					aOffset,
					func(a));
			});
	});
var $justinmimbs$tzif$TZif$decodeListStep = F2(
	function (decodeItem, _v0) {
		var n = _v0.a;
		var list = _v0.b;
		return (n <= 0) ? $elm$bytes$Bytes$Decode$succeed(
			$elm$bytes$Bytes$Decode$Done(list)) : A2(
			$elm$bytes$Bytes$Decode$map,
			function (item) {
				return $elm$bytes$Bytes$Decode$Loop(
					_Utils_Tuple2(
						n - 1,
						A2($elm$core$List$cons, item, list)));
			},
			decodeItem);
	});
var $elm$bytes$Bytes$Decode$loopHelp = F4(
	function (state, callback, bites, offset) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var decoder = _v0;
			var _v1 = A2(decoder, bites, offset);
			var newOffset = _v1.a;
			var step = _v1.b;
			if (!step.$) {
				var newState = step.a;
				var $temp$state = newState,
					$temp$callback = callback,
					$temp$bites = bites,
					$temp$offset = newOffset;
				state = $temp$state;
				callback = $temp$callback;
				bites = $temp$bites;
				offset = $temp$offset;
				continue loopHelp;
			} else {
				var result = step.a;
				return _Utils_Tuple2(newOffset, result);
			}
		}
	});
var $elm$bytes$Bytes$Decode$loop = F2(
	function (state, callback) {
		return A2($elm$bytes$Bytes$Decode$loopHelp, state, callback);
	});
var $justinmimbs$tzif$TZif$decodeList = F2(
	function (count, decodeItem) {
		return A2(
			$elm$bytes$Bytes$Decode$loop,
			_Utils_Tuple2(count, _List_Nil),
			$justinmimbs$tzif$TZif$decodeListStep(decodeItem));
	});
var $elm$bytes$Bytes$BE = 1;
var $elm$bytes$Bytes$Decode$signedInt32 = function (endianness) {
	return _Bytes_read_i32(!endianness);
};
var $justinmimbs$tzif$TZif$decodeSignedInt32 = $elm$bytes$Bytes$Decode$signedInt32(1);
var $elm$bytes$Bytes$Decode$unsignedInt32 = function (endianness) {
	return _Bytes_read_u32(!endianness);
};
var $justinmimbs$tzif$TZif$decodeUnsignedInt32 = $elm$bytes$Bytes$Decode$unsignedInt32(1);
var $elm$bytes$Bytes$Decode$map2 = F3(
	function (func, _v0, _v1) {
		var decodeA = _v0;
		var decodeB = _v1;
		return F2(
			function (bites, offset) {
				var _v2 = A2(decodeA, bites, offset);
				var aOffset = _v2.a;
				var a = _v2.b;
				var _v3 = A2(decodeB, bites, aOffset);
				var bOffset = _v3.a;
				var b = _v3.b;
				return _Utils_Tuple2(
					bOffset,
					A2(func, a, b));
			});
	});
var $elm$core$Basics$pow = _Basics_pow;
var $justinmimbs$tzif$TZif$decodeSignedInt64 = A3(
	$elm$bytes$Bytes$Decode$map2,
	F2(
		function (a, b) {
			return (a * A2($elm$core$Basics$pow, 2, 32)) + b;
		}),
	$justinmimbs$tzif$TZif$decodeSignedInt32,
	$justinmimbs$tzif$TZif$decodeUnsignedInt32);
var $justinmimbs$tzif$TZif$decodeTypeOffset = A3(
	$elm$bytes$Bytes$Decode$map2,
	$elm$core$Basics$always,
	$justinmimbs$tzif$TZif$decodeSignedInt32,
	$elm$bytes$Bytes$Decode$bytes(2));
var $elm$bytes$Bytes$Decode$map3 = F4(
	function (func, _v0, _v1, _v2) {
		var decodeA = _v0;
		var decodeB = _v1;
		var decodeC = _v2;
		return F2(
			function (bites, offset) {
				var _v3 = A2(decodeA, bites, offset);
				var aOffset = _v3.a;
				var a = _v3.b;
				var _v4 = A2(decodeB, bites, aOffset);
				var bOffset = _v4.a;
				var b = _v4.b;
				var _v5 = A2(decodeC, bites, bOffset);
				var cOffset = _v5.a;
				var c = _v5.b;
				return _Utils_Tuple2(
					cOffset,
					A3(func, a, b, c));
			});
	});
var $elm$bytes$Bytes$Decode$unsignedInt8 = _Bytes_read_u8;
var $justinmimbs$tzif$TZif$decodeBlock = function (header) {
	var decodeTime = (!header.b4) ? $justinmimbs$tzif$TZif$decodeSignedInt32 : $justinmimbs$tzif$TZif$decodeSignedInt64;
	return A4(
		$elm$bytes$Bytes$Decode$map3,
		$justinmimbs$tzif$TZif$Block,
		A2($justinmimbs$tzif$TZif$decodeList, header.aP, decodeTime),
		A2($justinmimbs$tzif$TZif$decodeList, header.aP, $elm$bytes$Bytes$Decode$unsignedInt8),
		A2(
			$elm$bytes$Bytes$Decode$map,
			$elm$core$List$reverse,
			A2($justinmimbs$tzif$TZif$decodeList, header.b1, $justinmimbs$tzif$TZif$decodeTypeOffset)));
};
var $justinmimbs$tzif$TZif$Header = F7(
	function (version, isutCount, isstdCount, leapCount, timeCount, typeCount, charCount) {
		return {ci: charCount, cJ: isstdCount, cK: isutCount, cP: leapCount, aP: timeCount, b1: typeCount, b4: version};
	});
var $elm$bytes$Bytes$Decode$string = function (n) {
	return _Bytes_read_string(n);
};
var $justinmimbs$tzif$TZif$decodeMagic = A2(
	$elm$bytes$Bytes$Decode$andThen,
	function (string) {
		return (string === 'TZif') ? $elm$bytes$Bytes$Decode$succeed(0) : $elm$bytes$Bytes$Decode$fail;
	},
	$elm$bytes$Bytes$Decode$string(4));
var $justinmimbs$tzif$TZif$V2 = 1;
var $justinmimbs$tzif$TZif$V3 = 2;
var $justinmimbs$tzif$TZif$decodeVersion = A2(
	$elm$bytes$Bytes$Decode$andThen,
	function (v) {
		switch (v) {
			case '\u0000':
				return $elm$bytes$Bytes$Decode$succeed(0);
			case '2':
				return $elm$bytes$Bytes$Decode$succeed(1);
			case '3':
				return $elm$bytes$Bytes$Decode$succeed(2);
			default:
				return $elm$bytes$Bytes$Decode$fail;
		}
	},
	$elm$bytes$Bytes$Decode$string(1));
var $justinmimbs$tzif$TZif$skip = F2(
	function (da, db) {
		return A3(
			$elm$bytes$Bytes$Decode$map2,
			F2(
				function (b, _v0) {
					return b;
				}),
			db,
			da);
	});
var $justinmimbs$tzif$TZif$take = F2(
	function (dx, df) {
		return A3(
			$elm$bytes$Bytes$Decode$map2,
			F2(
				function (f, x) {
					return f(x);
				}),
			df,
			dx);
	});
var $justinmimbs$tzif$TZif$decodeHeader = A2(
	$justinmimbs$tzif$TZif$take,
	$justinmimbs$tzif$TZif$decodeUnsignedInt32,
	A2(
		$justinmimbs$tzif$TZif$take,
		$justinmimbs$tzif$TZif$decodeUnsignedInt32,
		A2(
			$justinmimbs$tzif$TZif$take,
			$justinmimbs$tzif$TZif$decodeUnsignedInt32,
			A2(
				$justinmimbs$tzif$TZif$take,
				$justinmimbs$tzif$TZif$decodeUnsignedInt32,
				A2(
					$justinmimbs$tzif$TZif$take,
					$justinmimbs$tzif$TZif$decodeUnsignedInt32,
					A2(
						$justinmimbs$tzif$TZif$take,
						$justinmimbs$tzif$TZif$decodeUnsignedInt32,
						A2(
							$justinmimbs$tzif$TZif$skip,
							$elm$bytes$Bytes$Decode$bytes(15),
							A2(
								$justinmimbs$tzif$TZif$take,
								$justinmimbs$tzif$TZif$decodeVersion,
								A2(
									$justinmimbs$tzif$TZif$skip,
									$justinmimbs$tzif$TZif$decodeMagic,
									$elm$bytes$Bytes$Decode$succeed($justinmimbs$tzif$TZif$Header))))))))));
var $justinmimbs$tzif$TZif$decode = A2(
	$elm$bytes$Bytes$Decode$andThen,
	$justinmimbs$tzif$TZif$blockToZone,
	A2(
		$elm$bytes$Bytes$Decode$andThen,
		function (header1) {
			return (!header1.b4) ? $justinmimbs$tzif$TZif$decodeBlock(header1) : A2(
				$elm$bytes$Bytes$Decode$andThen,
				$justinmimbs$tzif$TZif$decodeBlock,
				A2(
					$elm$bytes$Bytes$Decode$andThen,
					$elm$core$Basics$always($justinmimbs$tzif$TZif$decodeHeader),
					$elm$bytes$Bytes$Decode$bytes(
						$justinmimbs$tzif$TZif$blockLengthV1(header1))));
		},
		$justinmimbs$tzif$TZif$decodeHeader));
var $author$project$SelectTimeslot$Api$resolveTzifBytes = function (resp) {
	switch (resp.$) {
		case 0:
			var url = resp.a;
			return $elm$core$Result$Err(
				$elm$http$Http$BadUrl(url));
		case 1:
			return $elm$core$Result$Err($elm$http$Http$Timeout);
		case 2:
			return $elm$core$Result$Err($elm$http$Http$NetworkError);
		case 3:
			var metadata = resp.a;
			return $elm$core$Result$Err(
				$elm$http$Http$BadStatus(metadata.dr));
		default:
			var body = resp.b;
			var _v1 = A2($elm$bytes$Bytes$Decode$decode, $justinmimbs$tzif$TZif$decode, body);
			if (!_v1.$) {
				var value = _v1.a;
				return $elm$core$Result$Ok(value);
			} else {
				return $elm$core$Result$Err(
					$elm$http$Http$BadBody('Unable to decode the timezone data'));
			}
	}
};
var $author$project$SelectTimeslot$Api$loadTimezoneDataTask = $elm$http$Http$task(
	{
		d1: $elm$http$Http$emptyBody,
		bh: _List_Nil,
		br: 'get',
		bE: $elm$http$Http$bytesResolver($author$project$SelectTimeslot$Api$resolveTzifBytes),
		bZ: $elm$core$Maybe$Nothing,
		dJ: A2(
			$elm$url$Url$Builder$absolute,
			_Utils_ap(
				$author$project$SelectTimeslot$Api$apiPath,
				_List_fromArray(
					['tzif'])),
			_List_Nil)
	});
var $author$project$SelectTimeslot$Api$hereAndNow = A3($elm$core$Task$map2, $author$project$SelectTimeslot$Api$HereAndNow, $author$project$SelectTimeslot$Api$loadTimezoneDataTask, $elm$time$Time$now);
var $author$project$ApiTypes$Privateclass$Index$SelectTimeslotData = F2(
	function (timezones, previousTeachers) {
		return {f8: previousTeachers, gP: timezones};
	});
var $author$project$ApiTypes$Staticdata$Timezone$TimezoneOption = F2(
	function (text, value) {
		return {gL: text, gY: value};
	});
var $author$project$SelectTimeslot$Data$timezoneOptionDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'text', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$Staticdata$Timezone$TimezoneOption)));
var $author$project$SelectTimeslot$Data$selectTimeslotDataDecoder = A2(
	$author$project$JsonUtils$apply,
	A2(
		$elm$json$Json$Decode$field,
		'previousTeachers',
		$elm$json$Json$Decode$list($author$project$SelectTimeslot$Data$teacherDecoder)),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'timezones',
			$elm$json$Json$Decode$list($author$project$SelectTimeslot$Data$timezoneOptionDecoder)),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$Privateclass$Index$SelectTimeslotData)));
var $author$project$SelectTimeslot$Api$loadDataTask = function (mTemplateUnitId) {
	var qs = A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			function (t) {
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'templateUnitId', t)
					]);
			},
			mTemplateUnitId));
	return $elm$http$Http$task(
		{
			d1: $elm$http$Http$emptyBody,
			bh: _List_Nil,
			br: 'get',
			bE: $author$project$HttpUtils$standardStringResolver($author$project$SelectTimeslot$Data$selectTimeslotDataDecoder),
			bZ: $elm$core$Maybe$Nothing,
			dJ: A2(
				$elm$url$Url$Builder$absolute,
				_Utils_ap(
					$author$project$SelectTimeslot$Api$apiPath,
					_List_fromArray(
						['selecttimeslotdata'])),
				qs)
		});
};
var $author$project$ApiTypes$Privateclass$Index$Topic = F6(
	function (courseTypeCode, levelCode, id, name, imageUrl, description) {
		return {a8: courseTypeCode, eC: description, fh: id, cF: imageUrl, aw: levelCode, cW: name};
	});
var $author$project$SelectTimeslot$Api$topicDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string)),
	A2(
		$author$project$JsonUtils$apply,
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'imageUrl', $elm$json$Json$Decode$string)),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
			A2(
				$author$project$JsonUtils$apply,
				$elm$json$Json$Decode$maybe(
					A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string)),
				A2(
					$author$project$JsonUtils$apply,
					$elm$json$Json$Decode$maybe(
						A2($elm$json$Json$Decode$field, 'levelCode', $elm$json$Json$Decode$string)),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'courseTypeCode', $elm$json$Json$Decode$string),
						$elm$json$Json$Decode$succeed($author$project$ApiTypes$Privateclass$Index$Topic)))))));
var $author$project$SelectTimeslot$Api$loadTopicTask = function (templateUnitId) {
	return $elm$http$Http$task(
		{
			d1: $elm$http$Http$emptyBody,
			bh: _List_Nil,
			br: 'get',
			bE: $author$project$HttpUtils$standardStringResolver($author$project$SelectTimeslot$Api$topicDecoder),
			bZ: $elm$core$Maybe$Nothing,
			dJ: A2(
				$elm$url$Url$Builder$absolute,
				_Utils_ap(
					$author$project$SelectTimeslot$Api$apiPath,
					_List_fromArray(
						['unitreviewtopic'])),
				_List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'templateUnitId', templateUnitId)
					]))
		});
};
var $elm$core$Task$map3 = F4(
	function (func, taskA, taskB, taskC) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return A2(
							$elm$core$Task$andThen,
							function (c) {
								return $elm$core$Task$succeed(
									A3(func, a, b, c));
							},
							taskC);
					},
					taskB);
			},
			taskA);
	});
var $author$project$SelectTimeslot$Api$loadInitialData = F3(
	function (external, initialisationMode, onResult) {
		if (initialisationMode.$ === 1) {
			var templateUnitId = initialisationMode.a;
			return A2(
				$elm$core$Task$attempt,
				onResult,
				A2(
					$elm$core$Task$andThen,
					A2($author$project$SelectTimeslot$Api$createInitialisedModel, external, initialisationMode),
					A4(
						$elm$core$Task$map3,
						F3(
							function (handn, data, topic_) {
								return _Utils_Tuple3(handn, data, topic_);
							}),
						$author$project$SelectTimeslot$Api$hereAndNow,
						$author$project$SelectTimeslot$Api$loadDataTask(
							$elm$core$Maybe$Just(templateUnitId)),
						$author$project$SelectTimeslot$Api$loadTopicTask(templateUnitId))));
		} else {
			var topic = initialisationMode.a;
			return A2(
				$elm$core$Task$attempt,
				onResult,
				A2(
					$elm$core$Task$andThen,
					A2($author$project$SelectTimeslot$Api$createInitialisedModel, external, initialisationMode),
					A4(
						$elm$core$Task$map3,
						F3(
							function (handn, data, topic_) {
								return _Utils_Tuple3(handn, data, topic_);
							}),
						$author$project$SelectTimeslot$Api$hereAndNow,
						$author$project$SelectTimeslot$Api$loadDataTask($elm$core$Maybe$Nothing),
						$elm$core$Task$succeed(topic))));
		}
	});
var $author$project$SelectTimeslot$Control$init = F2(
	function (external, initMode) {
		return _Utils_Tuple2(
			$author$project$SelectTimeslot$Data$Uninitialised(initMode),
			A2(
				$author$project$EffectUtils$fromCmd,
				0,
				A3($author$project$SelectTimeslot$Api$loadInitialData, external, initMode, $author$project$SelectTimeslot$Data$InitialDataReceived)));
	});
var $author$project$SelectTopic$Data$DataReceived = function (a) {
	return {$: 2, a: a};
};
var $author$project$SelectTopic$Data$LoadDataEffect = 0;
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $author$project$SelectTopic$Api$apiPath = _Utils_ap(
	$author$project$ServerPath$serverPathParts,
	_List_fromArray(
		['privateclass']));
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $author$project$ApiTypes$Privateclass$Index$SelectTopicData = F3(
	function (levelsOrCourses, topics, currentLevelOrCourse) {
		return {et: currentLevelOrCourse, fE: levelsOrCourses, gR: topics};
	});
var $author$project$ApiTypes$Privateclass$Index$LevelOrCourseOptionCourseOption = function (a) {
	return {$: 1, a: a};
};
var $author$project$ApiTypes$Privateclass$Index$LevelOrCourseOptionLevelOption = function (a) {
	return {$: 0, a: a};
};
var $author$project$ApiTypes$Privateclass$Index$CourseOption = F2(
	function (courseTypeCode, courseName) {
		return {ep: courseName, a8: courseTypeCode};
	});
var $author$project$SelectTopic$Data$courseOptionDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'courseName', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'courseTypeCode', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$ApiTypes$Privateclass$Index$CourseOption)));
var $author$project$ApiTypes$Privateclass$Index$LevelOption = F3(
	function (courseTypeCode, levelCode, levelName) {
		return {a8: courseTypeCode, aw: levelCode, fA: levelName};
	});
var $author$project$SelectTopic$Data$levelOptionDecoder = A2(
	$author$project$JsonUtils$apply,
	A2($elm$json$Json$Decode$field, 'levelName', $elm$json$Json$Decode$string),
	A2(
		$author$project$JsonUtils$apply,
		A2($elm$json$Json$Decode$field, 'levelCode', $elm$json$Json$Decode$string),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'courseTypeCode', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$succeed($author$project$ApiTypes$Privateclass$Index$LevelOption))));
var $author$project$SelectTopic$Data$levelsOrCoursesDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$map, $author$project$ApiTypes$Privateclass$Index$LevelOrCourseOptionLevelOption, $author$project$SelectTopic$Data$levelOptionDecoder),
			A2($elm$json$Json$Decode$map, $author$project$ApiTypes$Privateclass$Index$LevelOrCourseOptionCourseOption, $author$project$SelectTopic$Data$courseOptionDecoder),
			$elm$json$Json$Decode$fail('Unexpected level or course option received')
		]));
var $author$project$SelectTopic$Data$topicDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string)),
	A2(
		$author$project$JsonUtils$apply,
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'imageUrl', $elm$json$Json$Decode$string)),
		A2(
			$author$project$JsonUtils$apply,
			A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
			A2(
				$author$project$JsonUtils$apply,
				$elm$json$Json$Decode$maybe(
					A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string)),
				A2(
					$author$project$JsonUtils$apply,
					$elm$json$Json$Decode$maybe(
						A2($elm$json$Json$Decode$field, 'levelCode', $elm$json$Json$Decode$string)),
					A2(
						$author$project$JsonUtils$apply,
						A2($elm$json$Json$Decode$field, 'courseTypeCode', $elm$json$Json$Decode$string),
						$elm$json$Json$Decode$succeed($author$project$ApiTypes$Privateclass$Index$Topic)))))));
var $author$project$SelectTopic$Data$selectTopicDataDecoder = A2(
	$author$project$JsonUtils$apply,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'currentLevelOrCourse', $elm$json$Json$Decode$string)),
	A2(
		$author$project$JsonUtils$apply,
		A2(
			$elm$json$Json$Decode$field,
			'topics',
			$elm$json$Json$Decode$list($author$project$SelectTopic$Data$topicDecoder)),
		A2(
			$author$project$JsonUtils$apply,
			A2(
				$elm$json$Json$Decode$field,
				'levelsOrCourses',
				$elm$json$Json$Decode$list($author$project$SelectTopic$Data$levelsOrCoursesDecoder)),
			$elm$json$Json$Decode$succeed($author$project$ApiTypes$Privateclass$Index$SelectTopicData))));
var $author$project$SelectTopic$Api$loadData = F2(
	function (external, onResult) {
		var qs = function () {
			var _v0 = $author$project$Data$getLessonType(external);
			if (_v0.$ === 1) {
				var templateUnitId = _v0.a;
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'templateUnitId', templateUnitId)
					]);
			} else {
				return _List_Nil;
			}
		}();
		return $elm$http$Http$get(
			{
				cv: A2(
					$elm$http$Http$expectJson,
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, onResult),
					$author$project$SelectTopic$Data$selectTopicDataDecoder),
				dJ: A2(
					$elm$url$Url$Builder$absolute,
					_Utils_ap(
						$author$project$SelectTopic$Api$apiPath,
						_List_fromArray(
							['selecttopic'])),
					qs)
			});
	});
var $author$project$SelectTopic$Control$init = function (external) {
	return _Utils_Tuple2(
		{ah: $elm$core$Maybe$Nothing, ai: $krisajenkins$remotedata$RemoteData$Loading, bD: false, aJ: $elm$core$Maybe$Nothing, di: $elm$core$Maybe$Nothing, dn: $elm$core$Maybe$Nothing},
		A2(
			$author$project$EffectUtils$fromCmd,
			0,
			A2($author$project$SelectTopic$Api$loadData, external, $author$project$SelectTopic$Data$DataReceived)));
};
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $author$project$Components$ProgressIndicator$transitionTime = 300;
var $author$project$Components$ProgressIndicator$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var idx = msg.a;
			var _v1 = ($elm$core$Basics$abs(idx - model.o) <= 1) ? _Utils_Tuple2(
				_Utils_update(
					model,
					{o: idx}),
				$elm$core$Platform$Cmd$none) : (((idx - model.o) < 0) ? _Utils_Tuple2(
				_Utils_update(
					model,
					{o: model.o - 1}),
				A2(
					$elm$core$Task$perform,
					function (_v2) {
						return $author$project$Components$ProgressIndicator$Navigate(idx);
					},
					$elm$core$Process$sleep($author$project$Components$ProgressIndicator$transitionTime))) : _Utils_Tuple2(
				_Utils_update(
					model,
					{o: model.o + 1}),
				A2(
					$elm$core$Task$perform,
					function (_v3) {
						return $author$project$Components$ProgressIndicator$Navigate(idx);
					},
					$elm$core$Process$sleep($author$project$Components$ProgressIndicator$transitionTime))));
			var updated = _v1.a;
			var cmd = _v1.b;
			return _Utils_Tuple3(updated, cmd, idx);
		} else {
			var idx = msg.a;
			return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, idx);
		}
	});
var $author$project$Components$ProgressIndicator$navigate = function (idx) {
	return $author$project$Components$ProgressIndicator$update(
		$author$project$Components$ProgressIndicator$Navigate(idx));
};
var $author$project$Control$navigateBackTo = F3(
	function (external, s, idx) {
		var _v0 = _Utils_Tuple2(s, idx);
		_v0$3:
		while (true) {
			switch (_v0.a) {
				case 1:
					if (!_v0.b) {
						var _v1 = _v0.a;
						var _v2 = $author$project$Data$getLessonType(external);
						if (_v2.$ === 1) {
							return 1;
						} else {
							return 0;
						}
					} else {
						break _v0$3;
					}
				case 2:
					switch (_v0.b) {
						case 0:
							var _v3 = _v0.a;
							var _v4 = $author$project$Data$getLessonType(external);
							if (_v4.$ === 1) {
								return 2;
							} else {
								return 0;
							}
						case 1:
							var _v5 = _v0.a;
							return 1;
						default:
							break _v0$3;
					}
				default:
					break _v0$3;
			}
		}
		return s;
	});
var $author$project$EffectUtils$noEffect = $author$project$Scaffold$NoEffect;
var $author$project$Api$reloadBookings = function (onResult) {
	return $elm$http$Http$get(
		{
			cv: A2(
				$elm$http$Http$expectJson,
				onResult,
				$elm$json$Json$Decode$list($author$project$Data$bookedClassDecoder)),
			dJ: A2(
				$elm$url$Url$Builder$absolute,
				_Utils_ap(
					$author$project$Api$apiPath,
					_List_fromArray(
						['bookings'])),
				_List_Nil)
		});
};
var $author$project$Announcement$WriteToLocalStorage = 0;
var $author$project$Ports$writeToLocalStorage = _Platform_outgoingPort(
	'writeToLocalStorage',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$core$Basics$identity(b)
				]));
	});
var $author$project$Announcement$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					!model,
					A2(
						$author$project$EffectUtils$fromCmd,
						0,
						$author$project$Ports$writeToLocalStorage(
							_Utils_Tuple2(
								$author$project$Announcement$toggleKey,
								$elm$json$Json$Encode$bool(!model)))));
			case 1:
				var open = msg.a;
				return _Utils_Tuple2(open, $author$project$EffectUtils$noEffect);
			default:
				return _Utils_Tuple2(model, $author$project$EffectUtils$noEffect);
		}
	});
var $author$project$Confirm$Data$BlockUI = {$: 4};
var $author$project$Confirm$Data$BubbleErrorEffect = 3;
var $author$project$Confirm$Data$CancelModal = 2;
var $author$project$Confirm$Data$ChangeUrlEffect = 2;
var $author$project$Confirm$Data$GoBackToSelectTimeslot = {$: 1};
var $author$project$Confirm$Data$GoBackToSelectTopic = {$: 2};
var $author$project$Confirm$Data$MakeBookingEffect = 1;
var $author$project$Confirm$Data$MakeBookingResponse = function (a) {
	return {$: 9, a: a};
};
var $author$project$Confirm$Data$NoEvent = {$: 0};
var $author$project$Confirm$Data$SuccessfulBooking = function (a) {
	return {$: 3, a: a};
};
var $author$project$Confirm$Data$TermsModal = 1;
var $author$project$Confirm$Data$UnblockUI = {$: 5};
var $author$project$ApiTypes$Privateclass$Index$BookingResponseFailed = {$: 0};
var $author$project$ApiTypes$Privateclass$Index$BookingResponseGrantsAndCreditFeatures = function (a) {
	return {$: 1, a: a};
};
var $author$project$Confirm$Data$bookingResponseDecoder = A2(
	$elm$json$Json$Decode$map,
	function (maybeGandC) {
		if (maybeGandC.$ === 1) {
			return $author$project$ApiTypes$Privateclass$Index$BookingResponseFailed;
		} else {
			var gandc = maybeGandC.a;
			return $author$project$ApiTypes$Privateclass$Index$BookingResponseGrantsAndCreditFeatures(gandc);
		}
	},
	$elm$json$Json$Decode$maybe($author$project$Student$grantsAndCreditsDecoder));
var $author$project$Confirm$Api$encodeTimeslot = function (timeslot) {
	return $elm$json$Json$Encode$object(
		A2(
			$author$project$ListUtils$appendMaybe,
			A3($author$project$JsonUtils$encodeMaybeNameValue, 'teachLanguage', $elm$json$Json$Encode$string, timeslot.dw),
			A2(
				$author$project$ListUtils$appendMaybe,
				A3($author$project$JsonUtils$encodeMaybeNameValue, 'serviceType', $elm$json$Json$Encode$string, timeslot.dm),
				A2(
					$author$project$ListUtils$appendMaybe,
					A3($author$project$JsonUtils$encodeMaybeNameValue, 'serviceSubType', $elm$json$Json$Encode$string, timeslot.dl),
					_List_fromArray(
						[
							_Utils_Tuple2(
							'evcServer',
							$elm$json$Json$Encode$string(timeslot.ct)),
							_Utils_Tuple2(
							'freezeTime',
							$elm$json$Json$Encode$float(timeslot.cz)),
							_Utils_Tuple2(
							'isVideoClass',
							$elm$json$Json$Encode$bool(timeslot.cI)),
							_Utils_Tuple2(
							'language',
							$elm$json$Json$Encode$string(timeslot.cO)),
							_Utils_Tuple2(
							'levelCode',
							$elm$json$Json$Encode$string(timeslot.aw)),
							_Utils_Tuple2(
							'marketCode',
							$elm$json$Json$Encode$string(timeslot.cQ)),
							_Utils_Tuple2(
							'partnerCode',
							$elm$json$Json$Encode$string(timeslot.cZ)),
							_Utils_Tuple2(
							'techCheckUrlCn',
							$elm$json$Json$Encode$string(timeslot.dz)),
							_Utils_Tuple2(
							'techCheckUrlUs',
							$elm$json$Json$Encode$string(timeslot.dA)),
							_Utils_Tuple2(
							'startTime',
							$elm$json$Json$Encode$float(timeslot.dq.f7)),
							_Utils_Tuple2(
							'endTime',
							$elm$json$Json$Encode$float(timeslot.cs.f7))
						])))));
};
var $author$project$Confirm$Api$encodeTopic = function (topic) {
	return $elm$json$Json$Encode$object(
		A2(
			$author$project$ListUtils$appendMaybe,
			A3($author$project$JsonUtils$encodeMaybeNameValue, 'levelCode', $elm$json$Json$Encode$string, topic.aw),
			A2(
				$author$project$ListUtils$appendMaybe,
				A3($author$project$JsonUtils$encodeMaybeNameValue, 'id', $elm$json$Json$Encode$string, topic.fh),
				_List_fromArray(
					[
						_Utils_Tuple2(
						'name',
						$elm$json$Json$Encode$string(topic.cW)),
						_Utils_Tuple2(
						'courseTypeCode',
						$elm$json$Json$Encode$string(topic.a8))
					]))));
};
var $author$project$Confirm$Api$encodeBookingRequest = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'timeslot',
				$author$project$Confirm$Api$encodeTimeslot(model.gN)),
				_Utils_Tuple2(
				'teacherId',
				$elm$json$Json$Encode$string(model.gG.dx)),
				_Utils_Tuple2(
				'teacherSelectedByStudent',
				$elm$json$Json$Encode$bool(model.gJ)),
				_Utils_Tuple2(
				'topic',
				$author$project$Confirm$Api$encodeTopic(model.gQ)),
				_Utils_Tuple2(
				'sendClassRecording',
				$elm$json$Json$Encode$bool(model.gq))
			]));
};
var $elm$http$Http$post = function (r) {
	return $elm$http$Http$request(
		{d1: r.d1, cv: r.cv, bh: _List_Nil, br: 'POST', bZ: $elm$core$Maybe$Nothing, dE: $elm$core$Maybe$Nothing, dJ: r.dJ});
};
var $author$project$Confirm$Api$makeBooking = F3(
	function (external, model, onResult) {
		var qs = function () {
			var _v0 = $author$project$Data$getLessonType(external);
			if (_v0.$ === 1) {
				var templateUnitId = _v0.a;
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'templateUnitId', templateUnitId)
					]);
			} else {
				return _List_Nil;
			}
		}();
		return $elm$http$Http$post(
			{
				d1: $elm$http$Http$jsonBody(
					$author$project$Confirm$Api$encodeBookingRequest(model)),
				cv: A2($elm$http$Http$expectJson, onResult, $author$project$Confirm$Data$bookingResponseDecoder),
				dJ: A2(
					$elm$url$Url$Builder$absolute,
					_Utils_ap(
						$author$project$Confirm$Api$apiPath,
						_List_fromArray(
							['booking'])),
					qs)
			});
	});
var $author$project$Confirm$Data$AbortCancel = {$: 6};
var $author$project$Confirm$Data$ConfirmCancel = {$: 5};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Button$button = F2(
	function (props, content) {
		return A2(
			$elm$html$Html$button,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-button'),
						$elm$html$Html$Attributes$type_('button')
					]),
				A2(
					$elm$core$List$filterMap,
					function (prop) {
						if (!prop.$) {
							var a = prop.a;
							return $elm$core$Maybe$Just(a);
						} else {
							return $elm$core$Maybe$Nothing;
						}
					},
					props)),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-button__content')
						]),
					content)
				]));
	});
var $author$project$Button$ButtonProp = function (a) {
	return {$: 0, a: a};
};
var $author$project$Button$buttonProp = A2($elm$core$Basics$composeL, $author$project$Button$ButtonProp, $elm$html$Html$Attributes$class);
var $author$project$Button$class = $author$project$Button$buttonProp;
var $author$project$Button$dark = $author$project$Button$buttonProp('-dark');
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Button$onClick = function (msg) {
	return $author$project$Button$ButtonProp(
		$elm$html$Html$Events$onClick(msg));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$CssUtils$qa = $elm$html$Html$Attributes$attribute('data-qa');
var $author$project$Button$qa = A2($elm$core$Basics$composeR, $author$project$CssUtils$qa, $author$project$Button$ButtonProp);
var $hecrj$html_parser$Html$Parser$Element = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {cj: col, em: contextStack, c3: problem, de: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.de, s.cj, x, s.el));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{cj: 1, el: s.el, c: s.c, b: s.b + 1, de: s.de + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{cj: s.cj + 1, el: s.el, c: s.c, b: newOffset, de: s.de, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{cj: col, el: s0.el, c: s0.c, b: offset, de: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.de, s.cj, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $hecrj$html_parser$Html$Parser$chompOneOrMore = function (fn) {
	return A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$chompIf(fn),
		$elm$parser$Parser$chompWhile(fn));
};
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $hecrj$html_parser$Html$Parser$isSpaceCharacter = function (c) {
	return (c === ' ') || ((c === '\t') || ((c === '\n') || ((c === '\u000D') || ((c === '\u000C') || (c === '\u00A0')))));
};
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toLower = _String_toLower;
var $hecrj$html_parser$Html$Parser$closingTag = function (name) {
	var chompName = A2(
		$elm$parser$Parser$andThen,
		function (closingName) {
			return _Utils_eq(
				$elm$core$String$toLower(closingName),
				name) ? $elm$parser$Parser$succeed(0) : $elm$parser$Parser$problem('closing tag does not match opening tag: ' + name);
		},
		$elm$parser$Parser$getChompedString(
			$hecrj$html_parser$Html$Parser$chompOneOrMore(
				function (c) {
					return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && (c !== '>');
				})));
	return A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<')),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('/'))),
				chompName),
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('>')));
};
var $hecrj$html_parser$Html$Parser$Comment = function (a) {
	return {$: 2, a: a};
};
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.b, s.de, s.cj, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.el)) : A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.b, newOffset) < 0,
			0,
			{cj: newCol, el: s.el, c: s.c, b: newOffset, de: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$chompUntil = function (str) {
	return $elm$parser$Parser$Advanced$chompUntil(
		$elm$parser$Parser$toToken(str));
};
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.de, s.cj, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{cj: newCol, el: s.el, c: s.c, b: newOffset, de: newRow, a: s.a});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $hecrj$html_parser$Html$Parser$comment = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($hecrj$html_parser$Html$Parser$Comment),
			$elm$parser$Parser$token('<!')),
		$elm$parser$Parser$token('--')),
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompUntil('-->')),
		$elm$parser$Parser$token('-->')));
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $hecrj$html_parser$Html$Parser$voidElements = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $hecrj$html_parser$Html$Parser$isVoidElement = function (name) {
	return A2($elm$core$List$member, name, $hecrj$html_parser$Html$Parser$voidElements);
};
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $hecrj$html_parser$Html$Parser$many = function (parser_) {
	return A2(
		$elm$parser$Parser$loop,
		_List_Nil,
		function (list) {
			return $elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$map,
						function (_new) {
							return $elm$parser$Parser$Loop(
								A2($elm$core$List$cons, _new, list));
						},
						parser_),
						$elm$parser$Parser$succeed(
						$elm$parser$Parser$Done(
							$elm$core$List$reverse(list)))
					]));
		});
};
var $hecrj$html_parser$Html$Parser$isTagAttributeCharacter = function (c) {
	return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '>') && ((c !== '/') && (c !== '=')))));
};
var $hecrj$html_parser$Html$Parser$tagAttributeName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($hecrj$html_parser$Html$Parser$isTagAttributeCharacter)));
var $hecrj$html_parser$Html$Parser$chompSemicolon = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq(';'));
var $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Aacute', 'Á'),
			_Utils_Tuple2('aacute', 'á'),
			_Utils_Tuple2('Abreve', 'Ă'),
			_Utils_Tuple2('abreve', 'ă'),
			_Utils_Tuple2('ac', '∾'),
			_Utils_Tuple2('acd', '∿'),
			_Utils_Tuple2('acE', '∾̳'),
			_Utils_Tuple2('Acirc', 'Â'),
			_Utils_Tuple2('acirc', 'â'),
			_Utils_Tuple2('acute', '´'),
			_Utils_Tuple2('Acy', 'А'),
			_Utils_Tuple2('acy', 'а'),
			_Utils_Tuple2('AElig', 'Æ'),
			_Utils_Tuple2('aelig', 'æ'),
			_Utils_Tuple2('af', '\u2061'),
			_Utils_Tuple2('Afr', '\uD835\uDD04'),
			_Utils_Tuple2('afr', '\uD835\uDD1E'),
			_Utils_Tuple2('Agrave', 'À'),
			_Utils_Tuple2('agrave', 'à'),
			_Utils_Tuple2('alefsym', 'ℵ'),
			_Utils_Tuple2('aleph', 'ℵ'),
			_Utils_Tuple2('Alpha', 'Α'),
			_Utils_Tuple2('alpha', 'α'),
			_Utils_Tuple2('Amacr', 'Ā'),
			_Utils_Tuple2('amacr', 'ā'),
			_Utils_Tuple2('amalg', '⨿'),
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('AMP', '&'),
			_Utils_Tuple2('andand', '⩕'),
			_Utils_Tuple2('And', '⩓'),
			_Utils_Tuple2('and', '∧'),
			_Utils_Tuple2('andd', '⩜'),
			_Utils_Tuple2('andslope', '⩘'),
			_Utils_Tuple2('andv', '⩚'),
			_Utils_Tuple2('ang', '∠'),
			_Utils_Tuple2('ange', '⦤'),
			_Utils_Tuple2('angle', '∠'),
			_Utils_Tuple2('angmsdaa', '⦨'),
			_Utils_Tuple2('angmsdab', '⦩'),
			_Utils_Tuple2('angmsdac', '⦪'),
			_Utils_Tuple2('angmsdad', '⦫'),
			_Utils_Tuple2('angmsdae', '⦬'),
			_Utils_Tuple2('angmsdaf', '⦭'),
			_Utils_Tuple2('angmsdag', '⦮'),
			_Utils_Tuple2('angmsdah', '⦯'),
			_Utils_Tuple2('angmsd', '∡'),
			_Utils_Tuple2('angrt', '∟'),
			_Utils_Tuple2('angrtvb', '⊾'),
			_Utils_Tuple2('angrtvbd', '⦝'),
			_Utils_Tuple2('angsph', '∢'),
			_Utils_Tuple2('angst', 'Å'),
			_Utils_Tuple2('angzarr', '⍼'),
			_Utils_Tuple2('Aogon', 'Ą'),
			_Utils_Tuple2('aogon', 'ą'),
			_Utils_Tuple2('Aopf', '\uD835\uDD38'),
			_Utils_Tuple2('aopf', '\uD835\uDD52'),
			_Utils_Tuple2('apacir', '⩯'),
			_Utils_Tuple2('ap', '≈'),
			_Utils_Tuple2('apE', '⩰'),
			_Utils_Tuple2('ape', '≊'),
			_Utils_Tuple2('apid', '≋'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('ApplyFunction', '\u2061'),
			_Utils_Tuple2('approx', '≈'),
			_Utils_Tuple2('approxeq', '≊'),
			_Utils_Tuple2('Aring', 'Å'),
			_Utils_Tuple2('aring', 'å'),
			_Utils_Tuple2('Ascr', '\uD835\uDC9C'),
			_Utils_Tuple2('ascr', '\uD835\uDCB6'),
			_Utils_Tuple2('Assign', '≔'),
			_Utils_Tuple2('ast', '*'),
			_Utils_Tuple2('asymp', '≈'),
			_Utils_Tuple2('asympeq', '≍'),
			_Utils_Tuple2('Atilde', 'Ã'),
			_Utils_Tuple2('atilde', 'ã'),
			_Utils_Tuple2('Auml', 'Ä'),
			_Utils_Tuple2('auml', 'ä'),
			_Utils_Tuple2('awconint', '∳'),
			_Utils_Tuple2('awint', '⨑'),
			_Utils_Tuple2('backcong', '≌'),
			_Utils_Tuple2('backepsilon', '϶'),
			_Utils_Tuple2('backprime', '‵'),
			_Utils_Tuple2('backsim', '∽'),
			_Utils_Tuple2('backsimeq', '⋍'),
			_Utils_Tuple2('Backslash', '∖'),
			_Utils_Tuple2('Barv', '⫧'),
			_Utils_Tuple2('barvee', '⊽'),
			_Utils_Tuple2('barwed', '⌅'),
			_Utils_Tuple2('Barwed', '⌆'),
			_Utils_Tuple2('barwedge', '⌅'),
			_Utils_Tuple2('bbrk', '⎵'),
			_Utils_Tuple2('bbrktbrk', '⎶'),
			_Utils_Tuple2('bcong', '≌'),
			_Utils_Tuple2('Bcy', 'Б'),
			_Utils_Tuple2('bcy', 'б'),
			_Utils_Tuple2('bdquo', '„'),
			_Utils_Tuple2('becaus', '∵'),
			_Utils_Tuple2('because', '∵'),
			_Utils_Tuple2('Because', '∵'),
			_Utils_Tuple2('bemptyv', '⦰'),
			_Utils_Tuple2('bepsi', '϶'),
			_Utils_Tuple2('bernou', 'ℬ'),
			_Utils_Tuple2('Bernoullis', 'ℬ'),
			_Utils_Tuple2('Beta', 'Β'),
			_Utils_Tuple2('beta', 'β'),
			_Utils_Tuple2('beth', 'ℶ'),
			_Utils_Tuple2('between', '≬'),
			_Utils_Tuple2('Bfr', '\uD835\uDD05'),
			_Utils_Tuple2('bfr', '\uD835\uDD1F'),
			_Utils_Tuple2('bigcap', '⋂'),
			_Utils_Tuple2('bigcirc', '◯'),
			_Utils_Tuple2('bigcup', '⋃'),
			_Utils_Tuple2('bigodot', '⨀'),
			_Utils_Tuple2('bigoplus', '⨁'),
			_Utils_Tuple2('bigotimes', '⨂'),
			_Utils_Tuple2('bigsqcup', '⨆'),
			_Utils_Tuple2('bigstar', '★'),
			_Utils_Tuple2('bigtriangledown', '▽'),
			_Utils_Tuple2('bigtriangleup', '△'),
			_Utils_Tuple2('biguplus', '⨄'),
			_Utils_Tuple2('bigvee', '⋁'),
			_Utils_Tuple2('bigwedge', '⋀'),
			_Utils_Tuple2('bkarow', '⤍'),
			_Utils_Tuple2('blacklozenge', '⧫'),
			_Utils_Tuple2('blacksquare', '▪'),
			_Utils_Tuple2('blacktriangle', '▴'),
			_Utils_Tuple2('blacktriangledown', '▾'),
			_Utils_Tuple2('blacktriangleleft', '◂'),
			_Utils_Tuple2('blacktriangleright', '▸'),
			_Utils_Tuple2('blank', '␣'),
			_Utils_Tuple2('blk12', '▒'),
			_Utils_Tuple2('blk14', '░'),
			_Utils_Tuple2('blk34', '▓'),
			_Utils_Tuple2('block', '█'),
			_Utils_Tuple2('bne', '=⃥'),
			_Utils_Tuple2('bnequiv', '≡⃥'),
			_Utils_Tuple2('bNot', '⫭'),
			_Utils_Tuple2('bnot', '⌐'),
			_Utils_Tuple2('Bopf', '\uD835\uDD39'),
			_Utils_Tuple2('bopf', '\uD835\uDD53'),
			_Utils_Tuple2('bot', '⊥'),
			_Utils_Tuple2('bottom', '⊥'),
			_Utils_Tuple2('bowtie', '⋈'),
			_Utils_Tuple2('boxbox', '⧉'),
			_Utils_Tuple2('boxdl', '┐'),
			_Utils_Tuple2('boxdL', '╕'),
			_Utils_Tuple2('boxDl', '╖'),
			_Utils_Tuple2('boxDL', '╗'),
			_Utils_Tuple2('boxdr', '┌'),
			_Utils_Tuple2('boxdR', '╒'),
			_Utils_Tuple2('boxDr', '╓'),
			_Utils_Tuple2('boxDR', '╔'),
			_Utils_Tuple2('boxh', '─'),
			_Utils_Tuple2('boxH', '═'),
			_Utils_Tuple2('boxhd', '┬'),
			_Utils_Tuple2('boxHd', '╤'),
			_Utils_Tuple2('boxhD', '╥'),
			_Utils_Tuple2('boxHD', '╦'),
			_Utils_Tuple2('boxhu', '┴'),
			_Utils_Tuple2('boxHu', '╧'),
			_Utils_Tuple2('boxhU', '╨'),
			_Utils_Tuple2('boxHU', '╩'),
			_Utils_Tuple2('boxminus', '⊟'),
			_Utils_Tuple2('boxplus', '⊞'),
			_Utils_Tuple2('boxtimes', '⊠'),
			_Utils_Tuple2('boxul', '┘'),
			_Utils_Tuple2('boxuL', '╛'),
			_Utils_Tuple2('boxUl', '╜'),
			_Utils_Tuple2('boxUL', '╝'),
			_Utils_Tuple2('boxur', '└'),
			_Utils_Tuple2('boxuR', '╘'),
			_Utils_Tuple2('boxUr', '╙'),
			_Utils_Tuple2('boxUR', '╚'),
			_Utils_Tuple2('boxv', '│'),
			_Utils_Tuple2('boxV', '║'),
			_Utils_Tuple2('boxvh', '┼'),
			_Utils_Tuple2('boxvH', '╪'),
			_Utils_Tuple2('boxVh', '╫'),
			_Utils_Tuple2('boxVH', '╬'),
			_Utils_Tuple2('boxvl', '┤'),
			_Utils_Tuple2('boxvL', '╡'),
			_Utils_Tuple2('boxVl', '╢'),
			_Utils_Tuple2('boxVL', '╣'),
			_Utils_Tuple2('boxvr', '├'),
			_Utils_Tuple2('boxvR', '╞'),
			_Utils_Tuple2('boxVr', '╟'),
			_Utils_Tuple2('boxVR', '╠'),
			_Utils_Tuple2('bprime', '‵'),
			_Utils_Tuple2('breve', '˘'),
			_Utils_Tuple2('Breve', '˘'),
			_Utils_Tuple2('brvbar', '¦'),
			_Utils_Tuple2('bscr', '\uD835\uDCB7'),
			_Utils_Tuple2('Bscr', 'ℬ'),
			_Utils_Tuple2('bsemi', '⁏'),
			_Utils_Tuple2('bsim', '∽'),
			_Utils_Tuple2('bsime', '⋍'),
			_Utils_Tuple2('bsolb', '⧅'),
			_Utils_Tuple2('bsol', '\\'),
			_Utils_Tuple2('bsolhsub', '⟈'),
			_Utils_Tuple2('bull', '•'),
			_Utils_Tuple2('bullet', '•'),
			_Utils_Tuple2('bump', '≎'),
			_Utils_Tuple2('bumpE', '⪮'),
			_Utils_Tuple2('bumpe', '≏'),
			_Utils_Tuple2('Bumpeq', '≎'),
			_Utils_Tuple2('bumpeq', '≏'),
			_Utils_Tuple2('Cacute', 'Ć'),
			_Utils_Tuple2('cacute', 'ć'),
			_Utils_Tuple2('capand', '⩄'),
			_Utils_Tuple2('capbrcup', '⩉'),
			_Utils_Tuple2('capcap', '⩋'),
			_Utils_Tuple2('cap', '∩'),
			_Utils_Tuple2('Cap', '⋒'),
			_Utils_Tuple2('capcup', '⩇'),
			_Utils_Tuple2('capdot', '⩀'),
			_Utils_Tuple2('CapitalDifferentialD', 'ⅅ'),
			_Utils_Tuple2('caps', '∩︀'),
			_Utils_Tuple2('caret', '⁁'),
			_Utils_Tuple2('caron', 'ˇ'),
			_Utils_Tuple2('Cayleys', 'ℭ'),
			_Utils_Tuple2('ccaps', '⩍'),
			_Utils_Tuple2('Ccaron', 'Č'),
			_Utils_Tuple2('ccaron', 'č'),
			_Utils_Tuple2('Ccedil', 'Ç'),
			_Utils_Tuple2('ccedil', 'ç'),
			_Utils_Tuple2('Ccirc', 'Ĉ'),
			_Utils_Tuple2('ccirc', 'ĉ'),
			_Utils_Tuple2('Cconint', '∰'),
			_Utils_Tuple2('ccups', '⩌'),
			_Utils_Tuple2('ccupssm', '⩐'),
			_Utils_Tuple2('Cdot', 'Ċ'),
			_Utils_Tuple2('cdot', 'ċ'),
			_Utils_Tuple2('cedil', '¸'),
			_Utils_Tuple2('Cedilla', '¸'),
			_Utils_Tuple2('cemptyv', '⦲'),
			_Utils_Tuple2('cent', '¢'),
			_Utils_Tuple2('centerdot', '·'),
			_Utils_Tuple2('CenterDot', '·'),
			_Utils_Tuple2('cfr', '\uD835\uDD20'),
			_Utils_Tuple2('Cfr', 'ℭ'),
			_Utils_Tuple2('CHcy', 'Ч'),
			_Utils_Tuple2('chcy', 'ч'),
			_Utils_Tuple2('check', '✓'),
			_Utils_Tuple2('checkmark', '✓'),
			_Utils_Tuple2('Chi', 'Χ'),
			_Utils_Tuple2('chi', 'χ'),
			_Utils_Tuple2('circ', 'ˆ'),
			_Utils_Tuple2('circeq', '≗'),
			_Utils_Tuple2('circlearrowleft', '↺'),
			_Utils_Tuple2('circlearrowright', '↻'),
			_Utils_Tuple2('circledast', '⊛'),
			_Utils_Tuple2('circledcirc', '⊚'),
			_Utils_Tuple2('circleddash', '⊝'),
			_Utils_Tuple2('CircleDot', '⊙'),
			_Utils_Tuple2('circledR', '®'),
			_Utils_Tuple2('circledS', 'Ⓢ'),
			_Utils_Tuple2('CircleMinus', '⊖'),
			_Utils_Tuple2('CirclePlus', '⊕'),
			_Utils_Tuple2('CircleTimes', '⊗'),
			_Utils_Tuple2('cir', '○'),
			_Utils_Tuple2('cirE', '⧃'),
			_Utils_Tuple2('cire', '≗'),
			_Utils_Tuple2('cirfnint', '⨐'),
			_Utils_Tuple2('cirmid', '⫯'),
			_Utils_Tuple2('cirscir', '⧂'),
			_Utils_Tuple2('ClockwiseContourIntegral', '∲'),
			_Utils_Tuple2('CloseCurlyDoubleQuote', '”'),
			_Utils_Tuple2('CloseCurlyQuote', '’'),
			_Utils_Tuple2('clubs', '♣'),
			_Utils_Tuple2('clubsuit', '♣'),
			_Utils_Tuple2('colon', ':'),
			_Utils_Tuple2('Colon', '∷'),
			_Utils_Tuple2('Colone', '⩴'),
			_Utils_Tuple2('colone', '≔'),
			_Utils_Tuple2('coloneq', '≔'),
			_Utils_Tuple2('comma', ','),
			_Utils_Tuple2('commat', '@'),
			_Utils_Tuple2('comp', '∁'),
			_Utils_Tuple2('compfn', '∘'),
			_Utils_Tuple2('complement', '∁'),
			_Utils_Tuple2('complexes', 'ℂ'),
			_Utils_Tuple2('cong', '≅'),
			_Utils_Tuple2('congdot', '⩭'),
			_Utils_Tuple2('Congruent', '≡'),
			_Utils_Tuple2('conint', '∮'),
			_Utils_Tuple2('Conint', '∯'),
			_Utils_Tuple2('ContourIntegral', '∮'),
			_Utils_Tuple2('copf', '\uD835\uDD54'),
			_Utils_Tuple2('Copf', 'ℂ'),
			_Utils_Tuple2('coprod', '∐'),
			_Utils_Tuple2('Coproduct', '∐'),
			_Utils_Tuple2('copy', '©'),
			_Utils_Tuple2('COPY', '©'),
			_Utils_Tuple2('copysr', '℗'),
			_Utils_Tuple2('CounterClockwiseContourIntegral', '∳'),
			_Utils_Tuple2('crarr', '↵'),
			_Utils_Tuple2('cross', '✗'),
			_Utils_Tuple2('Cross', '⨯'),
			_Utils_Tuple2('Cscr', '\uD835\uDC9E'),
			_Utils_Tuple2('cscr', '\uD835\uDCB8'),
			_Utils_Tuple2('csub', '⫏'),
			_Utils_Tuple2('csube', '⫑'),
			_Utils_Tuple2('csup', '⫐'),
			_Utils_Tuple2('csupe', '⫒'),
			_Utils_Tuple2('ctdot', '⋯'),
			_Utils_Tuple2('cudarrl', '⤸'),
			_Utils_Tuple2('cudarrr', '⤵'),
			_Utils_Tuple2('cuepr', '⋞'),
			_Utils_Tuple2('cuesc', '⋟'),
			_Utils_Tuple2('cularr', '↶'),
			_Utils_Tuple2('cularrp', '⤽'),
			_Utils_Tuple2('cupbrcap', '⩈'),
			_Utils_Tuple2('cupcap', '⩆'),
			_Utils_Tuple2('CupCap', '≍'),
			_Utils_Tuple2('cup', '∪'),
			_Utils_Tuple2('Cup', '⋓'),
			_Utils_Tuple2('cupcup', '⩊'),
			_Utils_Tuple2('cupdot', '⊍'),
			_Utils_Tuple2('cupor', '⩅'),
			_Utils_Tuple2('cups', '∪︀'),
			_Utils_Tuple2('curarr', '↷'),
			_Utils_Tuple2('curarrm', '⤼'),
			_Utils_Tuple2('curlyeqprec', '⋞'),
			_Utils_Tuple2('curlyeqsucc', '⋟'),
			_Utils_Tuple2('curlyvee', '⋎'),
			_Utils_Tuple2('curlywedge', '⋏'),
			_Utils_Tuple2('curren', '¤'),
			_Utils_Tuple2('curvearrowleft', '↶'),
			_Utils_Tuple2('curvearrowright', '↷'),
			_Utils_Tuple2('cuvee', '⋎'),
			_Utils_Tuple2('cuwed', '⋏'),
			_Utils_Tuple2('cwconint', '∲'),
			_Utils_Tuple2('cwint', '∱'),
			_Utils_Tuple2('cylcty', '⌭'),
			_Utils_Tuple2('dagger', '†'),
			_Utils_Tuple2('Dagger', '‡'),
			_Utils_Tuple2('daleth', 'ℸ'),
			_Utils_Tuple2('darr', '↓'),
			_Utils_Tuple2('Darr', '↡'),
			_Utils_Tuple2('dArr', '⇓'),
			_Utils_Tuple2('dash', '‐'),
			_Utils_Tuple2('Dashv', '⫤'),
			_Utils_Tuple2('dashv', '⊣'),
			_Utils_Tuple2('dbkarow', '⤏'),
			_Utils_Tuple2('dblac', '˝'),
			_Utils_Tuple2('Dcaron', 'Ď'),
			_Utils_Tuple2('dcaron', 'ď'),
			_Utils_Tuple2('Dcy', 'Д'),
			_Utils_Tuple2('dcy', 'д'),
			_Utils_Tuple2('ddagger', '‡'),
			_Utils_Tuple2('ddarr', '⇊'),
			_Utils_Tuple2('DD', 'ⅅ'),
			_Utils_Tuple2('dd', 'ⅆ'),
			_Utils_Tuple2('DDotrahd', '⤑'),
			_Utils_Tuple2('ddotseq', '⩷'),
			_Utils_Tuple2('deg', '°'),
			_Utils_Tuple2('Del', '∇'),
			_Utils_Tuple2('Delta', 'Δ'),
			_Utils_Tuple2('delta', 'δ'),
			_Utils_Tuple2('demptyv', '⦱'),
			_Utils_Tuple2('dfisht', '⥿'),
			_Utils_Tuple2('Dfr', '\uD835\uDD07'),
			_Utils_Tuple2('dfr', '\uD835\uDD21'),
			_Utils_Tuple2('dHar', '⥥'),
			_Utils_Tuple2('dharl', '⇃'),
			_Utils_Tuple2('dharr', '⇂'),
			_Utils_Tuple2('DiacriticalAcute', '´'),
			_Utils_Tuple2('DiacriticalDot', '˙'),
			_Utils_Tuple2('DiacriticalDoubleAcute', '˝'),
			_Utils_Tuple2('DiacriticalGrave', '`'),
			_Utils_Tuple2('DiacriticalTilde', '˜'),
			_Utils_Tuple2('diam', '⋄'),
			_Utils_Tuple2('diamond', '⋄'),
			_Utils_Tuple2('Diamond', '⋄'),
			_Utils_Tuple2('diamondsuit', '♦'),
			_Utils_Tuple2('diams', '♦'),
			_Utils_Tuple2('die', '¨'),
			_Utils_Tuple2('DifferentialD', 'ⅆ'),
			_Utils_Tuple2('digamma', 'ϝ'),
			_Utils_Tuple2('disin', '⋲'),
			_Utils_Tuple2('div', '÷'),
			_Utils_Tuple2('divide', '÷'),
			_Utils_Tuple2('divideontimes', '⋇'),
			_Utils_Tuple2('divonx', '⋇'),
			_Utils_Tuple2('DJcy', 'Ђ'),
			_Utils_Tuple2('djcy', 'ђ'),
			_Utils_Tuple2('dlcorn', '⌞'),
			_Utils_Tuple2('dlcrop', '⌍'),
			_Utils_Tuple2('dollar', '$'),
			_Utils_Tuple2('Dopf', '\uD835\uDD3B'),
			_Utils_Tuple2('dopf', '\uD835\uDD55'),
			_Utils_Tuple2('Dot', '¨'),
			_Utils_Tuple2('dot', '˙'),
			_Utils_Tuple2('DotDot', '⃜'),
			_Utils_Tuple2('doteq', '≐'),
			_Utils_Tuple2('doteqdot', '≑'),
			_Utils_Tuple2('DotEqual', '≐'),
			_Utils_Tuple2('dotminus', '∸'),
			_Utils_Tuple2('dotplus', '∔'),
			_Utils_Tuple2('dotsquare', '⊡'),
			_Utils_Tuple2('doublebarwedge', '⌆'),
			_Utils_Tuple2('DoubleContourIntegral', '∯'),
			_Utils_Tuple2('DoubleDot', '¨'),
			_Utils_Tuple2('DoubleDownArrow', '⇓'),
			_Utils_Tuple2('DoubleLeftArrow', '⇐'),
			_Utils_Tuple2('DoubleLeftRightArrow', '⇔'),
			_Utils_Tuple2('DoubleLeftTee', '⫤'),
			_Utils_Tuple2('DoubleLongLeftArrow', '⟸'),
			_Utils_Tuple2('DoubleLongLeftRightArrow', '⟺'),
			_Utils_Tuple2('DoubleLongRightArrow', '⟹'),
			_Utils_Tuple2('DoubleRightArrow', '⇒'),
			_Utils_Tuple2('DoubleRightTee', '⊨'),
			_Utils_Tuple2('DoubleUpArrow', '⇑'),
			_Utils_Tuple2('DoubleUpDownArrow', '⇕'),
			_Utils_Tuple2('DoubleVerticalBar', '∥'),
			_Utils_Tuple2('DownArrowBar', '⤓'),
			_Utils_Tuple2('downarrow', '↓'),
			_Utils_Tuple2('DownArrow', '↓'),
			_Utils_Tuple2('Downarrow', '⇓'),
			_Utils_Tuple2('DownArrowUpArrow', '⇵'),
			_Utils_Tuple2('DownBreve', '̑'),
			_Utils_Tuple2('downdownarrows', '⇊'),
			_Utils_Tuple2('downharpoonleft', '⇃'),
			_Utils_Tuple2('downharpoonright', '⇂'),
			_Utils_Tuple2('DownLeftRightVector', '⥐'),
			_Utils_Tuple2('DownLeftTeeVector', '⥞'),
			_Utils_Tuple2('DownLeftVectorBar', '⥖'),
			_Utils_Tuple2('DownLeftVector', '↽'),
			_Utils_Tuple2('DownRightTeeVector', '⥟'),
			_Utils_Tuple2('DownRightVectorBar', '⥗'),
			_Utils_Tuple2('DownRightVector', '⇁'),
			_Utils_Tuple2('DownTeeArrow', '↧'),
			_Utils_Tuple2('DownTee', '⊤'),
			_Utils_Tuple2('drbkarow', '⤐'),
			_Utils_Tuple2('drcorn', '⌟'),
			_Utils_Tuple2('drcrop', '⌌'),
			_Utils_Tuple2('Dscr', '\uD835\uDC9F'),
			_Utils_Tuple2('dscr', '\uD835\uDCB9'),
			_Utils_Tuple2('DScy', 'Ѕ'),
			_Utils_Tuple2('dscy', 'ѕ'),
			_Utils_Tuple2('dsol', '⧶'),
			_Utils_Tuple2('Dstrok', 'Đ'),
			_Utils_Tuple2('dstrok', 'đ'),
			_Utils_Tuple2('dtdot', '⋱'),
			_Utils_Tuple2('dtri', '▿'),
			_Utils_Tuple2('dtrif', '▾'),
			_Utils_Tuple2('duarr', '⇵'),
			_Utils_Tuple2('duhar', '⥯'),
			_Utils_Tuple2('dwangle', '⦦'),
			_Utils_Tuple2('DZcy', 'Џ'),
			_Utils_Tuple2('dzcy', 'џ'),
			_Utils_Tuple2('dzigrarr', '⟿'),
			_Utils_Tuple2('Eacute', 'É'),
			_Utils_Tuple2('eacute', 'é'),
			_Utils_Tuple2('easter', '⩮'),
			_Utils_Tuple2('Ecaron', 'Ě'),
			_Utils_Tuple2('ecaron', 'ě'),
			_Utils_Tuple2('Ecirc', 'Ê'),
			_Utils_Tuple2('ecirc', 'ê'),
			_Utils_Tuple2('ecir', '≖'),
			_Utils_Tuple2('ecolon', '≕'),
			_Utils_Tuple2('Ecy', 'Э'),
			_Utils_Tuple2('ecy', 'э'),
			_Utils_Tuple2('eDDot', '⩷'),
			_Utils_Tuple2('Edot', 'Ė'),
			_Utils_Tuple2('edot', 'ė'),
			_Utils_Tuple2('eDot', '≑'),
			_Utils_Tuple2('ee', 'ⅇ'),
			_Utils_Tuple2('efDot', '≒'),
			_Utils_Tuple2('Efr', '\uD835\uDD08'),
			_Utils_Tuple2('efr', '\uD835\uDD22'),
			_Utils_Tuple2('eg', '⪚'),
			_Utils_Tuple2('Egrave', 'È'),
			_Utils_Tuple2('egrave', 'è'),
			_Utils_Tuple2('egs', '⪖'),
			_Utils_Tuple2('egsdot', '⪘'),
			_Utils_Tuple2('el', '⪙'),
			_Utils_Tuple2('Element', '∈'),
			_Utils_Tuple2('elinters', '⏧'),
			_Utils_Tuple2('ell', 'ℓ'),
			_Utils_Tuple2('els', '⪕'),
			_Utils_Tuple2('elsdot', '⪗'),
			_Utils_Tuple2('Emacr', 'Ē'),
			_Utils_Tuple2('emacr', 'ē'),
			_Utils_Tuple2('empty', '∅'),
			_Utils_Tuple2('emptyset', '∅'),
			_Utils_Tuple2('EmptySmallSquare', '◻'),
			_Utils_Tuple2('emptyv', '∅'),
			_Utils_Tuple2('EmptyVerySmallSquare', '▫'),
			_Utils_Tuple2('emsp13', '\u2004'),
			_Utils_Tuple2('emsp14', '\u2005'),
			_Utils_Tuple2('emsp', '\u2003'),
			_Utils_Tuple2('ENG', 'Ŋ'),
			_Utils_Tuple2('eng', 'ŋ'),
			_Utils_Tuple2('ensp', '\u2002'),
			_Utils_Tuple2('Eogon', 'Ę'),
			_Utils_Tuple2('eogon', 'ę'),
			_Utils_Tuple2('Eopf', '\uD835\uDD3C'),
			_Utils_Tuple2('eopf', '\uD835\uDD56'),
			_Utils_Tuple2('epar', '⋕'),
			_Utils_Tuple2('eparsl', '⧣'),
			_Utils_Tuple2('eplus', '⩱'),
			_Utils_Tuple2('epsi', 'ε'),
			_Utils_Tuple2('Epsilon', 'Ε'),
			_Utils_Tuple2('epsilon', 'ε'),
			_Utils_Tuple2('epsiv', 'ϵ'),
			_Utils_Tuple2('eqcirc', '≖'),
			_Utils_Tuple2('eqcolon', '≕'),
			_Utils_Tuple2('eqsim', '≂'),
			_Utils_Tuple2('eqslantgtr', '⪖'),
			_Utils_Tuple2('eqslantless', '⪕'),
			_Utils_Tuple2('Equal', '⩵'),
			_Utils_Tuple2('equals', '='),
			_Utils_Tuple2('EqualTilde', '≂'),
			_Utils_Tuple2('equest', '≟'),
			_Utils_Tuple2('Equilibrium', '⇌'),
			_Utils_Tuple2('equiv', '≡'),
			_Utils_Tuple2('equivDD', '⩸'),
			_Utils_Tuple2('eqvparsl', '⧥'),
			_Utils_Tuple2('erarr', '⥱'),
			_Utils_Tuple2('erDot', '≓'),
			_Utils_Tuple2('escr', 'ℯ'),
			_Utils_Tuple2('Escr', 'ℰ'),
			_Utils_Tuple2('esdot', '≐'),
			_Utils_Tuple2('Esim', '⩳'),
			_Utils_Tuple2('esim', '≂'),
			_Utils_Tuple2('Eta', 'Η'),
			_Utils_Tuple2('eta', 'η'),
			_Utils_Tuple2('ETH', 'Ð'),
			_Utils_Tuple2('eth', 'ð'),
			_Utils_Tuple2('Euml', 'Ë'),
			_Utils_Tuple2('euml', 'ë'),
			_Utils_Tuple2('euro', '€'),
			_Utils_Tuple2('excl', '!'),
			_Utils_Tuple2('exist', '∃'),
			_Utils_Tuple2('Exists', '∃'),
			_Utils_Tuple2('expectation', 'ℰ'),
			_Utils_Tuple2('exponentiale', 'ⅇ'),
			_Utils_Tuple2('ExponentialE', 'ⅇ'),
			_Utils_Tuple2('fallingdotseq', '≒'),
			_Utils_Tuple2('Fcy', 'Ф'),
			_Utils_Tuple2('fcy', 'ф'),
			_Utils_Tuple2('female', '♀'),
			_Utils_Tuple2('ffilig', 'ﬃ'),
			_Utils_Tuple2('fflig', 'ﬀ'),
			_Utils_Tuple2('ffllig', 'ﬄ'),
			_Utils_Tuple2('Ffr', '\uD835\uDD09'),
			_Utils_Tuple2('ffr', '\uD835\uDD23'),
			_Utils_Tuple2('filig', 'ﬁ'),
			_Utils_Tuple2('FilledSmallSquare', '◼'),
			_Utils_Tuple2('FilledVerySmallSquare', '▪'),
			_Utils_Tuple2('fjlig', 'fj'),
			_Utils_Tuple2('flat', '♭'),
			_Utils_Tuple2('fllig', 'ﬂ'),
			_Utils_Tuple2('fltns', '▱'),
			_Utils_Tuple2('fnof', 'ƒ'),
			_Utils_Tuple2('Fopf', '\uD835\uDD3D'),
			_Utils_Tuple2('fopf', '\uD835\uDD57'),
			_Utils_Tuple2('forall', '∀'),
			_Utils_Tuple2('ForAll', '∀'),
			_Utils_Tuple2('fork', '⋔'),
			_Utils_Tuple2('forkv', '⫙'),
			_Utils_Tuple2('Fouriertrf', 'ℱ'),
			_Utils_Tuple2('fpartint', '⨍'),
			_Utils_Tuple2('frac12', '½'),
			_Utils_Tuple2('frac13', '⅓'),
			_Utils_Tuple2('frac14', '¼'),
			_Utils_Tuple2('frac15', '⅕'),
			_Utils_Tuple2('frac16', '⅙'),
			_Utils_Tuple2('frac18', '⅛'),
			_Utils_Tuple2('frac23', '⅔'),
			_Utils_Tuple2('frac25', '⅖'),
			_Utils_Tuple2('frac34', '¾'),
			_Utils_Tuple2('frac35', '⅗'),
			_Utils_Tuple2('frac38', '⅜'),
			_Utils_Tuple2('frac45', '⅘'),
			_Utils_Tuple2('frac56', '⅚'),
			_Utils_Tuple2('frac58', '⅝'),
			_Utils_Tuple2('frac78', '⅞'),
			_Utils_Tuple2('frasl', '⁄'),
			_Utils_Tuple2('frown', '⌢'),
			_Utils_Tuple2('fscr', '\uD835\uDCBB'),
			_Utils_Tuple2('Fscr', 'ℱ'),
			_Utils_Tuple2('gacute', 'ǵ'),
			_Utils_Tuple2('Gamma', 'Γ'),
			_Utils_Tuple2('gamma', 'γ'),
			_Utils_Tuple2('Gammad', 'Ϝ'),
			_Utils_Tuple2('gammad', 'ϝ'),
			_Utils_Tuple2('gap', '⪆'),
			_Utils_Tuple2('Gbreve', 'Ğ'),
			_Utils_Tuple2('gbreve', 'ğ'),
			_Utils_Tuple2('Gcedil', 'Ģ'),
			_Utils_Tuple2('Gcirc', 'Ĝ'),
			_Utils_Tuple2('gcirc', 'ĝ'),
			_Utils_Tuple2('Gcy', 'Г'),
			_Utils_Tuple2('gcy', 'г'),
			_Utils_Tuple2('Gdot', 'Ġ'),
			_Utils_Tuple2('gdot', 'ġ'),
			_Utils_Tuple2('ge', '≥'),
			_Utils_Tuple2('gE', '≧'),
			_Utils_Tuple2('gEl', '⪌'),
			_Utils_Tuple2('gel', '⋛'),
			_Utils_Tuple2('geq', '≥'),
			_Utils_Tuple2('geqq', '≧'),
			_Utils_Tuple2('geqslant', '⩾'),
			_Utils_Tuple2('gescc', '⪩'),
			_Utils_Tuple2('ges', '⩾'),
			_Utils_Tuple2('gesdot', '⪀'),
			_Utils_Tuple2('gesdoto', '⪂'),
			_Utils_Tuple2('gesdotol', '⪄'),
			_Utils_Tuple2('gesl', '⋛︀'),
			_Utils_Tuple2('gesles', '⪔'),
			_Utils_Tuple2('Gfr', '\uD835\uDD0A'),
			_Utils_Tuple2('gfr', '\uD835\uDD24'),
			_Utils_Tuple2('gg', '≫'),
			_Utils_Tuple2('Gg', '⋙'),
			_Utils_Tuple2('ggg', '⋙'),
			_Utils_Tuple2('gimel', 'ℷ'),
			_Utils_Tuple2('GJcy', 'Ѓ'),
			_Utils_Tuple2('gjcy', 'ѓ'),
			_Utils_Tuple2('gla', '⪥'),
			_Utils_Tuple2('gl', '≷'),
			_Utils_Tuple2('glE', '⪒'),
			_Utils_Tuple2('glj', '⪤'),
			_Utils_Tuple2('gnap', '⪊'),
			_Utils_Tuple2('gnapprox', '⪊'),
			_Utils_Tuple2('gne', '⪈'),
			_Utils_Tuple2('gnE', '≩'),
			_Utils_Tuple2('gneq', '⪈'),
			_Utils_Tuple2('gneqq', '≩'),
			_Utils_Tuple2('gnsim', '⋧'),
			_Utils_Tuple2('Gopf', '\uD835\uDD3E'),
			_Utils_Tuple2('gopf', '\uD835\uDD58'),
			_Utils_Tuple2('grave', '`'),
			_Utils_Tuple2('GreaterEqual', '≥'),
			_Utils_Tuple2('GreaterEqualLess', '⋛'),
			_Utils_Tuple2('GreaterFullEqual', '≧'),
			_Utils_Tuple2('GreaterGreater', '⪢'),
			_Utils_Tuple2('GreaterLess', '≷'),
			_Utils_Tuple2('GreaterSlantEqual', '⩾'),
			_Utils_Tuple2('GreaterTilde', '≳'),
			_Utils_Tuple2('Gscr', '\uD835\uDCA2'),
			_Utils_Tuple2('gscr', 'ℊ'),
			_Utils_Tuple2('gsim', '≳'),
			_Utils_Tuple2('gsime', '⪎'),
			_Utils_Tuple2('gsiml', '⪐'),
			_Utils_Tuple2('gtcc', '⪧'),
			_Utils_Tuple2('gtcir', '⩺'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('GT', '>'),
			_Utils_Tuple2('Gt', '≫'),
			_Utils_Tuple2('gtdot', '⋗'),
			_Utils_Tuple2('gtlPar', '⦕'),
			_Utils_Tuple2('gtquest', '⩼'),
			_Utils_Tuple2('gtrapprox', '⪆'),
			_Utils_Tuple2('gtrarr', '⥸'),
			_Utils_Tuple2('gtrdot', '⋗'),
			_Utils_Tuple2('gtreqless', '⋛'),
			_Utils_Tuple2('gtreqqless', '⪌'),
			_Utils_Tuple2('gtrless', '≷'),
			_Utils_Tuple2('gtrsim', '≳'),
			_Utils_Tuple2('gvertneqq', '≩︀'),
			_Utils_Tuple2('gvnE', '≩︀'),
			_Utils_Tuple2('Hacek', 'ˇ'),
			_Utils_Tuple2('hairsp', '\u200A'),
			_Utils_Tuple2('half', '½'),
			_Utils_Tuple2('hamilt', 'ℋ'),
			_Utils_Tuple2('HARDcy', 'Ъ'),
			_Utils_Tuple2('hardcy', 'ъ'),
			_Utils_Tuple2('harrcir', '⥈'),
			_Utils_Tuple2('harr', '↔'),
			_Utils_Tuple2('hArr', '⇔'),
			_Utils_Tuple2('harrw', '↭'),
			_Utils_Tuple2('Hat', '^'),
			_Utils_Tuple2('hbar', 'ℏ'),
			_Utils_Tuple2('Hcirc', 'Ĥ'),
			_Utils_Tuple2('hcirc', 'ĥ'),
			_Utils_Tuple2('hearts', '♥'),
			_Utils_Tuple2('heartsuit', '♥'),
			_Utils_Tuple2('hellip', '…'),
			_Utils_Tuple2('hercon', '⊹'),
			_Utils_Tuple2('hfr', '\uD835\uDD25'),
			_Utils_Tuple2('Hfr', 'ℌ'),
			_Utils_Tuple2('HilbertSpace', 'ℋ'),
			_Utils_Tuple2('hksearow', '⤥'),
			_Utils_Tuple2('hkswarow', '⤦'),
			_Utils_Tuple2('hoarr', '⇿'),
			_Utils_Tuple2('homtht', '∻'),
			_Utils_Tuple2('hookleftarrow', '↩'),
			_Utils_Tuple2('hookrightarrow', '↪'),
			_Utils_Tuple2('hopf', '\uD835\uDD59'),
			_Utils_Tuple2('Hopf', 'ℍ'),
			_Utils_Tuple2('horbar', '―'),
			_Utils_Tuple2('HorizontalLine', '─'),
			_Utils_Tuple2('hscr', '\uD835\uDCBD'),
			_Utils_Tuple2('Hscr', 'ℋ'),
			_Utils_Tuple2('hslash', 'ℏ'),
			_Utils_Tuple2('Hstrok', 'Ħ'),
			_Utils_Tuple2('hstrok', 'ħ'),
			_Utils_Tuple2('HumpDownHump', '≎'),
			_Utils_Tuple2('HumpEqual', '≏'),
			_Utils_Tuple2('hybull', '⁃'),
			_Utils_Tuple2('hyphen', '‐'),
			_Utils_Tuple2('Iacute', 'Í'),
			_Utils_Tuple2('iacute', 'í'),
			_Utils_Tuple2('ic', '\u2063'),
			_Utils_Tuple2('Icirc', 'Î'),
			_Utils_Tuple2('icirc', 'î'),
			_Utils_Tuple2('Icy', 'И'),
			_Utils_Tuple2('icy', 'и'),
			_Utils_Tuple2('Idot', 'İ'),
			_Utils_Tuple2('IEcy', 'Е'),
			_Utils_Tuple2('iecy', 'е'),
			_Utils_Tuple2('iexcl', '¡'),
			_Utils_Tuple2('iff', '⇔'),
			_Utils_Tuple2('ifr', '\uD835\uDD26'),
			_Utils_Tuple2('Ifr', 'ℑ'),
			_Utils_Tuple2('Igrave', 'Ì'),
			_Utils_Tuple2('igrave', 'ì'),
			_Utils_Tuple2('ii', 'ⅈ'),
			_Utils_Tuple2('iiiint', '⨌'),
			_Utils_Tuple2('iiint', '∭'),
			_Utils_Tuple2('iinfin', '⧜'),
			_Utils_Tuple2('iiota', '℩'),
			_Utils_Tuple2('IJlig', 'Ĳ'),
			_Utils_Tuple2('ijlig', 'ĳ'),
			_Utils_Tuple2('Imacr', 'Ī'),
			_Utils_Tuple2('imacr', 'ī'),
			_Utils_Tuple2('image', 'ℑ'),
			_Utils_Tuple2('ImaginaryI', 'ⅈ'),
			_Utils_Tuple2('imagline', 'ℐ'),
			_Utils_Tuple2('imagpart', 'ℑ'),
			_Utils_Tuple2('imath', 'ı'),
			_Utils_Tuple2('Im', 'ℑ'),
			_Utils_Tuple2('imof', '⊷'),
			_Utils_Tuple2('imped', 'Ƶ'),
			_Utils_Tuple2('Implies', '⇒'),
			_Utils_Tuple2('incare', '℅'),
			_Utils_Tuple2('in', '∈'),
			_Utils_Tuple2('infin', '∞'),
			_Utils_Tuple2('infintie', '⧝'),
			_Utils_Tuple2('inodot', 'ı'),
			_Utils_Tuple2('intcal', '⊺'),
			_Utils_Tuple2('int', '∫'),
			_Utils_Tuple2('Int', '∬'),
			_Utils_Tuple2('integers', 'ℤ'),
			_Utils_Tuple2('Integral', '∫'),
			_Utils_Tuple2('intercal', '⊺'),
			_Utils_Tuple2('Intersection', '⋂'),
			_Utils_Tuple2('intlarhk', '⨗'),
			_Utils_Tuple2('intprod', '⨼'),
			_Utils_Tuple2('InvisibleComma', '\u2063'),
			_Utils_Tuple2('InvisibleTimes', '\u2062'),
			_Utils_Tuple2('IOcy', 'Ё'),
			_Utils_Tuple2('iocy', 'ё'),
			_Utils_Tuple2('Iogon', 'Į'),
			_Utils_Tuple2('iogon', 'į'),
			_Utils_Tuple2('Iopf', '\uD835\uDD40'),
			_Utils_Tuple2('iopf', '\uD835\uDD5A'),
			_Utils_Tuple2('Iota', 'Ι'),
			_Utils_Tuple2('iota', 'ι'),
			_Utils_Tuple2('iprod', '⨼'),
			_Utils_Tuple2('iquest', '¿'),
			_Utils_Tuple2('iscr', '\uD835\uDCBE'),
			_Utils_Tuple2('Iscr', 'ℐ'),
			_Utils_Tuple2('isin', '∈'),
			_Utils_Tuple2('isindot', '⋵'),
			_Utils_Tuple2('isinE', '⋹'),
			_Utils_Tuple2('isins', '⋴'),
			_Utils_Tuple2('isinsv', '⋳'),
			_Utils_Tuple2('isinv', '∈'),
			_Utils_Tuple2('it', '\u2062'),
			_Utils_Tuple2('Itilde', 'Ĩ'),
			_Utils_Tuple2('itilde', 'ĩ'),
			_Utils_Tuple2('Iukcy', 'І'),
			_Utils_Tuple2('iukcy', 'і'),
			_Utils_Tuple2('Iuml', 'Ï'),
			_Utils_Tuple2('iuml', 'ï'),
			_Utils_Tuple2('Jcirc', 'Ĵ'),
			_Utils_Tuple2('jcirc', 'ĵ'),
			_Utils_Tuple2('Jcy', 'Й'),
			_Utils_Tuple2('jcy', 'й'),
			_Utils_Tuple2('Jfr', '\uD835\uDD0D'),
			_Utils_Tuple2('jfr', '\uD835\uDD27'),
			_Utils_Tuple2('jmath', 'ȷ'),
			_Utils_Tuple2('Jopf', '\uD835\uDD41'),
			_Utils_Tuple2('jopf', '\uD835\uDD5B'),
			_Utils_Tuple2('Jscr', '\uD835\uDCA5'),
			_Utils_Tuple2('jscr', '\uD835\uDCBF'),
			_Utils_Tuple2('Jsercy', 'Ј'),
			_Utils_Tuple2('jsercy', 'ј'),
			_Utils_Tuple2('Jukcy', 'Є'),
			_Utils_Tuple2('jukcy', 'є'),
			_Utils_Tuple2('Kappa', 'Κ'),
			_Utils_Tuple2('kappa', 'κ'),
			_Utils_Tuple2('kappav', 'ϰ'),
			_Utils_Tuple2('Kcedil', 'Ķ'),
			_Utils_Tuple2('kcedil', 'ķ'),
			_Utils_Tuple2('Kcy', 'К'),
			_Utils_Tuple2('kcy', 'к'),
			_Utils_Tuple2('Kfr', '\uD835\uDD0E'),
			_Utils_Tuple2('kfr', '\uD835\uDD28'),
			_Utils_Tuple2('kgreen', 'ĸ'),
			_Utils_Tuple2('KHcy', 'Х'),
			_Utils_Tuple2('khcy', 'х'),
			_Utils_Tuple2('KJcy', 'Ќ'),
			_Utils_Tuple2('kjcy', 'ќ'),
			_Utils_Tuple2('Kopf', '\uD835\uDD42'),
			_Utils_Tuple2('kopf', '\uD835\uDD5C'),
			_Utils_Tuple2('Kscr', '\uD835\uDCA6'),
			_Utils_Tuple2('kscr', '\uD835\uDCC0'),
			_Utils_Tuple2('lAarr', '⇚'),
			_Utils_Tuple2('Lacute', 'Ĺ'),
			_Utils_Tuple2('lacute', 'ĺ'),
			_Utils_Tuple2('laemptyv', '⦴'),
			_Utils_Tuple2('lagran', 'ℒ'),
			_Utils_Tuple2('Lambda', 'Λ'),
			_Utils_Tuple2('lambda', 'λ'),
			_Utils_Tuple2('lang', '⟨'),
			_Utils_Tuple2('Lang', '⟪'),
			_Utils_Tuple2('langd', '⦑'),
			_Utils_Tuple2('langle', '⟨'),
			_Utils_Tuple2('lap', '⪅'),
			_Utils_Tuple2('Laplacetrf', 'ℒ'),
			_Utils_Tuple2('laquo', '«'),
			_Utils_Tuple2('larrb', '⇤'),
			_Utils_Tuple2('larrbfs', '⤟'),
			_Utils_Tuple2('larr', '←'),
			_Utils_Tuple2('Larr', '↞'),
			_Utils_Tuple2('lArr', '⇐'),
			_Utils_Tuple2('larrfs', '⤝'),
			_Utils_Tuple2('larrhk', '↩'),
			_Utils_Tuple2('larrlp', '↫'),
			_Utils_Tuple2('larrpl', '⤹'),
			_Utils_Tuple2('larrsim', '⥳'),
			_Utils_Tuple2('larrtl', '↢'),
			_Utils_Tuple2('latail', '⤙'),
			_Utils_Tuple2('lAtail', '⤛'),
			_Utils_Tuple2('lat', '⪫'),
			_Utils_Tuple2('late', '⪭'),
			_Utils_Tuple2('lates', '⪭︀'),
			_Utils_Tuple2('lbarr', '⤌'),
			_Utils_Tuple2('lBarr', '⤎'),
			_Utils_Tuple2('lbbrk', '❲'),
			_Utils_Tuple2('lbrace', '{'),
			_Utils_Tuple2('lbrack', '['),
			_Utils_Tuple2('lbrke', '⦋'),
			_Utils_Tuple2('lbrksld', '⦏'),
			_Utils_Tuple2('lbrkslu', '⦍'),
			_Utils_Tuple2('Lcaron', 'Ľ'),
			_Utils_Tuple2('lcaron', 'ľ'),
			_Utils_Tuple2('Lcedil', 'Ļ'),
			_Utils_Tuple2('lcedil', 'ļ'),
			_Utils_Tuple2('lceil', '⌈'),
			_Utils_Tuple2('lcub', '{'),
			_Utils_Tuple2('Lcy', 'Л'),
			_Utils_Tuple2('lcy', 'л'),
			_Utils_Tuple2('ldca', '⤶'),
			_Utils_Tuple2('ldquo', '“'),
			_Utils_Tuple2('ldquor', '„'),
			_Utils_Tuple2('ldrdhar', '⥧'),
			_Utils_Tuple2('ldrushar', '⥋'),
			_Utils_Tuple2('ldsh', '↲'),
			_Utils_Tuple2('le', '≤'),
			_Utils_Tuple2('lE', '≦'),
			_Utils_Tuple2('LeftAngleBracket', '⟨'),
			_Utils_Tuple2('LeftArrowBar', '⇤'),
			_Utils_Tuple2('leftarrow', '←'),
			_Utils_Tuple2('LeftArrow', '←'),
			_Utils_Tuple2('Leftarrow', '⇐'),
			_Utils_Tuple2('LeftArrowRightArrow', '⇆'),
			_Utils_Tuple2('leftarrowtail', '↢'),
			_Utils_Tuple2('LeftCeiling', '⌈'),
			_Utils_Tuple2('LeftDoubleBracket', '⟦'),
			_Utils_Tuple2('LeftDownTeeVector', '⥡'),
			_Utils_Tuple2('LeftDownVectorBar', '⥙'),
			_Utils_Tuple2('LeftDownVector', '⇃'),
			_Utils_Tuple2('LeftFloor', '⌊'),
			_Utils_Tuple2('leftharpoondown', '↽'),
			_Utils_Tuple2('leftharpoonup', '↼'),
			_Utils_Tuple2('leftleftarrows', '⇇'),
			_Utils_Tuple2('leftrightarrow', '↔'),
			_Utils_Tuple2('LeftRightArrow', '↔'),
			_Utils_Tuple2('Leftrightarrow', '⇔'),
			_Utils_Tuple2('leftrightarrows', '⇆'),
			_Utils_Tuple2('leftrightharpoons', '⇋'),
			_Utils_Tuple2('leftrightsquigarrow', '↭'),
			_Utils_Tuple2('LeftRightVector', '⥎'),
			_Utils_Tuple2('LeftTeeArrow', '↤'),
			_Utils_Tuple2('LeftTee', '⊣'),
			_Utils_Tuple2('LeftTeeVector', '⥚'),
			_Utils_Tuple2('leftthreetimes', '⋋'),
			_Utils_Tuple2('LeftTriangleBar', '⧏'),
			_Utils_Tuple2('LeftTriangle', '⊲'),
			_Utils_Tuple2('LeftTriangleEqual', '⊴'),
			_Utils_Tuple2('LeftUpDownVector', '⥑'),
			_Utils_Tuple2('LeftUpTeeVector', '⥠'),
			_Utils_Tuple2('LeftUpVectorBar', '⥘'),
			_Utils_Tuple2('LeftUpVector', '↿'),
			_Utils_Tuple2('LeftVectorBar', '⥒'),
			_Utils_Tuple2('LeftVector', '↼'),
			_Utils_Tuple2('lEg', '⪋'),
			_Utils_Tuple2('leg', '⋚'),
			_Utils_Tuple2('leq', '≤'),
			_Utils_Tuple2('leqq', '≦'),
			_Utils_Tuple2('leqslant', '⩽'),
			_Utils_Tuple2('lescc', '⪨'),
			_Utils_Tuple2('les', '⩽'),
			_Utils_Tuple2('lesdot', '⩿'),
			_Utils_Tuple2('lesdoto', '⪁'),
			_Utils_Tuple2('lesdotor', '⪃'),
			_Utils_Tuple2('lesg', '⋚︀'),
			_Utils_Tuple2('lesges', '⪓'),
			_Utils_Tuple2('lessapprox', '⪅'),
			_Utils_Tuple2('lessdot', '⋖'),
			_Utils_Tuple2('lesseqgtr', '⋚'),
			_Utils_Tuple2('lesseqqgtr', '⪋'),
			_Utils_Tuple2('LessEqualGreater', '⋚'),
			_Utils_Tuple2('LessFullEqual', '≦'),
			_Utils_Tuple2('LessGreater', '≶'),
			_Utils_Tuple2('lessgtr', '≶'),
			_Utils_Tuple2('LessLess', '⪡'),
			_Utils_Tuple2('lesssim', '≲'),
			_Utils_Tuple2('LessSlantEqual', '⩽'),
			_Utils_Tuple2('LessTilde', '≲'),
			_Utils_Tuple2('lfisht', '⥼'),
			_Utils_Tuple2('lfloor', '⌊'),
			_Utils_Tuple2('Lfr', '\uD835\uDD0F'),
			_Utils_Tuple2('lfr', '\uD835\uDD29'),
			_Utils_Tuple2('lg', '≶'),
			_Utils_Tuple2('lgE', '⪑'),
			_Utils_Tuple2('lHar', '⥢'),
			_Utils_Tuple2('lhard', '↽'),
			_Utils_Tuple2('lharu', '↼'),
			_Utils_Tuple2('lharul', '⥪'),
			_Utils_Tuple2('lhblk', '▄'),
			_Utils_Tuple2('LJcy', 'Љ'),
			_Utils_Tuple2('ljcy', 'љ'),
			_Utils_Tuple2('llarr', '⇇'),
			_Utils_Tuple2('ll', '≪'),
			_Utils_Tuple2('Ll', '⋘'),
			_Utils_Tuple2('llcorner', '⌞'),
			_Utils_Tuple2('Lleftarrow', '⇚'),
			_Utils_Tuple2('llhard', '⥫'),
			_Utils_Tuple2('lltri', '◺'),
			_Utils_Tuple2('Lmidot', 'Ŀ'),
			_Utils_Tuple2('lmidot', 'ŀ'),
			_Utils_Tuple2('lmoustache', '⎰'),
			_Utils_Tuple2('lmoust', '⎰'),
			_Utils_Tuple2('lnap', '⪉'),
			_Utils_Tuple2('lnapprox', '⪉'),
			_Utils_Tuple2('lne', '⪇'),
			_Utils_Tuple2('lnE', '≨'),
			_Utils_Tuple2('lneq', '⪇'),
			_Utils_Tuple2('lneqq', '≨'),
			_Utils_Tuple2('lnsim', '⋦'),
			_Utils_Tuple2('loang', '⟬'),
			_Utils_Tuple2('loarr', '⇽'),
			_Utils_Tuple2('lobrk', '⟦'),
			_Utils_Tuple2('longleftarrow', '⟵'),
			_Utils_Tuple2('LongLeftArrow', '⟵'),
			_Utils_Tuple2('Longleftarrow', '⟸'),
			_Utils_Tuple2('longleftrightarrow', '⟷'),
			_Utils_Tuple2('LongLeftRightArrow', '⟷'),
			_Utils_Tuple2('Longleftrightarrow', '⟺'),
			_Utils_Tuple2('longmapsto', '⟼'),
			_Utils_Tuple2('longrightarrow', '⟶'),
			_Utils_Tuple2('LongRightArrow', '⟶'),
			_Utils_Tuple2('Longrightarrow', '⟹'),
			_Utils_Tuple2('looparrowleft', '↫'),
			_Utils_Tuple2('looparrowright', '↬'),
			_Utils_Tuple2('lopar', '⦅'),
			_Utils_Tuple2('Lopf', '\uD835\uDD43'),
			_Utils_Tuple2('lopf', '\uD835\uDD5D'),
			_Utils_Tuple2('loplus', '⨭'),
			_Utils_Tuple2('lotimes', '⨴'),
			_Utils_Tuple2('lowast', '∗'),
			_Utils_Tuple2('lowbar', '_'),
			_Utils_Tuple2('LowerLeftArrow', '↙'),
			_Utils_Tuple2('LowerRightArrow', '↘'),
			_Utils_Tuple2('loz', '◊'),
			_Utils_Tuple2('lozenge', '◊'),
			_Utils_Tuple2('lozf', '⧫'),
			_Utils_Tuple2('lpar', '('),
			_Utils_Tuple2('lparlt', '⦓'),
			_Utils_Tuple2('lrarr', '⇆'),
			_Utils_Tuple2('lrcorner', '⌟'),
			_Utils_Tuple2('lrhar', '⇋'),
			_Utils_Tuple2('lrhard', '⥭'),
			_Utils_Tuple2('lrm', '\u200E'),
			_Utils_Tuple2('lrtri', '⊿'),
			_Utils_Tuple2('lsaquo', '‹'),
			_Utils_Tuple2('lscr', '\uD835\uDCC1'),
			_Utils_Tuple2('Lscr', 'ℒ'),
			_Utils_Tuple2('lsh', '↰'),
			_Utils_Tuple2('Lsh', '↰'),
			_Utils_Tuple2('lsim', '≲'),
			_Utils_Tuple2('lsime', '⪍'),
			_Utils_Tuple2('lsimg', '⪏'),
			_Utils_Tuple2('lsqb', '['),
			_Utils_Tuple2('lsquo', '‘'),
			_Utils_Tuple2('lsquor', '‚'),
			_Utils_Tuple2('Lstrok', 'Ł'),
			_Utils_Tuple2('lstrok', 'ł'),
			_Utils_Tuple2('ltcc', '⪦'),
			_Utils_Tuple2('ltcir', '⩹'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('LT', '<'),
			_Utils_Tuple2('Lt', '≪'),
			_Utils_Tuple2('ltdot', '⋖'),
			_Utils_Tuple2('lthree', '⋋'),
			_Utils_Tuple2('ltimes', '⋉'),
			_Utils_Tuple2('ltlarr', '⥶'),
			_Utils_Tuple2('ltquest', '⩻'),
			_Utils_Tuple2('ltri', '◃'),
			_Utils_Tuple2('ltrie', '⊴'),
			_Utils_Tuple2('ltrif', '◂'),
			_Utils_Tuple2('ltrPar', '⦖'),
			_Utils_Tuple2('lurdshar', '⥊'),
			_Utils_Tuple2('luruhar', '⥦'),
			_Utils_Tuple2('lvertneqq', '≨︀'),
			_Utils_Tuple2('lvnE', '≨︀'),
			_Utils_Tuple2('macr', '¯'),
			_Utils_Tuple2('male', '♂'),
			_Utils_Tuple2('malt', '✠'),
			_Utils_Tuple2('maltese', '✠'),
			_Utils_Tuple2('Map', '⤅'),
			_Utils_Tuple2('map', '↦'),
			_Utils_Tuple2('mapsto', '↦'),
			_Utils_Tuple2('mapstodown', '↧'),
			_Utils_Tuple2('mapstoleft', '↤'),
			_Utils_Tuple2('mapstoup', '↥'),
			_Utils_Tuple2('marker', '▮'),
			_Utils_Tuple2('mcomma', '⨩'),
			_Utils_Tuple2('Mcy', 'М'),
			_Utils_Tuple2('mcy', 'м'),
			_Utils_Tuple2('mdash', '—'),
			_Utils_Tuple2('mDDot', '∺'),
			_Utils_Tuple2('measuredangle', '∡'),
			_Utils_Tuple2('MediumSpace', '\u205F'),
			_Utils_Tuple2('Mellintrf', 'ℳ'),
			_Utils_Tuple2('Mfr', '\uD835\uDD10'),
			_Utils_Tuple2('mfr', '\uD835\uDD2A'),
			_Utils_Tuple2('mho', '℧'),
			_Utils_Tuple2('micro', 'µ'),
			_Utils_Tuple2('midast', '*'),
			_Utils_Tuple2('midcir', '⫰'),
			_Utils_Tuple2('mid', '∣'),
			_Utils_Tuple2('middot', '·'),
			_Utils_Tuple2('minusb', '⊟'),
			_Utils_Tuple2('minus', '−'),
			_Utils_Tuple2('minusd', '∸'),
			_Utils_Tuple2('minusdu', '⨪'),
			_Utils_Tuple2('MinusPlus', '∓'),
			_Utils_Tuple2('mlcp', '⫛'),
			_Utils_Tuple2('mldr', '…'),
			_Utils_Tuple2('mnplus', '∓'),
			_Utils_Tuple2('models', '⊧'),
			_Utils_Tuple2('Mopf', '\uD835\uDD44'),
			_Utils_Tuple2('mopf', '\uD835\uDD5E'),
			_Utils_Tuple2('mp', '∓'),
			_Utils_Tuple2('mscr', '\uD835\uDCC2'),
			_Utils_Tuple2('Mscr', 'ℳ'),
			_Utils_Tuple2('mstpos', '∾'),
			_Utils_Tuple2('Mu', 'Μ'),
			_Utils_Tuple2('mu', 'μ'),
			_Utils_Tuple2('multimap', '⊸'),
			_Utils_Tuple2('mumap', '⊸'),
			_Utils_Tuple2('nabla', '∇'),
			_Utils_Tuple2('Nacute', 'Ń'),
			_Utils_Tuple2('nacute', 'ń'),
			_Utils_Tuple2('nang', '∠⃒'),
			_Utils_Tuple2('nap', '≉'),
			_Utils_Tuple2('napE', '⩰̸'),
			_Utils_Tuple2('napid', '≋̸'),
			_Utils_Tuple2('napos', 'ŉ'),
			_Utils_Tuple2('napprox', '≉'),
			_Utils_Tuple2('natural', '♮'),
			_Utils_Tuple2('naturals', 'ℕ'),
			_Utils_Tuple2('natur', '♮'),
			_Utils_Tuple2('nbsp', '\u00A0'),
			_Utils_Tuple2('nbump', '≎̸'),
			_Utils_Tuple2('nbumpe', '≏̸'),
			_Utils_Tuple2('ncap', '⩃'),
			_Utils_Tuple2('Ncaron', 'Ň'),
			_Utils_Tuple2('ncaron', 'ň'),
			_Utils_Tuple2('Ncedil', 'Ņ'),
			_Utils_Tuple2('ncedil', 'ņ'),
			_Utils_Tuple2('ncong', '≇'),
			_Utils_Tuple2('ncongdot', '⩭̸'),
			_Utils_Tuple2('ncup', '⩂'),
			_Utils_Tuple2('Ncy', 'Н'),
			_Utils_Tuple2('ncy', 'н'),
			_Utils_Tuple2('ndash', '–'),
			_Utils_Tuple2('nearhk', '⤤'),
			_Utils_Tuple2('nearr', '↗'),
			_Utils_Tuple2('neArr', '⇗'),
			_Utils_Tuple2('nearrow', '↗'),
			_Utils_Tuple2('ne', '≠'),
			_Utils_Tuple2('nedot', '≐̸'),
			_Utils_Tuple2('NegativeMediumSpace', '\u200B'),
			_Utils_Tuple2('NegativeThickSpace', '\u200B'),
			_Utils_Tuple2('NegativeThinSpace', '\u200B'),
			_Utils_Tuple2('NegativeVeryThinSpace', '\u200B'),
			_Utils_Tuple2('nequiv', '≢'),
			_Utils_Tuple2('nesear', '⤨'),
			_Utils_Tuple2('nesim', '≂̸'),
			_Utils_Tuple2('NestedGreaterGreater', '≫'),
			_Utils_Tuple2('NestedLessLess', '≪'),
			_Utils_Tuple2('NewLine', '\n'),
			_Utils_Tuple2('nexist', '∄'),
			_Utils_Tuple2('nexists', '∄'),
			_Utils_Tuple2('Nfr', '\uD835\uDD11'),
			_Utils_Tuple2('nfr', '\uD835\uDD2B'),
			_Utils_Tuple2('ngE', '≧̸'),
			_Utils_Tuple2('nge', '≱'),
			_Utils_Tuple2('ngeq', '≱'),
			_Utils_Tuple2('ngeqq', '≧̸'),
			_Utils_Tuple2('ngeqslant', '⩾̸'),
			_Utils_Tuple2('nges', '⩾̸'),
			_Utils_Tuple2('nGg', '⋙̸'),
			_Utils_Tuple2('ngsim', '≵'),
			_Utils_Tuple2('nGt', '≫⃒'),
			_Utils_Tuple2('ngt', '≯'),
			_Utils_Tuple2('ngtr', '≯'),
			_Utils_Tuple2('nGtv', '≫̸'),
			_Utils_Tuple2('nharr', '↮'),
			_Utils_Tuple2('nhArr', '⇎'),
			_Utils_Tuple2('nhpar', '⫲'),
			_Utils_Tuple2('ni', '∋'),
			_Utils_Tuple2('nis', '⋼'),
			_Utils_Tuple2('nisd', '⋺'),
			_Utils_Tuple2('niv', '∋'),
			_Utils_Tuple2('NJcy', 'Њ'),
			_Utils_Tuple2('njcy', 'њ'),
			_Utils_Tuple2('nlarr', '↚'),
			_Utils_Tuple2('nlArr', '⇍'),
			_Utils_Tuple2('nldr', '‥'),
			_Utils_Tuple2('nlE', '≦̸'),
			_Utils_Tuple2('nle', '≰'),
			_Utils_Tuple2('nleftarrow', '↚'),
			_Utils_Tuple2('nLeftarrow', '⇍'),
			_Utils_Tuple2('nleftrightarrow', '↮'),
			_Utils_Tuple2('nLeftrightarrow', '⇎'),
			_Utils_Tuple2('nleq', '≰'),
			_Utils_Tuple2('nleqq', '≦̸'),
			_Utils_Tuple2('nleqslant', '⩽̸'),
			_Utils_Tuple2('nles', '⩽̸'),
			_Utils_Tuple2('nless', '≮'),
			_Utils_Tuple2('nLl', '⋘̸'),
			_Utils_Tuple2('nlsim', '≴'),
			_Utils_Tuple2('nLt', '≪⃒'),
			_Utils_Tuple2('nlt', '≮'),
			_Utils_Tuple2('nltri', '⋪'),
			_Utils_Tuple2('nltrie', '⋬'),
			_Utils_Tuple2('nLtv', '≪̸'),
			_Utils_Tuple2('nmid', '∤'),
			_Utils_Tuple2('NoBreak', '\u2060'),
			_Utils_Tuple2('NonBreakingSpace', '\u00A0'),
			_Utils_Tuple2('nopf', '\uD835\uDD5F'),
			_Utils_Tuple2('Nopf', 'ℕ'),
			_Utils_Tuple2('Not', '⫬'),
			_Utils_Tuple2('not', '¬'),
			_Utils_Tuple2('NotCongruent', '≢'),
			_Utils_Tuple2('NotCupCap', '≭'),
			_Utils_Tuple2('NotDoubleVerticalBar', '∦'),
			_Utils_Tuple2('NotElement', '∉'),
			_Utils_Tuple2('NotEqual', '≠'),
			_Utils_Tuple2('NotEqualTilde', '≂̸'),
			_Utils_Tuple2('NotExists', '∄'),
			_Utils_Tuple2('NotGreater', '≯'),
			_Utils_Tuple2('NotGreaterEqual', '≱'),
			_Utils_Tuple2('NotGreaterFullEqual', '≧̸'),
			_Utils_Tuple2('NotGreaterGreater', '≫̸'),
			_Utils_Tuple2('NotGreaterLess', '≹'),
			_Utils_Tuple2('NotGreaterSlantEqual', '⩾̸'),
			_Utils_Tuple2('NotGreaterTilde', '≵'),
			_Utils_Tuple2('NotHumpDownHump', '≎̸'),
			_Utils_Tuple2('NotHumpEqual', '≏̸'),
			_Utils_Tuple2('notin', '∉'),
			_Utils_Tuple2('notindot', '⋵̸'),
			_Utils_Tuple2('notinE', '⋹̸'),
			_Utils_Tuple2('notinva', '∉'),
			_Utils_Tuple2('notinvb', '⋷'),
			_Utils_Tuple2('notinvc', '⋶'),
			_Utils_Tuple2('NotLeftTriangleBar', '⧏̸'),
			_Utils_Tuple2('NotLeftTriangle', '⋪'),
			_Utils_Tuple2('NotLeftTriangleEqual', '⋬'),
			_Utils_Tuple2('NotLess', '≮'),
			_Utils_Tuple2('NotLessEqual', '≰'),
			_Utils_Tuple2('NotLessGreater', '≸'),
			_Utils_Tuple2('NotLessLess', '≪̸'),
			_Utils_Tuple2('NotLessSlantEqual', '⩽̸'),
			_Utils_Tuple2('NotLessTilde', '≴'),
			_Utils_Tuple2('NotNestedGreaterGreater', '⪢̸'),
			_Utils_Tuple2('NotNestedLessLess', '⪡̸'),
			_Utils_Tuple2('notni', '∌'),
			_Utils_Tuple2('notniva', '∌'),
			_Utils_Tuple2('notnivb', '⋾'),
			_Utils_Tuple2('notnivc', '⋽'),
			_Utils_Tuple2('NotPrecedes', '⊀'),
			_Utils_Tuple2('NotPrecedesEqual', '⪯̸'),
			_Utils_Tuple2('NotPrecedesSlantEqual', '⋠'),
			_Utils_Tuple2('NotReverseElement', '∌'),
			_Utils_Tuple2('NotRightTriangleBar', '⧐̸'),
			_Utils_Tuple2('NotRightTriangle', '⋫'),
			_Utils_Tuple2('NotRightTriangleEqual', '⋭'),
			_Utils_Tuple2('NotSquareSubset', '⊏̸'),
			_Utils_Tuple2('NotSquareSubsetEqual', '⋢'),
			_Utils_Tuple2('NotSquareSuperset', '⊐̸'),
			_Utils_Tuple2('NotSquareSupersetEqual', '⋣'),
			_Utils_Tuple2('NotSubset', '⊂⃒'),
			_Utils_Tuple2('NotSubsetEqual', '⊈'),
			_Utils_Tuple2('NotSucceeds', '⊁'),
			_Utils_Tuple2('NotSucceedsEqual', '⪰̸'),
			_Utils_Tuple2('NotSucceedsSlantEqual', '⋡'),
			_Utils_Tuple2('NotSucceedsTilde', '≿̸'),
			_Utils_Tuple2('NotSuperset', '⊃⃒'),
			_Utils_Tuple2('NotSupersetEqual', '⊉'),
			_Utils_Tuple2('NotTilde', '≁'),
			_Utils_Tuple2('NotTildeEqual', '≄'),
			_Utils_Tuple2('NotTildeFullEqual', '≇'),
			_Utils_Tuple2('NotTildeTilde', '≉'),
			_Utils_Tuple2('NotVerticalBar', '∤'),
			_Utils_Tuple2('nparallel', '∦'),
			_Utils_Tuple2('npar', '∦'),
			_Utils_Tuple2('nparsl', '⫽⃥'),
			_Utils_Tuple2('npart', '∂̸'),
			_Utils_Tuple2('npolint', '⨔'),
			_Utils_Tuple2('npr', '⊀'),
			_Utils_Tuple2('nprcue', '⋠'),
			_Utils_Tuple2('nprec', '⊀'),
			_Utils_Tuple2('npreceq', '⪯̸'),
			_Utils_Tuple2('npre', '⪯̸'),
			_Utils_Tuple2('nrarrc', '⤳̸'),
			_Utils_Tuple2('nrarr', '↛'),
			_Utils_Tuple2('nrArr', '⇏'),
			_Utils_Tuple2('nrarrw', '↝̸'),
			_Utils_Tuple2('nrightarrow', '↛'),
			_Utils_Tuple2('nRightarrow', '⇏'),
			_Utils_Tuple2('nrtri', '⋫'),
			_Utils_Tuple2('nrtrie', '⋭'),
			_Utils_Tuple2('nsc', '⊁'),
			_Utils_Tuple2('nsccue', '⋡'),
			_Utils_Tuple2('nsce', '⪰̸'),
			_Utils_Tuple2('Nscr', '\uD835\uDCA9'),
			_Utils_Tuple2('nscr', '\uD835\uDCC3'),
			_Utils_Tuple2('nshortmid', '∤'),
			_Utils_Tuple2('nshortparallel', '∦'),
			_Utils_Tuple2('nsim', '≁'),
			_Utils_Tuple2('nsime', '≄'),
			_Utils_Tuple2('nsimeq', '≄'),
			_Utils_Tuple2('nsmid', '∤'),
			_Utils_Tuple2('nspar', '∦'),
			_Utils_Tuple2('nsqsube', '⋢'),
			_Utils_Tuple2('nsqsupe', '⋣'),
			_Utils_Tuple2('nsub', '⊄'),
			_Utils_Tuple2('nsubE', '⫅̸'),
			_Utils_Tuple2('nsube', '⊈'),
			_Utils_Tuple2('nsubset', '⊂⃒'),
			_Utils_Tuple2('nsubseteq', '⊈'),
			_Utils_Tuple2('nsubseteqq', '⫅̸'),
			_Utils_Tuple2('nsucc', '⊁'),
			_Utils_Tuple2('nsucceq', '⪰̸'),
			_Utils_Tuple2('nsup', '⊅'),
			_Utils_Tuple2('nsupE', '⫆̸'),
			_Utils_Tuple2('nsupe', '⊉'),
			_Utils_Tuple2('nsupset', '⊃⃒'),
			_Utils_Tuple2('nsupseteq', '⊉'),
			_Utils_Tuple2('nsupseteqq', '⫆̸'),
			_Utils_Tuple2('ntgl', '≹'),
			_Utils_Tuple2('Ntilde', 'Ñ'),
			_Utils_Tuple2('ntilde', 'ñ'),
			_Utils_Tuple2('ntlg', '≸'),
			_Utils_Tuple2('ntriangleleft', '⋪'),
			_Utils_Tuple2('ntrianglelefteq', '⋬'),
			_Utils_Tuple2('ntriangleright', '⋫'),
			_Utils_Tuple2('ntrianglerighteq', '⋭'),
			_Utils_Tuple2('Nu', 'Ν'),
			_Utils_Tuple2('nu', 'ν'),
			_Utils_Tuple2('num', '#'),
			_Utils_Tuple2('numero', '№'),
			_Utils_Tuple2('numsp', '\u2007'),
			_Utils_Tuple2('nvap', '≍⃒'),
			_Utils_Tuple2('nvdash', '⊬'),
			_Utils_Tuple2('nvDash', '⊭'),
			_Utils_Tuple2('nVdash', '⊮'),
			_Utils_Tuple2('nVDash', '⊯'),
			_Utils_Tuple2('nvge', '≥⃒'),
			_Utils_Tuple2('nvgt', '>⃒'),
			_Utils_Tuple2('nvHarr', '⤄'),
			_Utils_Tuple2('nvinfin', '⧞'),
			_Utils_Tuple2('nvlArr', '⤂'),
			_Utils_Tuple2('nvle', '≤⃒'),
			_Utils_Tuple2('nvlt', '<⃒'),
			_Utils_Tuple2('nvltrie', '⊴⃒'),
			_Utils_Tuple2('nvrArr', '⤃'),
			_Utils_Tuple2('nvrtrie', '⊵⃒'),
			_Utils_Tuple2('nvsim', '∼⃒'),
			_Utils_Tuple2('nwarhk', '⤣'),
			_Utils_Tuple2('nwarr', '↖'),
			_Utils_Tuple2('nwArr', '⇖'),
			_Utils_Tuple2('nwarrow', '↖'),
			_Utils_Tuple2('nwnear', '⤧'),
			_Utils_Tuple2('Oacute', 'Ó'),
			_Utils_Tuple2('oacute', 'ó'),
			_Utils_Tuple2('oast', '⊛'),
			_Utils_Tuple2('Ocirc', 'Ô'),
			_Utils_Tuple2('ocirc', 'ô'),
			_Utils_Tuple2('ocir', '⊚'),
			_Utils_Tuple2('Ocy', 'О'),
			_Utils_Tuple2('ocy', 'о'),
			_Utils_Tuple2('odash', '⊝'),
			_Utils_Tuple2('Odblac', 'Ő'),
			_Utils_Tuple2('odblac', 'ő'),
			_Utils_Tuple2('odiv', '⨸'),
			_Utils_Tuple2('odot', '⊙'),
			_Utils_Tuple2('odsold', '⦼'),
			_Utils_Tuple2('OElig', 'Œ'),
			_Utils_Tuple2('oelig', 'œ'),
			_Utils_Tuple2('ofcir', '⦿'),
			_Utils_Tuple2('Ofr', '\uD835\uDD12'),
			_Utils_Tuple2('ofr', '\uD835\uDD2C'),
			_Utils_Tuple2('ogon', '˛'),
			_Utils_Tuple2('Ograve', 'Ò'),
			_Utils_Tuple2('ograve', 'ò'),
			_Utils_Tuple2('ogt', '⧁'),
			_Utils_Tuple2('ohbar', '⦵'),
			_Utils_Tuple2('ohm', 'Ω'),
			_Utils_Tuple2('oint', '∮'),
			_Utils_Tuple2('olarr', '↺'),
			_Utils_Tuple2('olcir', '⦾'),
			_Utils_Tuple2('olcross', '⦻'),
			_Utils_Tuple2('oline', '‾'),
			_Utils_Tuple2('olt', '⧀'),
			_Utils_Tuple2('Omacr', 'Ō'),
			_Utils_Tuple2('omacr', 'ō'),
			_Utils_Tuple2('Omega', 'Ω'),
			_Utils_Tuple2('omega', 'ω'),
			_Utils_Tuple2('Omicron', 'Ο'),
			_Utils_Tuple2('omicron', 'ο'),
			_Utils_Tuple2('omid', '⦶'),
			_Utils_Tuple2('ominus', '⊖'),
			_Utils_Tuple2('Oopf', '\uD835\uDD46'),
			_Utils_Tuple2('oopf', '\uD835\uDD60'),
			_Utils_Tuple2('opar', '⦷'),
			_Utils_Tuple2('OpenCurlyDoubleQuote', '“'),
			_Utils_Tuple2('OpenCurlyQuote', '‘'),
			_Utils_Tuple2('operp', '⦹'),
			_Utils_Tuple2('oplus', '⊕'),
			_Utils_Tuple2('orarr', '↻'),
			_Utils_Tuple2('Or', '⩔'),
			_Utils_Tuple2('or', '∨'),
			_Utils_Tuple2('ord', '⩝'),
			_Utils_Tuple2('order', 'ℴ'),
			_Utils_Tuple2('orderof', 'ℴ'),
			_Utils_Tuple2('ordf', 'ª'),
			_Utils_Tuple2('ordm', 'º'),
			_Utils_Tuple2('origof', '⊶'),
			_Utils_Tuple2('oror', '⩖'),
			_Utils_Tuple2('orslope', '⩗'),
			_Utils_Tuple2('orv', '⩛'),
			_Utils_Tuple2('oS', 'Ⓢ'),
			_Utils_Tuple2('Oscr', '\uD835\uDCAA'),
			_Utils_Tuple2('oscr', 'ℴ'),
			_Utils_Tuple2('Oslash', 'Ø'),
			_Utils_Tuple2('oslash', 'ø'),
			_Utils_Tuple2('osol', '⊘'),
			_Utils_Tuple2('Otilde', 'Õ'),
			_Utils_Tuple2('otilde', 'õ'),
			_Utils_Tuple2('otimesas', '⨶'),
			_Utils_Tuple2('Otimes', '⨷'),
			_Utils_Tuple2('otimes', '⊗'),
			_Utils_Tuple2('Ouml', 'Ö'),
			_Utils_Tuple2('ouml', 'ö'),
			_Utils_Tuple2('ovbar', '⌽'),
			_Utils_Tuple2('OverBar', '‾'),
			_Utils_Tuple2('OverBrace', '⏞'),
			_Utils_Tuple2('OverBracket', '⎴'),
			_Utils_Tuple2('OverParenthesis', '⏜'),
			_Utils_Tuple2('para', '¶'),
			_Utils_Tuple2('parallel', '∥'),
			_Utils_Tuple2('par', '∥'),
			_Utils_Tuple2('parsim', '⫳'),
			_Utils_Tuple2('parsl', '⫽'),
			_Utils_Tuple2('part', '∂'),
			_Utils_Tuple2('PartialD', '∂'),
			_Utils_Tuple2('Pcy', 'П'),
			_Utils_Tuple2('pcy', 'п'),
			_Utils_Tuple2('percnt', '%'),
			_Utils_Tuple2('period', '.'),
			_Utils_Tuple2('permil', '‰'),
			_Utils_Tuple2('perp', '⊥'),
			_Utils_Tuple2('pertenk', '‱'),
			_Utils_Tuple2('Pfr', '\uD835\uDD13'),
			_Utils_Tuple2('pfr', '\uD835\uDD2D'),
			_Utils_Tuple2('Phi', 'Φ'),
			_Utils_Tuple2('phi', 'φ'),
			_Utils_Tuple2('phiv', 'ϕ'),
			_Utils_Tuple2('phmmat', 'ℳ'),
			_Utils_Tuple2('phone', '☎'),
			_Utils_Tuple2('Pi', 'Π'),
			_Utils_Tuple2('pi', 'π'),
			_Utils_Tuple2('pitchfork', '⋔'),
			_Utils_Tuple2('piv', 'ϖ'),
			_Utils_Tuple2('planck', 'ℏ'),
			_Utils_Tuple2('planckh', 'ℎ'),
			_Utils_Tuple2('plankv', 'ℏ'),
			_Utils_Tuple2('plusacir', '⨣'),
			_Utils_Tuple2('plusb', '⊞'),
			_Utils_Tuple2('pluscir', '⨢'),
			_Utils_Tuple2('plus', '+'),
			_Utils_Tuple2('plusdo', '∔'),
			_Utils_Tuple2('plusdu', '⨥'),
			_Utils_Tuple2('pluse', '⩲'),
			_Utils_Tuple2('PlusMinus', '±'),
			_Utils_Tuple2('plusmn', '±'),
			_Utils_Tuple2('plussim', '⨦'),
			_Utils_Tuple2('plustwo', '⨧'),
			_Utils_Tuple2('pm', '±'),
			_Utils_Tuple2('Poincareplane', 'ℌ'),
			_Utils_Tuple2('pointint', '⨕'),
			_Utils_Tuple2('popf', '\uD835\uDD61'),
			_Utils_Tuple2('Popf', 'ℙ'),
			_Utils_Tuple2('pound', '£'),
			_Utils_Tuple2('prap', '⪷'),
			_Utils_Tuple2('Pr', '⪻'),
			_Utils_Tuple2('pr', '≺'),
			_Utils_Tuple2('prcue', '≼'),
			_Utils_Tuple2('precapprox', '⪷'),
			_Utils_Tuple2('prec', '≺'),
			_Utils_Tuple2('preccurlyeq', '≼'),
			_Utils_Tuple2('Precedes', '≺'),
			_Utils_Tuple2('PrecedesEqual', '⪯'),
			_Utils_Tuple2('PrecedesSlantEqual', '≼'),
			_Utils_Tuple2('PrecedesTilde', '≾'),
			_Utils_Tuple2('preceq', '⪯'),
			_Utils_Tuple2('precnapprox', '⪹'),
			_Utils_Tuple2('precneqq', '⪵'),
			_Utils_Tuple2('precnsim', '⋨'),
			_Utils_Tuple2('pre', '⪯'),
			_Utils_Tuple2('prE', '⪳'),
			_Utils_Tuple2('precsim', '≾'),
			_Utils_Tuple2('prime', '′'),
			_Utils_Tuple2('Prime', '″'),
			_Utils_Tuple2('primes', 'ℙ'),
			_Utils_Tuple2('prnap', '⪹'),
			_Utils_Tuple2('prnE', '⪵'),
			_Utils_Tuple2('prnsim', '⋨'),
			_Utils_Tuple2('prod', '∏'),
			_Utils_Tuple2('Product', '∏'),
			_Utils_Tuple2('profalar', '⌮'),
			_Utils_Tuple2('profline', '⌒'),
			_Utils_Tuple2('profsurf', '⌓'),
			_Utils_Tuple2('prop', '∝'),
			_Utils_Tuple2('Proportional', '∝'),
			_Utils_Tuple2('Proportion', '∷'),
			_Utils_Tuple2('propto', '∝'),
			_Utils_Tuple2('prsim', '≾'),
			_Utils_Tuple2('prurel', '⊰'),
			_Utils_Tuple2('Pscr', '\uD835\uDCAB'),
			_Utils_Tuple2('pscr', '\uD835\uDCC5'),
			_Utils_Tuple2('Psi', 'Ψ'),
			_Utils_Tuple2('psi', 'ψ'),
			_Utils_Tuple2('puncsp', '\u2008'),
			_Utils_Tuple2('Qfr', '\uD835\uDD14'),
			_Utils_Tuple2('qfr', '\uD835\uDD2E'),
			_Utils_Tuple2('qint', '⨌'),
			_Utils_Tuple2('qopf', '\uD835\uDD62'),
			_Utils_Tuple2('Qopf', 'ℚ'),
			_Utils_Tuple2('qprime', '⁗'),
			_Utils_Tuple2('Qscr', '\uD835\uDCAC'),
			_Utils_Tuple2('qscr', '\uD835\uDCC6'),
			_Utils_Tuple2('quaternions', 'ℍ'),
			_Utils_Tuple2('quatint', '⨖'),
			_Utils_Tuple2('quest', '?'),
			_Utils_Tuple2('questeq', '≟'),
			_Utils_Tuple2('quot', '\"'),
			_Utils_Tuple2('QUOT', '\"'),
			_Utils_Tuple2('rAarr', '⇛'),
			_Utils_Tuple2('race', '∽̱'),
			_Utils_Tuple2('Racute', 'Ŕ'),
			_Utils_Tuple2('racute', 'ŕ'),
			_Utils_Tuple2('radic', '√'),
			_Utils_Tuple2('raemptyv', '⦳'),
			_Utils_Tuple2('rang', '⟩'),
			_Utils_Tuple2('Rang', '⟫'),
			_Utils_Tuple2('rangd', '⦒'),
			_Utils_Tuple2('range', '⦥'),
			_Utils_Tuple2('rangle', '⟩'),
			_Utils_Tuple2('raquo', '»'),
			_Utils_Tuple2('rarrap', '⥵'),
			_Utils_Tuple2('rarrb', '⇥'),
			_Utils_Tuple2('rarrbfs', '⤠'),
			_Utils_Tuple2('rarrc', '⤳'),
			_Utils_Tuple2('rarr', '→'),
			_Utils_Tuple2('Rarr', '↠'),
			_Utils_Tuple2('rArr', '⇒'),
			_Utils_Tuple2('rarrfs', '⤞'),
			_Utils_Tuple2('rarrhk', '↪'),
			_Utils_Tuple2('rarrlp', '↬'),
			_Utils_Tuple2('rarrpl', '⥅'),
			_Utils_Tuple2('rarrsim', '⥴'),
			_Utils_Tuple2('Rarrtl', '⤖'),
			_Utils_Tuple2('rarrtl', '↣'),
			_Utils_Tuple2('rarrw', '↝'),
			_Utils_Tuple2('ratail', '⤚'),
			_Utils_Tuple2('rAtail', '⤜'),
			_Utils_Tuple2('ratio', '∶'),
			_Utils_Tuple2('rationals', 'ℚ'),
			_Utils_Tuple2('rbarr', '⤍'),
			_Utils_Tuple2('rBarr', '⤏'),
			_Utils_Tuple2('RBarr', '⤐'),
			_Utils_Tuple2('rbbrk', '❳'),
			_Utils_Tuple2('rbrace', '}'),
			_Utils_Tuple2('rbrack', ']'),
			_Utils_Tuple2('rbrke', '⦌'),
			_Utils_Tuple2('rbrksld', '⦎'),
			_Utils_Tuple2('rbrkslu', '⦐'),
			_Utils_Tuple2('Rcaron', 'Ř'),
			_Utils_Tuple2('rcaron', 'ř'),
			_Utils_Tuple2('Rcedil', 'Ŗ'),
			_Utils_Tuple2('rcedil', 'ŗ'),
			_Utils_Tuple2('rceil', '⌉'),
			_Utils_Tuple2('rcub', '}'),
			_Utils_Tuple2('Rcy', 'Р'),
			_Utils_Tuple2('rcy', 'р'),
			_Utils_Tuple2('rdca', '⤷'),
			_Utils_Tuple2('rdldhar', '⥩'),
			_Utils_Tuple2('rdquo', '”'),
			_Utils_Tuple2('rdquor', '”'),
			_Utils_Tuple2('rdsh', '↳'),
			_Utils_Tuple2('real', 'ℜ'),
			_Utils_Tuple2('realine', 'ℛ'),
			_Utils_Tuple2('realpart', 'ℜ'),
			_Utils_Tuple2('reals', 'ℝ'),
			_Utils_Tuple2('Re', 'ℜ'),
			_Utils_Tuple2('rect', '▭'),
			_Utils_Tuple2('reg', '®'),
			_Utils_Tuple2('REG', '®'),
			_Utils_Tuple2('ReverseElement', '∋'),
			_Utils_Tuple2('ReverseEquilibrium', '⇋'),
			_Utils_Tuple2('ReverseUpEquilibrium', '⥯'),
			_Utils_Tuple2('rfisht', '⥽'),
			_Utils_Tuple2('rfloor', '⌋'),
			_Utils_Tuple2('rfr', '\uD835\uDD2F'),
			_Utils_Tuple2('Rfr', 'ℜ'),
			_Utils_Tuple2('rHar', '⥤'),
			_Utils_Tuple2('rhard', '⇁'),
			_Utils_Tuple2('rharu', '⇀'),
			_Utils_Tuple2('rharul', '⥬'),
			_Utils_Tuple2('Rho', 'Ρ'),
			_Utils_Tuple2('rho', 'ρ'),
			_Utils_Tuple2('rhov', 'ϱ'),
			_Utils_Tuple2('RightAngleBracket', '⟩'),
			_Utils_Tuple2('RightArrowBar', '⇥'),
			_Utils_Tuple2('rightarrow', '→'),
			_Utils_Tuple2('RightArrow', '→'),
			_Utils_Tuple2('Rightarrow', '⇒'),
			_Utils_Tuple2('RightArrowLeftArrow', '⇄'),
			_Utils_Tuple2('rightarrowtail', '↣'),
			_Utils_Tuple2('RightCeiling', '⌉'),
			_Utils_Tuple2('RightDoubleBracket', '⟧'),
			_Utils_Tuple2('RightDownTeeVector', '⥝'),
			_Utils_Tuple2('RightDownVectorBar', '⥕'),
			_Utils_Tuple2('RightDownVector', '⇂'),
			_Utils_Tuple2('RightFloor', '⌋'),
			_Utils_Tuple2('rightharpoondown', '⇁'),
			_Utils_Tuple2('rightharpoonup', '⇀'),
			_Utils_Tuple2('rightleftarrows', '⇄'),
			_Utils_Tuple2('rightleftharpoons', '⇌'),
			_Utils_Tuple2('rightrightarrows', '⇉'),
			_Utils_Tuple2('rightsquigarrow', '↝'),
			_Utils_Tuple2('RightTeeArrow', '↦'),
			_Utils_Tuple2('RightTee', '⊢'),
			_Utils_Tuple2('RightTeeVector', '⥛'),
			_Utils_Tuple2('rightthreetimes', '⋌'),
			_Utils_Tuple2('RightTriangleBar', '⧐'),
			_Utils_Tuple2('RightTriangle', '⊳'),
			_Utils_Tuple2('RightTriangleEqual', '⊵'),
			_Utils_Tuple2('RightUpDownVector', '⥏'),
			_Utils_Tuple2('RightUpTeeVector', '⥜'),
			_Utils_Tuple2('RightUpVectorBar', '⥔'),
			_Utils_Tuple2('RightUpVector', '↾'),
			_Utils_Tuple2('RightVectorBar', '⥓'),
			_Utils_Tuple2('RightVector', '⇀'),
			_Utils_Tuple2('ring', '˚'),
			_Utils_Tuple2('risingdotseq', '≓'),
			_Utils_Tuple2('rlarr', '⇄'),
			_Utils_Tuple2('rlhar', '⇌'),
			_Utils_Tuple2('rlm', '\u200F'),
			_Utils_Tuple2('rmoustache', '⎱'),
			_Utils_Tuple2('rmoust', '⎱'),
			_Utils_Tuple2('rnmid', '⫮'),
			_Utils_Tuple2('roang', '⟭'),
			_Utils_Tuple2('roarr', '⇾'),
			_Utils_Tuple2('robrk', '⟧'),
			_Utils_Tuple2('ropar', '⦆'),
			_Utils_Tuple2('ropf', '\uD835\uDD63'),
			_Utils_Tuple2('Ropf', 'ℝ'),
			_Utils_Tuple2('roplus', '⨮'),
			_Utils_Tuple2('rotimes', '⨵'),
			_Utils_Tuple2('RoundImplies', '⥰'),
			_Utils_Tuple2('rpar', ')'),
			_Utils_Tuple2('rpargt', '⦔'),
			_Utils_Tuple2('rppolint', '⨒'),
			_Utils_Tuple2('rrarr', '⇉'),
			_Utils_Tuple2('Rrightarrow', '⇛'),
			_Utils_Tuple2('rsaquo', '›'),
			_Utils_Tuple2('rscr', '\uD835\uDCC7'),
			_Utils_Tuple2('Rscr', 'ℛ'),
			_Utils_Tuple2('rsh', '↱'),
			_Utils_Tuple2('Rsh', '↱'),
			_Utils_Tuple2('rsqb', ']'),
			_Utils_Tuple2('rsquo', '’'),
			_Utils_Tuple2('rsquor', '’'),
			_Utils_Tuple2('rthree', '⋌'),
			_Utils_Tuple2('rtimes', '⋊'),
			_Utils_Tuple2('rtri', '▹'),
			_Utils_Tuple2('rtrie', '⊵'),
			_Utils_Tuple2('rtrif', '▸'),
			_Utils_Tuple2('rtriltri', '⧎'),
			_Utils_Tuple2('RuleDelayed', '⧴'),
			_Utils_Tuple2('ruluhar', '⥨'),
			_Utils_Tuple2('rx', '℞'),
			_Utils_Tuple2('Sacute', 'Ś'),
			_Utils_Tuple2('sacute', 'ś'),
			_Utils_Tuple2('sbquo', '‚'),
			_Utils_Tuple2('scap', '⪸'),
			_Utils_Tuple2('Scaron', 'Š'),
			_Utils_Tuple2('scaron', 'š'),
			_Utils_Tuple2('Sc', '⪼'),
			_Utils_Tuple2('sc', '≻'),
			_Utils_Tuple2('sccue', '≽'),
			_Utils_Tuple2('sce', '⪰'),
			_Utils_Tuple2('scE', '⪴'),
			_Utils_Tuple2('Scedil', 'Ş'),
			_Utils_Tuple2('scedil', 'ş'),
			_Utils_Tuple2('Scirc', 'Ŝ'),
			_Utils_Tuple2('scirc', 'ŝ'),
			_Utils_Tuple2('scnap', '⪺'),
			_Utils_Tuple2('scnE', '⪶'),
			_Utils_Tuple2('scnsim', '⋩'),
			_Utils_Tuple2('scpolint', '⨓'),
			_Utils_Tuple2('scsim', '≿'),
			_Utils_Tuple2('Scy', 'С'),
			_Utils_Tuple2('scy', 'с'),
			_Utils_Tuple2('sdotb', '⊡'),
			_Utils_Tuple2('sdot', '⋅'),
			_Utils_Tuple2('sdote', '⩦'),
			_Utils_Tuple2('searhk', '⤥'),
			_Utils_Tuple2('searr', '↘'),
			_Utils_Tuple2('seArr', '⇘'),
			_Utils_Tuple2('searrow', '↘'),
			_Utils_Tuple2('sect', '§'),
			_Utils_Tuple2('semi', ';'),
			_Utils_Tuple2('seswar', '⤩'),
			_Utils_Tuple2('setminus', '∖'),
			_Utils_Tuple2('setmn', '∖'),
			_Utils_Tuple2('sext', '✶'),
			_Utils_Tuple2('Sfr', '\uD835\uDD16'),
			_Utils_Tuple2('sfr', '\uD835\uDD30'),
			_Utils_Tuple2('sfrown', '⌢'),
			_Utils_Tuple2('sharp', '♯'),
			_Utils_Tuple2('SHCHcy', 'Щ'),
			_Utils_Tuple2('shchcy', 'щ'),
			_Utils_Tuple2('SHcy', 'Ш'),
			_Utils_Tuple2('shcy', 'ш'),
			_Utils_Tuple2('ShortDownArrow', '↓'),
			_Utils_Tuple2('ShortLeftArrow', '←'),
			_Utils_Tuple2('shortmid', '∣'),
			_Utils_Tuple2('shortparallel', '∥'),
			_Utils_Tuple2('ShortRightArrow', '→'),
			_Utils_Tuple2('ShortUpArrow', '↑'),
			_Utils_Tuple2('shy', '\u00AD'),
			_Utils_Tuple2('Sigma', 'Σ'),
			_Utils_Tuple2('sigma', 'σ'),
			_Utils_Tuple2('sigmaf', 'ς'),
			_Utils_Tuple2('sigmav', 'ς'),
			_Utils_Tuple2('sim', '∼'),
			_Utils_Tuple2('simdot', '⩪'),
			_Utils_Tuple2('sime', '≃'),
			_Utils_Tuple2('simeq', '≃'),
			_Utils_Tuple2('simg', '⪞'),
			_Utils_Tuple2('simgE', '⪠'),
			_Utils_Tuple2('siml', '⪝'),
			_Utils_Tuple2('simlE', '⪟'),
			_Utils_Tuple2('simne', '≆'),
			_Utils_Tuple2('simplus', '⨤'),
			_Utils_Tuple2('simrarr', '⥲'),
			_Utils_Tuple2('slarr', '←'),
			_Utils_Tuple2('SmallCircle', '∘'),
			_Utils_Tuple2('smallsetminus', '∖'),
			_Utils_Tuple2('smashp', '⨳'),
			_Utils_Tuple2('smeparsl', '⧤'),
			_Utils_Tuple2('smid', '∣'),
			_Utils_Tuple2('smile', '⌣'),
			_Utils_Tuple2('smt', '⪪'),
			_Utils_Tuple2('smte', '⪬'),
			_Utils_Tuple2('smtes', '⪬︀'),
			_Utils_Tuple2('SOFTcy', 'Ь'),
			_Utils_Tuple2('softcy', 'ь'),
			_Utils_Tuple2('solbar', '⌿'),
			_Utils_Tuple2('solb', '⧄'),
			_Utils_Tuple2('sol', '/'),
			_Utils_Tuple2('Sopf', '\uD835\uDD4A'),
			_Utils_Tuple2('sopf', '\uD835\uDD64'),
			_Utils_Tuple2('spades', '♠'),
			_Utils_Tuple2('spadesuit', '♠'),
			_Utils_Tuple2('spar', '∥'),
			_Utils_Tuple2('sqcap', '⊓'),
			_Utils_Tuple2('sqcaps', '⊓︀'),
			_Utils_Tuple2('sqcup', '⊔'),
			_Utils_Tuple2('sqcups', '⊔︀'),
			_Utils_Tuple2('Sqrt', '√'),
			_Utils_Tuple2('sqsub', '⊏'),
			_Utils_Tuple2('sqsube', '⊑'),
			_Utils_Tuple2('sqsubset', '⊏'),
			_Utils_Tuple2('sqsubseteq', '⊑'),
			_Utils_Tuple2('sqsup', '⊐'),
			_Utils_Tuple2('sqsupe', '⊒'),
			_Utils_Tuple2('sqsupset', '⊐'),
			_Utils_Tuple2('sqsupseteq', '⊒'),
			_Utils_Tuple2('square', '□'),
			_Utils_Tuple2('Square', '□'),
			_Utils_Tuple2('SquareIntersection', '⊓'),
			_Utils_Tuple2('SquareSubset', '⊏'),
			_Utils_Tuple2('SquareSubsetEqual', '⊑'),
			_Utils_Tuple2('SquareSuperset', '⊐'),
			_Utils_Tuple2('SquareSupersetEqual', '⊒'),
			_Utils_Tuple2('SquareUnion', '⊔'),
			_Utils_Tuple2('squarf', '▪'),
			_Utils_Tuple2('squ', '□'),
			_Utils_Tuple2('squf', '▪'),
			_Utils_Tuple2('srarr', '→'),
			_Utils_Tuple2('Sscr', '\uD835\uDCAE'),
			_Utils_Tuple2('sscr', '\uD835\uDCC8'),
			_Utils_Tuple2('ssetmn', '∖'),
			_Utils_Tuple2('ssmile', '⌣'),
			_Utils_Tuple2('sstarf', '⋆'),
			_Utils_Tuple2('Star', '⋆'),
			_Utils_Tuple2('star', '☆'),
			_Utils_Tuple2('starf', '★'),
			_Utils_Tuple2('straightepsilon', 'ϵ'),
			_Utils_Tuple2('straightphi', 'ϕ'),
			_Utils_Tuple2('strns', '¯'),
			_Utils_Tuple2('sub', '⊂'),
			_Utils_Tuple2('Sub', '⋐'),
			_Utils_Tuple2('subdot', '⪽'),
			_Utils_Tuple2('subE', '⫅'),
			_Utils_Tuple2('sube', '⊆'),
			_Utils_Tuple2('subedot', '⫃'),
			_Utils_Tuple2('submult', '⫁'),
			_Utils_Tuple2('subnE', '⫋'),
			_Utils_Tuple2('subne', '⊊'),
			_Utils_Tuple2('subplus', '⪿'),
			_Utils_Tuple2('subrarr', '⥹'),
			_Utils_Tuple2('subset', '⊂'),
			_Utils_Tuple2('Subset', '⋐'),
			_Utils_Tuple2('subseteq', '⊆'),
			_Utils_Tuple2('subseteqq', '⫅'),
			_Utils_Tuple2('SubsetEqual', '⊆'),
			_Utils_Tuple2('subsetneq', '⊊'),
			_Utils_Tuple2('subsetneqq', '⫋'),
			_Utils_Tuple2('subsim', '⫇'),
			_Utils_Tuple2('subsub', '⫕'),
			_Utils_Tuple2('subsup', '⫓'),
			_Utils_Tuple2('succapprox', '⪸'),
			_Utils_Tuple2('succ', '≻'),
			_Utils_Tuple2('succcurlyeq', '≽'),
			_Utils_Tuple2('Succeeds', '≻'),
			_Utils_Tuple2('SucceedsEqual', '⪰'),
			_Utils_Tuple2('SucceedsSlantEqual', '≽'),
			_Utils_Tuple2('SucceedsTilde', '≿'),
			_Utils_Tuple2('succeq', '⪰'),
			_Utils_Tuple2('succnapprox', '⪺'),
			_Utils_Tuple2('succneqq', '⪶'),
			_Utils_Tuple2('succnsim', '⋩'),
			_Utils_Tuple2('succsim', '≿'),
			_Utils_Tuple2('SuchThat', '∋'),
			_Utils_Tuple2('sum', '∑'),
			_Utils_Tuple2('Sum', '∑'),
			_Utils_Tuple2('sung', '♪'),
			_Utils_Tuple2('sup1', '¹'),
			_Utils_Tuple2('sup2', '²'),
			_Utils_Tuple2('sup3', '³'),
			_Utils_Tuple2('sup', '⊃'),
			_Utils_Tuple2('Sup', '⋑'),
			_Utils_Tuple2('supdot', '⪾'),
			_Utils_Tuple2('supdsub', '⫘'),
			_Utils_Tuple2('supE', '⫆'),
			_Utils_Tuple2('supe', '⊇'),
			_Utils_Tuple2('supedot', '⫄'),
			_Utils_Tuple2('Superset', '⊃'),
			_Utils_Tuple2('SupersetEqual', '⊇'),
			_Utils_Tuple2('suphsol', '⟉'),
			_Utils_Tuple2('suphsub', '⫗'),
			_Utils_Tuple2('suplarr', '⥻'),
			_Utils_Tuple2('supmult', '⫂'),
			_Utils_Tuple2('supnE', '⫌'),
			_Utils_Tuple2('supne', '⊋'),
			_Utils_Tuple2('supplus', '⫀'),
			_Utils_Tuple2('supset', '⊃'),
			_Utils_Tuple2('Supset', '⋑'),
			_Utils_Tuple2('supseteq', '⊇'),
			_Utils_Tuple2('supseteqq', '⫆'),
			_Utils_Tuple2('supsetneq', '⊋'),
			_Utils_Tuple2('supsetneqq', '⫌'),
			_Utils_Tuple2('supsim', '⫈'),
			_Utils_Tuple2('supsub', '⫔'),
			_Utils_Tuple2('supsup', '⫖'),
			_Utils_Tuple2('swarhk', '⤦'),
			_Utils_Tuple2('swarr', '↙'),
			_Utils_Tuple2('swArr', '⇙'),
			_Utils_Tuple2('swarrow', '↙'),
			_Utils_Tuple2('swnwar', '⤪'),
			_Utils_Tuple2('szlig', 'ß'),
			_Utils_Tuple2('Tab', '\t'),
			_Utils_Tuple2('target', '⌖'),
			_Utils_Tuple2('Tau', 'Τ'),
			_Utils_Tuple2('tau', 'τ'),
			_Utils_Tuple2('tbrk', '⎴'),
			_Utils_Tuple2('Tcaron', 'Ť'),
			_Utils_Tuple2('tcaron', 'ť'),
			_Utils_Tuple2('Tcedil', 'Ţ'),
			_Utils_Tuple2('tcedil', 'ţ'),
			_Utils_Tuple2('Tcy', 'Т'),
			_Utils_Tuple2('tcy', 'т'),
			_Utils_Tuple2('tdot', '⃛'),
			_Utils_Tuple2('telrec', '⌕'),
			_Utils_Tuple2('Tfr', '\uD835\uDD17'),
			_Utils_Tuple2('tfr', '\uD835\uDD31'),
			_Utils_Tuple2('there4', '∴'),
			_Utils_Tuple2('therefore', '∴'),
			_Utils_Tuple2('Therefore', '∴'),
			_Utils_Tuple2('Theta', 'Θ'),
			_Utils_Tuple2('theta', 'θ'),
			_Utils_Tuple2('thetasym', 'ϑ'),
			_Utils_Tuple2('thetav', 'ϑ'),
			_Utils_Tuple2('thickapprox', '≈'),
			_Utils_Tuple2('thicksim', '∼'),
			_Utils_Tuple2('ThickSpace', '\u205F\u200A'),
			_Utils_Tuple2('ThinSpace', '\u2009'),
			_Utils_Tuple2('thinsp', '\u2009'),
			_Utils_Tuple2('thkap', '≈'),
			_Utils_Tuple2('thksim', '∼'),
			_Utils_Tuple2('THORN', 'Þ'),
			_Utils_Tuple2('thorn', 'þ'),
			_Utils_Tuple2('tilde', '˜'),
			_Utils_Tuple2('Tilde', '∼'),
			_Utils_Tuple2('TildeEqual', '≃'),
			_Utils_Tuple2('TildeFullEqual', '≅'),
			_Utils_Tuple2('TildeTilde', '≈'),
			_Utils_Tuple2('timesbar', '⨱'),
			_Utils_Tuple2('timesb', '⊠'),
			_Utils_Tuple2('times', '×'),
			_Utils_Tuple2('timesd', '⨰'),
			_Utils_Tuple2('tint', '∭'),
			_Utils_Tuple2('toea', '⤨'),
			_Utils_Tuple2('topbot', '⌶'),
			_Utils_Tuple2('topcir', '⫱'),
			_Utils_Tuple2('top', '⊤'),
			_Utils_Tuple2('Topf', '\uD835\uDD4B'),
			_Utils_Tuple2('topf', '\uD835\uDD65'),
			_Utils_Tuple2('topfork', '⫚'),
			_Utils_Tuple2('tosa', '⤩'),
			_Utils_Tuple2('tprime', '‴'),
			_Utils_Tuple2('trade', '™'),
			_Utils_Tuple2('TRADE', '™'),
			_Utils_Tuple2('triangle', '▵'),
			_Utils_Tuple2('triangledown', '▿'),
			_Utils_Tuple2('triangleleft', '◃'),
			_Utils_Tuple2('trianglelefteq', '⊴'),
			_Utils_Tuple2('triangleq', '≜'),
			_Utils_Tuple2('triangleright', '▹'),
			_Utils_Tuple2('trianglerighteq', '⊵'),
			_Utils_Tuple2('tridot', '◬'),
			_Utils_Tuple2('trie', '≜'),
			_Utils_Tuple2('triminus', '⨺'),
			_Utils_Tuple2('TripleDot', '⃛'),
			_Utils_Tuple2('triplus', '⨹'),
			_Utils_Tuple2('trisb', '⧍'),
			_Utils_Tuple2('tritime', '⨻'),
			_Utils_Tuple2('trpezium', '⏢'),
			_Utils_Tuple2('Tscr', '\uD835\uDCAF'),
			_Utils_Tuple2('tscr', '\uD835\uDCC9'),
			_Utils_Tuple2('TScy', 'Ц'),
			_Utils_Tuple2('tscy', 'ц'),
			_Utils_Tuple2('TSHcy', 'Ћ'),
			_Utils_Tuple2('tshcy', 'ћ'),
			_Utils_Tuple2('Tstrok', 'Ŧ'),
			_Utils_Tuple2('tstrok', 'ŧ'),
			_Utils_Tuple2('twixt', '≬'),
			_Utils_Tuple2('twoheadleftarrow', '↞'),
			_Utils_Tuple2('twoheadrightarrow', '↠'),
			_Utils_Tuple2('Uacute', 'Ú'),
			_Utils_Tuple2('uacute', 'ú'),
			_Utils_Tuple2('uarr', '↑'),
			_Utils_Tuple2('Uarr', '↟'),
			_Utils_Tuple2('uArr', '⇑'),
			_Utils_Tuple2('Uarrocir', '⥉'),
			_Utils_Tuple2('Ubrcy', 'Ў'),
			_Utils_Tuple2('ubrcy', 'ў'),
			_Utils_Tuple2('Ubreve', 'Ŭ'),
			_Utils_Tuple2('ubreve', 'ŭ'),
			_Utils_Tuple2('Ucirc', 'Û'),
			_Utils_Tuple2('ucirc', 'û'),
			_Utils_Tuple2('Ucy', 'У'),
			_Utils_Tuple2('ucy', 'у'),
			_Utils_Tuple2('udarr', '⇅'),
			_Utils_Tuple2('Udblac', 'Ű'),
			_Utils_Tuple2('udblac', 'ű'),
			_Utils_Tuple2('udhar', '⥮'),
			_Utils_Tuple2('ufisht', '⥾'),
			_Utils_Tuple2('Ufr', '\uD835\uDD18'),
			_Utils_Tuple2('ufr', '\uD835\uDD32'),
			_Utils_Tuple2('Ugrave', 'Ù'),
			_Utils_Tuple2('ugrave', 'ù'),
			_Utils_Tuple2('uHar', '⥣'),
			_Utils_Tuple2('uharl', '↿'),
			_Utils_Tuple2('uharr', '↾'),
			_Utils_Tuple2('uhblk', '▀'),
			_Utils_Tuple2('ulcorn', '⌜'),
			_Utils_Tuple2('ulcorner', '⌜'),
			_Utils_Tuple2('ulcrop', '⌏'),
			_Utils_Tuple2('ultri', '◸'),
			_Utils_Tuple2('Umacr', 'Ū'),
			_Utils_Tuple2('umacr', 'ū'),
			_Utils_Tuple2('uml', '¨'),
			_Utils_Tuple2('UnderBar', '_'),
			_Utils_Tuple2('UnderBrace', '⏟'),
			_Utils_Tuple2('UnderBracket', '⎵'),
			_Utils_Tuple2('UnderParenthesis', '⏝'),
			_Utils_Tuple2('Union', '⋃'),
			_Utils_Tuple2('UnionPlus', '⊎'),
			_Utils_Tuple2('Uogon', 'Ų'),
			_Utils_Tuple2('uogon', 'ų'),
			_Utils_Tuple2('Uopf', '\uD835\uDD4C'),
			_Utils_Tuple2('uopf', '\uD835\uDD66'),
			_Utils_Tuple2('UpArrowBar', '⤒'),
			_Utils_Tuple2('uparrow', '↑'),
			_Utils_Tuple2('UpArrow', '↑'),
			_Utils_Tuple2('Uparrow', '⇑'),
			_Utils_Tuple2('UpArrowDownArrow', '⇅'),
			_Utils_Tuple2('updownarrow', '↕'),
			_Utils_Tuple2('UpDownArrow', '↕'),
			_Utils_Tuple2('Updownarrow', '⇕'),
			_Utils_Tuple2('UpEquilibrium', '⥮'),
			_Utils_Tuple2('upharpoonleft', '↿'),
			_Utils_Tuple2('upharpoonright', '↾'),
			_Utils_Tuple2('uplus', '⊎'),
			_Utils_Tuple2('UpperLeftArrow', '↖'),
			_Utils_Tuple2('UpperRightArrow', '↗'),
			_Utils_Tuple2('upsi', 'υ'),
			_Utils_Tuple2('Upsi', 'ϒ'),
			_Utils_Tuple2('upsih', 'ϒ'),
			_Utils_Tuple2('Upsilon', 'Υ'),
			_Utils_Tuple2('upsilon', 'υ'),
			_Utils_Tuple2('UpTeeArrow', '↥'),
			_Utils_Tuple2('UpTee', '⊥'),
			_Utils_Tuple2('upuparrows', '⇈'),
			_Utils_Tuple2('urcorn', '⌝'),
			_Utils_Tuple2('urcorner', '⌝'),
			_Utils_Tuple2('urcrop', '⌎'),
			_Utils_Tuple2('Uring', 'Ů'),
			_Utils_Tuple2('uring', 'ů'),
			_Utils_Tuple2('urtri', '◹'),
			_Utils_Tuple2('Uscr', '\uD835\uDCB0'),
			_Utils_Tuple2('uscr', '\uD835\uDCCA'),
			_Utils_Tuple2('utdot', '⋰'),
			_Utils_Tuple2('Utilde', 'Ũ'),
			_Utils_Tuple2('utilde', 'ũ'),
			_Utils_Tuple2('utri', '▵'),
			_Utils_Tuple2('utrif', '▴'),
			_Utils_Tuple2('uuarr', '⇈'),
			_Utils_Tuple2('Uuml', 'Ü'),
			_Utils_Tuple2('uuml', 'ü'),
			_Utils_Tuple2('uwangle', '⦧'),
			_Utils_Tuple2('vangrt', '⦜'),
			_Utils_Tuple2('varepsilon', 'ϵ'),
			_Utils_Tuple2('varkappa', 'ϰ'),
			_Utils_Tuple2('varnothing', '∅'),
			_Utils_Tuple2('varphi', 'ϕ'),
			_Utils_Tuple2('varpi', 'ϖ'),
			_Utils_Tuple2('varpropto', '∝'),
			_Utils_Tuple2('varr', '↕'),
			_Utils_Tuple2('vArr', '⇕'),
			_Utils_Tuple2('varrho', 'ϱ'),
			_Utils_Tuple2('varsigma', 'ς'),
			_Utils_Tuple2('varsubsetneq', '⊊︀'),
			_Utils_Tuple2('varsubsetneqq', '⫋︀'),
			_Utils_Tuple2('varsupsetneq', '⊋︀'),
			_Utils_Tuple2('varsupsetneqq', '⫌︀'),
			_Utils_Tuple2('vartheta', 'ϑ'),
			_Utils_Tuple2('vartriangleleft', '⊲'),
			_Utils_Tuple2('vartriangleright', '⊳'),
			_Utils_Tuple2('vBar', '⫨'),
			_Utils_Tuple2('Vbar', '⫫'),
			_Utils_Tuple2('vBarv', '⫩'),
			_Utils_Tuple2('Vcy', 'В'),
			_Utils_Tuple2('vcy', 'в'),
			_Utils_Tuple2('vdash', '⊢'),
			_Utils_Tuple2('vDash', '⊨'),
			_Utils_Tuple2('Vdash', '⊩'),
			_Utils_Tuple2('VDash', '⊫'),
			_Utils_Tuple2('Vdashl', '⫦'),
			_Utils_Tuple2('veebar', '⊻'),
			_Utils_Tuple2('vee', '∨'),
			_Utils_Tuple2('Vee', '⋁'),
			_Utils_Tuple2('veeeq', '≚'),
			_Utils_Tuple2('vellip', '⋮'),
			_Utils_Tuple2('verbar', '|'),
			_Utils_Tuple2('Verbar', '‖'),
			_Utils_Tuple2('vert', '|'),
			_Utils_Tuple2('Vert', '‖'),
			_Utils_Tuple2('VerticalBar', '∣'),
			_Utils_Tuple2('VerticalLine', '|'),
			_Utils_Tuple2('VerticalSeparator', '❘'),
			_Utils_Tuple2('VerticalTilde', '≀'),
			_Utils_Tuple2('VeryThinSpace', '\u200A'),
			_Utils_Tuple2('Vfr', '\uD835\uDD19'),
			_Utils_Tuple2('vfr', '\uD835\uDD33'),
			_Utils_Tuple2('vltri', '⊲'),
			_Utils_Tuple2('vnsub', '⊂⃒'),
			_Utils_Tuple2('vnsup', '⊃⃒'),
			_Utils_Tuple2('Vopf', '\uD835\uDD4D'),
			_Utils_Tuple2('vopf', '\uD835\uDD67'),
			_Utils_Tuple2('vprop', '∝'),
			_Utils_Tuple2('vrtri', '⊳'),
			_Utils_Tuple2('Vscr', '\uD835\uDCB1'),
			_Utils_Tuple2('vscr', '\uD835\uDCCB'),
			_Utils_Tuple2('vsubnE', '⫋︀'),
			_Utils_Tuple2('vsubne', '⊊︀'),
			_Utils_Tuple2('vsupnE', '⫌︀'),
			_Utils_Tuple2('vsupne', '⊋︀'),
			_Utils_Tuple2('Vvdash', '⊪'),
			_Utils_Tuple2('vzigzag', '⦚'),
			_Utils_Tuple2('Wcirc', 'Ŵ'),
			_Utils_Tuple2('wcirc', 'ŵ'),
			_Utils_Tuple2('wedbar', '⩟'),
			_Utils_Tuple2('wedge', '∧'),
			_Utils_Tuple2('Wedge', '⋀'),
			_Utils_Tuple2('wedgeq', '≙'),
			_Utils_Tuple2('weierp', '℘'),
			_Utils_Tuple2('Wfr', '\uD835\uDD1A'),
			_Utils_Tuple2('wfr', '\uD835\uDD34'),
			_Utils_Tuple2('Wopf', '\uD835\uDD4E'),
			_Utils_Tuple2('wopf', '\uD835\uDD68'),
			_Utils_Tuple2('wp', '℘'),
			_Utils_Tuple2('wr', '≀'),
			_Utils_Tuple2('wreath', '≀'),
			_Utils_Tuple2('Wscr', '\uD835\uDCB2'),
			_Utils_Tuple2('wscr', '\uD835\uDCCC'),
			_Utils_Tuple2('xcap', '⋂'),
			_Utils_Tuple2('xcirc', '◯'),
			_Utils_Tuple2('xcup', '⋃'),
			_Utils_Tuple2('xdtri', '▽'),
			_Utils_Tuple2('Xfr', '\uD835\uDD1B'),
			_Utils_Tuple2('xfr', '\uD835\uDD35'),
			_Utils_Tuple2('xharr', '⟷'),
			_Utils_Tuple2('xhArr', '⟺'),
			_Utils_Tuple2('Xi', 'Ξ'),
			_Utils_Tuple2('xi', 'ξ'),
			_Utils_Tuple2('xlarr', '⟵'),
			_Utils_Tuple2('xlArr', '⟸'),
			_Utils_Tuple2('xmap', '⟼'),
			_Utils_Tuple2('xnis', '⋻'),
			_Utils_Tuple2('xodot', '⨀'),
			_Utils_Tuple2('Xopf', '\uD835\uDD4F'),
			_Utils_Tuple2('xopf', '\uD835\uDD69'),
			_Utils_Tuple2('xoplus', '⨁'),
			_Utils_Tuple2('xotime', '⨂'),
			_Utils_Tuple2('xrarr', '⟶'),
			_Utils_Tuple2('xrArr', '⟹'),
			_Utils_Tuple2('Xscr', '\uD835\uDCB3'),
			_Utils_Tuple2('xscr', '\uD835\uDCCD'),
			_Utils_Tuple2('xsqcup', '⨆'),
			_Utils_Tuple2('xuplus', '⨄'),
			_Utils_Tuple2('xutri', '△'),
			_Utils_Tuple2('xvee', '⋁'),
			_Utils_Tuple2('xwedge', '⋀'),
			_Utils_Tuple2('Yacute', 'Ý'),
			_Utils_Tuple2('yacute', 'ý'),
			_Utils_Tuple2('YAcy', 'Я'),
			_Utils_Tuple2('yacy', 'я'),
			_Utils_Tuple2('Ycirc', 'Ŷ'),
			_Utils_Tuple2('ycirc', 'ŷ'),
			_Utils_Tuple2('Ycy', 'Ы'),
			_Utils_Tuple2('ycy', 'ы'),
			_Utils_Tuple2('yen', '¥'),
			_Utils_Tuple2('Yfr', '\uD835\uDD1C'),
			_Utils_Tuple2('yfr', '\uD835\uDD36'),
			_Utils_Tuple2('YIcy', 'Ї'),
			_Utils_Tuple2('yicy', 'ї'),
			_Utils_Tuple2('Yopf', '\uD835\uDD50'),
			_Utils_Tuple2('yopf', '\uD835\uDD6A'),
			_Utils_Tuple2('Yscr', '\uD835\uDCB4'),
			_Utils_Tuple2('yscr', '\uD835\uDCCE'),
			_Utils_Tuple2('YUcy', 'Ю'),
			_Utils_Tuple2('yucy', 'ю'),
			_Utils_Tuple2('yuml', 'ÿ'),
			_Utils_Tuple2('Yuml', 'Ÿ'),
			_Utils_Tuple2('Zacute', 'Ź'),
			_Utils_Tuple2('zacute', 'ź'),
			_Utils_Tuple2('Zcaron', 'Ž'),
			_Utils_Tuple2('zcaron', 'ž'),
			_Utils_Tuple2('Zcy', 'З'),
			_Utils_Tuple2('zcy', 'з'),
			_Utils_Tuple2('Zdot', 'Ż'),
			_Utils_Tuple2('zdot', 'ż'),
			_Utils_Tuple2('zeetrf', 'ℨ'),
			_Utils_Tuple2('ZeroWidthSpace', '\u200B'),
			_Utils_Tuple2('Zeta', 'Ζ'),
			_Utils_Tuple2('zeta', 'ζ'),
			_Utils_Tuple2('zfr', '\uD835\uDD37'),
			_Utils_Tuple2('Zfr', 'ℨ'),
			_Utils_Tuple2('ZHcy', 'Ж'),
			_Utils_Tuple2('zhcy', 'ж'),
			_Utils_Tuple2('zigrarr', '⇝'),
			_Utils_Tuple2('zopf', '\uD835\uDD6B'),
			_Utils_Tuple2('Zopf', 'ℤ'),
			_Utils_Tuple2('Zscr', '\uD835\uDCB5'),
			_Utils_Tuple2('zscr', '\uD835\uDCCF'),
			_Utils_Tuple2('zwj', '\u200D'),
			_Utils_Tuple2('zwnj', '\u200C')
		]));
var $hecrj$html_parser$Html$Parser$namedCharacterReference = A2(
	$elm$parser$Parser$map,
	function (reference) {
		return A2(
			$elm$core$Maybe$withDefault,
			'&' + (reference + ';'),
			A2($elm$core$Dict$get, reference, $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict));
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isAlpha)));
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Char$fromCode = _Char_fromCode;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $elm$core$Char$isHexDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return ((48 <= code) && (code <= 57)) || (((65 <= code) && (code <= 70)) || ((97 <= code) && (code <= 102)));
};
var $hecrj$html_parser$Html$Parser$hexadecimal = A2(
	$elm$parser$Parser$andThen,
	function (hex) {
		var _v0 = $rtfeldman$elm_hex$Hex$fromString(
			$elm$core$String$toLower(hex));
		if (!_v0.$) {
			var value = _v0.a;
			return $elm$parser$Parser$succeed(value);
		} else {
			var error = _v0.a;
			return $elm$parser$Parser$problem(error);
		}
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isHexDigit)));
var $elm$parser$Parser$ExpectingInt = {$: 1};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {cj: s.cj + (newOffset - s.b), el: s.el, c: s.c, b: newOffset, de: s.de, a: s.a};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 1) {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.b, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$core$String$toFloat = _String_toFloat;
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.a);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.de, s.cj - (floatOffset + s.b), invalid, s.el));
		} else {
			if (_Utils_eq(s.b, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.b, intPair, s);
				} else {
					if (floatSettings.$ === 1) {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.b, floatOffset, s.a));
						if (_v1.$ === 1) {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return function (s) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.b, s.a)) {
			var zeroOffset = s.b + 1;
			var baseOffset = zeroOffset + 1;
			return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.fp,
				c.cC,
				baseOffset,
				A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.fp,
				c.cY,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.fp,
				c.cc,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.a),
				s) : A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.fp,
				c.cw,
				c.cH,
				c.cx,
				_Utils_Tuple2(zeroOffset, 0),
				s)));
		} else {
			return A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.fp,
				c.cw,
				c.cH,
				c.cx,
				A3($elm$parser$Parser$Advanced$consumeBase, 10, s.b, s.a),
				s);
		}
	};
};
var $elm$parser$Parser$Advanced$int = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				cc: $elm$core$Result$Err(invalid),
				cw: expecting,
				cx: $elm$core$Result$Err(invalid),
				cC: $elm$core$Result$Err(invalid),
				cH: $elm$core$Result$Ok($elm$core$Basics$identity),
				fp: invalid,
				cY: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$int = A2($elm$parser$Parser$Advanced$int, $elm$parser$Parser$ExpectingInt, $elm$parser$Parser$ExpectingInt);
var $hecrj$html_parser$Html$Parser$numericCharacterReference = function () {
	var codepoint = $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (c) {
							return (c === 'x') || (c === 'X');
						})),
				$hecrj$html_parser$Html$Parser$hexadecimal),
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq('0'))),
				$elm$parser$Parser$int)
			]));
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq('#'))),
		A2(
			$elm$parser$Parser$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			codepoint));
}();
var $hecrj$html_parser$Html$Parser$characterReference = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('&'))),
	$elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$namedCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$numericCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				$elm$parser$Parser$succeed('&')
			])));
var $hecrj$html_parser$Html$Parser$tagAttributeQuotedValue = function (quote) {
	var isQuotedValueChar = function (c) {
		return (!_Utils_eq(c, quote)) && (c !== '&');
	};
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$map,
				$elm$core$String$join(''),
				$hecrj$html_parser$Html$Parser$many(
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$getChompedString(
								$hecrj$html_parser$Html$Parser$chompOneOrMore(isQuotedValueChar)),
								$hecrj$html_parser$Html$Parser$characterReference
							])))),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))));
};
var $hecrj$html_parser$Html$Parser$oneOrMore = F2(
	function (type_, parser_) {
		return A2(
			$elm$parser$Parser$loop,
			_List_Nil,
			function (list) {
				return $elm$parser$Parser$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$map,
							function (_new) {
								return $elm$parser$Parser$Loop(
									A2($elm$core$List$cons, _new, list));
							},
							parser_),
							$elm$core$List$isEmpty(list) ? $elm$parser$Parser$problem('expecting at least one ' + type_) : $elm$parser$Parser$succeed(
							$elm$parser$Parser$Done(
								$elm$core$List$reverse(list)))
						]));
			});
	});
var $hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue = function () {
	var isUnquotedValueChar = function (c) {
		return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '=') && ((c !== '<') && ((c !== '>') && ((c !== '`') && (c !== '&')))))));
	};
	return A2(
		$elm$parser$Parser$map,
		$elm$core$String$join(''),
		A2(
			$hecrj$html_parser$Html$Parser$oneOrMore,
			'attribute value',
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$getChompedString(
						$hecrj$html_parser$Html$Parser$chompOneOrMore(isUnquotedValueChar)),
						$hecrj$html_parser$Html$Parser$characterReference
					]))));
}();
var $hecrj$html_parser$Html$Parser$tagAttributeValue = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('='))),
				$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue,
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\"'),
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\'')
					]))),
			$elm$parser$Parser$succeed('')
		]));
var $hecrj$html_parser$Html$Parser$tagAttribute = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$ignorer,
			$hecrj$html_parser$Html$Parser$tagAttributeName,
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
	A2(
		$elm$parser$Parser$ignorer,
		$hecrj$html_parser$Html$Parser$tagAttributeValue,
		$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)));
var $hecrj$html_parser$Html$Parser$tagAttributes = $hecrj$html_parser$Html$Parser$many($hecrj$html_parser$Html$Parser$tagAttribute);
var $hecrj$html_parser$Html$Parser$tagName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$chompIf($elm$core$Char$isAlphaNum),
			$elm$parser$Parser$chompWhile(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || (c === '-');
				}))));
var $hecrj$html_parser$Html$Parser$Text = function (a) {
	return {$: 0, a: a};
};
var $hecrj$html_parser$Html$Parser$text = A2(
	$elm$parser$Parser$map,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$join(''),
		$hecrj$html_parser$Html$Parser$Text),
	A2(
		$hecrj$html_parser$Html$Parser$oneOrMore,
		'text element',
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$getChompedString(
					$hecrj$html_parser$Html$Parser$chompOneOrMore(
						function (c) {
							return (c !== '<') && (c !== '&');
						})),
					$hecrj$html_parser$Html$Parser$characterReference
				]))));
function $hecrj$html_parser$Html$Parser$cyclic$node() {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				$hecrj$html_parser$Html$Parser$text,
				$hecrj$html_parser$Html$Parser$comment,
				$hecrj$html_parser$Html$Parser$cyclic$element()
			]));
}
function $hecrj$html_parser$Html$Parser$cyclic$element() {
	return A2(
		$elm$parser$Parser$andThen,
		function (_v0) {
			var name = _v0.a;
			var attributes = _v0.b;
			return $hecrj$html_parser$Html$Parser$isVoidElement(name) ? A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A3($hecrj$html_parser$Html$Parser$Element, name, attributes, _List_Nil)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$chompIf(
								$elm$core$Basics$eq('/')),
								$elm$parser$Parser$succeed(0)
							]))),
				$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('>'))) : A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A2($hecrj$html_parser$Html$Parser$Element, name, attributes)),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('>'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$many(
						$elm$parser$Parser$backtrackable(
							$hecrj$html_parser$Html$Parser$cyclic$node())),
					$hecrj$html_parser$Html$Parser$closingTag(name)));
		},
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Tuple$pair),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$tagName,
					$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
			$hecrj$html_parser$Html$Parser$tagAttributes));
}
var $hecrj$html_parser$Html$Parser$node = $hecrj$html_parser$Html$Parser$cyclic$node();
$hecrj$html_parser$Html$Parser$cyclic$node = function () {
	return $hecrj$html_parser$Html$Parser$node;
};
var $hecrj$html_parser$Html$Parser$element = $hecrj$html_parser$Html$Parser$cyclic$element();
$hecrj$html_parser$Html$Parser$cyclic$element = function () {
	return $hecrj$html_parser$Html$Parser$element;
};
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {cj: col, c3: problem, de: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.de, p.cj, p.c3);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{cj: 1, el: _List_Nil, c: 1, b: 0, de: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $hecrj$html_parser$Html$Parser$run = function (str) {
	return $elm$core$String$isEmpty(str) ? $elm$core$Result$Ok(_List_Nil) : A2(
		$elm$parser$Parser$run,
		A2($hecrj$html_parser$Html$Parser$oneOrMore, 'node', $hecrj$html_parser$Html$Parser$node),
		str);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $hecrj$html_parser$Html$Parser$Util$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, name, value);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDom = function (nodes) {
	return A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach, nodes);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach = function (node) {
	switch (node.$) {
		case 1:
			var name = node.a;
			var attrs = node.b;
			var children = node.c;
			return A3(
				$elm$html$Html$node,
				name,
				A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toAttribute, attrs),
				$hecrj$html_parser$Html$Parser$Util$toVirtualDom(children));
		case 0:
			var s = node.a;
			return $elm$html$Html$text(s);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$HtmlUtils$textHtml = function (str) {
	var _v0 = $hecrj$html_parser$Html$Parser$run(str);
	if (!_v0.$) {
		var nodes = _v0.a;
		return $hecrj$html_parser$Html$Parser$Util$toVirtualDom(nodes);
	} else {
		return _List_fromArray(
			[
				$elm$html$Html$text('Error parsing: ' + str)
			]);
	}
};
var $author$project$Confirm$View$modalView = F2(
	function (_v0, model) {
		var translate = _v0.gS;
		var _v1 = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.aA;
				},
				model));
		switch (_v1) {
			case 0:
				return $author$project$HtmlUtils$empty;
			case 1:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('u-p-8')
						]),
					$author$project$HtmlUtils$textHtml(
						translate($author$project$Confirm$Blurbs$blurbs.bX)));
			default:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-cancel-modal')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-cancel-modal__header')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$Confirm$Blurbs$blurbs.aT))
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-cancel-modal__msg')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$Confirm$Blurbs$blurbs.a3))
								])),
							A2(
							$author$project$Button$button,
							_List_fromArray(
								[
									$author$project$Button$class('c-cancel-modal__cancel'),
									$author$project$Button$qa('cancel_confirm'),
									$author$project$Button$dark,
									$author$project$Button$onClick($author$project$Confirm$Data$ConfirmCancel)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$Confirm$Blurbs$blurbs.b8))
								])),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Confirm$Data$AbortCancel),
									$elm$html$Html$Attributes$class('c-cancel-modal__continue'),
									$author$project$CssUtils$qa('cancel_continue')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$Confirm$Blurbs$blurbs.a7))
								]))
						]));
		}
	});
var $author$project$EffectUtils$openModal = A2($author$project$Scaffold$OpenModal, false, false);
var $author$project$Messages$simpleMessage = F2(
	function (title, message) {
		return {
			al: $elm$core$Maybe$Just(
				{bp: message, dB: title}),
			ax: $elm$core$Maybe$Nothing
		};
	});
var $author$project$Confirm$Control$update = F4(
	function (external, videoOptions, msg, model) {
		var translate = external.gS;
		var student = external.gy;
		switch (msg.$) {
			case 3:
				return _Utils_Tuple3(model, $author$project$EffectUtils$closeModal, $author$project$Confirm$Data$NoEvent);
			case 4:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aA: 1}),
					$author$project$EffectUtils$openModal($author$project$Confirm$View$modalView),
					$author$project$Confirm$Data$NoEvent);
			case 2:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{cf: true}),
					A2(
						$author$project$EffectUtils$fromCmd,
						1,
						A3($author$project$Confirm$Api$makeBooking, external, model, $author$project$Confirm$Data$MakeBookingResponse)),
					$author$project$Confirm$Data$BlockUI);
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aA: 2}),
					$author$project$EffectUtils$openModal($author$project$Confirm$View$modalView),
					$author$project$Confirm$Data$NoEvent);
			case 5:
				return _Utils_Tuple3(
					model,
					$author$project$EffectUtils$batchEffect(
						_List_fromArray(
							[
								A2(
								$author$project$EffectUtils$fromCmd,
								2,
								$author$project$Ports$changeUrl(external.f4.ff)),
								$author$project$EffectUtils$closeModal
							])),
					$author$project$Confirm$Data$NoEvent);
			case 7:
				return _Utils_Tuple3(model, $author$project$EffectUtils$noEffect, $author$project$Confirm$Data$GoBackToSelectTimeslot);
			case 8:
				return _Utils_Tuple3(model, $author$project$EffectUtils$noEffect, $author$project$Confirm$Data$GoBackToSelectTopic);
			case 6:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aA: 0}),
					$author$project$EffectUtils$closeModal,
					$author$project$Confirm$Data$NoEvent);
			case 9:
				if (!msg.a.$) {
					var resp = msg.a.a;
					if (resp.$ === 1) {
						var gandc = resp.a;
						return _Utils_Tuple3(
							model,
							$author$project$EffectUtils$noEffect,
							$author$project$Confirm$Data$SuccessfulBooking(
								_Utils_update(
									student,
									{e6: gandc})));
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{cf: false}),
							A2(
								$author$project$EffectUtils$fromCmd,
								3,
								$author$project$Ports$bubbleError(
									A2(
										$author$project$Messages$simpleMessage,
										'Error',
										translate($author$project$Confirm$Blurbs$blurbs.a$)))),
							$author$project$Confirm$Data$UnblockUI);
					}
				} else {
					var err = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{cf: false}),
						A2(
							$author$project$EffectUtils$fromCmd,
							3,
							$author$project$Ports$bubbleError(
								A4(
									$author$project$Messages$httpErrorMessage,
									translate($author$project$Confirm$Blurbs$blurbs.bf),
									$elm$core$Maybe$Just(
										translate($author$project$Confirm$Blurbs$blurbs.a$)),
									'MakeBooking',
									err))),
						$author$project$Confirm$Data$UnblockUI);
				}
			case 0:
				if (!msg.a.$) {
					var teacher = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{gG: teacher}),
						$author$project$EffectUtils$noEffect,
						$author$project$Confirm$Data$NoEvent);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple3(
						model,
						A2(
							$author$project$EffectUtils$fromCmd,
							3,
							$author$project$Ports$bubbleError(
								A4(
									$author$project$Messages$httpErrorMessage,
									translate($author$project$Confirm$Blurbs$blurbs.bf),
									$elm$core$Maybe$Nothing,
									'GetTeacher',
									err))),
						$author$project$Confirm$Data$NoEvent);
				}
			default:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							gq: videoOptions.ee ? (!model.gq) : model.gq
						}),
					$author$project$EffectUtils$noEffect,
					$author$project$Confirm$Data$NoEvent);
		}
	});
var $author$project$SelectTimeslot$Data$AutoCompleterEffect = 4;
var $author$project$SelectTimeslot$Data$BubbleErrorEffect = 1;
var $author$project$SelectTimeslot$Data$ChangeStudentEffect = 5;
var $author$project$SelectTimeslot$Data$ChangeUrlEffect = 2;
var $author$project$SelectTimeslot$Data$DatepickerEffect = 7;
var $author$project$SelectTimeslot$Data$DatepickerMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$SelectTimeslot$Data$GetTimeslotsEffect = 3;
var $author$project$SelectTimeslot$Data$RecommendedTeachersEffect = 8;
var $author$project$SelectTimeslot$Data$RecommendedTeachersReceived = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$SelectTimeslot$Data$SetTimezoneEffect = 6;
var $author$project$SelectTimeslot$Data$TimeslotsReceived = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$SelectTimeslot$Data$UpdateTimezoneComplete = F3(
	function (a, b, c) {
		return {$: 6, a: a, b: b, c: c};
	});
var $author$project$SelectTimeslot$Data$getTimeslotCacheKey = A2($elm$core$Tuple$mapBoth, $elm$time$Time$posixToMillis, $elm$time$Time$posixToMillis);
var $author$project$SelectTimeslot$Data$addToTimeslotCache = F3(
	function (range, timeslots, selectedTeacher) {
		if (!selectedTeacher.$) {
			var cache = selectedTeacher.a;
			return $author$project$SelectTimeslot$Data$AllTeachers(
				A3(
					$elm$core$Dict$insert,
					$author$project$SelectTimeslot$Data$getTimeslotCacheKey(range),
					timeslots,
					cache));
		} else {
			var teacherId = selectedTeacher.a;
			var cache = selectedTeacher.b;
			return A2(
				$author$project$SelectTimeslot$Data$SpecificTeacher,
				teacherId,
				A3(
					$elm$core$Dict$insert,
					$author$project$SelectTimeslot$Data$getTimeslotCacheKey(range),
					timeslots,
					cache));
		}
	});
var $author$project$SelectTimeslot$Api$getTimeslots = F4(
	function (external, selectedTeacher, dateRange, onResult) {
		return A2(
			$elm$core$Task$attempt,
			onResult,
			A3($author$project$SelectTimeslot$Api$getTimeslotsTask, external, selectedTeacher, dateRange));
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $author$project$SelectTimeslot$Data$isTimeslotInCache = function (timeslot) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Dict$values,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$concat,
			$elm$core$List$member(timeslot)));
};
var $author$project$SelectTimeslot$Data$isTimeslotValid = F2(
	function (mTimeslot, selectedTeacher) {
		var _v0 = _Utils_Tuple2(mTimeslot, selectedTeacher);
		if (_v0.a.$ === 1) {
			var _v1 = _v0.a;
			return true;
		} else {
			if (!_v0.b.$) {
				var timeslot = _v0.a.a;
				var cache = _v0.b.a;
				return A2($author$project$SelectTimeslot$Data$isTimeslotInCache, timeslot, cache);
			} else {
				var timeslot = _v0.a.a;
				var _v2 = _v0.b;
				var cache = _v2.b;
				return A2($author$project$SelectTimeslot$Data$isTimeslotInCache, timeslot, cache);
			}
		}
	});
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$SelectTimeslot$Api$recommendedTeachers = F3(
	function (external, startTime, onResult) {
		var qs = A2(
			$author$project$SelectTimeslot$Api$addTemplateUnitIdParam,
			external,
			_List_fromArray(
				[
					A2(
					$elm$url$Url$Builder$string,
					'startTime',
					$elm$core$String$fromFloat(startTime))
				]));
		return $elm$http$Http$get(
			{
				cv: A2(
					$elm$http$Http$expectJson,
					onResult,
					$elm$json$Json$Decode$list($author$project$SelectTimeslot$Data$teacherDecoder)),
				dJ: A2(
					$elm$url$Url$Builder$absolute,
					_Utils_ap(
						$author$project$SelectTimeslot$Api$apiPath,
						_List_fromArray(
							['recommendedteachers'])),
					qs)
			});
	});
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $author$project$SelectTimeslot$Api$setTimezone = F2(
	function (onResult, tz) {
		return $elm$http$Http$post(
			{
				d1: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'timezone',
								$elm$json$Json$Encode$string(tz))
							]))),
				cv: $elm$http$Http$expectWhatever(onResult),
				dJ: A2(
					$elm$url$Url$Builder$absolute,
					_Utils_ap(
						$author$project$SelectTimeslot$Api$apiPath,
						_List_fromArray(
							['timezone'])),
					_List_Nil)
			});
	});
var $author$project$SelectTimeslot$Data$getTimeslotCacheForSelectedTeacher = function (selectedTeacher) {
	if (!selectedTeacher.$) {
		var cache = selectedTeacher.a;
		return cache;
	} else {
		var cache = selectedTeacher.b;
		return cache;
	}
};
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $author$project$SelectTimeslot$Data$timeslotsLoaded = F2(
	function (range, selectedTeacher) {
		return A2(
			$elm$core$Dict$member,
			$author$project$SelectTimeslot$Data$getTimeslotCacheKey(range),
			$author$project$SelectTimeslot$Data$getTimeslotCacheForSelectedTeacher(selectedTeacher));
	});
var $author$project$Components$Autocompleter$CheckTyping = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Components$Autocompleter$NoEvent = {$: 0};
var $author$project$Components$Autocompleter$ReceivedThings = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Autocompleter$ThingSelected = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Autocompleter$debounceBy = 300;
var $author$project$Components$Autocompleter$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 10:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$Autocompleter$NoEvent);
			case 9:
				var idx = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{u: idx}),
					$elm$core$Platform$Cmd$none,
					$author$project$Components$Autocompleter$NoEvent);
			case 7:
				var index = model.u + 1;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							u: (_Utils_cmp(
								index,
								$elm$core$List$length(model.E) - 1) > 0) ? 0 : index
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Components$Autocompleter$NoEvent);
			case 8:
				var index = model.u - 1;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							u: (index < 0) ? ($elm$core$List$length(model.E) - 1) : index
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Components$Autocompleter$NoEvent);
			case 0:
				var val = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{gY: val}),
					A2(
						$elm$core$Task$perform,
						$author$project$Components$Autocompleter$CheckTyping(val),
						$elm$core$Process$sleep($author$project$Components$Autocompleter$debounceBy)),
					$author$project$Components$Autocompleter$NoEvent);
			case 4:
				var thing = msg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$author$project$Components$Autocompleter$ThingSelected(thing));
			case 1:
				var previousVal = msg.a;
				var _v1 = (_Utils_eq(previousVal, model.gY) && (model.gY !== '')) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{P: true}),
					A2(model.bm, model.gY, $author$project$Components$Autocompleter$ReceivedThings)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				var updated = _v1.a;
				var cmd = _v1.b;
				return _Utils_Tuple3(updated, cmd, $author$project$Components$Autocompleter$NoEvent);
			case 5:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{Z: false}),
					$elm$core$Platform$Cmd$none,
					$author$project$Components$Autocompleter$NoEvent);
			case 6:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{Z: true}),
					$elm$core$Platform$Cmd$none,
					$author$project$Components$Autocompleter$NoEvent);
			case 2:
				if (!msg.a.$) {
					var things = msg.a.a;
					var index = A3(
						$elm$core$Basics$clamp,
						0,
						$elm$core$List$length(things) - 1,
						model.u);
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{u: index, P: false, E: things}),
						$elm$core$Platform$Cmd$none,
						$author$project$Components$Autocompleter$NoEvent);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{P: false}),
						$author$project$Ports$bubbleError(
							A4(
								$author$project$Messages$httpErrorMessage,
								'Error',
								$elm$core$Maybe$Just('Unable to load things'),
								'GetThingsWithAutocompleter',
								err)),
						$author$project$Components$Autocompleter$NoEvent);
				}
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$Autocompleter$NoEvent);
		}
	});
var $author$project$Components$Datepicker$DateSelected = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Datepicker$MonthSelected = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Datepicker$NoEvent = {$: 0};
var $author$project$Components$Datepicker$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var date = msg.a;
				var dateRange = $author$project$Components$Datepicker$dateRangeFromDate(date);
				return _Utils_Tuple3(
					$elm$core$Maybe$Just(date),
					$elm$core$Platform$Cmd$none,
					$author$project$Components$Datepicker$MonthSelected(dateRange));
			case 1:
				var date = msg.a;
				return _Utils_Tuple3(
					$elm$core$Maybe$Just(date),
					$elm$core$Platform$Cmd$none,
					$author$project$Components$Datepicker$DateSelected(date));
			default:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Components$Datepicker$NoEvent);
		}
	});
var $author$project$SelectTimeslot$Control$update = F3(
	function (external, msg, model) {
		var translate = external.gS;
		var student = external.gy;
		if (!model.$) {
			if (msg.$ === 1) {
				if (!msg.a.$) {
					var initModel = msg.a.a;
					return _Utils_Tuple3(initModel, $author$project$EffectUtils$noEffect, $elm$core$Maybe$Nothing);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple3(
						model,
						A2(
							$author$project$EffectUtils$fromCmd,
							1,
							$author$project$Ports$bubbleError(
								A4(
									$author$project$Messages$httpErrorMessage,
									translate($author$project$SelectTimeslot$Blurbs$blurbs.bf),
									$elm$core$Maybe$Just(
										translate($author$project$SelectTimeslot$Blurbs$blurbs.bK)),
									'GetInitialSelectTimeslotData',
									err))),
						$elm$core$Maybe$Nothing);
				}
			} else {
				return _Utils_Tuple3(model, $author$project$EffectUtils$noEffect, $elm$core$Maybe$Nothing);
			}
		} else {
			var initModel = model.a;
			switch (msg.$) {
				case 14:
					return _Utils_Tuple3(
						$author$project$SelectTimeslot$Data$Initialised(initModel),
						A2(
							$author$project$EffectUtils$fromCmd,
							2,
							$author$project$Ports$changeUrl(external.f4.cl)),
						$elm$core$Maybe$Nothing);
				case 13:
					var subMsg = msg.a;
					var _v3 = A2($author$project$Components$Autocompleter$update, subMsg, initModel.dX);
					var subModel = _v3.a;
					var subCmd = _v3.b;
					var subEvt = _v3.c;
					var _v4 = function () {
						if (subEvt.$ === 1) {
							var teacher = subEvt.a;
							var selectedTeacher = A2($author$project$SelectTimeslot$Data$SpecificTeacher, teacher, $elm$core$Dict$empty);
							return _Utils_Tuple2(
								_Utils_update(
									initModel,
									{ea: false, fI: true, gm: selectedTeacher}),
								A2(
									$author$project$EffectUtils$fromCmd,
									3,
									A4(
										$author$project$SelectTimeslot$Api$getTimeslots,
										external,
										selectedTeacher,
										initModel.g0,
										$author$project$SelectTimeslot$Data$TimeslotsReceived(initModel.g0))));
						} else {
							return _Utils_Tuple2(initModel, $author$project$EffectUtils$noEffect);
						}
					}();
					var updated = _v4.a;
					var eff = _v4.b;
					return _Utils_Tuple3(
						$author$project$SelectTimeslot$Data$Initialised(
							_Utils_update(
								updated,
								{dX: subModel})),
						$author$project$EffectUtils$batchEffect(
							_List_fromArray(
								[
									A2(
									$author$project$EffectUtils$fromCmd,
									4,
									A2($elm$core$Platform$Cmd$map, $author$project$SelectTimeslot$Data$AutoCompleterMsg, subCmd)),
									eff
								])),
						$elm$core$Maybe$Nothing);
				case 9:
					var timeslot = msg.a;
					return _Utils_Tuple3(
						$author$project$SelectTimeslot$Data$Initialised(
							_Utils_update(
								initModel,
								{go: timeslot})),
						$author$project$EffectUtils$noEffect,
						$elm$core$Maybe$Nothing);
				case 8:
					return _Utils_Tuple3(
						$author$project$SelectTimeslot$Data$Uninitialised(initModel.fo),
						A2(
							$author$project$EffectUtils$fromCmd,
							0,
							A3($author$project$SelectTimeslot$Api$loadInitialData, external, initModel.fo, $author$project$SelectTimeslot$Data$InitialDataReceived)),
						$elm$core$Maybe$Nothing);
				case 6:
					if (!msg.c.$) {
						var tzKey = msg.a;
						var tzDisplayName = msg.b;
						var updatedStudent = _Utils_update(
							student,
							{
								gO: {eD: tzDisplayName, ft: tzKey}
							});
						return _Utils_Tuple3(
							$author$project$SelectTimeslot$Data$Initialised(
								_Utils_update(
									initModel,
									{eb: false})),
							A2(
								$author$project$EffectUtils$fromCmd,
								5,
								$author$project$Ports$changeStudent(
									$author$project$Student$encodeStudent(updatedStudent))),
							$elm$core$Maybe$Nothing);
					} else {
						var err = msg.c.a;
						return _Utils_Tuple3(
							$author$project$SelectTimeslot$Data$Initialised(
								_Utils_update(
									initModel,
									{eb: false})),
							A2(
								$author$project$EffectUtils$fromCmd,
								1,
								$author$project$Ports$bubbleError(
									A4(
										$author$project$Messages$httpErrorMessage,
										translate($author$project$SelectTimeslot$Blurbs$blurbs.bf),
										$elm$core$Maybe$Just(
											translate($author$project$SelectTimeslot$Blurbs$blurbs.b3)),
										'UpdateTimezone',
										err))),
							$elm$core$Maybe$Nothing);
					}
				case 5:
					var mOption = msg.a;
					var eff = A2(
						$elm$core$Maybe$withDefault,
						$author$project$EffectUtils$noEffect,
						A2(
							$elm$core$Maybe$map,
							function (_v6) {
								var k = _v6.a;
								var v = _v6.b;
								return A2(
									$author$project$EffectUtils$fromCmd,
									6,
									A2(
										$author$project$SelectTimeslot$Api$setTimezone,
										A2($author$project$SelectTimeslot$Data$UpdateTimezoneComplete, k, v),
										k));
							},
							mOption));
					return _Utils_Tuple3(
						$author$project$SelectTimeslot$Data$Initialised(initModel),
						eff,
						$elm$core$Maybe$Nothing);
				case 7:
					return _Utils_Tuple3(
						$author$project$SelectTimeslot$Data$Initialised(
							_Utils_update(
								initModel,
								{eb: true})),
						$author$project$EffectUtils$noEffect,
						$elm$core$Maybe$Nothing);
				case 11:
					return _Utils_Tuple3(
						$author$project$SelectTimeslot$Data$Initialised(
							_Utils_update(
								initModel,
								{ea: true})),
						$author$project$EffectUtils$noEffect,
						$elm$core$Maybe$Nothing);
				case 12:
					return _Utils_Tuple3(
						$author$project$SelectTimeslot$Data$Initialised(
							_Utils_update(
								initModel,
								{ea: false})),
						$author$project$EffectUtils$noEffect,
						$elm$core$Maybe$Nothing);
				case 10:
					var updated = _Utils_update(
						initModel,
						{
							gm: $author$project$SelectTimeslot$Data$AllTeachers($elm$core$Dict$empty)
						});
					return _Utils_Tuple3(
						$author$project$SelectTimeslot$Data$Initialised(
							_Utils_update(
								updated,
								{fI: true})),
						A2(
							$author$project$EffectUtils$fromCmd,
							3,
							A4(
								$author$project$SelectTimeslot$Api$getTimeslots,
								external,
								updated.gm,
								updated.g0,
								$author$project$SelectTimeslot$Data$TimeslotsReceived(updated.g0))),
						$elm$core$Maybe$Nothing);
				case 4:
					var subMsg = msg.a;
					var loadTimeslotsIfNecessary = F2(
						function (date, dateRange) {
							return A2($author$project$SelectTimeslot$Data$timeslotsLoaded, dateRange, initModel.gm) ? _Utils_Tuple2(
								_Utils_update(
									initModel,
									{bM: date, g0: dateRange}),
								$author$project$EffectUtils$noEffect) : _Utils_Tuple2(
								_Utils_update(
									initModel,
									{fI: true, bM: date, g0: dateRange}),
								A2(
									$author$project$EffectUtils$fromCmd,
									3,
									A4(
										$author$project$SelectTimeslot$Api$getTimeslots,
										external,
										initModel.gm,
										dateRange,
										$author$project$SelectTimeslot$Data$TimeslotsReceived(dateRange))));
						});
					var _v7 = A2($author$project$Components$Datepicker$update, subMsg, initModel.ez);
					var subModel = _v7.a;
					var subCmd = _v7.b;
					var pickerEvent = _v7.c;
					var _v8 = function () {
						switch (pickerEvent.$) {
							case 0:
								return _Utils_Tuple2(initModel, $author$project$EffectUtils$noEffect);
							case 1:
								var d = pickerEvent.a;
								return A2(
									loadTimeslotsIfNecessary,
									d,
									A2($author$project$SelectTimeslot$Data$posixRangeFromDate, initModel.g5, d));
							default:
								var dateRange = pickerEvent.a;
								return A2(
									loadTimeslotsIfNecessary,
									initModel.bM,
									A2($author$project$SelectTimeslot$Data$dateRangeToPosixRange, initModel.g5, dateRange));
						}
					}();
					var updated = _v8.a;
					var eff = _v8.b;
					return _Utils_Tuple3(
						$author$project$SelectTimeslot$Data$Initialised(
							_Utils_update(
								updated,
								{ez: subModel})),
						$author$project$EffectUtils$batchEffect(
							_List_fromArray(
								[
									A2(
									$author$project$EffectUtils$fromCmd,
									7,
									A2($elm$core$Platform$Cmd$map, $author$project$SelectTimeslot$Data$DatepickerMsg, subCmd)),
									eff
								])),
						$elm$core$Maybe$Nothing);
				case 0:
					var _v10 = _Utils_Tuple2(initModel.go, initModel.gm);
					if (_v10.a.$ === 1) {
						var _v11 = _v10.a;
						return _Utils_Tuple3(
							$author$project$SelectTimeslot$Data$Initialised(initModel),
							$author$project$EffectUtils$noEffect,
							$elm$core$Maybe$Nothing);
					} else {
						if (!_v10.b.$) {
							var timeslot = _v10.a.a;
							return _Utils_Tuple3(
								$author$project$SelectTimeslot$Data$Initialised(
									_Utils_update(
										initModel,
										{ek: true})),
								A2(
									$author$project$EffectUtils$fromCmd,
									8,
									A3(
										$author$project$SelectTimeslot$Api$recommendedTeachers,
										external,
										timeslot.dq.f7,
										$author$project$SelectTimeslot$Data$RecommendedTeachersReceived(timeslot))),
								$elm$core$Maybe$Nothing);
						} else {
							var timeslot = _v10.a.a;
							var _v12 = _v10.b;
							var teacher = _v12.a;
							return _Utils_Tuple3(
								$author$project$SelectTimeslot$Data$Initialised(initModel),
								$author$project$EffectUtils$noEffect,
								$elm$core$Maybe$Just(
									{gG: teacher, gJ: true, gN: timeslot, gQ: initModel.gQ, g5: initModel.g5}));
						}
					}
				case 2:
					if (!msg.b.$) {
						var timeslot = msg.a;
						var teachers = msg.b.a;
						if (!teachers.b) {
							return _Utils_Tuple3(
								$author$project$SelectTimeslot$Data$Initialised(
									_Utils_update(
										initModel,
										{ek: false})),
								A2(
									$author$project$EffectUtils$fromCmd,
									1,
									$author$project$Ports$bubbleError(
										A2(
											$author$project$Messages$simpleMessage,
											translate($author$project$SelectTimeslot$Blurbs$blurbs.bf),
											translate($author$project$SelectTimeslot$Blurbs$blurbs.bv)))),
								$elm$core$Maybe$Nothing);
						} else {
							var teacher = teachers.a;
							return _Utils_Tuple3(
								$author$project$SelectTimeslot$Data$Initialised(
									_Utils_update(
										initModel,
										{ek: false})),
								$author$project$EffectUtils$noEffect,
								$elm$core$Maybe$Just(
									{gG: teacher, gJ: false, gN: timeslot, gQ: initModel.gQ, g5: initModel.g5}));
						}
					} else {
						var err = msg.b.a;
						return _Utils_Tuple3(
							$author$project$SelectTimeslot$Data$Initialised(
								_Utils_update(
									initModel,
									{ek: false})),
							A2(
								$author$project$EffectUtils$fromCmd,
								1,
								$author$project$Ports$bubbleError(
									A4(
										$author$project$Messages$httpErrorMessage,
										translate($author$project$SelectTimeslot$Blurbs$blurbs.bf),
										$elm$core$Maybe$Just(
											translate($author$project$SelectTimeslot$Blurbs$blurbs.bA)),
										'GetRecommendedTeachers',
										err))),
							$elm$core$Maybe$Nothing);
					}
				case 3:
					if (!msg.b.$) {
						var dateRange = msg.a;
						var timeslots = msg.b.a;
						var selectedTeacher = A3($author$project$SelectTimeslot$Data$addToTimeslotCache, dateRange, timeslots, initModel.gm);
						return _Utils_Tuple3(
							$author$project$SelectTimeslot$Data$Initialised(
								_Utils_update(
									initModel,
									{
										fI: false,
										gm: selectedTeacher,
										go: A2($author$project$SelectTimeslot$Data$isTimeslotValid, initModel.go, selectedTeacher) ? initModel.go : $elm$core$Maybe$Nothing
									})),
							$author$project$EffectUtils$noEffect,
							$elm$core$Maybe$Nothing);
					} else {
						var err = msg.b.a;
						return _Utils_Tuple3(
							$author$project$SelectTimeslot$Data$Initialised(
								_Utils_update(
									initModel,
									{fI: false})),
							A2(
								$author$project$EffectUtils$fromCmd,
								1,
								$author$project$Ports$bubbleError(
									A4(
										$author$project$Messages$httpErrorMessage,
										translate($author$project$SelectTimeslot$Blurbs$blurbs.bf),
										$elm$core$Maybe$Just(
											translate($author$project$SelectTimeslot$Blurbs$blurbs.b_)),
										'GetTimeslots',
										err))),
							$elm$core$Maybe$Nothing);
					}
				default:
					return _Utils_Tuple3(
						$author$project$SelectTimeslot$Data$Initialised(initModel),
						$author$project$EffectUtils$noEffect,
						$elm$core$Maybe$Nothing);
			}
		}
	});
var $author$project$SelectTopic$Data$BubbleErrorEffect = 3;
var $author$project$SelectTopic$Data$FocusFieldEffect = 1;
var $author$project$SelectTopic$Data$LoadTopicsEffect = 2;
var $author$project$SelectTopic$Data$NoOp = {$: 0};
var $author$project$SelectTopic$Data$TopicsReceived = function (a) {
	return {$: 3, a: a};
};
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $author$project$SelectTopic$Api$loadTopicsForSelectedOption = F3(
	function (external, selectedOption, onResult) {
		var qs = function () {
			var _v0 = $author$project$Data$getLessonType(external);
			if (_v0.$ === 1) {
				var templateUnitId = _v0.a;
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'templateUnitId', templateUnitId),
						A2($elm$url$Url$Builder$string, 'selectedOption', selectedOption)
					]);
			} else {
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'selectedOption', selectedOption)
					]);
			}
		}();
		return $elm$http$Http$get(
			{
				cv: A2(
					$elm$http$Http$expectJson,
					onResult,
					$elm$json$Json$Decode$list($author$project$SelectTopic$Data$topicDecoder)),
				dJ: A2(
					$elm$url$Url$Builder$absolute,
					_Utils_ap(
						$author$project$SelectTopic$Api$apiPath,
						_List_fromArray(
							['topicsforoption'])),
					qs)
			});
	});
var $author$project$Ports$maybeBubbleError = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map($author$project$Ports$bubbleError),
	$elm$core$Maybe$withDefault($elm$core$Platform$Cmd$none));
var $author$project$SelectTopic$Data$CloseModal = {$: 1};
var $author$project$SelectTopic$Data$SelectTopic = function (a) {
	return {$: 5, a: a};
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Button$secondary = $author$project$Button$buttonProp('-secondary');
var $author$project$SelectTopic$TopicModal$view = F2(
	function (_v0, model) {
		var translate = _v0.gS;
		var _v1 = A2(
			$elm$core$Maybe$andThen,
			function ($) {
				return $.dn;
			},
			model);
		if (_v1.$ === 1) {
			return $author$project$HtmlUtils$empty;
		} else {
			var topic = _v1.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-topic-modal')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h3,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-topic-modal__header')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(topic.cW)
							])),
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-topic-modal__msg')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2($elm$core$Maybe$withDefault, '', topic.eC))
							])),
						A2(
						$author$project$Button$button,
						_List_fromArray(
							[
								$author$project$Button$class('c-topic-modal__select'),
								$author$project$Button$dark,
								$author$project$Button$onClick(
								$author$project$SelectTopic$Data$SelectTopic(topic))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								translate($author$project$SelectTopic$Blurbs$blurbs.bH))
							])),
						A2(
						$author$project$Button$button,
						_List_fromArray(
							[
								$author$project$Button$class('c-topic-modal__close'),
								$author$project$Button$secondary,
								$author$project$Button$onClick($author$project$SelectTopic$Data$CloseModal)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								translate($author$project$SelectTopic$Blurbs$blurbs.a4))
							]))
					]));
		}
	});
var $author$project$Messages$webDataErrorMessage = F4(
	function (displayTitle, displayMessage, context, data) {
		if (data.$ === 2) {
			var err = data.a;
			return $elm$core$Maybe$Just(
				A4($author$project$Messages$httpErrorMessage, displayTitle, displayMessage, context, err));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$SelectTopic$Control$update = F3(
	function (external, msg, model) {
		var translate = external.gS;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(model, $author$project$EffectUtils$noEffect, $elm$core$Maybe$Nothing);
			case 8:
				var topic = msg.a;
				var topic_ = ($elm$core$String$length(topic) > 0) ? $elm$core$Maybe$Just(topic) : $elm$core$Maybe$Nothing;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ah: topic_}),
					$author$project$EffectUtils$noEffect,
					$elm$core$Maybe$Nothing);
			case 7:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ah: $elm$core$Maybe$Nothing, bD: true}),
					A2(
						$author$project$EffectUtils$fromCmd,
						1,
						A2(
							$elm$core$Task$attempt,
							function (_v1) {
								return $author$project$SelectTopic$Data$NoOp;
							},
							$elm$browser$Browser$Dom$focus('custom_topic'))),
					$elm$core$Maybe$Nothing);
			case 6:
				var topic = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							dn: $elm$core$Maybe$Just(topic)
						}),
					$author$project$EffectUtils$openModal($author$project$SelectTopic$TopicModal$view),
					$elm$core$Maybe$Nothing);
			case 5:
				var topic = msg.a;
				var updated = function () {
					var _v2 = topic.fh;
					if (!_v2.$) {
						return _Utils_update(
							model,
							{ah: $elm$core$Maybe$Nothing, bD: false});
					} else {
						return model;
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						updated,
						{
							di: $elm$core$Maybe$Just(topic)
						}),
					$author$project$EffectUtils$noEffect,
					$elm$core$Maybe$Just(topic));
			case 1:
				return _Utils_Tuple3(model, $author$project$EffectUtils$closeModal, $elm$core$Maybe$Nothing);
			case 4:
				var pair = msg.a;
				var updated = A2(
					$elm$core$Maybe$withDefault,
					model,
					A2(
						$elm$core$Maybe$map,
						function (_v4) {
							var k = _v4.a;
							return _Utils_update(
								model,
								{
									aJ: $elm$core$Maybe$Just(k)
								});
						},
						pair));
				var eff = function () {
					var _v3 = updated.aJ;
					if (!_v3.$) {
						var o = _v3.a;
						return A2(
							$author$project$EffectUtils$fromCmd,
							2,
							A3($author$project$SelectTopic$Api$loadTopicsForSelectedOption, external, o, $author$project$SelectTopic$Data$TopicsReceived));
					} else {
						return $author$project$EffectUtils$noEffect;
					}
				}();
				return _Utils_Tuple3(updated, eff, $elm$core$Maybe$Nothing);
			case 3:
				if (!msg.a.$) {
					var topics = msg.a.a;
					var _v5 = model.ai;
					if (_v5.$ === 3) {
						var data = _v5.a;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									ai: $krisajenkins$remotedata$RemoteData$Success(
										_Utils_update(
											data,
											{gR: topics}))
								}),
							$author$project$EffectUtils$noEffect,
							$elm$core$Maybe$Nothing);
					} else {
						return _Utils_Tuple3(model, $author$project$EffectUtils$noEffect, $elm$core$Maybe$Nothing);
					}
				} else {
					var err = msg.a.a;
					return _Utils_Tuple3(
						model,
						A2(
							$author$project$EffectUtils$fromCmd,
							3,
							$author$project$Ports$bubbleError(
								A4(
									$author$project$Messages$httpErrorMessage,
									translate($author$project$SelectTopic$Blurbs$blurbs.bf),
									$elm$core$Maybe$Just(
										translate($author$project$SelectTopic$Blurbs$blurbs.b0)),
									'GetTopicsForOption',
									err))),
						$elm$core$Maybe$Nothing);
				}
			default:
				var data = msg.a;
				var _v6 = function () {
					switch (data.$) {
						case 3:
							var data_ = data.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{aJ: data_.et}),
								$author$project$EffectUtils$noEffect);
						case 2:
							return _Utils_Tuple2(
								model,
								A2(
									$author$project$EffectUtils$fromCmd,
									3,
									$author$project$Ports$maybeBubbleError(
										A4(
											$author$project$Messages$webDataErrorMessage,
											translate($author$project$SelectTopic$Blurbs$blurbs.bf),
											$elm$core$Maybe$Just(
												translate($author$project$SelectTopic$Blurbs$blurbs.bz)),
											'GetSelectTopicData',
											data))));
						default:
							return _Utils_Tuple2(model, $author$project$EffectUtils$noEffect);
					}
				}();
				var updated = _v6.a;
				var eff = _v6.b;
				return _Utils_Tuple3(
					_Utils_update(
						updated,
						{ai: data}),
					eff,
					$elm$core$Maybe$Nothing);
		}
	});
var $author$project$SelectTimeslot$Control$updateTopic = F2(
	function (topic, model) {
		if (!model.$) {
			return _Utils_Tuple2(model, $author$project$EffectUtils$noEffect);
		} else {
			var initModel = model.a;
			return _Utils_Tuple2(
				$author$project$SelectTimeslot$Data$Initialised(
					_Utils_update(
						initModel,
						{gQ: topic})),
				$author$project$EffectUtils$noEffect);
		}
	});
var $author$project$Control$update = F3(
	function (external, msg, model) {
		var student = external.gy;
		switch (msg.$) {
			case 8:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$EffectUtils$fromCmd,
						1,
						$author$project$Ports$changeUrl(external.f4.ff)));
			case 9:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$EffectUtils$fromCmd,
						1,
						$author$project$Ports$changeUrl(external.f4.cl)));
			case 7:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Announcement$update, subMsg, model.dU);
				var subModel = _v1.a;
				var subEff = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dU: subModel}),
					A4(
						$author$project$EffectUtils$mapEffect,
						$author$project$Data$AnnouncementMsg,
						function ($) {
							return $.dU;
						},
						2,
						subEff));
			case 6:
				if (!msg.a.$) {
					var data = msg.a.a;
					var lessonType = $author$project$Data$getLessonType(external);
					var unlicensed = function () {
						if (lessonType.$ === 1) {
							return _Utils_eq(student.e6.gU, $elm$core$Maybe$Nothing);
						} else {
							return _Utils_eq(student.e6.ga, $elm$core$Maybe$Nothing);
						}
					}();
					var zeroCredit = !function () {
						if (lessonType.$ === 1) {
							return $author$project$Student$hasGrantOrRemainingCredit(student.e6.gU);
						} else {
							return $author$project$Student$hasGrantOrRemainingCredit(student.e6.ga);
						}
					}();
					var countBookingsBy = function (pred) {
						return $elm$core$List$length(
							A2($elm$core$List$filter, pred, data.d2));
					};
					var numberOfPrivateBookings = countBookingsBy(
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.fs;
							},
							$elm$core$Basics$not));
					var numberOfUnitReviews = countBookingsBy(
						function ($) {
							return $.fs;
						});
					var maxBookings = (_Utils_cmp(numberOfPrivateBookings, data.fO) > -1) || (_Utils_cmp(numberOfUnitReviews, data.fP) > -1);
					var _v2 = function () {
						if (unlicensed) {
							return _Utils_Tuple2(
								_Utils_Tuple2(model, $author$project$EffectUtils$noEffect),
								_Utils_Tuple2(model.l, 6));
						} else {
							if (zeroCredit) {
								return _Utils_Tuple2(
									_Utils_Tuple2(model, $author$project$EffectUtils$noEffect),
									_Utils_Tuple2(model.l, 5));
							} else {
								if (maxBookings) {
									return _Utils_Tuple2(
										_Utils_Tuple2(model, $author$project$EffectUtils$noEffect),
										_Utils_Tuple2(model.l, 4));
								} else {
									if (lessonType.$ === 1) {
										var templateUnitId = lessonType.a;
										var _v6 = A2(
											$author$project$SelectTimeslot$Control$init,
											external,
											$author$project$SelectTimeslot$Data$TopicFromUnit(templateUnitId));
										var subModel = _v6.a;
										var subEff = _v6.b;
										return _Utils_Tuple2(
											_Utils_Tuple2(
												_Utils_update(
													model,
													{
														w: $elm$core$Maybe$Just(subModel)
													}),
												A4(
													$author$project$EffectUtils$mapEffect,
													$author$project$Data$SelectTimeslotMsg,
													function ($) {
														return $.w;
													},
													4,
													subEff)),
											_Utils_Tuple2(
												A2(
													$author$project$Components$ProgressIndicator$init,
													1,
													_List_fromArray(
														[
															_Utils_Tuple2($author$project$Blurbs$blurbs.bL, false),
															_Utils_Tuple2($author$project$Blurbs$blurbs.bJ, true),
															_Utils_Tuple2($author$project$Blurbs$blurbs.a5, true)
														])),
												1));
									} else {
										var _v7 = $author$project$SelectTopic$Control$init(external);
										var subModel = _v7.a;
										var subEff = _v7.b;
										return _Utils_Tuple2(
											_Utils_Tuple2(
												_Utils_update(
													model,
													{
														bL: $elm$core$Maybe$Just(subModel)
													}),
												A4(
													$author$project$EffectUtils$mapEffect,
													$author$project$Data$SelectTopicMsg,
													function ($) {
														return $.bL;
													},
													3,
													subEff)),
											_Utils_Tuple2(
												A2(
													$author$project$Components$ProgressIndicator$init,
													0,
													_List_fromArray(
														[
															_Utils_Tuple2($author$project$Blurbs$blurbs.bL, true),
															_Utils_Tuple2($author$project$Blurbs$blurbs.bJ, true),
															_Utils_Tuple2($author$project$Blurbs$blurbs.a5, true)
														])),
												0));
									}
								}
							}
						}
					}();
					var _v3 = _v2.a;
					var updatedModel = _v3.a;
					var eff = _v3.b;
					var _v4 = _v2.b;
					var progress = _v4.a;
					var subView = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							updatedModel,
							{
								ai: $elm$core$Maybe$Just(data),
								l: progress,
								D: subView
							}),
						eff);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$EffectUtils$fromCmd,
							6,
							$author$project$Ports$bubbleError(
								A4(
									$author$project$Messages$httpErrorMessage,
									external.gS($author$project$Blurbs$blurbs.bf),
									$elm$core$Maybe$Just(
										external.gS($author$project$Blurbs$blurbs.bj)),
									'GetInitialData',
									err))));
				}
			case 5:
				return _Utils_Tuple2(model, $author$project$EffectUtils$noEffect);
			case 4:
				var subMsg = msg.a;
				var _v10 = A2($author$project$Components$ProgressIndicator$update, subMsg, model.l);
				var subModel = _v10.a;
				var subCmd = _v10.b;
				var idx = _v10.c;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							l: subModel,
							D: A3($author$project$Control$navigateBackTo, external, model.D, idx)
						}),
					A2(
						$author$project$EffectUtils$fromCmd,
						7,
						A2($elm$core$Platform$Cmd$map, $author$project$Data$ProgressMsg, subCmd)));
			case 3:
				return $author$project$Control$init(external);
			case 10:
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$EffectUtils$fromCmd,
						8,
						$author$project$Api$reloadBookings($author$project$Data$BookingsReceived)));
			case 11:
				if (!msg.a.$) {
					var bookings = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ai: A2(
									$elm$core$Maybe$map,
									function (data) {
										return _Utils_update(
											data,
											{d2: bookings});
									},
									model.ai)
							}),
						$author$project$EffectUtils$noEffect);
				} else {
					var err = msg.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$EffectUtils$fromCmd,
							6,
							$author$project$Ports$bubbleError(
								A4(
									$author$project$Messages$httpErrorMessage,
									external.gS($author$project$Blurbs$blurbs.bf),
									$elm$core$Maybe$Just(
										external.gS($author$project$Blurbs$blurbs.bj)),
									'ReloadBookings',
									err))));
				}
			case 0:
				var subMsg = msg.a;
				var _v11 = model.bL;
				if (!_v11.$) {
					var subModel = _v11.a;
					var _v12 = A3($author$project$SelectTopic$Control$update, external, subMsg, subModel);
					var topicModel = _v12.a;
					var topicEff = _v12.b;
					var maybeTopic = _v12.c;
					var _v13 = function () {
						if (maybeTopic.$ === 1) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bL: $elm$core$Maybe$Just(topicModel)
									}),
								A4(
									$author$project$EffectUtils$mapEffect,
									$author$project$Data$SelectTopicMsg,
									function ($) {
										return $.bL;
									},
									3,
									topicEff));
						} else {
							var topic = maybeTopic.a;
							var _v15 = function () {
								var _v16 = model.w;
								if (!_v16.$) {
									var ts = _v16.a;
									return A2($author$project$SelectTimeslot$Control$updateTopic, topic, ts);
								} else {
									return A2(
										$author$project$SelectTimeslot$Control$init,
										external,
										$author$project$SelectTimeslot$Data$TopicSelected(topic));
								}
							}();
							var timeslotModel = _v15.a;
							var timeslotEff = _v15.b;
							var _v17 = A2($author$project$Components$ProgressIndicator$navigate, 1, model.l);
							var progressModel = _v17.a;
							var progressCmd = _v17.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										l: progressModel,
										w: $elm$core$Maybe$Just(timeslotModel),
										bL: $elm$core$Maybe$Just(topicModel),
										D: 1
									}),
								$author$project$EffectUtils$batchEffect(
									_List_fromArray(
										[
											A4(
											$author$project$EffectUtils$mapEffect,
											$author$project$Data$SelectTopicMsg,
											function ($) {
												return $.bL;
											},
											3,
											topicEff),
											A4(
											$author$project$EffectUtils$mapEffect,
											$author$project$Data$SelectTimeslotMsg,
											function ($) {
												return $.w;
											},
											4,
											timeslotEff),
											A2(
											$author$project$EffectUtils$fromCmd,
											7,
											A2($elm$core$Platform$Cmd$map, $author$project$Data$ProgressMsg, progressCmd)),
											$author$project$EffectUtils$closeModal
										])));
						}
					}();
					var updated = _v13.a;
					var eff = _v13.b;
					return _Utils_Tuple2(updated, eff);
				} else {
					return _Utils_Tuple2(model, $author$project$EffectUtils$noEffect);
				}
			case 1:
				var subMsg = msg.a;
				var _v18 = model.w;
				if (!_v18.$) {
					var subModel = _v18.a;
					var _v19 = A3($author$project$SelectTimeslot$Control$update, external, subMsg, subModel);
					var timeslotModel = _v19.a;
					var timeslotEff = _v19.b;
					var maybeResult = _v19.c;
					var _v20 = function () {
						if (maybeResult.$ === 1) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										w: $elm$core$Maybe$Just(timeslotModel)
									}),
								A4(
									$author$project$EffectUtils$mapEffect,
									$author$project$Data$SelectTimeslotMsg,
									function ($) {
										return $.w;
									},
									4,
									timeslotEff));
						} else {
							var topic = maybeResult.a.gQ;
							var teacher = maybeResult.a.gG;
							var timeslot = maybeResult.a.gN;
							var zone = maybeResult.a.g5;
							var teacherSelectedByStudent = maybeResult.a.gJ;
							var _v22 = A2($author$project$Components$ProgressIndicator$navigate, 2, model.l);
							var progressModel = _v22.a;
							var progressCmd = _v22.b;
							var _v23 = A7(
								$author$project$Confirm$Control$init,
								external,
								$author$project$Data$getVideoOptions(model),
								topic,
								teacher,
								timeslot,
								teacherSelectedByStudent,
								zone);
							var confirmModel = _v23.a;
							var confirmEff = _v23.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										t: $elm$core$Maybe$Just(confirmModel),
										l: progressModel,
										w: $elm$core$Maybe$Just(timeslotModel),
										D: 2
									}),
								$author$project$EffectUtils$batchEffect(
									_List_fromArray(
										[
											A4(
											$author$project$EffectUtils$mapEffect,
											$author$project$Data$ConfirmMsg,
											function ($) {
												return $.t;
											},
											5,
											confirmEff),
											A2(
											$author$project$EffectUtils$fromCmd,
											7,
											A2($elm$core$Platform$Cmd$map, $author$project$Data$ProgressMsg, progressCmd))
										])));
						}
					}();
					var updated = _v20.a;
					var eff = _v20.b;
					return _Utils_Tuple2(updated, eff);
				} else {
					return _Utils_Tuple2(model, $author$project$EffectUtils$noEffect);
				}
			default:
				var subMsg = msg.a;
				var _v24 = model.t;
				if (!_v24.$) {
					var subModel = _v24.a;
					var _v25 = A4(
						$author$project$Confirm$Control$update,
						external,
						$author$project$Data$getVideoOptions(model),
						subMsg,
						subModel);
					var confirmModel = _v25.a;
					var confirmEff = _v25.b;
					var evt = _v25.c;
					var mappedEff = A4(
						$author$project$EffectUtils$mapEffect,
						$author$project$Data$ConfirmMsg,
						function ($) {
							return $.t;
						},
						5,
						confirmEff);
					switch (evt.$) {
						case 1:
							var _v27 = A2($author$project$Components$ProgressIndicator$navigate, 1, model.l);
							var progressModel = _v27.a;
							var progressCmd = _v27.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										t: $elm$core$Maybe$Just(confirmModel),
										l: progressModel,
										D: 1
									}),
								$author$project$EffectUtils$batchEffect(
									_List_fromArray(
										[
											A2(
											$author$project$EffectUtils$fromCmd,
											7,
											A2($elm$core$Platform$Cmd$map, $author$project$Data$ProgressMsg, progressCmd)),
											mappedEff
										])));
						case 2:
							var _v28 = A2($author$project$Components$ProgressIndicator$navigate, 0, model.l);
							var progressModel = _v28.a;
							var progressCmd = _v28.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										t: $elm$core$Maybe$Just(confirmModel),
										l: progressModel,
										D: 0
									}),
								$author$project$EffectUtils$batchEffect(
									_List_fromArray(
										[
											A2(
											$author$project$EffectUtils$fromCmd,
											7,
											A2($elm$core$Platform$Cmd$map, $author$project$Data$ProgressMsg, progressCmd)),
											mappedEff
										])));
						case 3:
							var updatedStudent = evt.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										t: $elm$core$Maybe$Just(confirmModel)
									}),
								$author$project$EffectUtils$batchEffect(
									_List_fromArray(
										[
											A2(
											$author$project$EffectUtils$fromCmd,
											9,
											$author$project$Ports$changeStudent(
												$author$project$Student$encodeStudent(updatedStudent))),
											A2(
											$author$project$EffectUtils$fromCmd,
											1,
											$author$project$Ports$changeUrl(external.f4.cl)),
											mappedEff
										])));
						case 4:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aX: true,
										t: $elm$core$Maybe$Just(confirmModel)
									}),
								mappedEff);
						case 5:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aX: false,
										t: $elm$core$Maybe$Just(confirmModel)
									}),
								mappedEff);
						default:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										t: $elm$core$Maybe$Just(confirmModel)
									}),
								mappedEff);
					}
				} else {
					return _Utils_Tuple2(model, $author$project$EffectUtils$noEffect);
				}
		}
	});
var $author$project$Announcement$Toggle = {$: 0};
var $author$project$Announcement$view = F4(
	function (showTxt, hideTxt, open, importantInfo) {
		if (!importantInfo.$) {
			var info = importantInfo.a;
			var _v1 = A2($elm$core$String$split, '$$@@||', info);
			if ((_v1.b && _v1.b.b) && (!_v1.b.b.b)) {
				var title = _v1.a;
				var _v2 = _v1.b;
				var body = _v2.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-announcement'),
							$author$project$CssUtils$qa('announcement')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-announcement__title'),
									$author$project$CssUtils$qa('announcement_title')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(
											open ? '' : title)
										])),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$Announcement$Toggle),
											$elm$html$Html$Attributes$class('c-announcement__toggle'),
											$author$project$CssUtils$qa('announcement_toggle')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											open ? hideTxt : showTxt)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-announcement__body'),
									$author$project$CssUtils$qa('announcement_body'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('-is-closed', !open)
										]))
								]),
							$author$project$HtmlUtils$textHtml(body))
						]));
			} else {
				return $author$project$HtmlUtils$empty;
			}
		} else {
			return $author$project$HtmlUtils$empty;
		}
	});
var $author$project$HtmlUtils$when = F2(
	function (fn, a) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$HtmlUtils$empty,
			A2($elm$core$Maybe$map, fn, a));
	});
var $author$project$View$View$announcement = F2(
	function (_v0, model) {
		var translate = _v0.gS;
		var _v1 = model.D;
		switch (_v1) {
			case 3:
				return $author$project$HtmlUtils$empty;
			case 4:
				return $author$project$HtmlUtils$empty;
			case 5:
				return $author$project$HtmlUtils$empty;
			case 6:
				return $author$project$HtmlUtils$empty;
			default:
				return A2(
					$author$project$HtmlUtils$when,
					function (data) {
						return A2(
							$elm$html$Html$map,
							$author$project$Data$AnnouncementMsg,
							A4(
								$author$project$Announcement$view,
								translate($author$project$Blurbs$blurbs.bO),
								translate($author$project$Blurbs$blurbs.bi),
								model.dU,
								data.dU));
					},
					model.ai);
		}
	});
var $author$project$Data$GoHome = {$: 8};
var $author$project$Data$GoToCurrentBookings = {$: 9};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $author$project$View$View$cannotBook = F2(
	function (_v0, reason) {
		var translate = _v0.gS;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-cannot-book'),
					$author$project$CssUtils$qa('cannot_book')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-cannot-book__title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							translate($author$project$Blurbs$blurbs.bS))
						])),
					A2(
					$elm$html$Html$h2,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-cannot-book__info'),
							$author$project$CssUtils$qa('cannot_book_reason')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							translate(reason))
						])),
					A2(
					$author$project$Button$button,
					_List_fromArray(
						[
							$author$project$Button$dark,
							$author$project$Button$onClick($author$project$Data$GoHome),
							$author$project$Button$class('c-cannot-book__home'),
							$author$project$Button$qa('cannot_book_home')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							translate($author$project$Blurbs$blurbs.bV))
						])),
					A2(
					$author$project$Button$button,
					_List_fromArray(
						[
							$author$project$Button$secondary,
							$author$project$Button$onClick($author$project$Data$GoToCurrentBookings),
							$author$project$Button$qa('cannot_book_bookings')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							translate($author$project$Blurbs$blurbs.bg))
						]))
				]));
	});
var $author$project$SelectTimeslot$View$View$loadingView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('c-timeslot-page__content'),
			$author$project$CssUtils$qa('loading_view')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-timeslot-page__col -col1 -is-loading')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-timeslot-page__col -col2 -is-loading')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-timeslot-page__col -col3 -is-loading')
				]),
			_List_Nil)
		]));
var $author$project$SelectTopic$View$loadingView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('c-select-topic -is-loading'),
			$author$project$CssUtils$qa('loading_view')
		]),
	_List_Nil);
var $author$project$Confirm$Data$Cancel = {$: 1};
var $author$project$Confirm$Data$ChangeTimeslot = {$: 7};
var $author$project$Confirm$Data$ChangeTopic = {$: 8};
var $author$project$Confirm$Data$MakeBooking = {$: 2};
var $author$project$Confirm$Data$ToggleClassRecording = {$: 10};
var $author$project$ListUtils$appendIf = F3(
	function (pred, x, xs) {
		return pred ? _Utils_ap(
			xs,
			_List_fromArray(
				[x])) : xs;
	});
var $author$project$Button$NullProp = {$: 1};
var $author$project$Button$boolProp = F2(
	function (p, b) {
		return b ? p : $author$project$Button$NullProp;
	});
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $author$project$Button$disabled = $author$project$Button$boolProp(
	$author$project$Button$ButtonProp(
		$elm$html$Html$Attributes$disabled(true)));
var $author$project$Button$filled = $author$project$Button$buttonProp('-filled');
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$fillRule = _VirtualDom_attribute('fill-rule');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $author$project$Icons$Bilingual$icon = function (cls) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$viewBox('0 0 21 20'),
				$elm$svg$Svg$Attributes$width('16'),
				$elm$svg$Svg$Attributes$height('16'),
				$elm$svg$Svg$Attributes$class(cls)
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('#191919'),
						$elm$svg$Svg$Attributes$transform('translate(-130 -77)'),
						$elm$svg$Svg$Attributes$fillRule('evenodd')
					]),
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d('M148.563 88.375c.241 0 .437.196.437.437v6.125a.437.437 0 01-.438.438h-.437v2.187a.437.437 0 01-.774.28l-2.057-2.467h-5.919a.437.437 0 01-.438-.438v-1.75h6.782a.656.656 0 00.656-.656v-4.156zm-3.938-3.5c.242 0 .437.196.437.437v6.126a.437.437 0 01-.437.437h-5.92l-2.056 2.468a.437.437 0 01-.774-.28v-2.188h-.438a.437.437 0 01-.437-.437v-6.126c0-.241.196-.437.437-.437z')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Icons$CalendarWhite$icon = function (cls) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('14'),
				$elm$svg$Svg$Attributes$height('14'),
				$elm$svg$Svg$Attributes$class(cls)
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M12.688 2.187h-2.626v-.875a.437.437 0 00-.437-.437h-.437a.437.437 0 00-.438.437v.875h-3.5v-.875a.437.437 0 00-.438-.437h-.437a.437.437 0 00-.438.437v.875H1.312a.437.437 0 00-.437.438v10.063c0 .241.196.437.437.437h11.375a.437.437 0 00.438-.438V2.626a.437.437 0 00-.438-.438zM4.374 4.812h.437a.438.438 0 00.438-.437V3.5h3.5v.875c0 .242.196.437.437.437h.438a.437.437 0 00.438-.437V3.5h1.75v2.187H2.187V3.5h1.75v.875c0 .242.196.437.438.437zM2.187 7h9.625v4.813H2.188V7z'),
						$elm$svg$Svg$Attributes$fill('#191919'),
						$elm$svg$Svg$Attributes$fillRule('evenodd')
					]),
				_List_Nil)
			]));
};
var $author$project$Icons$ClockBlack$icon = function (cls) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('16'),
				$elm$svg$Svg$Attributes$height('16'),
				$elm$svg$Svg$Attributes$class(cls)
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M8 0a8 8 0 100 16A8 8 0 008 0zm3.53 11.175l-.355.355a.5.5 0 01-.705 0L7.395 8.455a.5.5 0 01-.135-.405V2a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v5.69l2.78 2.78a.5.5 0 01-.01.705z'),
						$elm$svg$Svg$Attributes$fill('#191919'),
						$elm$svg$Svg$Attributes$fillRule('evenodd')
					]),
				_List_Nil)
			]));
};
var $author$project$Icons$ClockHollow$icon = function (cls) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('14'),
				$elm$svg$Svg$Attributes$height('14'),
				$elm$svg$Svg$Attributes$class(cls)
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('#191919'),
						$elm$svg$Svg$Attributes$fillRule('nonzero')
					]),
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d('M7 0a7 7 0 100 14A7 7 0 007 0zm0 12.688A5.688 5.688 0 117 1.312a5.688 5.688 0 010 11.376z')
							]),
						_List_Nil),
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d('M7.656 6.729V2.625a.437.437 0 00-.437-.438H6.78a.437.437 0 00-.437.438v4.419c-.016.13.028.26.118.354l2.537 2.538a.438.438 0 00.617 0l.31-.311a.437.437 0 000-.617l-2.27-2.28z')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Button$loading = $author$project$Button$boolProp(
	$author$project$Button$buttonProp('-is-loading'));
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$StringUtils$replaceToken = F2(
	function (needle, replaceWith) {
		return A2($elm$core$String$replace, needle, replaceWith);
	});
var $author$project$StringUtils$replaceNamedToken = F2(
	function (name, replaceWith) {
		return A2($author$project$StringUtils$replaceToken, '{{' + (name + '}}'), replaceWith);
	});
var $author$project$Confirm$Data$ShowTerms = {$: 4};
var $author$project$Confirm$Data$TermsLink = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $author$project$Confirm$Data$termsLinkParser = function (_v0) {
	var translate = _v0.gS;
	var tandc = '^termsAndConditions^';
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed($author$project$Confirm$Data$TermsLink),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getChompedString(
						$elm$parser$Parser$chompUntil(tandc)),
					$elm$parser$Parser$symbol(tandc))),
			$elm$parser$Parser$succeed(
				translate($author$project$Confirm$Blurbs$blurbs.bW))),
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompWhile(
				function (_v1) {
					return true;
				})));
};
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Confirm$Data$parseTermsLink = function (external) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$parser$Parser$run(
			$author$project$Confirm$Data$termsLinkParser(external)),
		$elm$core$Result$toMaybe);
};
var $author$project$Confirm$View$termsAndConditionsLink = function (external) {
	var translate = external.gS;
	var _v0 = A2(
		$author$project$Confirm$Data$parseTermsLink,
		external,
		translate($author$project$Confirm$Blurbs$blurbs.bY));
	if (_v0.$ === 1) {
		return $author$project$HtmlUtils$empty;
	} else {
		var _v1 = _v0.a;
		var before = _v1.a;
		var link = _v1.b;
		var after = _v1.c;
		return A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-booking__terms')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(before),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-booking__termslink'),
							$author$project$CssUtils$qa('confirm_terms'),
							$elm$html$Html$Events$onClick($author$project$Confirm$Data$ShowTerms)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(link)
						])),
					$elm$html$Html$text(after)
				]));
	}
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$Forms$Checkbox$partition = A2(
	$elm$core$List$foldr,
	F2(
		function (p, _v0) {
			var lps = _v0.a;
			var ips = _v0.b;
			switch (p.$) {
				case 0:
					var a = p.a;
					return _Utils_Tuple2(
						A2($elm$core$List$cons, a, lps),
						ips);
				case 1:
					var a = p.a;
					return _Utils_Tuple2(
						lps,
						A2($elm$core$List$cons, a, ips));
				default:
					return _Utils_Tuple2(lps, ips);
			}
		}),
	_Utils_Tuple2(_List_Nil, _List_Nil));
var $author$project$Forms$Checkbox$checkbox = F2(
	function (props, content) {
		var _v0 = $author$project$Forms$Checkbox$partition(props);
		var labelProps = _v0.a;
		var inputProps = _v0.b;
		return A2(
			$elm$html$Html$label,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-input-w c-boolean')
					]),
				labelProps),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Attributes$class('c-boolean__input')
							]),
						inputProps),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-boolean__element -checkbox')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-boolean__label')
						]),
					content)
				]));
	});
var $author$project$Forms$Checkbox$InputProp = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $author$project$Forms$Checkbox$checked = A2($elm$core$Basics$composeL, $author$project$Forms$Checkbox$InputProp, $elm$html$Html$Attributes$checked);
var $author$project$Forms$Checkbox$NullProp = {$: 2};
var $author$project$Forms$Checkbox$boolProp = F2(
	function (p, b) {
		return b ? p : $author$project$Forms$Checkbox$NullProp;
	});
var $author$project$Forms$Checkbox$disabled = $author$project$Forms$Checkbox$boolProp(
	A2($elm$core$Basics$composeL, $author$project$Forms$Checkbox$InputProp, $elm$html$Html$Attributes$disabled)(true));
var $author$project$Icons$InfoIcon$icon = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 16 16')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$fill('#191919'),
					$elm$svg$Svg$Attributes$fillRule('evenodd')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M8 0a8 8 0 100 16A8 8 0 008 0zm0 14.5a6.5 6.5 0 110-13 6.5 6.5 0 010 13z'),
							$elm$svg$Svg$Attributes$fillRule('nonzero')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M8 9.5a.75.75 0 00.75-.75v-5a.75.75 0 00-1.5 0v5c0 .414.336.75.75.75zM8 13a1 1 0 100-2 1 1 0 000 2z')
						]),
					_List_Nil)
				]))
		]));
var $author$project$Forms$Checkbox$onClick = A2($elm$core$Basics$composeL, $author$project$Forms$Checkbox$InputProp, $elm$html$Html$Events$onClick);
var $author$project$Forms$Checkbox$qa = A2($elm$core$Basics$composeL, $author$project$Forms$Checkbox$InputProp, $author$project$CssUtils$qa);
var $author$project$ClassRecordingToggle$view = F5(
	function (label, tooltip, checked, disabled, onToggle) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-class-recording__checkbox')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Forms$Checkbox$checkbox,
					_List_fromArray(
						[
							$author$project$Forms$Checkbox$onClick(onToggle),
							$author$project$Forms$Checkbox$checked(checked),
							$author$project$Forms$Checkbox$disabled(disabled),
							$author$project$Forms$Checkbox$qa('send_class_recording')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-class-recording__tooltip-w'),
							$author$project$CssUtils$qa('send_video_tooltip')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-class-recording__tooltip-trigger')
								]),
							_List_fromArray(
								[$author$project$Icons$InfoIcon$icon])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-class-recording__tooltip-content c-tooltip -large')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(tooltip)
								]))
						]))
				]));
	});
var $author$project$Confirm$View$view = F3(
	function (external, videoOptions, model) {
		var translate = external.gS;
		var student = external.gy;
		var duration = $elm$core$String$fromFloat(model.gN.dy);
		var bilingual = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function (b) {
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-booking__tooltip-trigger')
								]),
							_List_fromArray(
								[
									$author$project$Icons$Bilingual$icon('c-booking__bilingual-icon'),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-booking__bilingual'),
											$author$project$CssUtils$qa('confirm_bilingualism')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											translate($author$project$Confirm$Blurbs$blurbs.aW))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-booking__tooltip-content c-tooltip')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(b)
								]))
						]);
				},
				model.gG.dZ));
		var _v0 = _Utils_Tuple2(model.gN.dq.eZ, model.gN.dq.e_);
		var dateText = _v0.a;
		var timeText = _v0.b;
		var _v1 = function () {
			var _v2 = $author$project$Data$getLessonType(external);
			if (_v2.$ === 1) {
				return _Utils_Tuple2(
					$author$project$Student$getGrantOrRemainingCredit(student.e6.gU),
					false);
			} else {
				return _Utils_Tuple2(
					$author$project$Student$getGrantOrRemainingCredit(student.e6.ga),
					true);
			}
		}();
		var grantOrCredit = _v1.a;
		var canChangeTopic = _v1.b;
		var remainingCreditsMsg = function () {
			if (grantOrCredit.$ === 1) {
				var n = grantOrCredit.a;
				return $elm$core$Maybe$Just(
					A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-confirm-page__remaining-credit'),
								$author$project$CssUtils$qa('confirm_credit')
							]),
						$author$project$HtmlUtils$textHtml(
							A3(
								$author$project$StringUtils$replaceNamedToken,
								'remainingCredits',
								$elm$core$String$fromFloat(n),
								translate($author$project$Confirm$Blurbs$blurbs.aV)))));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-confirm-page__content')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-confirm-page__col -col1')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-confirm-page__header'),
									$author$project$CssUtils$qa('confirm_title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$Confirm$Blurbs$blurbs.a5))
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('u-mb-5'),
									$author$project$CssUtils$qa('confirm_subtitle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$Confirm$Blurbs$blurbs.bF))
								])),
							videoOptions.ee ? A5(
							$author$project$ClassRecordingToggle$view,
							translate($author$project$Confirm$Blurbs$blurbs.gq),
							translate($author$project$Confirm$Blurbs$blurbs.b5),
							model.gq,
							model.cf,
							$author$project$Confirm$Data$ToggleClassRecording) : $author$project$HtmlUtils$empty,
							A2(
							$author$project$Button$button,
							_List_fromArray(
								[
									$author$project$Button$dark,
									$author$project$Button$class('c-confirm-page__book'),
									$author$project$Button$onClick($author$project$Confirm$Data$MakeBooking),
									$author$project$Button$loading(model.cf),
									$author$project$Button$disabled(model.cf),
									$author$project$Button$qa('confirm_book')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$Confirm$Blurbs$blurbs.aZ))
								])),
							A2(
							$author$project$Button$button,
							_List_fromArray(
								[
									$author$project$Button$secondary,
									$author$project$Button$filled,
									$author$project$Button$class('c-confirm-page__change'),
									$author$project$Button$onClick($author$project$Confirm$Data$Cancel),
									$author$project$Button$disabled(model.cf),
									$author$project$Button$qa('confirm_cancel')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$Confirm$Blurbs$blurbs.a0))
								])),
							function () {
							if (!remainingCreditsMsg.$) {
								var msg = remainingCreditsMsg.a;
								return msg;
							} else {
								return $author$project$HtmlUtils$empty;
							}
						}(),
							$author$project$Confirm$View$termsAndConditionsLink(external)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-confirm-page__col -col2')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-booking-w')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-booking')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('c-booking__attrs c-booking__tooltip-w')
												]),
											_Utils_ap(
												_List_fromArray(
													[
														$author$project$Icons$ClockBlack$icon('c-booking__duration-icon'),
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('c-booking__duration'),
																$author$project$CssUtils$qa('confirm_duration')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(duration + ' mins')
															]))
													]),
												bilingual)),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('c-booking__name-w')
												]),
											A3(
												$author$project$ListUtils$appendIf,
												canChangeTopic,
												A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('c-booking__change'),
															$author$project$CssUtils$qa('confirm_change_topic'),
															$elm$html$Html$Attributes$disabled(model.cf),
															$elm$html$Html$Events$onClick($author$project$Confirm$Data$ChangeTopic)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															translate($author$project$Confirm$Blurbs$blurbs.a1))
														])),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('c-booking__name'),
																$author$project$CssUtils$qa('confirm_topic')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(model.gQ.cW)
															]))
													]))),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('c-booking__datetime-row')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('c-booking__tooltip-w')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('c-booking__datetime c-booking__tooltip-trigger')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('c-booking__date')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('c-booking__datetime-label')
																				]),
																			_List_fromArray(
																				[
																					$author$project$Icons$CalendarWhite$icon('c-booking__datetime-icon'),
																					A2(
																					$elm$html$Html$span,
																					_List_Nil,
																					_List_fromArray(
																						[
																							$elm$html$Html$text(
																							translate($author$project$Confirm$Blurbs$blurbs.ey))
																						]))
																				])),
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('c-booking__datetime-text'),
																					$author$project$CssUtils$qa('confirm_date')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(dateText)
																				]))
																		])),
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('c-booking__time')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('c-booking__datetime-label')
																				]),
																			_List_fromArray(
																				[
																					$author$project$Icons$ClockHollow$icon('c-booking__datetime-icon'),
																					A2(
																					$elm$html$Html$span,
																					_List_Nil,
																					_List_fromArray(
																						[
																							$elm$html$Html$text(
																							translate($author$project$Confirm$Blurbs$blurbs.gM))
																						]))
																				])),
																			A2(
																			$elm$html$Html$div,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('c-booking__datetime-text'),
																					$author$project$CssUtils$qa('confirm_time')
																				]),
																			_List_fromArray(
																				[
																					$elm$html$Html$text(timeText)
																				]))
																		]))
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('c-booking__tooltip-content c-tooltip'),
																	$author$project$CssUtils$qa('confirm_tz_tooltip')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(student.gO.eD)
																]))
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('c-booking__change u-mt-5'),
															$author$project$CssUtils$qa('confirm_change_timeslot'),
															$elm$html$Html$Attributes$disabled(model.cf),
															$elm$html$Html$Events$onClick($author$project$Confirm$Data$ChangeTimeslot)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															translate($author$project$Confirm$Blurbs$blurbs.a1))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('c-booking__teacher')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('c-booking__teacher-initial'),
															$author$project$CssUtils$qa('confirm_teacher_initial')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															A2($elm$core$String$left, 1, model.gG.eD))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('c-booking__teacher-name-w')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('c-booking__teacher-label')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	translate($author$project$Confirm$Blurbs$blurbs.b9))
																])),
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('c-booking__teacher-name'),
																	$author$project$CssUtils$qa('confirm_teacher')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	A2($elm$core$Maybe$withDefault, '', model.gG.eW) + (' ' + A2($elm$core$Maybe$withDefault, '', model.gG.fv)))
																]))
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('c-booking__change u-pt-3'),
															$author$project$CssUtils$qa('confirm_change_teacher'),
															$elm$html$Html$Events$onClick($author$project$Confirm$Data$ChangeTimeslot),
															$elm$html$Html$Attributes$disabled(model.cf)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															translate($author$project$Confirm$Blurbs$blurbs.a1))
														]))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$SelectTimeslot$Data$Confirm = {$: 0};
var $author$project$SelectTimeslot$Data$getLookup = F2(
	function (zone, extractTime) {
		return A2(
			$elm$core$List$foldr,
			F2(
				function (thing, lookup) {
					var key = $justinmimbs$date$Date$toRataDie(
						A2(
							$justinmimbs$date$Date$fromPosix,
							zone,
							$author$project$DateUtils$floatToPosix(
								extractTime(thing))));
					var _v0 = A2($elm$core$Dict$get, key, lookup);
					if (!_v0.$) {
						var things = _v0.a;
						return A3(
							$elm$core$Dict$insert,
							key,
							A2($elm$core$List$cons, thing, things),
							lookup);
					} else {
						return A3(
							$elm$core$Dict$insert,
							key,
							_List_fromArray(
								[thing]),
							lookup);
					}
				}),
			$elm$core$Dict$empty);
	});
var $author$project$SelectTimeslot$Data$getBookingsLookup = function (zone) {
	return A2(
		$author$project$SelectTimeslot$Data$getLookup,
		zone,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.gM;
			},
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.gw;
				},
				function ($) {
					return $.f7;
				})));
};
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$Set$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$Set$insert, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, $elm$core$Set$empty, list, _List_Nil);
	});
var $author$project$SelectTimeslot$Data$dedupeTimeslots = $elm_community$list_extra$List$Extra$uniqueBy(
	A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.dq;
		},
		function ($) {
			return $.f7;
		}));
var $author$project$SelectTimeslot$Data$getTimeslotsLookup = function (zone) {
	return A2(
		$author$project$SelectTimeslot$Data$getLookup,
		zone,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.dq;
			},
			function ($) {
				return $.f7;
			}));
};
var $author$project$SelectTimeslot$Data$getTimeslotLookupForSelectedTeacher = function (zone) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$SelectTimeslot$Data$getTimeslotCacheForSelectedTeacher,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Dict$values,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$concat,
				A2(
					$elm$core$Basics$composeR,
					$author$project$SelectTimeslot$Data$dedupeTimeslots,
					$author$project$SelectTimeslot$Data$getTimeslotsLookup(zone)))));
};
var $justinmimbs$date$Date$compare = F2(
	function (_v0, _v1) {
		var a = _v0;
		var b = _v1;
		return A2($elm$core$Basics$compare, a, b);
	});
var $author$project$SelectTimeslot$Data$fromDateLookup = F2(
	function (lookup, date) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Dict$get,
				$justinmimbs$date$Date$toRataDie(date),
				lookup));
	});
var $author$project$SelectTimeslot$View$DateSelection$dateHasNoAvailability = function (lookup) {
	return A2(
		$elm$core$Basics$composeL,
		$elm$core$List$isEmpty,
		$author$project$SelectTimeslot$Data$fromDateLookup(lookup));
};
var $author$project$StringUtils$replaceAllNamedTokens = $elm$core$List$foldl(
	F2(
		function (_v0, rest) {
			var name = _v0.a;
			var val = _v0.b;
			return A3($author$project$StringUtils$replaceNamedToken, name, val, rest);
		}));
var $author$project$SelectTimeslot$View$DateSelection$dateTooltip = F3(
	function (_v0, lookup, date) {
		var translate = _v0.gS;
		var _v1 = A2(
			$elm$core$Dict$get,
			$justinmimbs$date$Date$toRataDie(date),
			lookup);
		if (_v1.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!_v1.a.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var _v2 = _v1.a;
				var b = _v2.a;
				var replacements = b.fs ? _List_fromArray(
					[
						_Utils_Tuple2(
						'classType',
						translate($author$project$SelectTimeslot$Blurbs$blurbs.gU)),
						_Utils_Tuple2('className', b.cW)
					]) : _List_fromArray(
					[
						_Utils_Tuple2(
						'classType',
						translate($author$project$SelectTimeslot$Blurbs$blurbs.f9)),
						_Utils_Tuple2('className', b.cW)
					]);
				return $elm$core$Maybe$Just(
					A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2(
									$author$project$StringUtils$replaceAllNamedTokens,
									translate($author$project$SelectTimeslot$Blurbs$blurbs.a_),
									replacements))
							])));
			}
		}
	});
var $author$project$SelectTimeslot$View$DateSelection$highlightDate = function (lookup) {
	return A2(
		$elm$core$Basics$composeL,
		A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$List$isEmpty),
		$author$project$SelectTimeslot$Data$fromDateLookup(lookup));
};
var $author$project$DateUtils$translateMonth = F2(
	function (translate, month) {
		switch (month) {
			case 0:
				return translate($author$project$DateUtils$monthBlurbs.as);
			case 1:
				return translate($author$project$DateUtils$monthBlurbs.an);
			case 2:
				return translate($author$project$DateUtils$monthBlurbs.ay);
			case 3:
				return translate($author$project$DateUtils$monthBlurbs.ae);
			case 4:
				return translate($author$project$DateUtils$monthBlurbs.bn);
			case 5:
				return translate($author$project$DateUtils$monthBlurbs.au);
			case 6:
				return translate($author$project$DateUtils$monthBlurbs.at);
			case 7:
				return translate($author$project$DateUtils$monthBlurbs.af);
			case 8:
				return translate($author$project$DateUtils$monthBlurbs.aL);
			case 9:
				return translate($author$project$DateUtils$monthBlurbs.aE);
			case 10:
				return translate($author$project$DateUtils$monthBlurbs.aD);
			default:
				return translate($author$project$DateUtils$monthBlurbs.aj);
		}
	});
var $author$project$DateUtils$translateWeekday = F2(
	function (translate, weekday) {
		switch (weekday) {
			case 0:
				return translate($author$project$DateUtils$weekdayBlurbs.aB);
			case 1:
				return translate($author$project$DateUtils$weekdayBlurbs.aQ);
			case 2:
				return translate($author$project$DateUtils$weekdayBlurbs.aR);
			case 3:
				return translate($author$project$DateUtils$weekdayBlurbs.aO);
			case 4:
				return translate($author$project$DateUtils$weekdayBlurbs.ao);
			case 5:
				return translate($author$project$DateUtils$weekdayBlurbs.aH);
			default:
				return translate($author$project$DateUtils$weekdayBlurbs.aN);
		}
	});
var $justinmimbs$date$Date$Day = 11;
var $author$project$Components$Datepicker$SelectDate = function (a) {
	return {$: 1, a: a};
};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$groupsOfWithStep = F3(
	function (size, step, xs) {
		var xs_ = A2($elm$core$List$drop, step, xs);
		var thisGroup = A2($elm$core$List$take, size, xs);
		var okayLength = _Utils_eq(
			size,
			$elm$core$List$length(thisGroup));
		var okayArgs = (size > 0) && (step > 0);
		return (okayArgs && okayLength) ? A2(
			$elm$core$List$cons,
			thisGroup,
			A3($elm_community$list_extra$List$Extra$groupsOfWithStep, size, step, xs_)) : _List_Nil;
	});
var $elm_community$list_extra$List$Extra$groupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$groupsOfWithStep, size, size, xs);
	});
var $author$project$Components$Datepicker$Navigate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Datepicker$navHeader = F2(
	function (props, date) {
		var year = $justinmimbs$date$Date$year(date);
		var month = $justinmimbs$date$Date$month(date);
		var headerTxt = props.cV(month) + (' ' + $elm$core$String$fromInt(year));
		var firstOfMonth = A3($justinmimbs$date$Date$fromCalendarDate, year, month, 1);
		var prevDate = A3($justinmimbs$date$Date$add, 1, -1, firstOfMonth);
		var canNavBack = props.cg(prevDate);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-datepicker__header')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					A3(
						$author$project$ListUtils$appendIf,
						canNavBack,
						$elm$html$Html$Events$onClick(
							$author$project$Components$Datepicker$Navigate(prevDate)),
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-datepicker__prev'),
								$author$project$CssUtils$qa('datepicker_prev'),
								A2($elm$html$Html$Attributes$attribute, 'role', 'button')
							])),
					_List_fromArray(
						[
							$elm$html$Html$text(
							canNavBack ? '<' : '')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-datepicker__month'),
							$author$project$CssUtils$qa('datepicker_month')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(headerTxt)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-datepicker__next'),
							$author$project$CssUtils$qa('datepicker_next'),
							A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
							$elm$html$Html$Events$onClick(
							$author$project$Components$Datepicker$Navigate(
								A3($justinmimbs$date$Date$add, 1, 1, firstOfMonth)))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('>')
						]))
				]));
	});
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1;
	});
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date;
		switch (interval) {
			case 0:
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 1:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 2:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 3:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 4:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 5:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 1, date);
			case 6:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 2, date);
			case 7:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 3, date);
			case 8:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 4, date);
			case 9:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 5, date);
			case 10:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 6, date);
			default:
				return date;
		}
	});
var $justinmimbs$date$Date$Weeks = 2;
var $justinmimbs$date$Date$Years = 0;
var $justinmimbs$date$Date$intervalToUnits = function (interval) {
	switch (interval) {
		case 0:
			return _Utils_Tuple2(1, 0);
		case 1:
			return _Utils_Tuple2(3, 1);
		case 2:
			return _Utils_Tuple2(1, 1);
		case 11:
			return _Utils_Tuple2(1, 3);
		default:
			var week = interval;
			return _Utils_Tuple2(1, 2);
	}
};
var $justinmimbs$date$Date$ceiling = F2(
	function (interval, date) {
		var floored = A2($justinmimbs$date$Date$floor, interval, date);
		if (_Utils_eq(date, floored)) {
			return date;
		} else {
			var _v0 = $justinmimbs$date$Date$intervalToUnits(interval);
			var n = _v0.a;
			var unit = _v0.b;
			return A3($justinmimbs$date$Date$add, unit, n, floored);
		}
	});
var $justinmimbs$date$Date$rangeHelp = F5(
	function (unit, step, until, revList, current) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(current, until) < 0) {
				var _v0 = A3($justinmimbs$date$Date$add, unit, step, current);
				var next = _v0;
				var $temp$unit = unit,
					$temp$step = step,
					$temp$until = until,
					$temp$revList = A2($elm$core$List$cons, current, revList),
					$temp$current = next;
				unit = $temp$unit;
				step = $temp$step;
				until = $temp$until;
				revList = $temp$revList;
				current = $temp$current;
				continue rangeHelp;
			} else {
				return $elm$core$List$reverse(revList);
			}
		}
	});
var $justinmimbs$date$Date$range = F4(
	function (interval, step, _v0, _v1) {
		var start = _v0;
		var until = _v1;
		var _v2 = $justinmimbs$date$Date$intervalToUnits(interval);
		var n = _v2.a;
		var unit = _v2.b;
		var _v3 = A2($justinmimbs$date$Date$ceiling, interval, start);
		var first = _v3;
		return (_Utils_cmp(first, until) < 0) ? A5(
			$justinmimbs$date$Date$rangeHelp,
			unit,
			A2($elm$core$Basics$max, 1, step) * n,
			until,
			_List_Nil,
			first) : _List_Nil;
	});
var $author$project$Components$Datepicker$weekdays = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6]);
var $author$project$Components$Datepicker$weekdayBanner = function (props) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('c-datepicker__weekdays')
			]),
		A2(
			$elm$core$List$map,
			function (w) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-datepicker__weekday'),
							$author$project$CssUtils$qa('datepicker_weekday')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							A3(
								$elm$core$String$slice,
								0,
								1,
								props.dM(w)))
						]));
			},
			$author$project$Components$Datepicker$weekdays));
};
var $author$project$Components$Datepicker$Parent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Datepicker$wrap = $elm$html$Html$map($author$project$Components$Datepicker$Parent);
var $author$project$Components$Datepicker$view = F2(
	function (props, _v0) {
		var showingDate = _v0;
		var date = A2($elm$core$Maybe$withDefault, props.bM, showingDate);
		var month = $justinmimbs$date$Date$month(date);
		var _v1 = $author$project$Components$Datepicker$dateRangeFromDate(date);
		var startDate = _v1.a;
		var endDate = _v1.b;
		var weeks = A2(
			$elm_community$list_extra$List$Extra$groupsOf,
			7,
			A4($justinmimbs$date$Date$range, 11, 1, startDate, endDate));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-datepicker'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('-with-spinner', props.cf)
						])),
					$author$project$CssUtils$qa('datepicker')
				]),
			_List_fromArray(
				[
					A2($author$project$Components$Datepicker$navHeader, props, date),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-datepicker__body')
						]),
					_List_fromArray(
						[
							$author$project$Components$Datepicker$weekdayBanner(props),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							A2(
								$elm$core$List$map,
								function (week) {
									return A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('c-datepicker__week'),
												$author$project$CssUtils$qa('datepicker_week')
											]),
										A2(
											$elm$core$List$map,
											function (d) {
												var tooltip = A2(
													$elm$core$Maybe$map,
													function (t) {
														return $author$project$Components$Datepicker$wrap(
															A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('c-datepicker__tooltip-content c-tooltip')
																	]),
																_List_fromArray(
																	[t])));
													},
													props.dD(d));
												return A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('c-datepicker__tooltip-w'),
															$author$project$CssUtils$qa('datepicker_tooltip_wrapper')
														]),
													A2(
														$author$project$ListUtils$appendMaybe,
														tooltip,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																A3(
																	$author$project$ListUtils$appendIf,
																	!props.be(d),
																	$elm$html$Html$Events$onClick(
																		$author$project$Components$Datepicker$SelectDate(d)),
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('c-datepicker__day c-datepicker__tooltip-trigger'),
																			$author$project$CssUtils$qa('datepicker_day'),
																			A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
																			$elm$html$Html$Attributes$classList(
																			A2(
																				$elm$core$List$cons,
																				_Utils_Tuple2(
																					'-disabled',
																					props.be(d)),
																				props.cn(d)))
																		])),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('c-datepicker__day-inner'),
																				$author$project$CssUtils$qa('datepicker_day_inner'),
																				$elm$html$Html$Attributes$classList(
																				_List_fromArray(
																					[
																						_Utils_Tuple2(
																						'-outside',
																						!_Utils_eq(
																							$justinmimbs$date$Date$month(d),
																							month)),
																						_Utils_Tuple2(
																						'-selected',
																						_Utils_eq(d, props.bM)),
																						_Utils_Tuple2(
																						'-today',
																						_Utils_eq(d, props.dC))
																					]))
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text(
																				$elm$core$String$fromInt(
																					$justinmimbs$date$Date$day(d)))
																			]))
																	]))
															])));
											},
											week));
								},
								weeks))
						]))
				]));
	});
var $author$project$SelectTimeslot$View$DateSelection$view = F4(
	function (external, model, bookingsLookup, timeslotLookup) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-datepicker-view')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$map,
					$author$project$SelectTimeslot$Data$DatepickerMsg,
					A2(
						$author$project$Components$Datepicker$view,
						{
							cf: model.fI,
							cg: function (date) {
								var year = $justinmimbs$date$Date$year(model.dC);
								var month = $justinmimbs$date$Date$month(model.dC);
								var firstOfMonth = A3($justinmimbs$date$Date$fromCalendarDate, year, month, 1);
								return !(!A2($justinmimbs$date$Date$compare, date, firstOfMonth));
							},
							cn: function (date) {
								return _List_fromArray(
									[
										_Utils_Tuple2(
										'-highlight',
										A2($author$project$SelectTimeslot$View$DateSelection$highlightDate, bookingsLookup, date))
									]);
							},
							be: $author$project$SelectTimeslot$View$DateSelection$dateHasNoAvailability(timeslotLookup),
							cV: $author$project$DateUtils$translateMonth(external.gS),
							bM: model.bM,
							dC: model.dC,
							dD: A2($author$project$SelectTimeslot$View$DateSelection$dateTooltip, external, bookingsLookup),
							dM: $author$project$DateUtils$translateWeekday(external.gS)
						},
						model.ez))
				]));
	});
var $author$project$SelectTimeslot$Data$GoToCurrentBookings = {$: 14};
var $author$project$SelectTimeslot$Data$SelectTimeslot = function (a) {
	return {$: 9, a: a};
};
var $elm$svg$Svg$defs = $elm$svg$Svg$trustedNode('defs');
var $elm$svg$Svg$Attributes$dy = _VirtualDom_attribute('dy');
var $elm$svg$Svg$feColorMatrix = $elm$svg$Svg$trustedNode('feColorMatrix');
var $elm$svg$Svg$feGaussianBlur = $elm$svg$Svg$trustedNode('feGaussianBlur');
var $elm$svg$Svg$feMerge = $elm$svg$Svg$trustedNode('feMerge');
var $elm$svg$Svg$feMergeNode = $elm$svg$Svg$trustedNode('feMergeNode');
var $elm$svg$Svg$feOffset = $elm$svg$Svg$trustedNode('feOffset');
var $elm$svg$Svg$filter = $elm$svg$Svg$trustedNode('filter');
var $elm$svg$Svg$Attributes$filterUnits = _VirtualDom_attribute('filterUnits');
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $elm$svg$Svg$Attributes$in_ = _VirtualDom_attribute('in');
var $elm$svg$Svg$Attributes$result = _VirtualDom_attribute('result');
var $elm$svg$Svg$Attributes$stdDeviation = _VirtualDom_attribute('stdDeviation');
var $elm$svg$Svg$Attributes$values = function (value) {
	return A2(
		_VirtualDom_attribute,
		'values',
		_VirtualDom_noJavaScriptUri(value));
};
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $author$project$Icons$ClockWhite$icon = function (cls) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('30'),
				$elm$svg$Svg$Attributes$height('30'),
				$elm$svg$Svg$Attributes$class(cls)
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$defs,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$filter,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$x('-4.1%'),
								$elm$svg$Svg$Attributes$y('-7.1%'),
								$elm$svg$Svg$Attributes$width('108.2%'),
								$elm$svg$Svg$Attributes$height('114.2%'),
								$elm$svg$Svg$Attributes$filterUnits('objectBoundingBox'),
								$elm$svg$Svg$Attributes$id('a')
							]),
						_List_fromArray(
							[
								A2(
								$elm$svg$Svg$feOffset,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$dy('2'),
										$elm$svg$Svg$Attributes$in_('SourceAlpha'),
										$elm$svg$Svg$Attributes$result('shadowOffsetOuter1')
									]),
								_List_Nil),
								A2(
								$elm$svg$Svg$feGaussianBlur,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$stdDeviation('4'),
										$elm$svg$Svg$Attributes$in_('shadowOffsetOuter1'),
										$elm$svg$Svg$Attributes$result('shadowBlurOuter1')
									]),
								_List_Nil),
								A2(
								$elm$svg$Svg$feColorMatrix,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$values('0 0 0 0 0.0980392157 0 0 0 0 0.0980392157 0 0 0 0 0.0980392157 0 0 0 0.13 0'),
										$elm$svg$Svg$Attributes$in_('shadowBlurOuter1'),
										$elm$svg$Svg$Attributes$result('shadowMatrixOuter1')
									]),
								_List_Nil),
								A2(
								$elm$svg$Svg$feMerge,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$svg$Svg$feMergeNode,
										_List_fromArray(
											[
												$elm$svg$Svg$Attributes$in_('shadowMatrixOuter1')
											]),
										_List_Nil),
										A2(
										$elm$svg$Svg$feMergeNode,
										_List_fromArray(
											[
												$elm$svg$Svg$Attributes$in_('SourceGraphic')
											]),
										_List_Nil)
									]))
							]))
					])),
				A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$transform('translate(-51 -98)'),
						$elm$svg$Svg$Attributes$fill('#FFF'),
						$elm$svg$Svg$Attributes$fillRule('evenodd')
					]),
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d('M66 104a7 7 0 100 14 7 7 0 000-14zm3.089 9.778l-.31.31a.437.437 0 01-.618 0l-2.69-2.69a.437.437 0 01-.119-.354v-5.294c0-.242.196-.438.438-.438h.437c.242 0 .438.196.438.438v4.979l2.432 2.432a.437.437 0 01-.008.617z')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$SelectTimeslot$View$Schedule$imageUrl = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map(
		function (u) {
			return '/_imgs/evc/pl/topic/238x238/' + u;
		}),
	$elm$core$Maybe$withDefault('https://source.unsplash.com/600x400/?education,business'));
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$SelectTimeslot$View$Schedule$candidateBooking = F2(
	function (timeslot, model) {
		var duration = $elm$core$String$fromFloat(timeslot.dy);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-candidate__img'),
						A2(
						$elm$html$Html$Attributes$style,
						'background-image',
						'url(' + ($author$project$SelectTimeslot$View$Schedule$imageUrl(model.gQ.cF) + ')'))
					]),
				_List_fromArray(
					[
						$author$project$Icons$ClockWhite$icon('c-candidate__icon'),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-candidate__duration'),
								$author$project$CssUtils$qa('candidate_duration')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(duration + ' min')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-candidate__name'),
						$elm$html$Html$Attributes$title(model.gQ.cW),
						$author$project$CssUtils$qa('candidate_name')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(model.gQ.cW)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-candidate__cancel'),
						$author$project$CssUtils$qa('candidate_cancel'),
						$elm$html$Html$Events$onClick(
						$author$project$SelectTimeslot$Data$SelectTimeslot($elm$core$Maybe$Nothing)),
						A2($elm$html$Html$Attributes$attribute, 'role', 'button')
					]),
				_List_fromArray(
					[$author$project$Icons$CloseIcon$icon]))
			]);
	});
var $author$project$SelectTimeslot$Data$ChangeTimezone = {$: 7};
var $author$project$Forms$Select$Option = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$SelectTimeslot$Data$UpdateTimezone = function (a) {
	return {$: 5, a: a};
};
var $author$project$Forms$Select$WrapperProp = function (a) {
	return {$: 1, a: a};
};
var $author$project$Forms$Select$class = A2($elm$core$Basics$composeL, $author$project$Forms$Select$WrapperProp, $elm$html$Html$Attributes$class);
var $author$project$Forms$Select$SelectProp = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm_community$json_extra$Json$Decode$Extra$combine = A2(
	$elm$core$List$foldr,
	$elm$json$Json$Decode$map2($elm$core$List$cons),
	$elm$json$Json$Decode$succeed(_List_Nil));
var $elm_community$json_extra$Json$Decode$Extra$collection = function (decoder) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (length) {
			return $elm_community$json_extra$Json$Decode$Extra$combine(
				A2(
					$elm$core$List$map,
					function (index) {
						return A2(
							$elm$json$Json$Decode$field,
							$elm$core$String$fromInt(index),
							decoder);
					},
					A2($elm$core$List$range, 0, length - 1)));
		},
		A2($elm$json$Json$Decode$field, 'length', $elm$json$Json$Decode$int));
};
var $author$project$Forms$Select$optionDecoder = A3(
	$elm$json$Json$Decode$map2,
	$elm$core$Tuple$pair,
	A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'text', $elm$json$Json$Decode$string));
var $author$project$Forms$Select$optionParser = A2(
	$elm$json$Json$Decode$map,
	$elm$core$List$head,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['target', 'selectedOptions']),
		$elm_community$json_extra$Json$Decode$Extra$collection($author$project$Forms$Select$optionDecoder)));
var $author$project$Forms$Select$onSelect = function (handler) {
	return $author$project$Forms$Select$SelectProp(
		A2(
			$elm$html$Html$Events$on,
			'change',
			A2($elm$json$Json$Decode$map, handler, $author$project$Forms$Select$optionParser)));
};
var $author$project$Forms$Select$qa = A2($elm$core$Basics$composeL, $author$project$Forms$Select$SelectProp, $author$project$CssUtils$qa);
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Forms$Select$option = function (o) {
	if (!o.$) {
		var selected = o.a;
		var key = o.b;
		var val = o.c;
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(key),
					$elm$html$Html$Attributes$selected(selected)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(val)
				]));
	} else {
		var selected = o.a;
		var val = o.b;
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(''),
					$elm$html$Html$Attributes$selected(selected),
					$elm$html$Html$Attributes$disabled(true)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(val)
				]));
	}
};
var $elm$html$Html$select = _VirtualDom_node('select');
function $author$project$Forms$Select$cyclic$selectAttrs() {
	return A2(
		$elm$core$List$foldr,
		F2(
			function (p, attrs) {
				switch (p.$) {
					case 0:
						var a = p.a;
						return A2($elm$core$List$cons, a, attrs);
					case 2:
						var p_ = p.a;
						return _Utils_ap(
							attrs,
							$author$project$Forms$Select$cyclic$selectAttrs()(p_));
					default:
						return attrs;
				}
			}),
		_List_Nil);
}
var $author$project$Forms$Select$selectAttrs = $author$project$Forms$Select$cyclic$selectAttrs();
$author$project$Forms$Select$cyclic$selectAttrs = function () {
	return $author$project$Forms$Select$selectAttrs;
};
function $author$project$Forms$Select$cyclic$wrapperAttrs() {
	return A2(
		$elm$core$List$foldr,
		F2(
			function (p, attrs) {
				switch (p.$) {
					case 1:
						var a = p.a;
						return A2($elm$core$List$cons, a, attrs);
					case 2:
						var p_ = p.a;
						return _Utils_ap(
							attrs,
							$author$project$Forms$Select$cyclic$wrapperAttrs()(p_));
					default:
						return attrs;
				}
			}),
		_List_Nil);
}
var $author$project$Forms$Select$wrapperAttrs = $author$project$Forms$Select$cyclic$wrapperAttrs();
$author$project$Forms$Select$cyclic$wrapperAttrs = function () {
	return $author$project$Forms$Select$wrapperAttrs;
};
var $author$project$Forms$Select$select = F2(
	function (props, options) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('c-input-w -select'),
				$author$project$Forms$Select$wrapperAttrs(props)),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$select,
					A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('c-input'),
						$author$project$Forms$Select$selectAttrs(props)),
					A2($elm$core$List$map, $author$project$Forms$Select$option, options))
				]));
	});
var $author$project$SelectTimeslot$View$Schedule$header = F2(
	function (_v0, model) {
		var translate = _v0.gS;
		var student = _v0.gy;
		var timezoneOptions = A2(
			$elm$core$List$map,
			function (tz) {
				return A3(
					$author$project$Forms$Select$Option,
					_Utils_eq(student.gO.eD, tz.gL),
					tz.gY,
					tz.gL);
			},
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.gL;
				},
				model.ai.gP));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-schedule__header'),
					$author$project$CssUtils$qa('schedule_header'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('-is-changing', model.eb)
						]))
				]),
			model.eb ? _List_fromArray(
				[
					A2(
					$author$project$Forms$Select$select,
					_List_fromArray(
						[
							$author$project$Forms$Select$onSelect($author$project$SelectTimeslot$Data$UpdateTimezone),
							$author$project$Forms$Select$class('c-schedule__timezone-select'),
							$author$project$Forms$Select$qa('timezone_selector')
						]),
					timezoneOptions)
				]) : _List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-schedule__header-label')
						]),
					$author$project$HtmlUtils$textHtml(
						translate($author$project$SelectTimeslot$Blurbs$blurbs.aS))),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-schedule__header-current'),
							$author$project$CssUtils$qa('current_timezone')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(student.gO.eD)
						])),
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$SelectTimeslot$Data$ChangeTimezone),
							$elm$html$Html$Attributes$class('c-schedule__header-toggle'),
							$author$project$CssUtils$qa('change_timezone')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							translate($author$project$SelectTimeslot$Blurbs$blurbs.a2))
						]))
				]));
	});
var $author$project$SelectTimeslot$View$Schedule$BookableSlot = function (a) {
	return {$: 0, a: a};
};
var $author$project$SelectTimeslot$View$Schedule$BookedSlot = function (a) {
	return {$: 1, a: a};
};
var $author$project$SelectTimeslot$View$Schedule$CandidateBooking = function (a) {
	return {$: 2, a: a};
};
var $author$project$SelectTimeslot$View$Schedule$mergedTimeline = F3(
	function (selectedTimeslot, timeslots, bookings) {
		return A2(
			$elm$core$List$sortBy,
			function (slot) {
				switch (slot.$) {
					case 0:
						var t = slot.a;
						return t.dq.f7;
					case 1:
						var t = slot.a;
						return t.gM.gw.f7;
					default:
						var t = slot.a;
						return t.dq.f7;
				}
			},
			_Utils_ap(
				A2(
					$elm$core$List$map,
					function (t) {
						return _Utils_eq(
							selectedTimeslot,
							$elm$core$Maybe$Just(t)) ? $author$project$SelectTimeslot$View$Schedule$CandidateBooking(t) : $author$project$SelectTimeslot$View$Schedule$BookableSlot(t);
					},
					timeslots),
				A2($elm$core$List$map, $author$project$SelectTimeslot$View$Schedule$BookedSlot, bookings)));
	});
var $author$project$SelectTimeslot$View$Schedule$view = F4(
	function (external, model, bookingsLookup, timeslotLookup) {
		var translate = external.gS;
		var todaysTimeslots = A2($author$project$SelectTimeslot$Data$fromDateLookup, timeslotLookup, model.bM);
		var todaysBookings = A2($author$project$SelectTimeslot$Data$fromDateLookup, bookingsLookup, model.bM);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-schedule'),
					$author$project$CssUtils$qa('schedule'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('-with-spinner', model.fI)
						])),
					$elm$html$Html$Attributes$disabled(model.fI)
				]),
			_List_fromArray(
				[
					A2($author$project$SelectTimeslot$View$Schedule$header, external, model),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-schedule__body')
						]),
					function () {
						var _v0 = A3($author$project$SelectTimeslot$View$Schedule$mergedTimeline, model.go, todaysTimeslots, todaysBookings);
						if (!_v0.b) {
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-schedule__empty')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											translate($author$project$SelectTimeslot$Blurbs$blurbs.bu))
										]))
								]);
						} else {
							var slots = _v0;
							return A2(
								$elm$core$List$map,
								function (slot) {
									switch (slot.$) {
										case 0:
											var timeslot = slot.a;
											return A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('c-schedule__timeslot -is-bookable'),
														$elm$html$Html$Events$onClick(
														$author$project$SelectTimeslot$Data$SelectTimeslot(
															$elm$core$Maybe$Just(timeslot))),
														A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
														$author$project$CssUtils$qa('bookable_slot')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(timeslot.dq.e_)
													]));
										case 1:
											var booking = slot.a;
											return A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('c-schedule__timeslot')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$a,
														_List_fromArray(
															[
																$elm$html$Html$Events$onClick($author$project$SelectTimeslot$Data$GoToCurrentBookings),
																$author$project$CssUtils$qa('existing_booking')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(booking.gM.gw.e_ + (' - ' + booking.cW))
															]))
													]));
										default:
											var timeslot = slot.a;
											return A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('c-schedule__timeslot c-candidate'),
														$author$project$CssUtils$qa('candidate_booking')
													]),
												A2($author$project$SelectTimeslot$View$Schedule$candidateBooking, timeslot, model));
									}
								},
								slots);
						}
					}())
				]));
	});
var $author$project$SelectTimeslot$Data$CancelChangeTeacher = {$: 12};
var $author$project$SelectTimeslot$Data$ChangeTeacher = {$: 11};
var $author$project$SelectTimeslot$Data$SelectAllTeachers = {$: 10};
var $author$project$SelectTimeslot$View$TeacherSelection$renderTeacher = F4(
	function (_v0, actionText, mAction, teacher) {
		var translate = _v0.gS;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-select-teacher'),
					$author$project$CssUtils$qa('selected_teacher'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'-is-selected',
							!_Utils_eq(mAction, $elm$core$Maybe$Nothing))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-select-teacher__initial'),
							$author$project$CssUtils$qa('selected_teacher_initial')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2($elm$core$String$left, 1, teacher.eD))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-select-teacher__name'),
							$author$project$CssUtils$qa('selected_teacher_name')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(teacher.eD)
						])),
					A2(
					$elm$html$Html$a,
					A2(
						$author$project$ListUtils$appendMaybe,
						A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, mAction),
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-select-teacher__select'),
								$author$project$CssUtils$qa('selected_teacher_change')
							])),
					_List_fromArray(
						[
							$elm$html$Html$text(
							translate(actionText))
						]))
				]));
	});
var $author$project$Components$Autocompleter$HideThings = {$: 5};
var $author$project$Components$Autocompleter$Highlight = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Autocompleter$OnInput = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Autocompleter$ShowThings = {$: 6};
function $author$project$Forms$Input$cyclic$inputAttrs() {
	return A2(
		$elm$core$List$foldr,
		F2(
			function (p, attrs) {
				switch (p.$) {
					case 0:
						var a = p.a;
						return A2($elm$core$List$cons, a, attrs);
					case 2:
						var p_ = p.a;
						return _Utils_ap(
							attrs,
							$author$project$Forms$Input$cyclic$inputAttrs()(p_));
					default:
						return attrs;
				}
			}),
		_List_Nil);
}
var $author$project$Forms$Input$inputAttrs = $author$project$Forms$Input$cyclic$inputAttrs();
$author$project$Forms$Input$cyclic$inputAttrs = function () {
	return $author$project$Forms$Input$inputAttrs;
};
function $author$project$Forms$Input$cyclic$wrapperAttrs() {
	return A2(
		$elm$core$List$foldr,
		F2(
			function (p, attrs) {
				switch (p.$) {
					case 1:
						var a = p.a;
						return A2($elm$core$List$cons, a, attrs);
					case 2:
						var p_ = p.a;
						return _Utils_ap(
							attrs,
							$author$project$Forms$Input$cyclic$wrapperAttrs()(p_));
					default:
						return attrs;
				}
			}),
		_List_Nil);
}
var $author$project$Forms$Input$wrapperAttrs = $author$project$Forms$Input$cyclic$wrapperAttrs();
$author$project$Forms$Input$cyclic$wrapperAttrs = function () {
	return $author$project$Forms$Input$wrapperAttrs;
};
var $author$project$Forms$Input$input = function (props) {
	return A2(
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$class('c-input-w'),
			$author$project$Forms$Input$wrapperAttrs(props)),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$class('c-input'),
					$author$project$Forms$Input$inputAttrs(props)),
				_List_Nil)
			]));
};
var $author$project$Forms$Input$WrapperProp = function (a) {
	return {$: 1, a: a};
};
var $author$project$Forms$Input$NullProp = {$: 3};
var $author$project$Forms$Input$boolProp = F2(
	function (p, b) {
		return b ? p : $author$project$Forms$Input$NullProp;
	});
var $author$project$Forms$Input$loading = $author$project$Forms$Input$boolProp(
	$author$project$Forms$Input$WrapperProp(
		$elm$html$Html$Attributes$class('-is-loading')));
var $author$project$Forms$Input$InputProp = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Forms$Input$onBlur = A2($elm$core$Basics$composeL, $author$project$Forms$Input$InputProp, $elm$html$Html$Events$onBlur);
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Forms$Input$onFocus = A2($elm$core$Basics$composeL, $author$project$Forms$Input$InputProp, $elm$html$Html$Events$onFocus);
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $author$project$Forms$Input$onInput = A2($elm$core$Basics$composeL, $author$project$Forms$Input$InputProp, $elm$html$Html$Events$onInput);
var $elm$html$Html$Events$onMouseOver = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseover',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $author$project$Forms$Input$placeholder = A2($elm$core$Basics$composeL, $author$project$Forms$Input$InputProp, $elm$html$Html$Attributes$placeholder);
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $author$project$Forms$Input$qa = A2($elm$core$Basics$composeR, $author$project$CssUtils$qa, $author$project$Forms$Input$InputProp);
var $author$project$Components$Autocompleter$Parent = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Autocompleter$wrap = $elm$html$Html$map($author$project$Components$Autocompleter$Parent);
var $author$project$Components$Autocompleter$view = F2(
	function (props, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-autocompleter'),
					$author$project$CssUtils$qa('autocompleter_container')
				]),
			_List_fromArray(
				[
					$author$project$Forms$Input$input(
					_List_fromArray(
						[
							$author$project$Forms$Input$loading(model.P),
							$author$project$Forms$Input$onInput($author$project$Components$Autocompleter$OnInput),
							$author$project$Forms$Input$onBlur($author$project$Components$Autocompleter$HideThings),
							$author$project$Forms$Input$onFocus($author$project$Components$Autocompleter$ShowThings),
							$author$project$Forms$Input$placeholder(props.c1),
							$author$project$Forms$Input$qa('autocompleter_input')
						])),
					(!($elm$core$List$isEmpty(model.E) || (!model.Z))) ? A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-autocompleter__results')
						]),
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, thing) {
								return A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-autocompleter__result'),
											$author$project$CssUtils$qa('autocompleter_result'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'-is-highlighted',
													_Utils_eq(i, model.u))
												])),
											A2(
											$elm$html$Html$Events$preventDefaultOn,
											'mousedown',
											$elm$json$Json$Decode$succeed(
												_Utils_Tuple2(
													$author$project$Components$Autocompleter$SelectThing(thing),
													true))),
											$elm$html$Html$Events$onMouseOver(
											$author$project$Components$Autocompleter$Highlight(i))
										]),
									_List_fromArray(
										[
											$author$project$Components$Autocompleter$wrap(
											props.c9(thing))
										]));
							}),
						model.E)) : $author$project$HtmlUtils$empty
				]));
	});
var $author$project$SelectTimeslot$View$TeacherSelection$view = F2(
	function (external, model) {
		var translate = external.gS;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					function () {
					var _v0 = model.gm;
					if (!_v0.$) {
						return A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$map,
											$author$project$SelectTimeslot$Data$AutoCompleterMsg,
											A2(
												$author$project$Components$Autocompleter$view,
												{
													c1: translate($author$project$SelectTimeslot$Blurbs$blurbs.bG),
													c9: A3($author$project$SelectTimeslot$View$TeacherSelection$renderTeacher, external, $author$project$SelectTimeslot$Blurbs$blurbs.bH, $elm$core$Maybe$Nothing),
													gn: function () {
														var _v1 = model.gm;
														if (!_v1.$) {
															return $elm$core$Maybe$Nothing;
														} else {
															var teacher = _v1.a;
															return $elm$core$Maybe$Just(teacher);
														}
													}()
												},
												model.dX))
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-select-teacher__link'),
											$author$project$CssUtils$qa('showing_all_teachers')
										]),
									$author$project$HtmlUtils$textHtml(
										translate($author$project$SelectTimeslot$Blurbs$blurbs.bQ)))
								]));
					} else {
						var teacher = _v0.a;
						return model.ea ? A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$map,
											$author$project$SelectTimeslot$Data$AutoCompleterMsg,
											A2(
												$author$project$Components$Autocompleter$view,
												{
													c1: translate($author$project$SelectTimeslot$Blurbs$blurbs.bG),
													c9: A3($author$project$SelectTimeslot$View$TeacherSelection$renderTeacher, external, $author$project$SelectTimeslot$Blurbs$blurbs.bH, $elm$core$Maybe$Nothing),
													gn: $elm$core$Maybe$Just(teacher)
												},
												model.dX))
										])),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-select-teacher__link'),
											$elm$html$Html$Events$onClick($author$project$SelectTimeslot$Data$CancelChangeTeacher),
											$author$project$CssUtils$qa('showing_specific_teacher')
										]),
									$author$project$HtmlUtils$textHtml(
										A3(
											$author$project$StringUtils$replaceNamedToken,
											'currentTeacher',
											teacher.eD,
											translate($author$project$SelectTimeslot$Blurbs$blurbs.bR))))
								])) : A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A4(
									$author$project$SelectTimeslot$View$TeacherSelection$renderTeacher,
									external,
									$author$project$SelectTimeslot$Blurbs$blurbs.a1,
									$elm$core$Maybe$Just($author$project$SelectTimeslot$Data$ChangeTeacher),
									teacher),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-select-teacher__link'),
											$elm$html$Html$Events$onClick($author$project$SelectTimeslot$Data$SelectAllTeachers),
											$author$project$CssUtils$qa('show_all_teachers')
										]),
									$author$project$HtmlUtils$textHtml(
										translate($author$project$SelectTimeslot$Blurbs$blurbs.bP)))
								]));
					}
				}()
				]));
	});
var $author$project$Icons$BookBlack$icon = function (cls) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$width('16'),
				$elm$svg$Svg$Attributes$height('16'),
				$elm$svg$Svg$Attributes$class(cls)
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d('M6.885 3.5L1.635 2A.5.5 0 001 2.5V12a.5.5 0 00.365.5l5.25 1.5a.42.42 0 00.135 0 .5.5 0 00.5-.5V4a.5.5 0 00-.365-.5zM14.8 2.1a.5.5 0 00-.435-.1l-.865.265v3a.5.5 0 01-.3.46l-.5.215a.465.465 0 01-.2.045.5.5 0 01-.275-.085.5.5 0 01-.225-.415v-2.79L9.115 3.5a.5.5 0 00-.365.5v9.5a.5.5 0 00.5.5.42.42 0 00.135 0l5.25-1.5A.5.5 0 0015 12V2.5a.5.5 0 00-.2-.4z'),
						$elm$svg$Svg$Attributes$fill('#191919'),
						$elm$svg$Svg$Attributes$fillRule('evenodd')
					]),
				_List_Nil)
			]));
};
var $author$project$SelectTimeslot$View$TopicSummary$view = F2(
	function (_v0, model) {
		var translate = _v0.gS;
		var _v1 = A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2('--', '--'),
			A2(
				$elm$core$Maybe$map,
				function (t) {
					return _Utils_Tuple2(t.dq.eZ, t.dq.e_);
				},
				model.go));
		var dateText = _v1.a;
		var timeText = _v1.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-topic-summary-w'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'-is-complete',
							!_Utils_eq(model.go, $elm$core$Maybe$Nothing))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-topic-summary')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-topic-summary__label')
								]),
							_List_fromArray(
								[
									$author$project$Icons$BookBlack$icon('c-topic-summary__icon'),
									$elm$html$Html$text(
									translate($author$project$SelectTimeslot$Blurbs$blurbs.gQ))
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-topic-summary__value'),
									$author$project$CssUtils$qa('topic_summary_name')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(model.gQ.cW)
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-topic-summary__label')
								]),
							_List_fromArray(
								[
									$author$project$Icons$CalendarWhite$icon('c-topic-summary__icon'),
									$elm$html$Html$text(
									translate($author$project$SelectTimeslot$Blurbs$blurbs.ey))
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-topic-summary__value'),
									$author$project$CssUtils$qa('topic_summary_date')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(dateText)
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-topic-summary__label')
								]),
							_List_fromArray(
								[
									$author$project$Icons$ClockHollow$icon('c-topic-summary__icon'),
									$elm$html$Html$text(
									translate($author$project$SelectTimeslot$Blurbs$blurbs.gM))
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-topic-summary__value'),
									$author$project$CssUtils$qa('topic_summary_time')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(timeText)
								]))
						]))
				]));
	});
var $author$project$SelectTimeslot$View$View$loadedView = F3(
	function (external, bookings, model) {
		var timeslotLookup = A2($author$project$SelectTimeslot$Data$getTimeslotLookupForSelectedTeacher, model.g5, model.gm);
		var bookingsLookup = A2($author$project$SelectTimeslot$Data$getBookingsLookup, model.g5, bookings);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-timeslot-page__content')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-timeslot-page__col -col1')
						]),
					_List_fromArray(
						[
							A4($author$project$SelectTimeslot$View$DateSelection$view, external, model, bookingsLookup, timeslotLookup),
							A2($author$project$SelectTimeslot$View$TeacherSelection$view, external, model)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-timeslot-page__col -col2')
						]),
					_List_fromArray(
						[
							A4($author$project$SelectTimeslot$View$Schedule$view, external, model, bookingsLookup, timeslotLookup)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-timeslot-page__col -col3')
						]),
					_List_fromArray(
						[
							A2($author$project$SelectTimeslot$View$TopicSummary$view, external, model),
							A2(
							$author$project$Button$button,
							_List_fromArray(
								[
									$author$project$Button$dark,
									$author$project$Button$onClick($author$project$SelectTimeslot$Data$Confirm),
									$author$project$Button$class('c-topic-summary__confirm'),
									$author$project$Button$qa('confirm_timeslot'),
									$author$project$Button$disabled(
									_Utils_eq(model.go, $elm$core$Maybe$Nothing) || model.ek),
									$author$project$Button$loading(model.ek)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									external.gS($author$project$SelectTimeslot$Blurbs$blurbs.bJ))
								]))
						]))
				]));
	});
var $author$project$SelectTimeslot$View$View$view = F3(
	function (external, bookings, model) {
		var translate = external.gS;
		if (!model.$) {
			return $author$project$SelectTimeslot$View$View$loadingView;
		} else {
			var initModel = model.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-timeslot-page')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h3,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-timeslot-page__header'),
								$author$project$CssUtils$qa('select_timeslot_title')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								translate($author$project$SelectTimeslot$Blurbs$blurbs.aU))
							])),
						A3($author$project$SelectTimeslot$View$View$loadedView, external, bookings, initModel)
					]));
		}
	});
var $author$project$SelectTopic$Data$SelectLevel = function (a) {
	return {$: 4, a: a};
};
var $author$project$SelectTopic$View$header = F3(
	function (_v0, model, data) {
		var translate = _v0.gS;
		var optionKeyVal = function (loc) {
			if (!loc.$) {
				var courseTypeCode = loc.a.a8;
				var levelCode = loc.a.aw;
				var levelName = loc.a.fA;
				return _Utils_Tuple2(courseTypeCode + ('_' + levelCode), levelName);
			} else {
				var courseTypeCode = loc.a.a8;
				var courseName = loc.a.ep;
				return _Utils_Tuple2(courseTypeCode, courseName);
			}
		};
		var options = A2(
			$elm$core$List$map,
			function (_v1) {
				var k = _v1.a;
				var v = _v1.b;
				return A3(
					$author$project$Forms$Select$Option,
					_Utils_eq(
						model.aJ,
						$elm$core$Maybe$Just(k)),
					k,
					v);
			},
			A2($elm$core$List$map, optionKeyVal, data.fE));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-topic-header')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-topic-header__title'),
									$author$project$CssUtils$qa('select_topic_title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$SelectTopic$Blurbs$blurbs.bI))
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-topic-header__subtitle'),
									$author$project$CssUtils$qa('select_topic_subtitle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									'* ' + translate($author$project$SelectTopic$Blurbs$blurbs.bB))
								]))
						])),
					A2(
					$author$project$Forms$Select$select,
					_List_fromArray(
						[
							$author$project$Forms$Select$onSelect($author$project$SelectTopic$Data$SelectLevel),
							$author$project$Forms$Select$class('c-topic-header__level'),
							$author$project$Forms$Select$qa('select_topic_level')
						]),
					options)
				]));
	});
var $author$project$SelectTopic$Data$OpenRequestTopic = {$: 7};
var $author$project$SelectTopic$Data$UpdateCustomTopic = function (a) {
	return {$: 8, a: a};
};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$Attributes$maxlength = function (n) {
	return A2(
		_VirtualDom_attribute,
		'maxlength',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Attributes$rows = function (n) {
	return A2(
		_VirtualDom_attribute,
		'rows',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$SelectTopic$View$customTopicTextArea = F2(
	function (_v0, model) {
		var translate = _v0.gS;
		var max = 100;
		var remaining = A2(
			$elm$core$Maybe$withDefault,
			max,
			A2(
				$elm$core$Maybe$map,
				function (topic) {
					return max - $elm$core$String$length(topic);
				},
				model.ah));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-custom-topic-w')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$textarea,
					_List_fromArray(
						[
							$elm$html$Html$Events$onInput($author$project$SelectTopic$Data$UpdateCustomTopic),
							$elm$html$Html$Attributes$id('custom_topic'),
							$elm$html$Html$Attributes$class('c-custom-topic c-input'),
							$elm$html$Html$Attributes$value(
							A2($elm$core$Maybe$withDefault, '', model.ah)),
							$elm$html$Html$Attributes$maxlength(100),
							$elm$html$Html$Attributes$placeholder(
							translate($author$project$SelectTopic$Blurbs$blurbs.ba)),
							$elm$html$Html$Attributes$rows(3),
							$author$project$CssUtils$qa('custom_topic_text')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-custom-topic__countdown'),
							$author$project$CssUtils$qa('custom_topic_countdown'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('-warn', remaining <= 10)
								]))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(remaining))
						]))
				]));
	});
var $elm$core$String$trimRight = _String_trimRight;
var $author$project$SelectTopic$View$requestTopic = F2(
	function (external, model) {
		var translate = external.gS;
		var trimmedTopic = A2($elm$core$Maybe$map, $elm$core$String$trimRight, model.ah);
		var customTopicArea = model.bD ? A2($author$project$SelectTopic$View$customTopicTextArea, external, model) : $author$project$HtmlUtils$empty;
		var btnType = model.bD ? $author$project$Button$dark : $author$project$Button$secondary;
		var btnEnabled = model.bD ? A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (topic) {
					return $elm$core$String$length(topic) > 0;
				},
				trimmedTopic)) : true;
		var _v0 = function (parts) {
			_v1$2:
			while (true) {
				if (parts.b) {
					if (parts.b.b) {
						if (!parts.b.b.b) {
							var c = parts.a;
							var _v2 = parts.b;
							var l = _v2.a;
							return _Utils_Tuple2(
								c,
								$elm$core$Maybe$Just(l));
						} else {
							break _v1$2;
						}
					} else {
						var c = parts.a;
						return _Utils_Tuple2(c, $elm$core$Maybe$Nothing);
					}
				} else {
					break _v1$2;
				}
			}
			return _Utils_Tuple2(
				'GE',
				$elm$core$Maybe$Just('0A'));
		}(
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Maybe$map,
					$elm$core$String$split('_'),
					model.aJ)));
		var course = _v0.a;
		var level = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-request'),
					$author$project$CssUtils$qa('custom_topic')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-request__line')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-request__txt'),
									$author$project$CssUtils$qa('custom_topic_label')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$SelectTopic$Blurbs$blurbs.bd))
								]))
						])),
					customTopicArea,
					A2(
					$author$project$Button$button,
					A3(
						$author$project$ListUtils$appendIf,
						btnEnabled,
						model.bD ? $author$project$Button$onClick(
							$author$project$SelectTopic$Data$SelectTopic(
								{
									a8: course,
									eC: $elm$core$Maybe$Nothing,
									fh: $elm$core$Maybe$Nothing,
									cF: $elm$core$Maybe$Nothing,
									aw: level,
									cW: A2($elm$core$Maybe$withDefault, '', trimmedTopic)
								})) : $author$project$Button$onClick($author$project$SelectTopic$Data$OpenRequestTopic),
						_List_fromArray(
							[
								$author$project$Button$class('c-request__btn'),
								$author$project$Button$disabled(!btnEnabled),
								btnType,
								$author$project$Button$filled,
								$author$project$Button$qa('custom_topic_button')
							])),
					_List_fromArray(
						[
							$elm$html$Html$text(
							translate($author$project$SelectTopic$Blurbs$blurbs.bC))
						]))
				]));
	});
var $author$project$SelectTopic$Data$ShowTopic = function (a) {
	return {$: 6, a: a};
};
var $author$project$Icons$InfoIconBlack$icon = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$width('16'),
			$elm$svg$Svg$Attributes$height('16')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M8 0a8 8 0 100 16A8 8 0 008 0zm.75 12.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v5.5zM8 5a1 1 0 110-2 1 1 0 010 2z'),
					$elm$svg$Svg$Attributes$fill('#191919'),
					$elm$svg$Svg$Attributes$fillRule('evenodd')
				]),
			_List_Nil)
		]));
var $author$project$SelectTopic$View$imageUrl = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map(
		function (u) {
			return '/_imgs/evc/pl/topic/238x238/' + u;
		}),
	$elm$core$Maybe$withDefault('https://source.unsplash.com/600x400/?education,business'));
var $author$project$SelectTopic$View$topicView = F2(
	function (_v0, topic) {
		var selectedTopic = _v0.di;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-topic'),
					A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
					$elm$html$Html$Events$onClick(
					$author$project$SelectTopic$Data$SelectTopic(topic)),
					$author$project$CssUtils$qa('topic_card'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'-selected',
							_Utils_eq(
								$elm$core$Maybe$Just(topic),
								selectedTopic))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-topic__img'),
							$author$project$CssUtils$qa('topic_img'),
							A2(
							$elm$html$Html$Attributes$style,
							'background-image',
							'url(' + ($author$project$SelectTopic$View$imageUrl(topic.cF) + ')'))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-topic__name'),
							$author$project$CssUtils$qa('topic_name')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(topic.cW)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-topic__info'),
							$author$project$CssUtils$qa('topic_info'),
							A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
							A2(
							$elm$html$Html$Events$stopPropagationOn,
							'click',
							$elm$json$Json$Decode$succeed(
								_Utils_Tuple2(
									$author$project$SelectTopic$Data$ShowTopic(topic),
									true)))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-topic__modal-trigger')
								]),
							_List_fromArray(
								[$author$project$Icons$InfoIconBlack$icon]))
						]))
				]));
	});
var $author$project$SelectTopic$View$topicsView = F2(
	function (model, data) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-topics')
				]),
			A2(
				$elm$core$List$map,
				$author$project$SelectTopic$View$topicView(model),
				data.gR));
	});
var $author$project$SelectTopic$View$loadedView = F3(
	function (external, model, data) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-select-topic')
				]),
			_List_fromArray(
				[
					A3($author$project$SelectTopic$View$header, external, model, data),
					A2($author$project$SelectTopic$View$topicsView, model, data),
					A2($author$project$SelectTopic$View$requestTopic, external, model)
				]));
	});
var $author$project$SelectTopic$View$view = F2(
	function (external, model) {
		var _v0 = model.ai;
		if (_v0.$ === 3) {
			var data = _v0.a;
			return A3($author$project$SelectTopic$View$loadedView, external, model, data);
		} else {
			return $author$project$SelectTopic$View$loadingView;
		}
	});
var $author$project$View$View$contentView = F2(
	function (external, model) {
		var bookings = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.d2;
				},
				model.ai));
		var _v0 = model.D;
		switch (_v0) {
			case 0:
				return A2(
					$author$project$HtmlUtils$when,
					A2(
						$elm$core$Basics$composeL,
						$elm$html$Html$map($author$project$Data$SelectTopicMsg),
						$author$project$SelectTopic$View$view(external)),
					model.bL);
			case 1:
				return A2(
					$author$project$HtmlUtils$when,
					A2(
						$elm$core$Basics$composeL,
						$elm$html$Html$map($author$project$Data$SelectTimeslotMsg),
						A2($author$project$SelectTimeslot$View$View$view, external, bookings)),
					model.w);
			case 2:
				return A2(
					$author$project$HtmlUtils$when,
					A2(
						$elm$core$Basics$composeL,
						$elm$html$Html$map($author$project$Data$ConfirmMsg),
						A2(
							$author$project$Confirm$View$view,
							external,
							$author$project$Data$getVideoOptions(model))),
					model.t);
			case 3:
				var _v1 = $author$project$Data$getLessonType(external);
				if (_v1.$ === 1) {
					return $author$project$SelectTimeslot$View$View$loadingView;
				} else {
					return $author$project$SelectTopic$View$loadingView;
				}
			case 4:
				return A2($author$project$View$View$cannotBook, external, $author$project$Blurbs$blurbs.b7);
			case 5:
				return A2($author$project$View$View$cannotBook, external, $author$project$Blurbs$blurbs.bk);
			default:
				return A2($author$project$View$View$cannotBook, external, $author$project$Blurbs$blurbs.b2);
		}
	});
var $author$project$Components$ProgressIndicator$CompleteStep = 2;
var $author$project$Components$ProgressIndicator$CurrentStep = 1;
var $author$project$Components$ProgressIndicator$DisabledStep = 3;
var $author$project$Components$ProgressIndicator$FutureStep = 0;
var $author$project$Components$ProgressIndicator$renderJoiner = F2(
	function (blocked, stepType) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-progress__joiner')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-progress__inner-joiner'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('-is-complete', (stepType === 2) || (stepType === 3)),
									_Utils_Tuple2('-is-blocked', blocked)
								]))
						]),
					_List_Nil)
				]));
	});
var $author$project$Components$ProgressIndicator$renderLabel = F4(
	function (blocked, action, stepType, label) {
		var navAction = function () {
			var _v0 = _Utils_Tuple2(blocked, stepType);
			if (_v0.a) {
				return $elm$core$Maybe$Nothing;
			} else {
				if (_v0.b === 2) {
					var _v1 = _v0.b;
					return action;
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}();
		return A2(
			$elm$html$Html$div,
			A2(
				$author$project$ListUtils$appendMaybe,
				A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, navAction),
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-progress__label'),
						$author$project$CssUtils$qa('progress_label'),
						A2($elm$html$Html$Attributes$attribute, 'role', 'button'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('-is-complete', (!blocked) && ((stepType === 2) || (stepType === 3))),
								_Utils_Tuple2('-is-current', (!blocked) && (stepType === 1)),
								_Utils_Tuple2('-is-disabled', (!blocked) && (stepType === 3)),
								_Utils_Tuple2('-is-blocked', blocked)
							]))
					])),
			_List_fromArray(
				[
					$elm$html$Html$text(label)
				]));
	});
var $author$project$Components$ProgressIndicator$renderStep = F2(
	function (blocked, stepType) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-progress__step'),
					$author$project$CssUtils$qa('progress_step'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('-is-current', stepType === 1),
							_Utils_Tuple2('-is-complete', (stepType === 2) || (stepType === 3)),
							_Utils_Tuple2('-is-blocked', blocked)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-progress__step-inner'),
							$author$project$CssUtils$qa('progress_step_inner'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('-is-complete', (stepType === 2) || (stepType === 3)),
									_Utils_Tuple2('-is-blocked', blocked)
								]))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-progress__step-outer'),
							$author$project$CssUtils$qa('progress_step_outer'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('-is-current', stepType === 1),
									_Utils_Tuple2('-is-blocked', blocked)
								]))
						]),
					_List_Nil)
				]));
	});
var $author$project$Components$ProgressIndicator$view = F3(
	function (_v0, blocked, model) {
		var translate = _v0.gS;
		var stepType = function (s) {
			if (s.a.$ === 1) {
				return 3;
			} else {
				var idx = s.a.a;
				return _Utils_eq(idx, model.o) ? 1 : ((_Utils_cmp(idx, model.o) < 0) ? 2 : 0);
			}
		};
		var stepsHtml = $elm$core$List$concat(
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (idx, s) {
						var st = stepType(s);
						var step = A2($author$project$Components$ProgressIndicator$renderStep, blocked, st);
						return (idx < 2) ? _List_fromArray(
							[
								step,
								A2($author$project$Components$ProgressIndicator$renderJoiner, blocked, st)
							]) : _List_fromArray(
							[step]);
					}),
				model.aM));
		var labels = A2(
			$elm$core$List$indexedMap,
			F2(
				function (idx, step) {
					var msg = step.a;
					var blurb = step.b;
					return A4(
						$author$project$Components$ProgressIndicator$renderLabel,
						blocked,
						((_Utils_cmp(idx, model.o) < 0) && (!_Utils_eq(
							msg,
							$author$project$Components$ProgressIndicator$NoOp(idx)))) ? $elm$core$Maybe$Just(msg) : $elm$core$Maybe$Nothing,
						stepType(step),
						translate(blurb));
				}),
			model.aM);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-banner__subtitle')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-progress')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-progress__steps')
								]),
							stepsHtml),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-progress__labels')
								]),
							labels)
						]))
				]));
	});
var $author$project$View$Banner$view = F2(
	function (external, model) {
		var translate = external.gS;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-banner-w')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-banner l-container')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-banner__title'),
									$author$project$CssUtils$qa('banner_title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									translate($author$project$Blurbs$blurbs.aY))
								])),
							A2(
							$elm$html$Html$map,
							$author$project$Data$ProgressMsg,
							A3($author$project$Components$ProgressIndicator$view, external, model.aX, model.l))
						]))
				]));
	});
var $author$project$View$View$view = F2(
	function (external, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-pl-page')
				]),
			_List_fromArray(
				[
					A2($author$project$View$Banner$view, external, model),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('l-container'),
							$author$project$CssUtils$qa('privateclass-content')
						]),
					_List_fromArray(
						[
							A2($author$project$View$View$announcement, external, model),
							A2($author$project$View$View$contentView, external, model)
						]))
				]));
	});
var $author$project$Main$main = A6($author$project$Scaffold$bootstrap, $author$project$Blurbs$allBlurbs, $author$project$View$View$view, $author$project$HtmlUtils$empty, $author$project$Control$init, $author$project$Control$update, $author$project$Control$subscriptions);
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));